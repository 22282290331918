import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import ResetPassword from "./ResetPassword";

const Receta = props => {
    const elements = React.useMemo(()=>[
        {
            type: "text",
            name: "nombre",
            label: "Nombre",
        },
        {
            type: "text",
            name: "correo",
            label: "Correo",
        },
        {			
          component: ResetPassword,
          name: "password",
          label: "Restablecer contraseña",
        }
  ],[]);
  return (
    <>
      <ModalForm elements={elements} onChange={props.onChange} data={props.data} {...props}>
			</ModalForm>
    </>
  );
};

export default Receta;
