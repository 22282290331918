import React from "react";
import styles from "Styles.module.scss";
import { AppBar, Tabs, Tab, Typography, Box, Button } from "@mui/material";
import withPreventLeave from "components/HOCs/withPreventLeave";

import Antropometria from "./Antropometria";
import Bioquimicos from "./Bioquimicos";
import SeleccionarDieta from "./SeleccionarDieta";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useSave from "Hooks/useSave";

import General from "./General";
import { useWindowSize } from "hooks";
import {CONSULTATION} from "store/constants";
import AutoSaveStatus from "components/AutoSaveStatus/AutoSaveStatus";
function TabPanel(props) {
	const { children, value, index, ...other } = props;
  
	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</Typography>
	);
}

const TabsConsultas = props => {
	const {innerWidth} = useWindowSize();
	const [value, setValue] = React.useState(0);
	const [viewAllConsultation, setViewAllConsultation] = React.useState(false);
	let match = useRouteMatch();

	const selectorData = useSelector(state =>
    state["CONSULTATION"]?.data[match.params.idPaciente]?.find(
      data => data._id === match.params.idConsulta
    )
	);
	const {data, onChange, onFile, remove, save, status} = useSave({type:CONSULTATION, autoSave:true, data:selectorData, parent_id:match.params.idPaciente});
	React.useEffect(()=>{
		if(status.status!=="LOADED"){
			props.canLeave(false);
		}else{
			props.canLeave(true);
		}
	},[status.status]);


	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	return (
		<>
			<>

				<Tabs
					value={value}
					onChange={handleChange}
					variant={innerWidth < 769 ? "scrollable" : "fullWidth"}
					scrollButtons={innerWidth < 769 ? "on" : "auto"}
					className={styles.flexContainer}
					indicatorColor="primary"
				>
					<Tab label="2.1 General" />
					<Tab label="2.2 Antropometría" />
					<Tab label="2.3 Dieta" />
					<Tab label="2.4 Bioquímicos" />
				</Tabs>
				<AutoSaveStatus status={status} save={save} />

				<TabPanel value={value} index={0}>
					<General
						data={data}
						onChange={onChange}
						idPaciente={match.params.idPaciente}
						idConsulta={match.params.idConsulta}
					/>
				</TabPanel>
				<TabPanel value={value} index={1}>
					<Antropometria
						data={data}
						onChange={onChange}
						idPaciente={match.params.idPaciente}
						idConsulta={match.params.idConsulta}
					/>
				</TabPanel>
				<TabPanel value={value} index={2}>
					<SeleccionarDieta
						data={data}
						onChange={onChange}
						type={CONSULTATION}
						url={`patients/${match.params.idPaciente}/consultations/${match.params.idConsulta}`}
						id={match.params.idConsulta}
						idPaciente={match.params.idPaciente}
						idConsulta={match.params.idConsulta}
					/>
				</TabPanel>
				<TabPanel value={value} index={3}>
					<Bioquimicos
						data={data}
						onChange={onChange}
						idPaciente={match.params.idPaciente}
						idConsulta={match.params.idConsulta}
					/>
				</TabPanel>
			</>
			{/* )} */}
		</>
	);
};
export default withPreventLeave(TabsConsultas);

// import React from "react";
// import styles from "Styles.module.scss";
// import { AppBar, Tabs, Tab, Typography, Box, Button } from "@mui/material";

// import Antropometria from "./Antropometria";
// import Bioquimicos from "./Bioquimicos";
// import SeleccionarDieta from "./SeleccionarDieta";
// import { useHistory, useRouteMatch } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";

// import General from "./General";
// import MyTabs from "components/Tabs/Tabs";
// import { useWindowSize } from "hooks";
// import {CONSULTATION} from "store/constants";

// function TabPanel(props) {
// 	const { children, value, index, ...other } = props;
  
// 	return (
// 		<Typography
// 			component="div"
// 			role="tabpanel"
// 			hidden={value !== index}
// 			id={`simple-tabpanel-${index}`}
// 			aria-labelledby={`simple-tab-${index}`}
// 			{...other}
// 		>
// 			{value === index && <Box p={3}>{children}</Box>}
// 		</Typography>
// 	);
// }

// const TabsConsultas = props => {
// 	const {innerWidth} = useWindowSize();
// 	const [value, setValue] = React.useState(0);
// 	const [viewAllConsultation, setViewAllConsultation] = React.useState(false);
// 	let match = useRouteMatch();

// 	const data = useSelector(state =>
//     state["CONSULTATION"]?.data[match.params.idPaciente]?.find(
//       data => data._id === match.params.idConsulta
//     )
// 	);
// 	const dispatch = useDispatch();

// 	const handleChange = (event, newValue) => {
// 		setValue(newValue);
// 	};
// 	return (
// 		<>
// 			<>
// 				<Tabs
// 					value={value}
// 					onChange={handleChange}
// 					variant={innerWidth < 769 ? "scrollable" : "fullWidth"}
// 					scrollButtons={innerWidth < 769 ? "on" : "auto"}
// 					className={styles.flexContainer}
// 					indicatorColor="primary"
// 				>
// 					<Tab label="General" />
// 					<Tab label="Antropometría" />
// 					<Tab label="Dieta" />
// 					<Tab label="Bioquímicos" />
// 				</Tabs>
// 				<TabPanel value={value} index={0}>
// 					<General
// 						data={data}
// 						idPaciente={props.idPaciente}
// 						idConsulta={match.params.idConsulta}
// 					/>
// 				</TabPanel>
// 				<TabPanel value={value} index={1}>
// 					<Antropometria
// 						data={data}
// 						idPaciente={props.idPaciente}
// 						idConsulta={match.params.idConsulta}
// 					/>
// 				</TabPanel>
// 				<TabPanel value={value} index={2}>
// 					<SeleccionarDieta
// 						data={data}
// 						type={CONSULTATION}
// 						url={`patients/${match.params.idPaciente}/consultations/${match.params.idConsulta}`}
// 						id={match.params.idConsulta}
// 						idPaciente={props.idPaciente}
// 						idConsulta={match.params.idConsulta}
// 					/>
// 				</TabPanel>
// 				<TabPanel value={value} index={3}>
// 					<Bioquimicos
// 						data={data}
// 						idPaciente={props.idPaciente}
// 						idConsulta={match.params.idConsulta}
// 					/>
// 				</TabPanel>
// 			<MyTabs tabs={tabs} urlKey="seccion" defParams={{idPaciente: match.params.idPaciente, idConsulta: match.params.idConsulta, data: data, type: CONSULTATION, url: `patients/${match.params.idPaciente}/consultations/${match.params.idConsulta}` }}></MyTabs>
// 			</>
// 			{/* )} */}
// 		</>
// 	);
// };

// export default TabsConsultas;

// const tabs=[{
// 	label:"2.1 General",
// 	value:"recetario",
// 	component: General,
// },{
// 	label:"2.2 Antropometría",
// 	value:"categoria",
// 	component:Antropometria,
// },{
// 	label:"2.3 Dieta",
// 	value:"sellos",
// 	component:SeleccionarDieta,
// },{
// 	label:"2.4 Bioquímicos",
// 	value:"a",
// 	component:Bioquimicos,
// }];