import React,{useEffect, useState} from "react";
import { Prompt } from 'react-router'

const withPreventLeave = (Component) => (props)=>{
	const [canLeave, setCanLeave] = useState(true);
	useEffect(()=>{
		if (!canLeave) {
			window.onbeforeunload = () => "¿Hay cambios sin guardar, seguro que deseas salir?";
		} else {
			window.onbeforeunload = undefined;
		}
	});
	useEffect(()=>{
		console.log(canLeave);
	},[canLeave]);

	return <>
		<Prompt
			when={!canLeave}
			message={()=>"¿Hay cambios sin guardar, seguro que deseas salir?"}
		/> 
		<Component {...props} canLeave={setCanLeave} />

	</>;
};
  
//   return (
//     <Route {...rest} render={props =>
//       !isAuthenticated ? (
//         <Redirect to='/login'/>
//       ) : (
//         <Component {...props} />
//       )
//     }
//     />
//   );
// };
// export default PrivateRoute;

// function withPreventLeave(Component){
// 	return function Component(props){
		
		
// 		return <>
// 			<Component {...props} canLeave={setCanLeave}  />
			
// 		</>;
// 	};
// }
export default withPreventLeave;