import React,{useState} from "react";
import {Link, Grid, Box, Hidden, Paper, Typography, Container, Checkbox, 
	TextField, FormControlLabel, CssBaseline, Button, Avatar, CircularProgress} from "@mui/material";
import {Alert} from "@mui/lab";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { makeStyles } from "@mui/styles";
import { useImmer } from "use-immer";
import {post} from "api";
import loader from "api/loader";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

export default function SignIn() {
	const classes = useStyles();
	let history = useHistory();
	const [user, setUser] = useImmer({correo:"",password:""});
	const [status, setStatus] = useState({status:"ready"});

	const handleLogin = (e) => {
		e.preventDefault();
		setStatus({status:"loading"});
		post("superadmin-login",user).then(r=>{
			loader.login(r.data.token);
			history.replace("/pacientes");
		}).catch(e=>{
			console.log(e.message);
			setStatus({status:"error",message:e});
		});
		console.log(user);
	};
	return (
		<Grid container spacing={0}>
        
			<Hidden smDown>
				<Grid item xs>
					<img src={require("assets/img/login.png")}
						alt="imagen principal"
						style={{ width: "100%",
							height: "100vh",
							objectFit: "cover"}}
					/>
				</Grid>
			</Hidden>
			<Grid item md={6} sm={12}>
				<Container component="main" maxWidth="xs">
					<CssBaseline />
					<div className={classes.paper}>
						<img src={require("assets/img/logo/logo_nombre.png")}
							alt="imagen principal"
							style={{ 
								height: "130px",
								marginBottom:"30px",
								objectFit: "cover"}}
						/>
						<Typography color="primary" component="h1" variant="h5">
							INGRESA TUS DATOS DE ACCESO
						</Typography>
						<Typography style={{textAlign:"center"}} color="secondary">
							Recuerda que estos son los datos para acceder como nutriólogo y no a la App para pacientes.
						</Typography>
						<form className={classes.form} noValidate>
						
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="email"
								label="Correo"
								name="email"
								autoComplete="email"
								autoFocus
								value={user.correo}
								onChange={(e)=>{
									e.persist();
									setUser((draft)=>{draft.correo = e.target.value;});
								}}
							/>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								name="password"
								label="Contraseña"
								type="password"
								id="password"
								autoComplete="current-password"
								value={user.password}
								onChange={(e)=>{
									e.persist();

									let value = e.target.value;
									setUser((draft)=>{draft.password = value;});
								}}
							/>
							{status.status==="error" &&
                  <Alert severity="error">{status.message}</Alert>
							}
							{status.status==="loading" ?
								<CircularProgress />
								:
								<Button
									type="submit"
									fullWidth
									variant="contained"
									color="primary"
									onClick={handleLogin}
								>
                    Iniciar sesión
								</Button>
							}
                
                
							<Grid style={{marginTop:"17px"}} container>
								<Grid item xs>
									<Link href="/forgot" variant="body2">
                     ¿Olvidaste tu contraseña?
									</Link>
								</Grid>
								{/* <Grid item>
                    <Link href="#" variant="body2">
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid> */}
							</Grid>
						</form>
					</div>
				</Container>
			</Grid>
		</Grid>
     
    
	);
}