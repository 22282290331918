import React, {useRef, useEffect, useState} from "react";
import BotonVerApp from "assets/img/buttons/boton-ver-app.png";
import { Card, Typography, Modal, Fade, Button } from "@mui/material";
import styles from "Styles.module.scss";
import "./AppView.scss";

export default function AppView({img}){
	const [openModal,setOpenModal] = useState(false);
	const handleModal = () => {
		setOpenModal((state)=>setOpenModal(!state));
	};
	
	return(
		<>
			<button style={{background: "none", border: "none", margin: "0 10px", display: "flex"}} onClick={()=>setOpenModal(true)}><img src={BotonVerApp} style={{ width: "40px", height: "36px" }} alt="vista-app" /></button>
			<Modal open={openModal} onClose={handleModal} className={styles.modal}>
				<Fade in={openModal}>
					<div className={styles.paper}>
						<div style={{textAlign: "center"}}>
							<img src={BotonVerApp} style={{width: "50px"}} alt="boton-ver-app"/>
							<h2 style={{margin: "15px 0"}}>Esta sección aparece en la App de tu paciente de la siguiente manera:</h2>
							<img src={require(`assets/img/vistas-app-paciente/${img}.gif`)} alt="app-paciente" width="250px"/>
						</div>
						<div style={{textAlign: "right"}}>
							<Button variant="contained" color="secondary" onClick={()=>setOpenModal(false)}>Cerrar</Button>
						</div>
					</div>
				</Fade>
			</Modal>
		</>
	);
}