import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import PropTypes from "prop-types";

const Categorias = props => {    
	const elements = [
		{
			type: "text",
			name: "nombre",
			label: "Nombre",
		}
	];
	return (
		<>
			<ModalForm elements={elements} onChange={props.onChange} data={props.data}></ModalForm>
		</>
	);
};

export default Categorias;

Categorias.propTypes = {
	onChange: PropTypes.func,
	data: PropTypes.object
};