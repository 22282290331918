import React from "react";
import { Route, Switch } from "react-router-dom";
import { Button } from "@mui/material";
import CrudTable from "../../../../components/Table/CrudTable/CrudTable";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import DateCell from "components/Table/CellTypes/DateCell/DateCell";

import TabsConsultas from "./TabsConsultas";
import {CONSULTATION} from "store/constants";
import { useCallback } from "react";
const TablaConsultas = props => {
	const history = useHistory();

	const data = useSelector(state => state[CONSULTATION.name]);
	const myData = data.data[props.idPaciente] || [];
	console.log(data,myData,props)

	const dispatch = useDispatch();

	let match = useRouteMatch();

	const columns = React.useMemo(
		() => [
			{
				Header: "Fecha de consulta",
				accessor: "fecha",
				Cell: DateCell
			},
			{
				Header: "Peso",
				accessor: "peso"
			},
			{
				Header: "% grasa",
				accessor: "p_grasa"
			},
			{
				Header: "IMC",
				accessor: "imc"
			},
			{
				Header: "Dieta",
				accessor: "dieta.nombre"
			},
			{
				Header: "Calificación dieta",
				accessor: "calificacion_dieta"
			},
			{
				Header: "Calificación ejercicio",
				accessor: "calificacion_ejercicio"
			}
			
		],
		[]
	);
	useEffect(() => {
		dispatch({ type: "LOAD_CONSULTATION", idPaciente: props.idPaciente })
		// if(myData.length < 1) insertarConsulta();
	}, []);
	// useEffect(() => {
	// 	if (data.view.status === "INSERTED") {
	// 		dispatch({
	// 			type: "CONSULTATION_STATUS",
	// 			payload: {status:"LOADED"}
	// 		});
		
	// 	}
	// }, [data.view]);
	
	const insertarConsulta = () => {
		dispatch({ 
			type: "INSERT_CONSULTATION", 
			idPaciente: props.idPaciente,
			callback:(d)=>history.push(`${match.url}/consulta/${d.data._id}`)
		});
		// dispatch({ type: "UPDATE_PATIENTS", payload: {ultima_consulta: new Date().toISOString(),_id: props.idPaciente}});
	};
	const irAConsulta = id => {
		return history.push(`${match.url}/consulta/${id}`);
	};
	
	
	return (
		<>
			<Switch>
				<Route
					exact
					path="/paciente/:idPaciente/1"
					render={() => (
						<CrudTable
							_id={props.idPaciente}
							data={myData}
							columns={columns}
							type={CONSULTATION}
							status={data.view}
							options={{
								defaultSorting:[{
									id: "fecha",
									desc: true
								}]
							}}
							editAction={(cell) => irAConsulta(cell.row.original._id)}
							insertAction={() => insertarConsulta()}
							cantDeleteFirst={true}
						/>
					)}
				/>
				<Route
					exact
					path="/paciente/:idPaciente/1/consulta/:idConsulta"
					render={() => <TabsConsultas />}
				/>
			</Switch>
		</>
	);
};

export default TablaConsultas;
