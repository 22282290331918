import React, { useRef, useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
// import ProgressBar from "@ramonak/react-progress-bar";
import ReactSlider from "react-slider";
import i18n from "i18n";


import Lock from "@mui/icons-material/Lock";
import LockOpen from "@mui/icons-material/LockOpen";

const StyledSlider = styled(ReactSlider)`
    width: 85%;
    height: 25px;
		margin-right: 5px;
`;

const StyledThumb = styled.div`
    height: 25px;
    line-height: 25px;
    width: 25px;
    text-align: center;
    background-color: ${props => props.color};
    color: #fff;
    border-radius: 50%;
    cursor: grab;
`;

// const Thumb = (props, state) =>  <StyledThumb {...props}></StyledThumb>;

const StyledTrack = styled.div`
    top: 0;
    bottom: 0;
    background: ${props => props.index === 1 ? "#E8E8E8" : props.color};
    border-radius: 999px;
// 		-webkit-box-shadow: inset 0px 9px 12px 2px rgba(0,0,0,0.69); 
// box-shadow: inset 0px 9px 12px 2px rgba(0,0,0,0.69);
		
`;

const Text = styled.div`
  position: absolute;
  width: ${props=>props.value > 3 ? props.value : null }%;
  text-align: right;
	color: white;
	font-weight: bold;
	z-index: 100;
	padding-right: 10px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 100%;
	cursor: grab;
`;

const Track = ({props, state}) => {
	return <div style={{width:"100%"}}>
		<Text value={state.value}>{state.value}%</Text>
		<StyledTrack {...props} index={state.index} color={props.color} />
	</div>;
};

// function Slider({percent, color, disabled}){
// 	return <StyledSlider
// 		defaultValue={percent}
// 		renderTrack={(props, state)=><Track props={{...props, color}} state={state} />}
// 		renderThumb={Thumb}
// 	/>;
// }
function Block({blocked, onToggle}){
	return <div onClick={onToggle} style={{width: "3%", marginRight: 10, textAlign: "center"}}>
		{blocked 
			? 
			<Lock color="secondary" />
			:	
			<LockOpen color="secondary" />
		}
			
	</div>;
}
const macros = {
	prot:{ color:"#E77067", label: i18n.t("common:proteinas"), name:"prot" },
	lip:{ color:"#F7D542", label: i18n.t("common:lipidos"), name:"lip" },
	hidr:{ color:"#7BAAFB", label: i18n.t("common:hidratos_carbono"), name:"hidr" }
};
export default function SetMacros({onChange, kcal, day, prevValue}){
	
	// const [confDias, setConfDias] = useState(value ?? []);
	const [macros, setMacros] = useState(prevValue?.percentage ?? {prot:0, hidr:0, lip:0});
	const [gramos, setGramos] = useState(prevValue ?? {prot:0, hidr:0, lip:0});
	const [blocked, setBlocked] = useState({prot:false, hidr:false, lip:false});

	const change = macro => value =>{
		const nombres = ["lip", "prot", "hidr"].filter(m=>m!==macro);
		let block = false;
		nombres.forEach(n=>{
			if(blocked[n])block = n;
		});
		let result;	
		if(!block){
			let porcRestante = 100 - value;
			result = nombres.reduce((acc, nombre)=>{
				return {...acc, [nombre]:porcRestante/2};
			},{[macro]:value});
			setMacros(()=>result);
		}else{
			let porcRestante = 100 - value - macros[block];
			if(porcRestante<0)porcRestante = 0;
			result = nombres.reduce((acc, nombre)=>{
				if(blocked[nombre]){
					return {...acc, [nombre]:macros[nombre]};
				}else{
					return {...acc, [nombre]:porcRestante};
				}
			},{});
			result[macro] = Object.values(result).reduce((acc, v)=>acc-v,100);
			setMacros(()=>result);
		}
	};
	useEffect(()=>{
		let conf = [];
		const kcalPorMacro = Object.entries(macros).reduce((acc, [k, porc])=>({...acc, [k]: porc/100 * (kcal ?? gramos.kcal) }),{});
		const equival = Object.entries(kcalPorMacro).reduce((acc, [k, value])=>{
			let gramos = 0;
			if(k==="prot") gramos = 4;
			if(k==="lip") gramos = 9;
			if(k==="hidr") gramos = 4;
			return {...acc, [k]: value/gramos};
		},0);
		
		
		for(let i=0;i<7;i++){
			conf.push({...equival, kcal });
		}
		
		setGramos(equival);
		onChange && onChange(kcalPorMacro, "pieChartData");
		if(day){
			onChange({...equival, kcal}, "conf");
			return;
		}
		onChange && onChange(conf, "conf_dias");
	},[macros, kcal]);
	const onToggleBlock = (macro) => () => {
		setBlocked(Object.fromEntries(Object.entries(blocked).map(([k, v])=>{
			if(k===macro)return [k, !v];
			else return [k, false];
		})));
	};
	
	return <>
		<Macro macro="prot" onChange={change} gramos={gramos.prot} value={macros.prot} blocked={blocked.prot} onToggle={onToggleBlock}  />
		<Macro macro="lip" onChange={change} gramos={gramos.lip} value={macros.lip} blocked={blocked.lip} onToggle={onToggleBlock}  />
		<Macro macro="hidr" onChange={change} gramos={gramos.hidr} value={macros.hidr} blocked={blocked.hidr} onToggle={onToggleBlock}  />
	</>;
}
function Macro({ onChange, value, blocked, onToggle, macro, gramos }){
	const color = React.useMemo(()=>{
		let color;
		switch(macro){
		case "prot": color = "red"; break;
		case "lip": color = "blue"; break;
		case "hidr": color = "green"; break;
		}
		return color;
	},[macro]);
	return <div>
		<div style={{fontWeight: "bold", margin: "10px 0 5px 0"}}>{macros[macro].label}</div>
		<StyledMacro>
			<Block st blocked={blocked} onToggle={onToggle(macro)} />
			<StyledSlider
				value={value}
				onChange={onChange(macro)}
				renderTrack={(props, state)=><Track props={{...props, color:macros[macro].color}} state={state} />}
				renderThumb={(props, state)=><StyledThumb {...props} color={macros[macro].color} />}
			/>
			<div style={{width:"7%", textAlign: "center"}}>{!isNaN(gramos) ? gramos.toFixed(2) : 0}gr</div>

			
		</StyledMacro>
	</div>;
}

const StyledMacro = styled.div`
	display:flex;
	justify-content: flex-start;
	align-items: center;
	/* justify-content: flex-start; */
`;