import React, {useState, useEffect} from "react";
import Button from "../../components/Buttons/Button";
import ButtonMaterialUi from "@mui/material/Button";

import DehazeIcon from "@mui/icons-material/Dehaze";
import SyncIcon from "@mui/icons-material/Sync";

import "./NavBar.scss";

import Pacientes from "../../assets/img/icons/pacientes.png";
import PacientesOnline from "../../assets/img/pacientes_online.png";
import Citas from "../../assets/img/icons/citas.png";
import Mensajes from "../../assets/img/icons/mensajes.png";
import Dietas from "../../assets/img/icons/dietas.png";
import Premios from "../../assets/img/icons/premios.png";
import MiRecetario from "../../assets/img/icons/recetario.png";
import ProductosQueAmamos from "../../assets/img/amamos.png";
import Configuracion from "../../assets/img/icons/configuracion.png";
import Notificaciones from "../../assets/img/icons/notificaciones.png";
import RecomiendaYGana from "../../assets/img/icons/recomienda.png";
import Logo from "../../assets/img/logo/logo_sin_texto.png";

import { useWindowSize } from "../../hooks";
import { Icon } from "@mui/material";
import ConsultasOnlineButton from "components/Header/ConsultasOnlineButton";
import RefreshButton from "components/Header/RefreshButton";
import CerrarSesionButton from "components/Header/CerrarSesionButton";

const NavBar = () => {
  

	const {innerWidth} = useWindowSize();

	const [collapsed, setCollapsed] = useState(innerWidth < 769 );

	useEffect(() => {
		setCollapsed( innerWidth < 769 );
	}, [innerWidth]);

	const onClick = () => {
		if(innerWidth < 769) setCollapsed(!collapsed);
	};

	return (
		<div className="container-navbar">
			<div className="logo-navbar">
				<img src={Logo} alt="logo" />
				{
					innerWidth < 769 &&
        <div className="button-collapsed">
        	<ButtonMaterialUi onClick={()=> {setCollapsed(!collapsed);} }><Icon style={{color:"#ffffff"}}>dehazeIcon</Icon></ButtonMaterialUi>
        </div>
				}
			</div>

			{
				!collapsed &&
          <div className={"container-routes"}>
          	<Button onClick={onClick} path="/clientes" text="Clientes" image={Pacientes} link />
          	<Button onClick={onClick} path="/clientesPagando" text="Clientes Pagando" image={Pacientes} link />
          	<Button onClick={onClick} path="/clientesCalificacion" text="Clientes Calificacion" image={Pacientes} link />
          	<Button onClick={onClick} path="/clientesMensuales" text="Clientes Mensuales" image={Pacientes} link />
          	<Button onClick={onClick} path="/clientesRenovacion" text="Clientes Renovacion" image={Pacientes} link />
          	<Button onClick={onClick} path="/clientesPlazos" text="Clientes Plazos" image={Pacientes} link />
          	<Button onClick={onClick} path="/clientesAnalytics" text="Clientes analytics" image={Pacientes} link />
          	<Button onClick={onClick} path="/clientesContratacion" text="Clientes contratacion" image={Pacientes} link />
          	<Button onClick={onClick} path="/pacientes" text="Paciente" image={Pacientes} link />
          	<Button onClick={onClick} path="/infografias" text="Infografias" image={Pacientes} link />
          	{/* <Button onClick={onClick} path="/pacientesonline" text="Pacientes online" image={PacientesOnline} link /> */}
          	<Button onClick={onClick} path="/dietas" text="Dietas" image={Dietas} link />
          	<Button onClick={onClick} path="/premios" text="Premios" image={Premios} link />
          	<Button onClick={onClick} path="/recetas" text="Recetario" image={MiRecetario} link />
          	<Button onClick={onClick} path="/productos" text="Productos" image={ProductosQueAmamos} link />
          	<Button onClick={onClick} path="/planes" text="Planes" image={Configuracion} link />
          	<Button onClick={onClick} path="/cupones-planes" text="Cupones" image={Configuracion} link />
          	<Button onClick={onClick} path="/descuentos" text="Descuentos" image={Configuracion} link />
          	<Button onClick={onClick} path="/nutriologos" text="Nutriologos" image={Configuracion} link />
          	<Button onClick={onClick} path="/online-consultations" text="Consultas Online" image={Configuracion} link />
          	<Button onClick={onClick} path="/mensajes" text="Mensajes pac. online" image={Configuracion} link />
          	{/* <Button onClick={onClick} path="/mensajes-nutriologos" text="Mensajes nutriologos" image={Configuracion} link /> */}
          	<Button onClick={onClick} path="/configuracion" text="Configuraciones" image={Configuracion} link />
          	<Button onClick={onClick} path="/notificaciones" text="Notificaciones" image={Notificaciones} link />
          	<Button onClick={onClick} path="/tutoriales" text="Tutoriales" image={Notificaciones} link />
          	{/* <Button onClick={onClick} path="/recomienda" text="Recomienda y Gana" image={RecomiendaYGana} link /> */}
          	{innerWidth < 769 &&
              <>
              	<RefreshButton />
              	<CerrarSesionButton />
              </>
          	}
          </div>
			}
		</div>
	);
};

export default NavBar;
