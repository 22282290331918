import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import SelectAlimento from "./SelectAlimento/SelectAlimento";
import Select from "react-select";
import Modal from "components/Modal/Modal";
import Micronutrimentos from "./Micronutrimentos";
import NewButton from "components/Buttons/NewButton/NewButton";
import { MENU_SELECT_LIST } from "store/constants";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { get } from "api";
import MenuList from "./SelectAlimento/MenuList";
import Option from "./SelectAlimento/Option";
import AddAlimentoFromList from "./AddAlimentoFromList";
import {deepMult} from "functions/general";
import AddAlimentoMenuSelect from "../ListaAlimentos/AddAlimentoMenuSelect";
import _pick from "lodash.pick";
import { useQueryClient } from "react-query";

function AddComida({ onSave, editValue, hideHeader, newButtonsVariant="contained" }) {
	const { t } = useTranslation("common");
	const queryClient = useQueryClient();
	const [showModal, setShowModal] = useState(false);
	const [showModalCustomMedida, setShowModalCustomMedida] = useState(false);

	const [alimentoData, setAlimentoData] = useState({});
	const menuSelectList = useSelector((state) => state[MENU_SELECT_LIST.name].data);
	const { data: Final } = useQuery("equivalenciasMenu", () =>
		fetch("/Final.json").then((res) => res.json())
	);

	useEffect(() => {
		if (editValue) {
			const val = [...Object.values(Final), ...menuSelectList].flat().find((val) => val.id === editValue.id);
			const selectedAlimento = val ? {...val, selectValue:editValue} : {
				...editValue,
				selectValue:editValue,
				medidas: [{ ...editValue }],
			};
			setAlimentoData(selectedAlimento);
		}
	}, [editValue, Final, menuSelectList]);

	const handleSelectAlimento = async (data) => {
		await get(`${process.env.REACT_APP_API_URL}menu-select/medida/${data.id}`).then(({data: res}) => {
			const medidas = [...data.medidas, ...res];

			let defaultValue;
			if(medidas?.length > 1) defaultValue = medidas?.[1];
			else defaultValue = medidas?.[0];

			setAlimentoData({...data, _id:data._id, medidas,selectValue:defaultValue, ...defaultValue});
		});
	};

	const onChangeCantidad = (cant) => {
		const medida = alimentoData.medidas.find(m=>m.unidad===alimentoData.selectValue.unidad);
		const mult = cant/medida?.cant;
		const informacion = {
			gram: +(mult * (medida.gram||0)).toFixed(2),
			kcal: +(mult * (medida.kcal||0)).toFixed(2),
			prot: +(mult * (medida.prot||0)).toFixed(2),
			lip: +(mult * (medida.lip||0)).toFixed(2),
			hidr: +(mult * (medida.hidr||0)).toFixed(2),
			eq: deepMult(medida.eq, mult)

		};


		
		setAlimentoData((prev) => ({ ...prev, selectValue:{...prev.selectValue, ...informacion, cant} }));
	};

	const onMedidaChange = (selectedOption) => {
		if(selectedOption.unidad === "nueva medida") {
			setShowModalCustomMedida(true);
			return;
		}
		else{
			setAlimentoData((prev) => ({
				...prev,
				selectValue: selectedOption,
				unidad: selectedOption.unidad,
			}));
		}
	};

	const saveAlimento = () => {
		const d=_pick({...alimentoData, ...alimentoData.selectValue, _id: alimentoData._id}, [
			"_id", "id", "nombre", "cant", "categoria", "eq", "gram", "hidr", "kcal", "lip", "prot", "unidad","alimentos"
		]);

		onSave(d);
		setAlimentoData({});
	};

	const onInsertAlimento = () => {
		console.log("oninsert")
		queryClient.invalidateQueries("menu-select-alimentos")
	};
	return (
		<div style={{ marginTop: "10px" }}>
			{!hideHeader && (
				<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 5 }}>
					<p style={{ fontWeight: "bold" }}>Buscar alimentos</p>
					<div style={{ display: "flex", gap: 5 }}>
						<NewButton modal={AddAlimentoMenuSelect} variant={newButtonsVariant} options={{ alimentoPreparado: true }} fullWidth={false} type={MENU_SELECT_LIST} label="alimento preparado" onInsert={onInsertAlimento} />
						<NewButton modal={AddAlimentoMenuSelect} variant={newButtonsVariant} fullWidth={false} type={MENU_SELECT_LIST} onInsert={onInsertAlimento} />
					</div>
				</div>
			)}
			<SelectAlimento value={alimentoData} onChange={handleSelectAlimento} />
			{alimentoData.nombre && (
				<div style={{ marginTop: 10 }}>
					<p style={{ fontWeight: "bold" }}>{t("dieta_menu_select_add_comida_establece_cantidad")}</p>
					<div style={{ display: "grid", gridTemplateColumns: "1fr 4fr", textTransform: "capitalize" }}>
						<input type="number" min={0} value={alimentoData.selectValue?.cant || ""} onChange={(e) => onChangeCantidad( +e.target.value)} />
						<Select
							value={alimentoData.selectValue}
							options={[...alimentoData.medidas, ...(alimentoData.alimentos ? [] : [{unidad: "nueva medida", styles: {textDecoration: "underline", background: "#bace8288"}}])]}
							getOptionLabel={(option) => option.unidad}
							getOptionValue={(option) => option.unidad}
							onChange={onMedidaChange}
							placeholder={t("dieta_menu_select_add_comida_selecciona_medida")}
							components={{
								MenuList,
								Option
							}}
						/>
					</div>
				</div>
			)}
			{alimentoData.nombre && (
				<div style={{ textAlign: "right", marginTop: "5px" }}>
					<Button color="secondary" onClick={() => setShowModal(true)}>
						Ver Nutrientes
					</Button>
					<Button onClick={saveAlimento} color="secondary" variant="contained">
						{t("agregar")}
					</Button>
				</div>
			)}
			<Modal onClickClose={() => setShowModal(false)} open={showModal}>
				<Micronutrimentos alimento={alimentoData.selectValue} onClose={()=>setShowModal(false)} options={{chart: {legend: {show: true}}}} />
			</Modal>
			<Modal onClickClose={()=>setShowModalCustomMedida(false)} open={showModalCustomMedida} widthPaper="60%">
				<AddAlimentoFromList 
					alimento={alimentoData} 
					handleModal={data=>{
						setAlimentoData(data)
						setShowModalCustomMedida(false);
					}} 
				/>
			</Modal>
		</div>
	);
}

export default AddComida;
