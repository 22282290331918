import React from "react";
import Chart from "react-apexcharts";
import { colorByCategory } from "constants/index";
import i18n from "i18n";
import { useTranslation } from "react-i18next";

const dias = [
	{ name: "monday", label: i18n.t("common:modal_dias_laborales_lunes") },
	{ name: "tuesday", label: i18n.t("common:modal_dias_laborales_martes") },
	{ name: "wednesday", label: i18n.t("common:modal_dias_laborales_miercoles") },
	{ name: "thursday", label: i18n.t("common:modal_dias_laborales_jueves") },
	{ name: "friday", label: i18n.t("common:modal_dias_laborales_viernes") },
	{ name: "saturday", label: i18n.t("common:modal_dias_laborales_sabado") },
	{ name: "sunday", label: i18n.t("common:modal_dias_laborales_domingo") },
];

function ChartGrupos({alimentos, dia}) {
	const {t} = useTranslation("common");
	const grupos = 	React.useMemo(()=>alimentos.reduce((acc, val)=>{
		if(val.categoria){
			acc[val.categoria].cantidad++;
			acc[val.categoria].kcal += val.kcal;
			acc[val.categoria].prot += val.prot;
			acc[val.categoria].lip += val.lip;
			acc[val.categoria].hidr += val.hidr;
		}
		return acc;
	},{
		verduras: { cantidad: 0, kcal: 0, prot: 0, lip: 0, hidr: 0},
		frutas: { cantidad: 0, kcal: 0, prot: 0, lip: 0, hidr: 0},
		cereales: { cantidad: 0, kcal: 0, prot: 0, lip: 0, hidr: 0},
		leguminosas: { cantidad: 0, kcal: 0, prot: 0, lip: 0, hidr: 0},
		proteinas: { cantidad: 0, kcal: 0, prot: 0, lip: 0, hidr: 0},
		lacteos: { cantidad: 0, kcal: 0, prot: 0, lip: 0, hidr: 0},
		grasas: { cantidad: 0, kcal: 0, prot: 0, lip: 0, hidr: 0},
		azucares: { cantidad: 0, kcal: 0, prot: 0, lip: 0, hidr: 0},
		alcohol: { cantidad: 0, kcal: 0, prot: 0, lip: 0, hidr: 0},
		libres: { cantidad: 0, kcal: 0, prot: 0, lip: 0, hidr: 0},
		preparados: { cantidad: 0, kcal: 0, prot: 0, lip: 0, hidr: 0}
	}),[alimentos]);
	
	let series = [{data: [grupos.verduras.cantidad, grupos.frutas.cantidad, grupos.cereales.cantidad, grupos.leguminosas.cantidad, grupos.proteinas.cantidad, grupos.lacteos.cantidad, grupos.grasas.cantidad, grupos.azucares.cantidad, grupos.alcohol.cantidad, grupos.libres.cantidad, grupos.preparados.cantidad]}];

	let options = {
		chart: {
			type: "bar",
			height: 300,
			toolbar:{
				show: false,
			}
		},
		plotOptions: {
			bar: {
				horizontal: true,
				distributed: true,
				borderRadius: 8,
				barHeight: "50%",
				dataLabels: {
					position: "top"
				}
			},
		},
		yaxis: {
			labels: {
				align: "left",
				maxWidth: "auto",
				// offsetX: 20,
				style: {
					colors: ["#70DC55", "#FC75BE", "#D79B57", "#627BF5", "#E46964", "#3FBDD9", "#F6D75D", "#FF784D", "#d91212", "#286272", "#2e7228"],
					fontWeight: "bold",
					fontSize: "12px"
				},
			},
		},
		xaxis: {
			categories: [t("grupos_verduras"), t("grupos_frutas"), t("grupos_cereales"), t("grupos_leguminosas"), t("grupos_proteinas"), t("grupos_lacteos"), t("grupos_grasas"), t("grupos_azucares"), t("grupos_alcohol"), t("grupos_libres"), t("grupos_preparados_2")],
			labels: {
				show: false
			},
			axisBorder: {
				show: false
			},
			axisTicks: {
				show: false,
			},
		},
		tooltip: {
			custom: function({series, seriesIndex, dataPointIndex, w}) {
				const valores = Object.values(w.config.grupos).map(val=>val);
				return `
				<div style="padding: 10px;">
					<div style="margin-bottom: 10px;">
						<p style="font-size: 19px; font-weight: bold; color: ${colorByCategory[w.globals.labels[dataPointIndex]]}">${w.globals.labels[dataPointIndex]}</p>
					</div>
					<div style="margin-bottom: 10px;">
						<p style="font-size: 17px; font-weight: bold;">Kcal totales por grupo</p>
						<p style="font-size: 15px; font-weight: bold;">${valores[dataPointIndex].kcal.toFixed(2)}</p>
					</div>
					<div>
						<div style="margin-bottom: 10px;">
							<p style="font-size: 15px; color: #E77067; font-weight: bold;">PROTEINAS</p>
							<p style="font-size: 13px; font-weight: bold;">${valores[dataPointIndex].prot.toFixed(2)}</p>
						</div>
						<div style="margin-bottom: 10px;">
							<p style="font-size: 15px; color: #F7D542; font-weight: bold;">LÍPIDOS</p>
							<p style="font-size: 13px; font-weight: bold;">${valores[dataPointIndex].lip.toFixed(2)}</p>
						</div>
						<div style="margin-bottom: 10px;">
							<p style="font-size: 15px; color: #7BAAFB; font-weight: bold;">HIDRATOS DE CARBONO</p>
							<p style="font-size: 13px; font-weight: bold;">${valores[dataPointIndex].hidr.toFixed(2)}</p>
						</div>
					</div>
				</div>
			`;
			},
			x: {
				show: false,
			},
			onDatasetHover: {
				highlightDataSeries: true,
			},
			fixed: {
				enabled: true,
				position: "bottomRight",
			},
		},
		colors: ["#70DC55", "#FC75BE", "#D79B57", "#627BF5", "#E46964", "#3FBDD9", "#F6D75D", "#FF784D", "#d91212", "#286272", "#2e7228"],
		dataLabels: {
			enabled: true,
			formatter: function(value, { seriesIndex, dataPointIndex, w }) {
				return value !== 0 ? w.config.series[seriesIndex].data[dataPointIndex] : "";
			},
			style: {
				fontSize: "12px",
				colors: ["#000"],
				fontWeight: "bold"
			},
			offsetX: 25,
		},
		legend: {
			show: false
		},
		fill: {
			opacity: 1
		},
		grid: {
			show: false
		},
	};
	
	return (
		<div>
			{typeof dia === "number" && <p style={{fontWeight: "bold", fontSize: "12pt"}}>{dias[dia].label}</p>}
			<Chart
				options={{...options, grupos}}
				series={series}
				type="bar"
				height="300"
			/>

		</div>
	);
}

export default ChartGrupos;
