import React from "react";
import CrudTable from "components/Table/CrudTable/CrudTable";
import Cliente from "./Cliente";
import { useSelector } from "react-redux";
import {CUSTOMER, PLAN} from 'store/constants'
import Header from "../../components/Header/Header";
import DateCell from "components/Table/CellTypes/DateCell/DateCell";
import BoolCell from "components/Table/CellTypes/BoolCell/BoolCell";
import RefFilter from "components/Table/FilterTypes/RefFilter/RefFilter";
import RefCell from "components/Table/CellTypes/RefCell/RefCell";
import { post } from "api";
import swal from "@sweetalert/with-react";
import { Button, Menu, MenuItem } from "@mui/material";
import {crudFunctions} from "api";
import { useQuery } from 'react-query'

const moment = require("moment");
const query = {
	"subscripcion.gratis":{$ne:true},
	"subscripcion.id_plan":{$nin:["5ec1da89d20c904e60c4617e","5ec1da89d20c904e60c4618d"]},
	"subscripcion.activo":true, 
	$or:[{"subscripcion.vencimiento":null},{"subscripcion.vencimiento":{$gt:new Date()}}]
};
const TablaClientes = props => {
	const [page, setPage] = React.useState();
	const [sort, setSort] = React.useState();
	const [filterData, setFilterData] = React.useState({});
	const actions = crudFunctions(CUSTOMER);
	const {data, status} = useQuery([CUSTOMER.name, "pagando", {page, filter:filterData, query, sort}], actions.getPaginated, {keepPreviousData:true});
	

	// const pagando = React.useMemo(
  //   () => data.data.filter(c=>{
	// 		if(c.subscripcion && !c.subscripcion.gratis){
	// 			if(!c.subscripcion.vencimiento || moment(c.subscripcion.vencimiento).isAfter(moment())){
	// 				if( c.subscripcion.activo && !["5ec1da89d20c904e60c4617e"].includes(c.subscripcion.id_plan)){
	// 					return true;
	// 				}
	// 		}
	// 	}
		
	// 	return false;
	// }),[data.data])

  const columns = React.useMemo(
    () => [
			// {
			// 	accessor: "_id",
			// 	Header: "Id",
			// },
      {
        Header: "Clave",
        accessor: "clave"
      },
      {
        Header: "Nombre",
        accessor: "nutritionist.nombre"
      },
      {
        Header: "Telefono",
        accessor: "nutritionist.telefono"
      },
      {
        Header: "Correo",
        accessor: "nutritionist.correo"
			},
			{
				Header: "Plan",
				accessor: "subscripcion.id_plan",
				type: PLAN,
				Cell: RefCell,
				filter:"ref",
				exportType: "ref"
			},
			{
        Header: "Tipo de pago",
        accessor: "subscripcion.tipo_pago"
			},
			{
        Header: "Capacitacion",
        accessor: "fecha_capacitacion",
				Cell:BoolCell,
				labelOnTrue:(cell)=>`${moment(cell.row.original.fecha_capacitacion).format("DD/MMM/YYYY")}`
			},
			
			{
        Header: "Vencimiento",
				accessor: "subscripcion.vencimiento",
				Cell:DateCell,
				filter:"daterange",
				sortType: "date"
			},
			{
        Header: "Ultima conexion",
				accessor: "ultima_conexion",
				Cell:DateCell,
				filter:"daterange",
				sortType: "date"
      }
    ],
    []
	);
	
	

  return (
    <>
			<Header title="Clientes" />

      <CrudTable
       	data={data?.data || []}
				status={status}

				columns={columns}
				filters={columns}
        type={CUSTOMER}
        modal={Cliente}
        options={{loadBeforeEdit:true, orderBy:true}}

				onFilterChange={setFilterData}
				onPaginationChange={setPage}
				onSortChanged={(s)=>setSort(s[0])}
				totalRows={data?.total}
				manualPagination
				actions={[ButtonsSendEmail]}
      />
			<div>{data?.total}</div>
    </>
  );
};

export default TablaClientes;

const ButtonsSendEmail = ({cell}) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const sendEmail = (data) => {
		post("/admin/send-email-subscription", {...data}).then(()=>swal({
			text: "Mensaje enviado exitosamente",
			icon: "success",
		}))
		handleClose();
	}

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
	return(
		<div>
			<Button aria-controls="simple-menu" aria-haspopup="true" variant="contained" onClick={handleClick}>
				Enviar correo
			</Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
				<MenuItem onClick={() => sendEmail({id_customer: cell.row.original._id, type: "pago"})}>Último pago</MenuItem>
				<MenuItem onClick={() =>sendEmail({id_customer: cell.row.original._id, type: "pago", subType: "renovacion"})}>Renovación</MenuItem>
			</Menu>
			{/* <Button variant="contained" color="primary" style={{fontSize: 10}} >Enviar correo último pago</Button>
			<Button variant="contained" color="primary" style={{fontSize: 10}}>Enviar correo renovación</Button> */}
		</div>
	)
}

// const ButtonSendEmailPago = ({cell}) => 
// const ButtonSendEmailRenovacion = ({cell}) => 