import React, {useEffect} from "react";

// import styled from 'styled-components'
import { useTable } from "react-table";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import update from "immutability-helper";
import ReorderIcon from "@mui/icons-material/Reorder";
import "./DragAndDropTable.scss";

const DND_ITEM_TYPE = "row";

// Our table component
export default function Table({ columns, data, onChange }) {
	const [records, setRecords] = React.useState(data);
	


	// console.log({skipPageReset:props.skipPageReset})
	const getRowId = React.useCallback(row => {
		return row._id || row.id || row.shortid;
	}, []);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		rows,
	} = useTable({
		columns: columns,
		data,
		getRowId
	});

	const moveRow = (dragIndex, hoverIndex) => {
		const dragRecord = data[dragIndex];
		onChange(
			update(data, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, dragRecord],
				],
			})
		);
		// onChange(records);
	};

	console.log({rows, data})
	// useEffect(()=>{
	// 	if(data !== records){
	// 		setRecords(data);
	// 	}
	// },[data]);

	// useEffect(() => {
	// 	onChange(records);
	// }, [records]);

	return (
		<DndProvider backend={HTML5Backend}>
			<table {...getTableProps()} className="dragndrop">
				<thead className="header">
					{headerGroups.map((headerGroup,idx) => (
						<tr key={idx} {...headerGroup.getHeaderGroupProps()}>
							<th></th>
							{headerGroup.headers.map(column => (
								<th
									key={column.id}
									className={(column.render("id") === "actions") ? "actions-table" : undefined}
									{...column.getHeaderProps()}
								>
									{column.render("Header")}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()} className="body">
					{rows.map((row, i) => 
						prepareRow(row) || (
							<Row
								key={i}
								index={i}
								row={row}
								moveRow={moveRow}
								{...row.getRowProps()}
							/>
						)
						// prepareRow(row);
						// return (
						// 	<tr onClick={(event)=>handleRowClick(row, event)} key={i} {...row.getRowProps()} className="container-element">
						// 		{props?.dragAndDrop && <MoveRow moveRow={moveRow} />}
						// 		{row.cells.map(cell => (
						// 			<td key={cell.id} {...cell.getCellProps()} className={`element ${cell.column.render("id") === "actions" ? "actions-table":""}`}>
						// 				{cell.render("Cell")}
						// 			</td>	
						// 		))}
						// 	</tr>
						// );
					)}
				</tbody>
			</table>
			
		</DndProvider>
	);
}


const Row = ({ row, index, moveRow }) => {
	const dropRef = React.useRef(null);
	const dragRef = React.useRef(null);

	const [, drop] = useDrop({
		accept: DND_ITEM_TYPE,
		hover(item, monitor) {
			if (!dropRef.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;
			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}
			// Determine rectangle on screen
			const hoverBoundingRect = dropRef.current.getBoundingClientRect();
			// Get vertical middle
			const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			// Determine mouse position
			const clientOffset = monitor.getClientOffset();
			// Get pixels to the top
			const hoverClientY = clientOffset.y - hoverBoundingRect.top;
			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%
			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}
			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}
			// Time to actually perform the action
			moveRow(dragIndex, hoverIndex);
			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex;
		},
	});

	const [{ isDragging }, drag, preview] = useDrag({
		type: DND_ITEM_TYPE,
		item: { index },
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const opacity = isDragging ? 0 : 1;

	preview(drop(dropRef));
	drag(dragRef);

	return (
		<tr ref={dropRef} style={{ opacity }} {...row.getRowProps()} className="container-element">
			<td ref={dragRef} style={{textAlign: "center", padding: "10px", cursor: "grab"}}><ReorderIcon color="secondary"/></td>
			{row.cells.map(cell => {
				return <td key={cell.id} {...cell.getCellProps()} className={`element ${cell.column.render("id") === "actions" ? "actions-table":""}`}>{cell.render("Cell")}</td>;
			})}
		</tr>
	);
};