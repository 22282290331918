import {useQuery} from "react-query";
import {get, getCustomer} from "api";
import fold from "accent-fold";
const nombresTiempoToTags = {
	desayuno:"d",
	colacion1:"sn",
	comida:"co",
	colacion2:"sn",
	cena:"ce",
};
export default function useAlimentosAI(){

	const { data:menuSelectList } = useQuery("menu-select-alimentos",  ()=>get("/menu-select/list2"));
	const { data:dataEquivalencias } = useQuery("equivalenciasMenu", () => fetch("/Final.json").then((res)=>res.json()) );
	const { data:customer } = useQuery("CUSTOMER", getCustomer);


	
	function getAlimentosFiltrados(){
		let list = menuSelectList?.data;
		if(customer?.data?.nutritionist.lista_omision_alimentos_ai){
			list = menuSelectList?.data.filter(m=>!customer?.data?.nutritionist.lista_omision_alimentos_ai.includes(m._id));
		}
		return [...list, ...dataEquivalencias.smae];
	}
	function getEquivalencias({dieta, idx_tiempo, idx_dia, idx_alimento, isEq}){
		let alimentos = [...(menuSelectList?.data||{}), ...dataEquivalencias.smae];

		alimentos = alimentos.map(a=>({...a, medidas:getBestMedida(a)}))
		function getBestMedida(alimento){
			if(alimento.medidas.length===1) return alimento.medidas[0];
		
			const medida = alimento.medidas.find(m=>{
				if(m.unidad!=="gramos") return true
				return false;
			});
			if(medida) return medida;
			return alimento.medidas[0];
		}

		// const slots = dieta.distribucion_menu[idx_tiempo].slots[idx_alimento];
		const alimentoReemplazar = dieta.tiempos_comida[idx_tiempo].menu[idx_dia][idx_alimento];
		const nameTiempo = dieta.tiempos_comida[idx_tiempo].nombre_tiempo.name;
		let filteredObjects = filterObjectsWithSubarray(alimentos, dieta.restricciones||[], "tags");
		filteredObjects = filterObjectsWithSubarray(filteredObjects, [nombresTiempoToTags[nameTiempo]], "tiempos_c")
		filteredObjects = filterObjectsWithSubarray(filteredObjects, alimentoReemplazar.tipos_c||[], "tipos_c")
		filteredObjects = getAlimentos(filteredObjects, dieta.restricciones, dieta.restricciones_alimentos);
		
		let sorted = [];
		if(isEq){
			sorted = filteredObjects.sort((a, b) => calculateSimilarityScoreEq(alimentoReemplazar, a) - calculateSimilarityScoreEq(alimentoReemplazar, b))
		}else{
			sorted = filteredObjects.sort((a, b) => calculateSimilarityScore(alimentoReemplazar, a) - calculateSimilarityScore(alimentoReemplazar, b));

		}


		return sorted;


	}
	return {getEquivalencias, getAlimentosFiltrados};

}
function mult(v=0, c){
	if(c===undefined){
		return v;
	}
	else{
		return v*c;
	}

}
function calculateValuesFromPhenotype(phenotype) {
	// Initialize the accumulator object
	let val = {
		c: 0, p: 0, g: 0, f: 0, v: 0, l: 0, azu: 0, ag: 0, leg: 0,
		// Initialize all possible subgroup accumulators
		c_sg: 0, c_cg: 0, p_mb: 0, p_mo: 0, p_b: 0, p_a: 0, g_cp: 0, g_sp: 0, 
		l_d: 0, l_sd: 0, l_e: 0, l_ca: 0, azu_cg: 0, azu_sg: 0, // ... etc.
	};

	console.log({phenotype})
	// Loop through the phenotype array
	phenotype.alimentos.forEach(item => {
		// Loop through the keys in the equivalence object of the item
		for (const [key, value] of Object.entries(item.eq||{})) {
			if (typeof value === 'object') {
				// Handle subgroups
				for (const [subkey, subvalue] of Object.entries(value)) {
					const calculatedValue = mult(subvalue, item.cantidad);
					if (typeof calculatedValue === 'number') {
						val[key + '_' + subkey] += calculatedValue; // e.g., 'c_sg'
						val[key] += calculatedValue; // Add to general group as well
					}
				}
			} else if (typeof value === 'number') {
				// Handle general groups
				const calculatedValue = mult(value, item.cantidad);
				if (typeof calculatedValue === 'number') {
					val[key] += calculatedValue;
				}
			}
		}
	});

	return val;
}
function sumObjectValues(obj) {
	return Object.values(obj).reduce((sum, value) => sum + value, 0);
}
function flattenObject(obj, prefix = '') {
	let flattened = {};

	for (const key in obj) {
		if (typeof obj[key] === 'object' && obj[key] !== null) {
			// If the value is an object, recurse and add the current key to the prefix
			Object.assign(flattened, flattenObject(obj[key], prefix + key + '_'));
		} else {
			// If the value is not an object, add it to the flattened object with the combined key
			flattened[prefix + key] = obj[key];
		}
	}

	return flattened;
}
function setZeroes(acc){
	acc.c_sg = acc.c_sg||0
	acc.c_cg = acc.c_cg||0
	acc.p_mb = acc.p_mb||0
	acc.p_mo = acc.p_mo||0
	acc.p_b =	acc.p_b||0
	acc.p_a = acc.p_a||0
	acc.g_cp = acc.g_cp||0
	acc.g_sp=	acc.g_sp||0
	acc.f =acc.f||0
	acc.v=acc.v||0
	acc.l_d =acc.l_d||0
	acc.l_sd=acc.l_sd||0
	acc.l_e=acc.l_e||0
	acc.l_ca=acc.l_ca||0
	acc.azu_cg=acc.azu_cg||0
	acc.azu_sg=acc.azu_sg||0
	acc.ag=acc.ag||0
	acc.leg=acc.leg||0
	return acc;
}
function calculateSimilarityScoreEq(objective, alimento) {
	objective = setZeroes(flattenObject(objective.eq))
	const val = calculateValuesFromPhenotype(alimento);
	let diff = 0;
	let exactMatchWeight = 1; // Weight for matching the exact total of a group
	let subgroupMismatchWeight = 2; // Weight for mismatch in subgroups
	let totalMismatchWeight = 3; // Additional weight for not matching the total

	// Loop through each objective
	for (const [key, objectiveValue] of Object.entries(objective)) {
		if (typeof objectiveValue === 'object') {
			// Handle subgroups
			let totalObjectiveValue = sumObjectValues(objectiveValue);
			let totalPhenotypeValue = val[key] || 0;

			// Check if total values match
			let totalMatch = totalObjectiveValue === totalPhenotypeValue;

			// Penalize for each subgroup mismatch
			for (const [subkey, subvalue] of Object.entries(objectiveValue)) {
				const phenotypeValue = val[key + '_' + subkey] || 0;
				if (subvalue !== phenotypeValue) {
					// console.log({subvalue, phenotypeValue})
					diff += subgroupMismatchWeight * Math.abs(subvalue - phenotypeValue);
				}
			}

			// Penalize for not matching the total, if there's a mismatch
			if (!totalMatch) {
				// console.log({totalObjectiveValue, totalPhenotypeValue})

				diff += totalMismatchWeight * Math.abs(totalObjectiveValue - totalPhenotypeValue);
			}
		} else {
			// console.log({objectiveValue, valKey:val[key],val,key , general:Math.abs(objectiveValue - (val[key] || 0))})

			// Handle general group
			diff += exactMatchWeight * Math.abs(objectiveValue - (val[key] || 0));
		}
	}
	return diff;
}
function calculateSimilarityScore(objective, alimento) {
	const epsilon = 0.01; // To prevent division by zero
	let protDiff = Math.abs((objective.prot || 0) - (alimento.medidas.prot || 0)) / Math.max(objective.prot, epsilon);
	let lipDiff = Math.abs((objective.lip || 0) - (alimento.medidas.lip || 0)) / Math.max(objective.lip, epsilon);
	let hidrDiff = Math.abs((objective.hidr || 0) - (alimento.medidas.hidr || 0)) / Math.max(objective.hidr, epsilon);

	// Sum the differences to get a total similarity score
	let similarityScore = protDiff + lipDiff + hidrDiff;

	return similarityScore;
}
function hasSubarray(obj, subarray, name) {
	return subarray.every(item => (obj[name]||[]).includes(item));
}

function filterObjectsWithSubarray(objects, subarray, name) {
	return objects.filter(obj => hasSubarray(obj, subarray, name));
}



function filterIngredient(restriccionesArray, alimento){
	
	if(containsIngredient(restriccionesArray, alimento))return false;

	if(!alimento.alimentos) return true;
	for(let i=0;i<alimento.alimentos.length;i++){		
		if(containsIngredient(restriccionesArray, alimento.alimentos[i]))return false;
	}
	return true;
}
function containsIngredient(restriccionesArray, alimento){
	
	for(let i=0;i<restriccionesArray.length;i++){
		if(fold(alimento.nombre).includes(restriccionesArray[i])) return true
	}
	return false;
}
function getAlimentos(alimentos, restricciones, restricciones_alimentos=""){
	restricciones_alimentos = fold(restricciones_alimentos.toLowerCase());

	const restriccionesArray = restricciones_alimentos.split(",").map(r=>r.trim()).filter(r=>r!=="");

	alimentos= alimentos.map(a=>({
		...a,
		nombre:a.nombre.toLowerCase(),
		alimentos:a.alimentos ? a.alimentos.map(a2=>({
			...a2,
			nombre:a2.nombre.toLowerCase(),
		})):undefined
	}));

	const a= alimentos.filter(al=>hasAtLeastSameElements(al.tags, restricciones)).filter(a=>filterIngredient(restriccionesArray, a));
	return a;
}
function hasAtLeastSameElements(arrayA=[], arrayB=[]) {
	return arrayB.every(elem => arrayA.includes(elem));
}