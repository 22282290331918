import React, {useRef, useState} from "react";
import Dieta from "./Dieta";
import PrintButton from "components/Buttons/PrintButton/PrintButton";
import HeaderPrint from "components/Buttons/PrintButton/HeaderPrint";
import { Alert } from "@mui/lab";
import { MENU_SELECT_DIET } from "store/constants";
import { useTranslation } from "react-i18next";
import AssignMacros from "./AssignMacros";
import { Button, CircularProgress } from "@mui/material";
import Radio from "components/FormComponents/Radio/Radio";
import {Puntos} from "./AIDiet2";

const DietaMenusSelect = props => {
	const {t} = useTranslation("common");
	const [tipo, setTipo] = React.useState();
	
	const [step, setStep] = useState(props.data ? 2 : 0);
	const [puntos, setPuntos] = useState();
	
	const onChangeEquivalencias= (v, distribucion_macros)=>{ 
		setPuntos(v)
		// props.onChange(d=>({...d, distribucion_eq:v, distribucion_macros}));
	};
	React.useEffect(()=>{
		props.setHideSaveButton && props.setHideSaveButton(step===0 || step===1);
	},[step]);
	const goToStep2 = ()=>{
		props.setFullScreen && props.setFullScreen(true)
		props.onChange("eq", "subtipo");
		props.onChange([0,1,2,3,4,5,6].map(()=>puntos), "conf_dias");		
		setStep(2);
	};
	const ref = useRef(null);

	return (
		<>
			{step===0 && <div style={{marginBottom:10}}>
				<Radio horizontal options={[{value:"1", label:"Por macronutrientes"}, {value:"2", label:"Por puntos SMAE"}]} onChange={setTipo} value={tipo} />
				<Button onClick={()=>setStep(1)} variant="contained" color="secondary" style={{marginTop: 10, float:"right"}}>{t("siguiente")}</Button>	

			</div> }
			{step===1 &&<>
				{tipo==="2" && <div>
					<Puntos onChange={onChangeEquivalencias} />
					<Button onClick={goToStep2} variant="contained" color="secondary" style={{marginTop: 10, float:"right"}}>{t("siguiente")}</Button>	
				</div>}
				{tipo==="1" && <div style={{margin: "auto",}}>
					<AssignMacros onChange={props.onChange} data={props.data} />
					<div style={{textAlign: "right"}}>
						{Object.values(props.data?.pieChartData || []).filter(val=>val > 0 ).length > 0 && <Button onClick={()=>(setStep(2),props.setFullScreen && props.setFullScreen(true))} variant="contained" color="secondary" style={{marginTop: 10, float:"right"}}>{t("siguiente")}</Button>}
					</div>
				</div>}
				
			</>}
			{step===2 &&<>
				<div className="buttons-dietas">
					<PrintButton type={MENU_SELECT_DIET.name} printRef={ref} />
				</div>
				<div className="container-dieta" ref={ref}>
					<div className="allow-print">
						<HeaderPrint data={props.data}/>
					</div>
					<Dieta onChange={props.onChange} value={props.data} onFile={props.onFile} removeFile={props.removeFile} onRemoveExistingFile={props.onRemoveExistingFile}  __deleted_files={props.__deleted_files} />
					{/* alimentos={props.alimentos} restricciones={props.restricciones} distribucionMacros={props.distribucion_macros} distribucionMenu={props.data.distribucion_menu} */}
				</div>

			</>}
		</>
	);
};

export default DietaMenusSelect;

// DietaMenusSelect.propTypes = {
// 	onChange: PropTypes.func,
// 	data: PropTypes.object,
// 	select: PropTypes.bool
// };