import React, { useState } from "react";
import { Route, BrowserRouter } from "react-router-dom";

import Theme from "../Theme";
import "./Routes.scss";

import Header from "../components/Header/Header";

import Home from "../pages/Home/Home";
import NavBar from "../pages/NavBar/NavBar";
import TablaClientes from "../pages/Clientes/TablaClientes";
import TablaClientesPagando from "../pages/Clientes/TablaClientesPagando";
import TablaClientesCalificacion from "../pages/Clientes/TablaClientesCalificacion";
import TablaClientesMensuales from "../pages/Clientes/TablaClientesMensuales";
import TablaClientesRenovacion from "../pages/Clientes/TablaClientesRenovacion";
import TablaClientesPlazos from "../pages/Clientes/TablaClientesPlazos";
import ClientesAnalytics from "../pages/Clientes/Analytics";
import TablaContrataciones from "../pages/Clientes/TablaContrataciones";
import TablaPacientes from "../pages/Pacientes/TablaPacientes";
import TablaDescuentos from "../pages/Descuentos/TablaDescuentos";
import Paciente from "../pages/Pacientes/Consultas/Consultas";
import Dietas from "../pages/Dietas/Dietas";
import TablaPremios from "../pages/Premios/TablaPremios";
import Recetas from "../pages/Recetas/Recetas";
import ProductosQueAmamos from "../pages/ProductosQueAmamos/Productos";
import Mensaje from "../pages/Mensajes/Mensaje";
import TablaPlanes from "../pages/Planes/TablaPlanes";
import MensajesNutriologos from "../pages/MensajeNutriologo/TablaMensajes";
import TablaCupones from "../pages/CuponesPlanes/TablaCupones";
import TablaNutriologos from "../pages/Nutriologos/TablaNutriologos";
import TablaConsultasOnline from "../pages/ConsultasOnline/TablaConsultasOnline";
import TablaTutoriales from "../pages/Tutoriales/TablaTutoriales";

import Login from "../pages/Login/Login";
import Forgot from "../pages/Login/Olvide";
import Reset from "../pages/Login/Reset";
import ConnectStripe from "../components/Stripe/ConnectStripe";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import PublicRoute from "./PublicRoute/PublicRoute";
import Configuraciones from "../pages/Configuraciones/Configuraciones";	
import Notificaciones from "../pages/Notificaciones/Notificaciones";	
import TablaInfografias from "../pages/Infografias/TablaInfografias";	
// import usePrevious from "Hooks/usePrevious";

const Routes = () => {

	let RNavBar = window.location.pathname === "/login" || window.location.pathname.startsWith("/reset") || window.location.pathname === "/forgot" || window.location.origin === "/consultaonline" ? null : <NavBar />;
	return (
		<>
			<div className="container">
				<PublicRoute exact path="/login" component={Login} />
				<PublicRoute exact path="/forgot" component={Forgot} />
				<PublicRoute exact path="/reset/:token/:idNutriologo" component={Reset} />

				<div className={(window.location.pathname === "/login"||window.location.pathname === "/forgot") ? "": "container-app"}>
					{/* <Header /> */}
					{RNavBar}

					<PrivateRoute exact path="/" component={Home} />
					<PrivateRoute path="/clientes" component={TablaClientes} />
					<PrivateRoute path="/clientesPagando" component={TablaClientesPagando} />
					<PrivateRoute path="/clientesCalificacion" component={TablaClientesCalificacion} />
					<PrivateRoute path="/clientesMensuales" component={TablaClientesMensuales} />
					<PrivateRoute path="/clientesRenovacion" component={TablaClientesRenovacion} />
					<PrivateRoute path="/clientesPlazos" component={TablaClientesPlazos} />
					<PrivateRoute path="/clientesAnalytics" component={ClientesAnalytics} />
					<PrivateRoute path="/clientesContratacion" component={TablaContrataciones} />
					<PrivateRoute path="/pacientes" component={TablaPacientes} />
					<PrivateRoute path="/paciente/:idPaciente/:seccion?/:subseccion?" component={Paciente} />

					<PrivateRoute path="/dietas/:tipo?/:seccion?" component={Dietas} />
					<PrivateRoute path="/premios" component={TablaPremios} />
					<PrivateRoute path="/recetas/:seccion?" component={Recetas} />
					<PrivateRoute path="/productos/:seccion?/:subseccion?" component={ProductosQueAmamos} />
					<PrivateRoute path="/planes" component={TablaPlanes} />
					<PrivateRoute path="/cupones-planes" component={TablaCupones} />
					<PrivateRoute path="/descuentos" component={TablaDescuentos} />
					<PrivateRoute path="/nutriologos" component={TablaNutriologos} />
					<PrivateRoute path="/online-consultations" component={TablaConsultasOnline} />
					<PrivateRoute path="/mensajes" component={Mensaje} />
					<PrivateRoute path="/mensajes-nutriologos" component={MensajesNutriologos} />
					<PrivateRoute path="/configuracion" component={Configuraciones} />
					<PrivateRoute path="/notificaciones/:seccion?" component={Notificaciones} />
					<PrivateRoute path="/infografias" component={TablaInfografias} />
					<PrivateRoute path="/tutoriales" component={TablaTutoriales} />
					
					

					{/* <Route
              path="/paciente/:idPaciente/consulta/:idConsulta"
              component={TabsConsultas}
            /> */}
				</div>
			</div>
		</>
	);
};

export default Routes;
