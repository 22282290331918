import React from "react";
import BoxForm from "components/FormComponents/BoxForm/BoxForm";
import CrudTable from "components/Table/CrudTable/CrudTable";
import {LABORATORIO} from "store/constants";
import Laboratorio from "./Laboratorio";
import DateCell from "components/Table/CellTypes/DateCell/DateCell";

const elements = [
	{
		type: "group",
		name: "balance_acido_base",
		label: "Balance ácido-base",
		fields: [{
			type: "text",
			subtype: "number",
			name: "balance_acido_base_ph_arterial",
			label: "Ph Arterial",
		},{
			type: "text",
			subtype: "number",
			name: "balance_acido_base_bicarbonato_arterial",
			label: "Bicarbonato Arterial",
		},{
			type: "text",
			subtype: "number",
			name: "balance_acido_base_presion_dioxido",
			label: "Presión parcial de dióxido de carbono en la sangre arterial, PACo2",
		},{
			type: "text",
			subtype: "number",
			name: "balance_acido_base_presion_oxigeno",
			label: "Presión parcial de oxigeno en la sangre arterial, PaO2",
		},{
			type: "text",
			subtype: "number",
			name: "balance_acido_base_ph_venoso",
			label: "Ph venoso",
		},{
			type: "text",
			subtype: "number",
			name: "balance_acido_base_bicarbonato_venoso",
			label: "Bicarbonato venoso",
		}]
	},{
		type: "group",
		name: "perfil_renal_y_de_electrolitos",
		label: "Perfil renal y de electrolitos",
		fields: [{
			type: "text",
			subtype: "number",
			name: "perfil_renal_nitrogeno",
			label: "Nitrógeno Ureico en Sangre BUN",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_renal_creatinina",
			label: "Creatinina",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_renal_relacion_bun",
			label: "Relación BUN: creatinina",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_renal_tasa_filtracion",
			label: "Tasa de filtración glomerular",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_renal_socio",
			label: "Socio",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_renal_cloro",
			label: "Cloro",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_renal_potasion",
			label: "Potasio",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_renal_magnesio",
			label: "Magnesio",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_renal_calcio_serico",
			label: "Calcio, sérico",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_renal_calcio_ionizado",
			label: "Calcio, ionizado",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_renal_fosforo",
			label: "Fósforo",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_renal_osmolalidad_serica",
			label: "Osmolalidad sérica",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_renal_hormona_paratiroidea",
			label: "Hormona Paratiroidea",
		}]
	},{
		type: "group",
		name: "perfil_de_acidos_grasos_esenciales",
		label: "Perfil de ácidos grasos esenciales",
		fields: [{
			type: "text",
			subtype: "number",
			name: "acidos_grasos_rel_trieno_tetreno",
			label: "Relación trieno/Tetreno",
		}]
	},{
		type: "group",
		name: "perfil_gastrointestinal",
		label: "Perfil gastrointestinal",
		fields: [{
			type: "text",
			subtype: "number",
			name: "perfil_gastrointestinal_fosfatasa",
			label: "Fosfatasa alcalina",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_gastrointestinal_alanino",
			label: "Alanino aminotransferasa, ALT",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_gastrointestinal_aspartato",
			label: "Aspartato aminotransferasa, AST",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_gastrointestinal_glutamil",
			label: "Gamma glutamil transferasa, GGT",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_gastrointestinal_vol_residual",
			label: "Volumen residual gástrico",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_gastrointestinal_bilirrubina",
			label: "Bilirrubina, total",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_gastrointestinal_amonio",
			label: "Amonio, sérico",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_gastrointestinal_toxicologia",
			label: "Reporte de toxicología, incluyendo alcohol",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_gastrointestinal_tiempo_protrombina",
			label: "Tiempo de protrombina, PT",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_gastrointestinal_tiempo_tromboplastina",
			label: "Tiempo parcial de tromboplastina, PTT",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_gastrointestinal_inr",
			label: "Relación Normalizada Internacional (INR) (tasa)",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_gastrointestinal_grasa_fecal",
			label: "Grasa fecal",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_gastrointestinal_amilasa",
			label: "Amilasa",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_gastrointestinal_lipasa",
			label: "Lipasa",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_gastrointestinal_otras_enzimas",
			label: "Otras enzimas pancreáticas (especificar)",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_gastrointestinal_dxylosa",
			label: "D-xylosa",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_gastrointestinal_hidrogeno",
			label: "Pruebas de hidrógeno en el aliento",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_gastrointestinal_biopsia",
			label: "Biopsia intestinal",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_gastrointestinal_coprocultivo",
			label: "Coprocultivo",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_gastrointestinal_tiempo_vaciado_gastrico",
			label: "Tiempo de vaciado gástrico",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_gastrointestinal_tiempo_transido_intestino",
			label: "Tiempo de tránsito del intestino delgado",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_gastrointestinal_placas_abdominales",
			label: "Placas abdominales (rayos X)",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_gastrointestinal_estudio",
			label: "Estudio ",
		}]
	},{
		type: "group",
		name: "perfil_glucosa_endocrino",
		label: "Perfil glucosa/endócrino",
		fields: [{
			type: "text",
			subtype: "number",
			name: "perfil_glucosa_ayuno",
			label: "Glucosa, ayuno",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_glucosa_casual",
			label: "Glucosa, casual",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_glucosa_hgba1c",
			label: "HgbA1c",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_glucosa_capilar_postprandial",
			label: "Glucosa capilar plasmática postprandial",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_glucosa_pico_capilar",
			label: "Pico de glucosa capilar plasmática postprandial",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_glucosa_tolerancia",
			label: "Prueba de tolerancia a la glucosa",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_glucosa_cortisol",
			label: "Nivel de cortisol",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_glucosa_proteina_igf",
			label: "Proteína ligadora de IGF",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_glucosa_tiroidea_tsh",
			label: "Pruebas de función tiroidea TSh",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_glucosa_tiroidea_t4",
			label: "Pruebas de función tiroidea T4",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_glucosa_tiroidea_t3",
			label: "Pruebas de función tiroidea T3",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_glucosa_tpo",
			label: "TPO",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_glucosa_hc",
			label: "Pruebas de hormona del Crecimiento (HC), (HACT), HL, HFE",
		}]
	},{
		type: "group",
		name: "perfil_inflamatorio",
		label: "Perfil inflamatorio",
		fields: [{
			type: "text",
			subtype: "number",
			name: "perfil_inflamatorio_c_reactiva",
			label: "Proteína C-reactiva",
		}]
	},{
		type: "group",
		name: "perfil_de_lipidos",
		label: "Perfil de lípidos",
		fields: [{
			type: "text",
			subtype: "number",
			name: "perfil_lipidos_colesterol_serico",
			label: "Colesterol, sérico",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_lipidos_colesterol_hdl",
			label: "Colesterol, HDL",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_lipidos_colesterol_ldl",
			label: "Colesterol, LDL",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_lipidos_colesterol_no_ldl",
			label: "Colesterol, no HDL",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_lipidos_colesterol_total",
			label: "Colesterol total: Colesterol HDL",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_lipidos_ldl_hdl",
			label: "LDL:HDL",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_lipidos_triacilgliceridos",
			label: "Triacilglicéridos, séricos",
		}]
	},{
		type: "group",
		name: "perfil_de_taza_metabolica",
		label: "Perfil de taza metabólica",
		fields: [{
			type: "text",
			subtype: "number",
			name: "perfil_metabolica_gasto_metabolico",
			label: "Tasa de gasto metabólico en reposo, medio",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_metabolica_coeficiente_respiratorio",
			label: "Coeficiente respiratorio, medio",
		}]
	},{
		type: "group",
		name: "perfil_de_minerales",
		label: "Perfil de minerales",
		fields: [{
			type: "text",
			subtype: "number",
			name: "perfil_minerales_cobre",
			label: "Cobre, suero o plasma",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_minerales_yodo",
			label: "Yodo, excreción urinaria",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_minerales_zinc",
			label: "Zinc, suero o plasma",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_minerales_boro",
			label: "Boro, suero o plasma",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_minerales_cromo",
			label: "Cromo, suero u urinario",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_minerales_fluor",
			label: "Flúor, plasma",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_minerales_manganeso",
			label: "Manganeso, urinario, sanguíneo, plasma",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_minerales_molibdeno",
			label: "Molibdeno, suero",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_minerales_selenio",
			label: "Selenio, suero o urinario",
		}]
	},{
		type: "group",
		name: "perfil_de_anemias_nutricias",
		label: "Perfil de anemias nutricias",
		fields: [{
			type: "text",
			subtype: "number",
			name: "perfil_anemias_hemoglobina",
			label: "Hemoglobina",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_anemias_hematocrito",
			label: "Hematocrito",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_anemias_volumen_corpuscular",
			label: "Volumen corpuscular medio",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_anemias_folato_celulas_rojas",
			label: "Folato en células rojas",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_anemias_distribucion_celulas_rojas",
			label: "Ancho de la distribución de las células rojas",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_anemias_b12",
			label: "B12, suero",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_anemias_metilmalonico",
			label: "Ácido metilmalónico, suero",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_anemias_folato",
			label: "Folato, suero",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_anemias_homocisteina",
			label: "Homocisteína, suero",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_anemias_fernitina",
			label: "Fernitina, suero",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_anemias_hierro",
			label: "Hierro, suero",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_anemias_capacidad_hierro",
			label: "Capacidad total fijadora de hierro",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_anemias_saturacion_transferrina",
			label: "Saturación de transferrina",
		}]
	},{
		type: "group",
		name: "perfil_de_proteinas",
		label: "Perfil de proteínas",
		fields: [{
			type: "text",
			subtype: "number",
			name: "perfil_proteinas_albumina",
			label: "Albúmina",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_proteinas_prealbumina",
			label: "Prealbúmina",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_proteinas_transferrina",
			label: "Transferrina",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_proteinas_fenilalanina",
			label: "Fenilalanina, plasma",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_proteinas_tirosina",
			label: "Tirosina, plasma",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_proteinas_otros_aminoacidos",
			label: "Otros aminoácidos, especificar",
		},{
			type: "text",
			name: "perfil_proteinas_nivel_anticuerpos",
			label: "Nivel de anticuerpos, especificar",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_proteinas_transferrina_deficiente",
			label: "Transferrina deficiente de hidratos de carbono",
		}]
	},{
		type: "group",
		name: "perfil_de_orina",
		label: "Perfil de orina",
		fields: [{
			type: "text",
			name: "perfil_orina_color",
			label: "Color de la orina",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_orina_osmolalidad",
			label: "Osmolalidad urinaria",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_orina_gravedad",
			label: "Gravedad específica de la orina",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_orina_prueba",
			label: "Prueba de orina, especificar",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_orina_volumen",
			label: "Volumen urinario",
		}]
	},{
		type: "group",
		name: "perfil_de_vitaminas",
		label: "Perfil de vitaminas",
		fields: [{
			type: "text",
			subtype: "number",
			name: "perfil_vitamina_a",
			label: "Vitamina A, suero o retinol plasmático",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_vitamina_c",
			label: "Vitamina C, plasma o suero",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_vitamina_d",
			label: "Vitamina D, 25-hydroxy",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_vitamina_e",
			label: "Vitamina E, alfa-tocoferol plasmático",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_vitamina_tiamina",
			label: "Tiamina, coeficiente de actividad de la transcetolasa eritrocitaria",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_vitamina_riboflavina",
			label: "Riboflavina, coeficiente de actividad de la glutatión reductasa eritrocitaria",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_vitamina_niacina",
			label: "Niacina, concentración urinaria de la N´metil-nicotinamida",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_vitamina_b6",
			label: "Vitamina B6, plasma o concentración sérica de la 5° fosfato piridoxal",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_vitamina_acido_pantotenico",
			label: "Ácido pantoténico, excreción urinaria de pantotenato, plasma",
		},{
			type: "text",
			subtype: "number",
			name: "perfil_vitamina_biotina",
			label: "Biotina, excreción urinaria del ácido 3-hydroxisovalérico o propionil- CoA carboxilasa sérica en el embarazo",
		}]
	},{
		type: "multiGroup",
		name: "bioquimicos_otros",
		label: "Otros",
		labelAddButton: "Añadir",
		fields: [
			{
				type:"text",
				name:"otro",
			}
		]
	},{
		type:"text",
		name: "diagnostico_nutricias",
		label: "Diagnostico Nutricias",
		moreRows: true
	}
];

const Bioquimicos = props => {
	const columns = React.useMemo(
		() => [
			{
				Header: "Titulo",
				accessor: "nombre"
			},
			{
				Header: "Fecha",
				accessor: "fecha",
				Cell: DateCell,
			},
			{
				Header: "Archivo",
				accessor: "archivo.tipo"
			}
		],
		[]
	);
	return (
		<>
			<BoxForm data={props.data} elements={elements} onChange={props.onChange} indexElements={true} ></BoxForm>
			<CrudTable
				data={props.data?.laboratorio || []}
				columns={columns}
				type={LABORATORIO}
				modal={Laboratorio}
				status={{}}
				_id={props.idConsulta}
			/>
		</>
	);
};

export default Bioquimicos;
