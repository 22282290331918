import React from "react";
import { fileUrl } from "store/constants";
import {Avatar, Typography} from "@mui/material";

import "./UserCell.scss";

export default ({row}) => {
	return (
		<>
			<div className="user-cell">
				<div className="image">
					<Avatar alt="foto" src={row.original?.foto?.path && `${process.env.REACT_APP_FILE_URL}${row.original?.foto?.path}`} />
				</div>
				<div className="datos">
				<Typography className="nombre" variant="body1">
					{row.original.nombre}
				</Typography>
					 {/* <p >{row.original.nombre}</p> */}
					<p className="email">{row.original.correo}</p>
				</div>
			</div>
		</>
	);
};