import React, {useState} from "react";
import cx from "classnames";
 
const Option = (props) => {
	const {children, isSelected, innerProps, data} = props;
	return <div
		className={cx("react-select__option", {
			"react-select__option_selected": isSelected
		})}
		id={innerProps.id}
		tabIndex={innerProps.tabIndex}
		onClick={innerProps.onClick}
		style={{...data?.styles, padding: 10, cursor: "pointer"}}
	>
		<p>{children}</p>
		<p style={{fontSize: 12}}>{props.data.lista}</p>
	</div>;
};

export default Option;