import React,{useState} from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions} from "@mui/material";
import {Input, Button} from "@mui/material";

const EMPTY_FUNC = () => {};
const DialogContext = React.createContext([
	EMPTY_FUNC,
	EMPTY_FUNC
]);
export const useDialog = () => React.useContext(DialogContext);

function DialogContainer(props) {
	const { content, Content, title, actions, open, onClose, onKill, width, disableBackdropClick } = props;
	const [data, setData] = useState();
	const close = (a, reason) => {
		if(!disableBackdropClick){
			return onClose();
		}
	};
	return (
		<Dialog className="cont_dialog" fullWidth={true}	maxWidth={width} open={open} onClose={close} onExited={onKill}>
			<DialogTitle style={{textAlign: "center"}}>{title}</DialogTitle>
			<DialogContent>
				{content==="input" && <Input onChange={e=>setData(e.target.value)} />}
				<Content {...props} />
				
			</DialogContent>
			<DialogActions>
				{(actions || []).map((a, idx)=><Button key={a.label + idx} onClick={()=>(a.onClick(data),onClose())}>{a.label}</Button>)}
			</DialogActions>
		</Dialog>
	);
}

export default function DialogProvider({ children }) {
	const [dialogs, setDialogs] = React.useState([]);
	const createDialog = (option) => {
		const dialog = { ...option, open: true };
		setDialogs((dialogs) =>{
			const dialogExist = dialogs.find(d=>d.id===option.id);
			if(dialogExist && option.id)return dialogs;
			return [...dialogs, dialog];
		});
	};
	const closeDialog = (...data) => {
		setDialogs((dialogs) => {
			const latestDialog = dialogs.pop();
			if (!latestDialog) return dialogs;
			if (latestDialog.onClose) latestDialog.onClose(...data);
			return [...dialogs].concat({ ...latestDialog, open: false });
		});
	};
	const contextValue = React.useRef([createDialog, closeDialog]);

	return (
		<DialogContext.Provider value={contextValue.current}>
			{children}
			{dialogs.map((dialog, i) => {
				const { onClose, ...dialogParams } = dialog;
				const handleKill = () => {
					if (dialog.onExited) dialog.onExited();
					setDialogs((dialogs) => dialogs.slice(0, dialogs.length - 1));
				};
				return <>
					<DialogContainer
						key={i}
						onClose={closeDialog}
						onKill={handleKill}
						{...dialogParams}
					/>
				</>;
			})}
		</DialogContext.Provider>
	);
}
