import React,{useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";

import { CircularProgress } from "@mui/material";
import { Alert } from "@mui/lab";

import Premios from "./Informacion/Premios";
import DatosPersonales from "./Informacion/DatosPersonales";
import Antecedentes from "./Informacion/Antecedentes";
import HistoriaClinica from "./Informacion/HistoriaClinica";
import Header from "components/Header/Header";
import TablaConsultas from "./Consultas/TablaConsultas";

import TabInformacion from "assets/img/icons/info-1.png";
import TabInformacionSelected from "assets/img/icons/info-2.png";
import TabConsultas from "assets/img/icons/consulta-1.png";
import TabConsultasSelected from "assets/img/icons/consulta-2.png";
import TabPremios from "assets/img/icons/premios-1.png";
import TabPremiosSelected from "assets/img/icons/premios-2.png";
import TabDatosPersonales from "assets/img/icons/datos-1.png";
import TabDatosPersonalesSelected from "assets/img/icons/datos-2.png";
import TabHistoriaClinica from "assets/img/icons/hist-1.png";
import TabHistoriaClinicaSelected from "assets/img/icons/hist-2.png";
import TabDietaHabitual from "assets/img/icons/diet-1.png";
import TabDietaHabitualSelected from "assets/img/icons/diet-2.png";

import MyTabs from "components/Tabs/Tabs";
import { useRouteMatch } from "react-router-dom";
import {PATIENTS} from "store/constants";
import useSave from "Hooks/useSave";
import AutoSaveStatus from "components/AutoSaveStatus/AutoSaveStatus";
import withPreventLeave from "components/HOCs/withPreventLeave";

import "./Consultas.scss";


let firstTime = true;
const Consultas = (props) => {
	let match = useRouteMatch();

	const paciente = useSelector(state => state["PATIENTS"]?.data.find(data => data._id === match.params.idPaciente));



	const {data, onChange, onFile, save, status} = useSave({type:PATIENTS, autoSave:true, data:paciente, parent_id:match.params.idPaciente});
	React.useEffect(()=>{
		if(status.status!=="LOADED"){
			props.canLeave(false);
		}else{
			props.canLeave(true);
		}
	},[status.status]);
	
	const loadPaciente = () =>{
		dispatch({type:"LOAD_ONE_PATIENTS",payload:match.params.idPaciente });

	}
	const dispatch = useDispatch();
	const view = useSelector(state=>state.PATIENTS.view);
	// React.useEffect(()=>{
	// 	dispatch({type:"LOAD_ONE_PATIENTS",payload:match.params.idPaciente });

	// },[]);
	React.useEffect(()=>{
		switch (view.status) {
		case "LOADED":
			if(firstTime){
				firstTime = false;
				dispatch({type:"LOAD_ONE_PATIENTS",payload:match.params.idPaciente });
			}
			break;
		default:
			break;
		}
	},[view.status]);


	const tabs=[{
		label:"1. Información",
		value:"recetario",
		component: TabsInformacion,
		props:{
			idPaciente: match.params.idPaciente,
			data: data,
			onChange: onChange,
			onFile: onFile,
			status:status,
			save:save
		},
		img: TabInformacion,
		selectedImg: TabInformacionSelected
	},{
		label:"2. Consultas",
		value:"categoria",
		component:TablaConsultas,
		props:{
			idPaciente: match.params.idPaciente
		},
		img: TabConsultas,
		selectedImg: TabConsultasSelected
	},{
		label:"3. Premios",
		value:"sellos",
		component:Premios,
		props:{
			idPaciente: match.params.idPaciente,
			data: data,
			onChange: onChange,
			onFile: onFile,
			status:status,
			save:save
		},
		img: TabPremios,
		selectedImg: TabPremiosSelected
	}];
	return (
		<>
			<Header title={paciente?.nombre} video time="5:35" />
			{view.status==="LOADING" 
				? 
				<CircularProgress />
				:
				view.status==="LOAD_FAILED"
					?
					<Alert severity="error">Error al cargar la informacion<button className="reintentar-button" onClick={loadPaciente}>Reintentar</button></Alert>
					:
					<MyTabs tabs={tabs} icon={true} keys={["idPaciente"]} urlKey="seccion" root defParams={{idPaciente: match.params.idPaciente}} ></MyTabs>
			}
			

		</>
	);
};

export default withPreventLeave(Consultas);

function TabsInformacion (props) {
	let match = useRouteMatch();
	const tabs=[{
		label:"1.1 Datos Personales",
		value:"datosp",
		component:DatosPersonales,
		props:{
			idPaciente: match.params.idPaciente,
			data: props.data,
			onChange: props.onChange,
			onFile: props.onFile
		},
		img: TabDatosPersonales,
		selectedImg: TabDatosPersonalesSelected
	},{
		label:"1.2 Historia del Cliente",
		value:"categoria",
		component:HistoriaClinica,
		props:{
			idPaciente: match.params.idPaciente,
			data: props.data,
			onChange: props.onChange,
			onFile: props.onFile
		},
		img: TabHistoriaClinica,
		selectedImg: TabHistoriaClinicaSelected
		
	},{
		label:"1.3 Antecedentes Dietéticos",
		value:"sellos",
		component:Antecedentes,
		props:{
			idPaciente: match.params.idPaciente,
			data: props.data,
			onChange: props.onChange,
			onFile: props.onFile
		},
		img: TabDietaHabitual,
		selectedImg: TabDietaHabitualSelected
	}];
	return (
		<MyTabs tabs={tabs} defSelected={match.params.seccion} icon={true} keys={["idPaciente","seccion"]} urlKey="subseccion" root={false} >
			<AutoSaveStatus status={props.status} save={props.save} />
		</MyTabs>);

}