import { createTheme } from '@mui/material/styles';

const Theme = createTheme({
  palette: {
    primary: {
      main: "#A5DD72",
      contrastText: "#fff"
    },
    secondary: {
      main: "#6896F9",
      contrastText: "#fff"
    },
    warning: {
      main: "#FF0000"
    }
  },
  components: {
    MuiBox: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: "100px"
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginBottom: "10px"
        },
        flexContainer: {
          background: "#f8f8f8"
        },
        indicator: {
          height: "5px"
        },
      }
    },
    MuiTab: {
      styleOverrides: {
        textColorInherit: {
          opacity: "1"
        }
      }
    },
    MuiIcon: {
      styleOverrides: {
        colorPrimary: {
          color: "#ffffff"
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        elevation4: {
          boxShadow: "none"
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        h5: {
          fontSize: "14pt",
          fontWeight: "bold"
        }
      }
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          flexWrap: "initial"
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: "100%"
        }
      }
    }
  }
});

export default Theme;
