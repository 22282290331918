import {
	loadRequest,
	insertRequest,
	updateRequest,
	trashRequest,
	untrashRequest,
	removeRequest
} from "./generators";
import sub from "./subGenerators";
import api from "../../api/";
import { 
	PATIENTS, 
	CONSULTATION, 
	BIOIMPEDANCIA, 
	LABORATORIO, 
	INSERT, 
	LOAD, 
	UPDATE, 
	UPDATE_FAILED, 
	READY, 
	UPDATED, 
	REMOVE } from "../constants";
import {
	LOADING,
	LOADED,
	LOAD_FAILED,

	INSERTING,
	INSERT_FAILED,
	INSERTED,
	UPDATING,
	TRASH,
	UNTRASH
} from "../constants";
import { call, put } from "redux-saga/effects";
import { takeEvery, all, takeLatest } from "redux-saga/effects";
const {post,get,put:update, upload, remove } = api;

const loadPatients = loadRequest(PATIENTS);
const insertPatients = insertRequest(PATIENTS);
const updatePatients = updateRequest(PATIENTS);
const trashPatients = trashRequest(PATIENTS);
const untrashPatients = untrashRequest(PATIENTS);
const removePatients = removeRequest(PATIENTS);

// const loadConsultations = sub.loadRequest(CONSULTATION);
// const insertConsultation = sub.insertRequest(CONSULTATION);
// const updateConsultation = sub.updateRequest(CONSULTATION);
// const trashConsultation = sub.trashRequest(CONSULTATION);
// const untrashConsultation = sub.untrashRequest(CONSULTATION);
// const removeConsultation = sub.removeRequest(CONSULTATION);

function* loadPatient() {
	yield takeLatest(LOAD+"ONE_" + PATIENTS.name, function* load(action) {
		const status = "PATIENTS_STATUS";
		try {
			const url = PATIENTS.url+"/"+action.payload;
			yield put({ type: status, payload: {status: LOADING} });
			const response = yield call(get, url);
			yield put({ type: status, payload: {status: LOADED} });
			yield put({ type: "UPDATE_L_" + PATIENTS.name, payload: response.data });
		} catch (e) {
			yield put({ type: status, payload: {status: LOAD_FAILED} });
		}
	});
}

function* insertConsultation () {
	yield takeEvery(INSERT + CONSULTATION.name, function* insert(action) {
		const status = "CONSULTATION_STATUS";
		try {
			const url = PATIENTS.url+"/"+action.idPaciente+"/consultations";
			yield put({ type: status, payload: {status: INSERTING} });
			const payload = action.payload || {};
			payload.id_paciente = action.idPaciente;
			const response = yield call(post, url, payload);
			yield put({ type: "INSERT_L_" + CONSULTATION.name, idPaciente:action.idPaciente, payload: response.data.data });
			yield put({ type: status, payload: {status:INSERTED, id:response.data.data._id} });
			if(action.callback){
				yield call(action.callback,response.data);
			}
		} catch (e) {
			if(action.errorCallback){
				yield call(action.errorCallback,e);
			}
			yield put({ type: status, payload: {status: INSERT_FAILED} });
		}
	});
}
function* updateConsultation () {
	yield takeEvery(UPDATE + CONSULTATION.name, function* insert(action) {
		const status = "CONSULTATION_STATUS";
		try {
			console.log(action.payload)
			const url = PATIENTS.url+"/"+action.payload.parent_id+"/consultations/"+action.payload._id;
			yield put({ type: status, payload: {status: UPDATING} });
			
			const {_id, parent_id, ...realPayload} = action.payload;
			const payload = realPayload || {};
			payload.id_paciente = action.idPaciente;
			const response = yield call(update, url, payload);
			yield put({ type: "UPDATE_L_" + CONSULTATION.name, idPaciente:action.parent_id, payload: response.data.data });
			yield put({ type: status, payload: {status:UPDATED, id:response.data.data._id} });
			if(action.callback){
				yield call(action.callback,response.data);
			}
		} catch (e) {
			if(action.errorCallback){
				yield call(action.errorCallback,e);
			}
			yield put({ type: status, payload: {status: UPDATE_FAILED} });
		}
	});
}
function* loadConsultations() {
	yield takeLatest(LOAD + CONSULTATION.name, function* load(action) {
		const status = "CONSULTATION_STATUS";
		try {
			const url = PATIENTS.url+"/"+action.idPaciente+"/consultations";
			yield put({ type: status, payload: {status: LOADING} });
			const response = yield call(get, url);
			yield put({ type: status, payload: {status: LOADED} });
			yield put({ type: "SET_" + CONSULTATION.name, idPaciente:action.idPaciente, payload: response.data });
		} catch (e) {

			yield put({ type: status, payload: {status: LOAD_FAILED} });
		}
	});
}
function* trashConsultation(){
	yield takeEvery(TRASH + CONSULTATION.name, function* trash(action) {
		const status = "CONSULTATION_STATUS";

		const url = PATIENTS.url+"/"+action.payload.parent_id+"/consultations/"+action.payload._id;
		try {
			yield put({ type: status, payload: {status: UPDATING} });
			const payload = {_trash: true};
			let response = yield call(update, url, {...payload});
			yield put({ type: "UPDATE_L_" + CONSULTATION.name, payload: response.data.data });
			yield put({ type: status, payload: {status: LOADED, id: response.data.data._id} });
		} catch (e) {
			yield put({ type: status, payload: {status: UPDATE_FAILED} });
		}
	});
}
function* untrashConsultation(){
	yield takeEvery(UNTRASH + CONSULTATION.name, function* trash(action) {
		const status = "CONSULTATION_STATUS";

		const url = PATIENTS.url+"/"+action.payload.parent_id+"/consultations/"+action.payload._id;
		try {
			yield put({ type: status, payload: {status: UPDATING} });
			const payload = {_trash: false};
			let response = yield call(update, url, {...payload});
			yield put({ type: "UPDATE_L_" + CONSULTATION.name, payload: response.data.data });
			yield put({ type: status, payload: {status: LOADED, id: response.data.data._id} });
		} catch (e) {
			yield put({ type: status, payload: {status: UPDATE_FAILED} });
		}
	});
}
function* insertBioimpedancia() {
	const name = `${BIOIMPEDANCIA.name}_${BIOIMPEDANCIA.tail_name}`;

	yield takeEvery(INSERT + name, function* insertBioimpedancia(action) {
		// const status = makeStatusFromType(type);
		const status = "CONSULTATION_STATUS";

		try {
			yield put({ type: status, payload: {status: INSERTING} });
			const {files, ...payload} = action.payload;
			const url = `consultation/${action.payload.parent_id}/bioimpedancia`;

			let response = yield call(post, url, payload);
			if(files && files.length>0){
				response = yield all(files.map((file) => call(upload, `consultation/${action.payload.parent_id}/bioimpedancia/${response.data.subdocument._id}/archivo`, file.file)));
				response = response[response.length-1];
			}
			// yield put({ type: "UPDATE_L_" + type.name, payload: response.data.data });
			yield put({ type: "UPDATE_L_" + CONSULTATION.name, idPaciente:action.parent_id, payload: response.data.data });

			yield put({ type: status, payload: {status:INSERTED, id:response.data.data._id} });
			if(action.callback){
				yield call(action.callback,response);
			}
		} catch (e) {
			yield put({ type: status, payload: {status: INSERT_FAILED, errorMsg: e} });
			if(action.errorCallback){
				yield call(action.errorCallback,e);
			}
		}
	});
}
function* updateBioimpedancia() {
	const name = `${BIOIMPEDANCIA.name}_${BIOIMPEDANCIA.tail_name}`;

	yield takeEvery(UPDATE + name, function* updateBioimpedancia(action) {
		// const status = makeStatusFromType(type);
		const status = "CONSULTATION_STATUS";

		try {
			yield put({ type: status, payload: {status: INSERTING} });
			const {files, ...payload} = action.payload;
			const url = `consultation/${action.payload.parent_id}/bioimpedancia/${action.payload._id}`;

			let response = yield call(update, url, payload);
			if(files && files.length>0){
				response = yield all(files.map((file) => call(upload, `consultation/${action.payload.parent_id}/bioimpedancia/${action.payload._id}/archivo`, file.file)));
				response = response[response.length-1];
			}
			// yield put({ type: "UPDATE_L_" + type.name, payload: response.data.data });
			yield put({ type: "UPDATE_L_" + CONSULTATION.name, idPaciente:action.parent_id, payload: response.data.data });

			yield put({ type: status, payload: {status:INSERTED, id:response.data.data._id} });
			if(action.callback){
				yield call(action.callback,response);
			}
		} catch (e) {
			yield put({ type: status, payload: {status: INSERT_FAILED, errorMsg: e} });
			if(action.errorCallback){
				yield call(action.errorCallback,e);
			}
		}
	});
}
function* trashBioimpedancia() {
	const name = `${BIOIMPEDANCIA.name}_${BIOIMPEDANCIA.tail_name}`;

	yield takeEvery(TRASH + name, function* trashBioimpedancia(action) {
		const status = "CONSULTATION_STATUS";
		try {
			yield put({ type: status, payload: {status: INSERTING} });
			const url = `consultation/${action.payload.parent_id}/bioimpedancia/${action.payload._id}`;
			let response = yield call(update, url, {_trash:true});
			yield put({ type: "UPDATE_L_" + CONSULTATION.name, idPaciente:action.parent_id, payload: response.data.data });
			yield put({ type: status, payload: {status:INSERTED, id:response.data.data._id} });
		} catch (e) {
			yield put({ type: status, payload: {status: INSERT_FAILED, errorMsg: e} });
		}
	});
}
function* untrashBioimpedancia() {
	const name = `${BIOIMPEDANCIA.name}_${BIOIMPEDANCIA.tail_name}`;

	yield takeEvery(UNTRASH + name, function* untrashBioimpedancia(action) {
		const status = "CONSULTATION_STATUS";
		try {
			yield put({ type: status, payload: {status: INSERTING} });
			const url = `consultation/${action.payload.parent_id}/bioimpedancia/${action.payload._id}`;
			let response = yield call(update, url, {_trash:false});
			yield put({ type: "UPDATE_L_" + CONSULTATION.name, idPaciente:action.parent_id, payload: response.data.data });
			yield put({ type: status, payload: {status:INSERTED, id:response.data.data._id} });
		} catch (e) {
			yield put({ type: status, payload: {status: INSERT_FAILED, errorMsg: e} });
		}
	});
}
function* removeBioimpedancia() {
	const name = `${BIOIMPEDANCIA.name}_${BIOIMPEDANCIA.tail_name}`;

	yield takeEvery(REMOVE + name, function* untrashBioimpedancia(action) {
		const status = "CONSULTATION_STATUS";
		try {
			yield put({ type: status, payload: {status: INSERTING} });
			const url = `consultation/${action.payload.parent_id}/bioimpedancia/${action.payload._id}`;
			let response = yield call(remove, url);
			yield put({ type: "UPDATE_L_" + CONSULTATION.name, idPaciente:action.parent_id, payload: response.data.data });
			yield put({ type: status, payload: {status:INSERTED, id:response.data.data._id} });
		} catch (e) {
			yield put({ type: status, payload: {status: INSERT_FAILED, errorMsg: e} });
		}
	});
}


function* insertLaboratorio() {
	const name = `${LABORATORIO.name}_${LABORATORIO.tail_name}`;

	yield takeEvery(INSERT + name, function* insertLaboratorio(action) {
		// const status = makeStatusFromType(type);
		const status = "CONSULTATION_STATUS";

		try {
			yield put({ type: status, payload: {status: INSERTING} });
			const {files, ...payload} = action.payload;
			const url = `consultation/${action.payload.parent_id}/laboratorio`;

			let response = yield call(post, url, payload);
			if(files && files.length>0){
				response = yield all(files.map((file) => call(upload, `consultation/${action.payload.parent_id}/laboratorio/${response.data.subdocument._id}/archivo`, file.file)));
				response = response[response.length-1];
			}
			// yield put({ type: "UPDATE_L_" + type.name, payload: response.data.data });
			yield put({ type: "UPDATE_L_" + CONSULTATION.name, idPaciente:action.parent_id, payload: response.data.data });

			yield put({ type: status, payload: {status:INSERTED, id:response.data.data._id} });
			if(action.callback){
				yield call(action.callback,response);
			}
		} catch (e) {
			yield put({ type: status, payload: {status: INSERT_FAILED, errorMsg: e} });
			if(action.errorCallback){
				yield call(action.errorCallback,e);
			}
		}
	});
}
function* updateLaboratorio() {
	const name = `${LABORATORIO.name}_${LABORATORIO.tail_name}`;

	yield takeEvery(UPDATE + name, function* updateLaboratorio(action) {
		// const status = makeStatusFromType(type);
		const status = "CONSULTATION_STATUS";

		try {
			yield put({ type: status, payload: {status: INSERTING} });
			const {files, ...payload} = action.payload;
			const url = `consultation/${action.payload.parent_id}/laboratorio/${action.payload._id}`;

			let response = yield call(update, url, payload);
			if(files && files.length>0){
				response = yield all(files.map((file) => call(upload, `consultation/${action.payload.parent_id}/laboratorio/${action.payload._id}/archivo`, file.file)));
				response = response[response.length-1];
			}
			// yield put({ type: "UPDATE_L_" + type.name, payload: response.data.data });
			yield put({ type: "UPDATE_L_" + CONSULTATION.name, idPaciente:action.parent_id, payload: response.data.data });

			yield put({ type: status, payload: {status:INSERTED, id:response.data.data._id} });
			if(action.callback){
				yield call(action.callback,response);
			}
		} catch (e) {
			yield put({ type: status, payload: {status: INSERT_FAILED, errorMsg: e} });
			if(action.errorCallback){
				yield call(action.errorCallback,e);
			}
		}
	});
}
function* trashLaboratorio() {
	const name = `${LABORATORIO.name}_${LABORATORIO.tail_name}`;

	yield takeEvery(TRASH + name, function* trashLaboratorio(action) {
		const status = "CONSULTATION_STATUS";
		try {
			yield put({ type: status, payload: {status: INSERTING} });
			const url = `consultation/${action.payload.parent_id}/laboratorio/${action.payload._id}`;
			let response = yield call(update, url, {_trash:true});
			yield put({ type: "UPDATE_L_" + CONSULTATION.name, idPaciente:action.parent_id, payload: response.data.data });
			yield put({ type: status, payload: {status:INSERTED, id:response.data.data._id} });
		} catch (e) {
			yield put({ type: status, payload: {status: INSERT_FAILED, errorMsg: e} });
		}
	});
}
function* untrashLaboratorio() {
	const name = `${LABORATORIO.name}_${LABORATORIO.tail_name}`;

	yield takeEvery(UNTRASH + name, function* untrashLaboratorio(action) {
		const status = "CONSULTATION_STATUS";
		try {
			yield put({ type: status, payload: {status: INSERTING} });
			const url = `consultation/${action.payload.parent_id}/laboratorio/${action.payload._id}`;
			let response = yield call(update, url, {_trash:false});
			yield put({ type: "UPDATE_L_" + CONSULTATION.name, idPaciente:action.parent_id, payload: response.data.data });
			yield put({ type: status, payload: {status:INSERTED, id:response.data.data._id} });
		} catch (e) {
			yield put({ type: status, payload: {status: INSERT_FAILED, errorMsg: e} });
		}
	});
}
function* removeLaboratorio() {
	const name = `${LABORATORIO.name}_${LABORATORIO.tail_name}`;

	yield takeEvery(REMOVE + name, function* untrashLaboratorio(action) {
		const status = "CONSULTATION_STATUS";
		try {
			yield put({ type: status, payload: {status: INSERTING} });
			const url = `consultation/${action.payload.parent_id}/laboratorio/${action.payload._id}`;
			let response = yield call(remove, url);
			yield put({ type: "UPDATE_L_" + CONSULTATION.name, idPaciente:action.parent_id, payload: response.data.data });
			yield put({ type: status, payload: {status:INSERTED, id:response.data.data._id} });
		} catch (e) {
			yield put({ type: status, payload: {status: INSERT_FAILED, errorMsg: e} });
		}
	});
}

// export const removeConsultation = (type) =>
// 	function* insert() {
// 		yield takeEvery(REMOVE + type.name, function* insert(action) {
// 			const status = makeStatusFromType(type.name);
// 			const url = type.url+"/" + action.payload._id;
// 			try {
// 				yield put({ type: status, payload: {status: DELETING} });
// 				yield call(remove, url);
// 				yield put({ type: "UNSET_" + type.name, payload: action.payload._id });
// 				yield put({ type: status, payload: {status: LOADED} });
// 			} catch (e) {
// 				yield put({ type: status, payload: {status: DELETE_FAILED} });
// 			}
// 		});
// 	};


function* rootSaga() {
	yield all([
		loadPatient(),
		loadPatients(), 
		insertPatients(), updatePatients(),insertConsultation(),
		trashPatients(),untrashPatients(),removePatients(),
	
		loadConsultations(),
		updateConsultation(),
		trashConsultation(),
		untrashConsultation(),
		insertBioimpedancia(),
		updateBioimpedancia(),
		trashBioimpedancia(),
		untrashBioimpedancia(),
		removeBioimpedancia(),
		insertLaboratorio(),
		updateLaboratorio(),
		trashLaboratorio(),
		untrashLaboratorio(),
		removeLaboratorio()
		// updateConsultation(),
		// trashConsultation(),
		// untrashConsultation(),
		// removeConsultation(),
	]);
}

export default rootSaga;
