import React, { useEffect, useState } from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useImmer } from "use-immer";
import styled from "styled-components";
import i18n from "i18n";
import {Edit, Delete} from "@mui/icons-material";
import { Button, DialogTitle, Tooltip } from "@mui/material";
import * as immutable from "object-path-immutable";
import WhiteButton from "components/Buttons/WhiteButton";
import DynamicImage from "components/DynamicImage";

const productosPredeterminados = {
	frut_verd: ["Manzana", "Pera", "Plátano", "Mango", "Papaya", "Sandía", "Fresa", "Zarzamora", "Frambuesa", "Naranja", "Toronja", "Melón", "Durazno", "Guayaba", "Uva", "Piña", "Mamey", "Ciruela", "Jitomate", "Aguacate", "Zanahoria", "Jicama", "Pepino", "Lechuga", "Espinaca", "Calabaza", "Nopal", "Chayote", "Champiñón", "Cebolla", "Betabel", "Espárrago", "Ejote", "Chícharos", "Papa", "Brócoli", "Limón" ],
	cer_leg: ["Pan de caja integral", "Pan de centeno", "Pan de caja sin gluten", "Totopos de maíz", "Tortilla de maíz", "Tortilla de nopal", "Avena", "Amaranto", "Quinoa ", "Arroz", "Galletas horneadas de maíz", "Galletas integrales", "Cereal de caja natural", "Barra de avena", "Barra de Amaranto", "Frijol", "Lenteja", "Garbanzo", "Haba", "Hummus"],
	lac: ["Leche deslactosada", "Leche baja en grasa", "Leche de almendra", "Leche de soya", "Leche de arroz", "Leche de coco", "Yogurt natural", "Yogurt natural Griego", "Yogurt deslactosado"],
	prot: ["Pechuga de pollo", "Filete de Pescado", "Salmón fresco", "Salmón ahumado", "Atún fresco", "Atún de lata", "Molida de res", "Filete de res", "Bistec de res", "Molida de cerdo", "Pulpa de cerdo", "Bistec de cerdo", "Queso Panela", "Queso Oaxaca", "Queso Cottage", "Queso de Cabra", "Queso manchego", "Requesón"],
	bebidas: ["Agua simple", "Bebida isotónica", "Te varios sabores", "Café ", "Aguas saborizadas sin azúcar ", "Bebida en polvo sin azúcar ", "Infusiones ", "Zumo de frutas", "Zumo de verduras", "Agua de coco",],
	otros: ["Caja de té varios sabores", "Edulcorante con stevia", "Edulcorante con sucralosa", "Café soluble ", "Cocoa en polvo", "Aceite en aerosol", "Agua embotellada"],
};

const categorias = [
	{
		color: "#f767a4",
		name: "frut_verd",
		label: i18n.t("common:modal_lista_compras_frut_verd"),
		img: "frutas"
	},
	{
		color: "#cc9556",
		name: "cer_leg",
		label: i18n.t("common:modal_lista_compras_cer_leg"),
		img: "cereales"
	},
	{
		color: "#3bb8d7",
		name: "lac",
		label: i18n.t("common:modal_lista_compras_lac"),
		img: "lacteos"
	},
	{
		color: "#e76e67",
		name: "prot",
		label: i18n.t("common:modal_lista_compras_prot"),
		img: "proteinas"
	},
	{
		color: "#ff3f55",
		name: "bebidas",
		label: i18n.t("common:modal_lista_compras_bebidas"),
		img: "bebidas"
	},
	{
		color: "#7be35c",
		name: "otros",
		label: i18n.t("common:modal_lista_compras_otros"),
		img: "otros"
	},
];

const ModalListaCompras = ({onChange, data: value}) => {
	const {t} = useTranslation("common");
	const [inputValues, setInputValues] = useImmer(["", "", "", "", "", ""]);
	const [selectedProductosPredeterminados, setSelectedProductosPredeterminados] = useImmer([]);
	const [data, setData] = useState(value ?? {
		nombre: "",
		lista: {
			frut_verd: [],
			cer_leg: [],
			lac: [],
			prot: [],
			otros: [],
			bebidas: []
		},
	});
	const elements = [
		{
			type:"text",
			name:"nombre",
			label: t("lista_compras_nombre")
		}
	];

	const insertProducto = (value, name) => {
		const newData = [...(data?.lista?.[name] || []), {producto: value}];
		setData(state=>{
			return immutable.set(state, `lista.${name}`, newData);
		})
	};

	const insertFromDefaultProductos = (value, name) => {
		setSelectedProductosPredeterminados(draft => {draft.push(value);});
		insertProducto(value, name);
	};

	const onChangeProducto = (value, name, idy) => {
		setData(state=>{
			return immutable.set(state, `lista.${name}.${idy}.producto`, value);
		})
	};

	const onDeleteProducto = (idy, name) => {
		let newData = [...data.lista[name]];
		newData = [...newData.slice(0, idy), ...newData.slice(idy + 1)];
		
		setData(state=>{
			return immutable.set(state, `lista.${name}`, newData);
		})
	};

	useEffect(() => {
		onChange(data)
	}, [data])
	
	
	return (
		<>
			<ModalForm elements={elements} onChange={(value, name)=>setData(state => ({...state, [name]: value}))} data={data}>
			{/* <ModalForm elements={elements} onChange={(value, name)=>onChange(value, name)} data={data}> */}
				<ContainerModalListaCompras>
					{categorias.map((val, idx) => (
						<div className="cont-lista" key={idx} style={{background:val.color+"19"}}>
							<div className="header">
								<DynamicImage
									src={`${val.img}.png`}
									alt={val.label}
									className="img-category"
								/>
								<p className="label-category" style={{color: val.color}}>{val.label}</p>
							</div>
							<div className="cont-input-add-producto">
								<div className="cont-input">
									<p className="p-sug">Presiona Enter para añadirlo a tu lista</p>
									<form>
										<input
											type="text"
											className="input"
											name="producto"
											id="producto"
											placeholder="Añadir producto"
											onKeyDown={(e) => {
												if(!inputValues[idx]) return;
												if (e.key === "Enter") {
													insertProducto(inputValues[idx], val.name);
													setInputValues(draft => {draft[idx] = "";});
													e.preventDefault();
												}
											}}
											onChange={e => {e.persist(); setInputValues(draft=> {draft[idx] = e.target.value;});}}
											value={inputValues[idx]}
										/>
									</form>
								</div>
								<div className="cont-alim-pred">
									<p className="p-sug">Sugerencias</p>
									<div>
										{productosPredeterminados[val.name].filter(defFood => !selectedProductosPredeterminados.includes(defFood)).map((defFood, idy) => <button key={idy} onClick={()=>insertFromDefaultProductos(defFood, val.name)} className="alim-pred" style={{backgroundColor: val.color}}>{defFood}</button>)}
									</div>
								</div>
							</div>
							<div className="cont-alimentos">
								{data?.lista?.[val.name]?.map(({producto, createdByPatient}, idy) => (
									<div className="cont-producto" key={idx + idy}>
										<input
											key={idy}
											className={`input ${createdByPatient && 'created-by-patient'}`}
											name="producto"
											id="producto"
											onChange={e => onChangeProducto(e.target.value, val.name, idy)}
											value={!createdByPatient ? producto : `${producto} (Creado por el paciente)`}
											disabled={createdByPatient}
										/>
										{!createdByPatient && <WhiteButton type="eliminar" onClick={()=>onDeleteProducto(idy, val.name)} />}
									</div>
								))}
							</div>
						</div>
					))}
				</ContainerModalListaCompras>
			</ModalForm>
		</>
	);
};

export default ModalListaCompras;

ModalListaCompras.propTypes = {
	onChange: PropTypes.func,
	data: PropTypes.object
};

const ContainerModalListaCompras = styled.div`
	.cont-lista{
		margin: 20px 0;
		padding: 15px;
		border-radius: 10px;
		.header{
			display: flex;
			align-items: center;
			margin-bottom: 10px;
			.img-category{
				width: 30px;
				margin-right: 10px;
			}
		}
		.cont-input-add-producto{
			margin-bottom: 10px;
			display: flex;
			.cont-alim-pred{
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				height: 55px;
				overflow: hidden;
				justify-content: flex-start;
				padding-left: 10px;
				width: 60%;
				.alim-pred{
					border-color: transparent;
					border-radius: 50px;
					padding: 5px 10px;
					margin: 0 5px 5px 0;
					height: 35px
				}
			}
			.p-sug{
				font-style: italic;
				font-size: 12px;
				margin-bottom: 5px;
				margin-right: 10px;
			}
			.cont-input{
				width: 40%;
				input{
					width: 100%;
				}
			}
		}
		.cont-alimentos{
			.cont-producto{
				display: flex;
				align-items: center;
				margin: 10px 0;
				input{
					width: 100%;
				}
				.tooltip{
					display: none;
				}
				&:hover > .tooltip{
					display: block;
				}
			}
		} 
		input{
			border: 1px solid transparent;
			border-radius: 10px;
			padding: 10px;
			transition: .3s;
			&:focus{
				outline: none;
				box-shadow: 0 0 10px #ccc;
			}
			&.created-by-patient{
				font-style: italic;
			}
		}
		input:disabled{
			background: #fafafa;
		}
	}
`;