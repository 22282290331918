export const READY = "READY";
export const LOADING = "LOADING";
export const LOADED = "LOADED";
export const LOAD_FAILED = "LOAD_FAILED";

export const SAVING = "SAVING";
export const SAVED = "SAVED";
export const SAVE_FAILED = "SAVE_FAILED";

export const INSERTING = "INSERTING";
export const INSERTED = "INSERTED";
export const INSERT_FAILED = "INSERT_FAILED";

export const UPDATING = "UPDATING";
export const UPDATED = "UPDATED";
export const UPDATE_FAILED = "UPDATE_FAILED";

export const DELETING = "DELETING";
export const DELETED = "DELETED";
export const DELETE_FAILED = "DELETE_FAILED";

export const LOAD = "LOAD_";
export const INSERT = "INSERT_";
export const UPDATE = "UPDATE_";
export const REMOVE = "REMOVE_";
export const TRASH = "TRASH_";
export const UNTRASH = "UNTRASH_";

export const EQUIVALENT_SMAE_DIET= {url:"admin/diets/equivalents-smae", name:"EQUIVALENT_SMAE_DIET", label:"Dieta"};
export const EQUIVALENT_SMAE= {url:"admin/equivalents-smae", name:"EQUIVALENT_SMAE", label:"Equivalente"};
export const EQUIVALENT_SMAE_LIST= {url:"admin/equivalents-smae/list", name:"EQUIVALENT_SMAE_LIST", label:"Lista"};
export const EQUIVALENT_SMAE_SUBCAT= {url:"admin/equivalents-smae/sub-cat", name:"EQUIVALENT_SMAE_SUBCAT", label:"Grupo"};

export const EQUIVALENT_DIET= {url:"admin/diets/equivalents", name:"EQUIVALENT_DIET", label:"Dieta"};
export const EQUIVALENT= {url:"admin/equivalents", name:"EQUIVALENT", label:"Equivalente"};
export const EQUIVALENT_LIST= {url:"admin/equivalents/list", name:"EQUIVALENT_LIST", label:"Lista"};
export const EQUIVALENT_SUBCAT= {url:"admin/equivalents/sub-cat", name:"EQUIVALENT_SUBCAT", label:"Grupo"};
export const MENU_DIET= {url:"admin/diets/menu", name:"MENU_DIET", label:"Dieta"};
export const COLUMNS_DIET= {url:"admin/diets/columns", name:"COLUMNS_DIET", label:"Dieta"};
export const OPTIONS_DIET= {url:"admin/diets/options", name:"OPTIONS_DIET", label:"Dieta"};
export const PERSONALIZED_DIET= {url:"admin/diets/personalized", name:"PERSONALIZED_DIET", label:"Dieta"};

export const MENU_SELECT_DIET= {url:"admin/diets/menu-select", name:"MENU_SELECT_DIET", label:"Dieta"};
export const MENU_SELECT_LIST= {url:"admin/diets/menu-select/list", name:"MENU_SELECT_LIST", label:"Alimento"};

export const RECEIPE= {url:"admin/receipes", name:"RECEIPE", label:"Receta"};
export const RECEIPE_TAG= {url:"admin/receipes/tags", name:"RECEIPE_TAG", label:"Etiqueta"};
export const RECEIPE_CATEGORY= {url:"admin/receipes/categories", name:"RECEIPE_CATEGORY", label:"Categoría"};

export const PRODUCT= {url:"admin/products", name:"PRODUCT", label:"Producto"};
export const PRODUCT_TAG= {url:"admin/products/tags", name:"PRODUCT_TAG", label:"Etiqueta"};
export const PRODUCT_CATEGORY= {url:"admin/products/categories", name:"PRODUCT_CATEGORY", label:"Categoría"};

export const PRIZE= {url:"prizes", name:"PRIZE", label:"Premio"};
export const APPOINTMENT= {url:"appointments", name:"APPOINTMENT", label:"Cita"};
export const PATIENTS= {url:"patients", name:"PATIENTS", label:"Paciente"};
export const MESSAGE= {url:"admin/patients/push-notification", name:"MESSAGE", label:"Mensaje"};
export const CUSTOMER= {url:"admin/customers", name:"CUSTOMER", label:"Cliente"};
export const SUCURSAL= { url:"customers",url_tail:"sucursales", name:"CUSTOMER", base_name:"CUSTOMER", tail_name:"SUCURSAL", key:"consultorios", label:"Sucursal" };
export const BIOIMPEDANCIA= { url:"consultation", url_tail:"bioimpedancia", name:"CONSULTATION", base_name:"CONSULTATION", tail_name:"BIOIMPEDANCIA", key:"bioimpedancia", label:"Bioimpedancia" };

export const LABORATORIO= { url:"consultation", url_tail:"laboratorio", name:"CONSULTATION", base_name:"CONSULTATION", tail_name:"LABORATORIO", key:"laboratorio", label:"Laboratorio" };

export const PLAN= {url:"plans", name:"PLAN", label:"Plan"};
export const LICENCE_COUPON= {url:"admin/licences/coupons", name:"LICENCE_COUPON", label:"Cupon"};

export const CONSULTATION= {url:"consultations", name:"CONSULTATION", label:"Consulta"};
export const DIA_INHABIL= { url:"nutritionists",url_tail:"dia-inhabil", name:"NUTRITIONIST", base_name:"NUTRITIONIST", tail_name:"DIA_INNHABIL", key:"horarios.dia_inhabil", label:"Dia inhabil" };

export const DISCOUNT= {url:"admin/discounts", name:"DISCOUNT", label:"Descuento"};
export const NUTRITIONIST= {url:"admin/nutritionists", name:"NUTRITIONIST", label:"Nutriólogo"};
export const ONLINE_CONSULTATION= {url:"admin/online-consultations", name:"ONLINE_CONSULTATION", label:"Consulta online"};
export const ONLINE_PATIENT= {url:"admin/patients", name:"ONLINE_PATIENT", label:"Paciente online"};

export const NUTRITIONIST_MESSAGE= {url:"nutritionist-message", name:"NUTRITIONIST_MESSAGE", label:"Mensaje"};
export const NUTRITIONIST_NOTIFICATION= {url:"admin/nutritionist-notifications", name:"NUTRITIONIST_NOTIFICATION", label:"Notificación"};
export const NUTRITIONIST_NOTIFICATION_TAGS= {url:"admin/nutritionist-notifications/tags", name:"NUTRITIONIST_NOTIFICATION_TAGS", label:"Etiquetas"};

export const INFOGRAFIA= {url:"admin/infografias", name:"INFOGRAFIAS", label:"Infografia"};
export const TUTORIAL= {url:"admin/tutoriales", name:"TUTORIALES", label:"Tutorial"};


export const makeStatusFromType = type => type.toUpperCase() + "_STATUS";
