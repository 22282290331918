import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";

const Receta = props => {
    const elements = React.useMemo(()=>[
    {
        type: "text",
        name: "nombre",
        label: "Nombre",
    },
    {
        type: "text",
        name: "descripcion",
        label: "Descripción",
    },
    {
        type: "text",
        name: "features",
        label: "Caracteristicas (separadas por enter)",
        multiline: true,
    },
    {
        type: "text",
        subtype: "number",
        name: "limite_pacientes",
        label: "Limite de pacientes",
    },
    {
				type: "text",
				subtype: "number",
        name: "limite_consultorios",
        label: "Limite de consultorios",
    },
    {
				type: "text",
				subtype: "number",
        name: "limite_nutriologos",
        label: "Limite de nutriologos",
    },
    {
        type: "bool",
        name: "pacientes_online",
        label: "Pacientes Online",
    },
    {
        type: "bool",
        name: "personalizado",
        label: "Personalizacion",
    },
    {
        type: "multiGroup",
        name: "planes",
        label: "Planes",
        disableOnValue:true,
        fields:[
            {
                type: "text",
                name: "nombre",
                label: "Nombre",
            },
            {
                type: "text",
								subtype: "number",
                name: "precio",
                label: "Precio en centavos",
                disableOnValue:true
            },
            {
                type: "select",
                name: "moneda",
                label: "Moneda",
                options:[
                    {label:'Selecciona una opción',value:-1},
                    {label:'Pesos Mexicanos',value:'mxn'},
                    {label:'Dólares',value:'usd'},
                    {label:'Euros',value:'eur'}
                ],
            },
            {
                type: "bool",
                name: "is_subscription",
                label: "Es subscripcion",
                disableOnValue:true
            },
            {
                type: "select",
                name: "intervalo",
                label: "Intervalo",
                options:[
                    {label:'Dia',value:'dia'},
                    {label:'Semana',value:'week'},
                    {label:'Mes',value:'month'},
                    {label:'Año',value:'year'},
                ],
                disableOnValue:true

            },
            {
								type: "text",
								subtype: "number",
                name: "tiempo_intervalo",
                label: "Cada cuanto",
                disableOnValue:true
            },
            {
								type: "text",
								subtype: "number",
                name: "msi",
                label: "Meses sin intereses",
                disableOnValue:true
            }
            
            
        ]
    },

  ],[]);

  return (
    <>
      <ModalForm elements={elements} onChange={props.onChange} data={props.data} {...props}></ModalForm>
    </>
  );
};

export default Receta;
