
import React, {useState, useRef,useEffect} from "react";


import {
	TextField,
	Checkbox
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {Autocomplete} from "@mui/lab";
import { useSelector } from "react-redux";
// const NoPaddingAutocomplete = withStyles({
//   inputRoot: {
//     '&&[class*="MuiOutlinedInput-root"]': {
//       padding: 1
//     }
//   },
//   input: {}
// })(Autocomplete);

function useTraceUpdate(props) {
	const prev = useRef(props);
	useEffect(() => {
		const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
			if (prev.current[k] !== v) {
				ps[k] = [prev.current[k], v];
			}
			return ps;
		}, {});
		if (Object.keys(changedProps).length > 0) {
			// console.log("Changed props:", changedProps);
		}
		prev.current = props;
	});
}
const MultiRefOptions = props => {
	useTraceUpdate(props);
	const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
	const checkedIcon = <CheckBoxIcon fontSize="small" />;
	// const options = useSelector(eval(props.selector));
	let options = props.options;
	const getLabel = (value) => {
		const obj = options.find(o=>o.value===value);
		return obj ? obj.label : "";
	};
	const handleChange = (event, value) =>  {
		props.onChange(value, props.name);
	};
	return <Autocomplete
		id="combo-box-demo"
		size="small"
		multiple
		defaultValue={props.value}
		disableCloseOnSelect
		options={options.map(o=>o.value)}
		getOptionLabel={option => getLabel(option)}
		renderOption={(option,{selected}) => (
			<>
				<React.Fragment>
					<Checkbox
						icon={icon}
						checkedIcon={checkedIcon}
						style={{ marginRight: 8 }}
						checked={selected}
					/>
					{getLabel(option)}
				</React.Fragment>
			</>
		)}
		style={{ width: "100%" }}
		onChange={handleChange}
		renderInput={params => (
			<>
				<TextField
					{...params}
					variant="outlined"
					fullWidth
				/>
			</>
		)}
	/>;
    
};

export default MultiRefOptions;



