import React, {useState} from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Button, IconButton, TextField } from "@mui/material";
import PropTypes from "prop-types";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";


import Menu from "@mui/material/Menu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useTranslation } from "react-i18next";
import moment from "moment";
import desayunoImg from "assets/img/desayuno.png";
import colacion1Img from "assets/img/colacion1.png";
import comidaImg from "assets/img/comida.png";
import colacion2Img from "assets/img/colacion2.png";
import cenaImg from "assets/img/cena.png";
import DynamicImage from "components/DynamicImage";
import dayjs from "dayjs"
const TiempoComida = ({tiempoComida, index, onChange, del, isAi}) => {
	const {t} = useTranslation("common");

	
	// useEffect(() => {
	// 	if(label || img){
	// 		onChange({label, img}, index)
	// 	}
	// 	}, [img, label])
			
			
			
	const [label, setLabel] = useState("");
	const [editImg, setEditImg] = useState(false);
	const [editlabel, setEditLabel] = useState(false);
	const [editTime, setEditTime] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	return (
		<div style={{display: "flex", flexDirection:"column", alignItems:"center",width: "80%"}}>			
			<IconButton
				aria-label="more"
				// aria-controls="long-menu"
				// aria-haspopup="true"
				// disableRipple={false}
				onClick={handleClick}
				style={{width: "100%", borderRadius: 0, margin: "12px", padding: 0}}
				className="avoid-print"
			>
				<MoreHorizIcon fontSize="small"/>
			</IconButton>
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
			>
				<MenuItem onClick={()=>{
					setEditImg(true);
					handleClose();
				}}>
					{t("tiempo_comida_editar_icono")}
				</MenuItem>
				<MenuItem onClick={()=>{
					setEditLabel(true);
					handleClose();
				}}>
					{t("tiempo_comida_editar_titulo")}
				</MenuItem>
				<MenuItem onClick={()=>{
					setEditTime(true);
					handleClose();
				}}>
					Editar hora
				</MenuItem>
				{!isAi && <MenuItem onClick={()=>{
					del(index);
					handleClose();
					setEditLabel(false);
				}}>
					{t("eliminar")}
				</MenuItem>}
			</Menu>
			<div style={{
				display:"flex",
				// background:tiempoComida.nombre_ti	empo.color+"38", 
				flexDirection: "column",	
				alignItems: "center", 
				justifyContent: "center",
				width: "100%"
			}}>
				{editImg || !tiempoComida.nombre_tiempo.img ? 
					<>
						<Select
							value={tiempoComida.nombre_tiempo.img}
							renderValue={(value)=>(
								<DynamicImage
									src={`${value}.png`}
									style={{height:"30px"}}
									className="img-category"
									alt="tiempo-comida"
								/>
							)}
							onChange={(e)=>{
								onChange("img", e.target.value, index);
								setEditImg(false);
							}}
						>
							<MenuItem value={"desayuno"}>
				
								<img
									src={desayunoImg}
									style={{height:"30px"}}
									className="img-category"
									alt="tiempo-comida"
								/>
							</MenuItem>
							<MenuItem value={"colacion1"}>
								<img
									src={colacion1Img}
									style={{height:"30px"}}
									className="img-category"
									alt="tiempo-comida"
								/>
				
							</MenuItem>
							<MenuItem value={"comida"}>
								<img
									src={comidaImg}
									style={{height:"30px"}}
									className="img-category"
									alt="tiempo-comida"
								/>
				
							</MenuItem>
							<MenuItem value={"colacion2"}>
								<img
									src={colacion2Img}
									style={{height:"30px"}}
									className="img-category"
									alt="tiempo-comida"
								/>
							</MenuItem>
							<MenuItem value={"cena"}>
								<img
									src={cenaImg}
									style={{height:"30px"}}
									className="img-category"
									alt="tiempo-comida"
								/>
							</MenuItem>
						</Select>
						<p style={{color: "#00000070"}}>{t("tiempo_comida_elige_icono")}</p>
					</>
					:
					<div>
						<DynamicImage
							src={`${tiempoComida.nombre_tiempo.img}.png`}
							alt={tiempoComida.nombre_tiempo.name}
							className="img-category"
						/>
					</div>
				}
				{editlabel || !tiempoComida.nombre_tiempo.label ? 
					<div style={{textAlign: "center", marginTop: "5px"}}>
						<TextField 
							placeholder={t("tiempo_comida_asignar_nombre")}
							value={label} 
							onChange={(e) => setLabel(e.target.value)}
							className="text-field"
						/>
						<div style={{display: "flex", justifyContent: "center"}}>
							{tiempoComida.nombre_tiempo.label &&
								<Button
									style={{marginTop: "5px"}}
									onClick={() => {
										setEditLabel(false);
										setLabel("");
									}}
								>{t("cancelar")}</Button>
							}
							<Button 
								variant="contained" 
								color="secondary" 
								style={{marginTop: "5px"}}
								onClick={() => {
									onChange("label", label, index);
									setLabel("");
									setEditLabel(false);
								}}
							>{t("tiempo_comida_asignar")}</Button>
						</div>
					</div>
					:
					<p>{tiempoComida.nombre_tiempo.label}</p>
				}
				{editTime || !tiempoComida.nombre_tiempo.hora_comer ? 
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<TimePicker
							// label="Hora de consumo"
							// variant="inline"
							value={tiempoComida.nombre_tiempo.hora_comer ? dayjs(tiempoComida.nombre_tiempo.hora_comer) : ""}
							minutesStep={5}
							onChange={(value)=>{
								onChange("hora_comer", value.toISOString(), index);
								setEditTime(false);
							}}
							style={{marginTop: 5}}
						/>
						<p style={{fontSize: "8pt"}}>{t("tiempo_comida_asignar_hora")}</p>
					</LocalizationProvider>
					:
					<p>{moment(tiempoComida.nombre_tiempo.hora_comer).format("hh:mm a")}</p>
				}
			</div>
		</div>
	);
};

export default TiempoComida;

TiempoComida.propTypes = {
	tiempoComida: PropTypes.object, 
	index: PropTypes.number,
	del: PropTypes.func,
	onChange: PropTypes.func,
};