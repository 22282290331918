import React, {useRef} from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import {CUSTOMER} from "store/constants";
import DateCell from "components/Table/CellTypes/DateCell/DateCell";
import MapCell from "components/Table/CellTypes/MapCell/MapCell";
import { Authorization} from "api/index";
import HTMLEditor from "./HTMLEditor";
import GratuitoFilterCell from "./GratuitoFilterCell";

function Notificacion(props) {		
  const editor = useRef(null);
	const [defaultValue] = React.useState(props?.data?.mensaje || "");
  const onChange = React.useMemo(()=>(val)=>{
		props.onChange(val,"mensaje");
	});
  
  const columnsClientes = React.useMemo(
    () => [
      {
        Header: "Clave",
        accessor: "clave"
      },
      {
        Header: "Nombre",
        accessor: "nutritionist.nombre"
        // Cell: UserCell
      },
      {
        Header: "Correo",
        accessor: "nutritionist.correo"
      },
      {
        Header: "Teléfono",
        accessor: "nutritionist.telefono"
      },
      // {
      //   Header: "Vencimiento",
      //   accessor: "subscripcion.vencimiento",
      //   Cell:DateCell,
      // },
      {
        Header: "Ultima conexion",
        accessor: "ultima_conexion",
        Cell:DateCell,
      },
      {
        Header: "Tipo de plan",
        accessor: "subscripcion.tipo_pago",
      },
      {
        Header: "País",
        accessor: "nutritionist.pais"
      },
      // {
      //   Header: "Plan activo",
      //   accessor: "subscripcion.activo",
      //   Cell: MapCell,
      //   map: {[true]: "Activo", [false]: "Inactivo"},

      // },
      // {
      //   Header: "Tiempo en Nutrify",
      //   accessor: "_created_date",
      // },
    ],
    []
  );
	console.log("dksklml")
  const filters = React.useMemo(
    () => [
      {
        Header: "Clave",
        accessor: "clave"
      },
      {
        Header: "Nombre",
        accessor: "nutritionist.nombre"
      },
      {
        Header: "Correo",
        accessor: "nutritionist.correo"
      },
      {
        Header: "Teléfono",
        accessor: "nutritionist.telefono"
      },
      {
        Header: "Vencimiento",
        accessor: "subscripcion.vencimiento",
        Cell:DateCell,
        filter:"daterange",
        sortType: "date"
      },
      {
        Header: "Ultima conexion",
        accessor: "ultima_conexion",
        Cell:DateCell,
        filter:"daterange",
        sortType: "date"
      },
      {
        Header: "Tipo de plan",
        accessor: "subscripcion.tipo_pago",
        filter:"select",
        filterOptions: [{value: "", label: "Todos"}, {value: "mensual", label: "Mensual"}, {value: "anual", label: "Anual"}],
      },
      {
        Header: "País",
        accessor: ""
      },
      {
        Header: "Plan activo",
        accessor: "subscripcion.id_plan",
        Filtro: GratuitoFilterCell,
        filterOptions: [{value: "", label: "Todos"}, {value: "gratis", label: "Gratuitos"}, {value: "paga", label: "De paga"}],
      },
      {
        Header: "Tiempo en Nutrify",
        accessor: "_created_date",
        filter:"daterange",
        sortType: "date"
      },
      {
        Header: "Tiempo en Nutrify",
        accessor: "_created_date",
        filter:"daterange",
        sortType: "date"
      },
    ],
    []
  );
  const elements = [
  {
    type:"multiSelect",
    name:"nut",
    label: "Para",
    datatype:CUSTOMER,
    columns:columnsClientes,
    filters:filters,
		remote:true,
  },
  {
    type:"text",
    name:"titulo",
    label:"Titulo notificación"
  },
  {
    type:"text",
    name:"subtitulo",
    label: "Subtitulo notificación"
  },
  {
    type: "file",
    name: "imagen",
    label: "Imagen de notificación",
    onFile: (file, name)=>props.onFile(file, name)
  },
  {
    
			type:"multiref",
			name:"tags",
			label:"Etiquetas",
			selector:(state)=>state.NUTRITIONIST_NOTIFICATION_TAGS.data.filter((r)=>!r._trash)
  },
  {
    type: "multiGroup",
    name: "acciones",
    label: "Acciones",
    fields:[
      {
        type:'select',
        name:'coleccion',
        options:[
          {label: 'Recetas', value: 'RECEIPE'},
          {label: 'Productos', value: 'PRODUCT'},
          {label: 'Dieta Selección de alimentos', value: 'MENU_SELECT_DIET'},
          {label: 'Dieta SMAE', value: 'EQUIVALENT_SMAE_DIET'},
          {label: 'Dieta Equivalencias', value: 'EQUIVALENT_DIET'},
          {label: 'Dieta Menu', value: 'MENU_DIET'},
          {label: 'Dieta Columnas', value: 'COLUMNS_DIET'},
          {label: 'Dieta Opciones', value: 'OPTIONS_DIET'},
          {label: 'Dieta Personalizada', value: 'PERSONALIZED_DIET'},
        ]
      },
      {
        type:'dynamicRef',
        name:'ids'
      },
    ]
  },
  {
    type: "radio",
    name: "autoOpen",
    label: "Abrir en automático",
    options: [
      {label: "No", value: "false"},
      {label: "Si", value: "true"}
    ]
  },
  
];
const config = React.useMemo(
  () => ({
    key:"9f953c25d7f8d0805d8dcbe856fd48ef",
    language: "es", 
    enableDragAndDropFileToEditor: true,
    uploader: {
      url: `${process.env.REACT_APP_API_URL}admin/nutritionist-notifications/imagen`,
      headers:{
        "Authorization": Authorization
      },
      isSuccess: function (resp) {
        return resp;
      },
      process: function (resp) { 
        return {
          files: resp.data.files,
          path: resp.data.path,
          baseurl: resp.data.baseurl,
          error: resp.data.error,
          message: resp.data.message
        };
      },
      defaultHandlerSuccess: function (data) {
        var i, field = "files";      
        if (data[field] && data[field].length) {
          for (i = 0; i < data[field].length; i += 1) {
            editor.current.current.selection.insertImage(data.baseurl+"/"+ data[field][i], {}, 100);
          }
        }
      },
    },


    // tabIndex: 1,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false, 
    autoResize: false
    // triggerChangeEvent: false, 
    // processPasteFromWord: true
  }),
  []
);

  return (
    <div>
      <ModalForm elements={elements} onChange={props.onChange} data={props.data}></ModalForm>
      <div style={{width: "60%", margin: "auto"}}>
        <HTMLEditor
              style={{display:"none"}}
              ref={editor}
              defaultValue={props.data?.mensaje ?? ""}
              value={props.data?.mensaje}
              config={config}
              tabIndex={1} // tabIndex of textarea
              onChange={onChange} 
            />
      </div>
    </div>
  )
}

export default Notificacion
