import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";

const Bioimpedancia = props => {
	const elements = React.useMemo(()=>[
    
		{
			type: "text",
			name: "nombre",
			label: "Titulo",
		},
		{
			type: "calendar",
			name: "fecha",
			label: "Fecha",
		},
		{
			type: "file",
			name: "archivo",
			label: "Archivo",
			acceptFiles: true,
			download: true
		}
	],[]);
	return (
		<>
			<ModalForm elements={elements}  onChange={props.onChange} data={props.data} {...props}></ModalForm>
		</>
	);
};

export default Bioimpedancia;
