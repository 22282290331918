
import React from "react";
import styled from "styled-components";
import { Tooltip } from "@mui/material";
// import editarIcon from "v2/icons/editar.svg";
// import archivarIcon from "v2/icons/archivar.svg";
// import desarchivarIcon from "v2/icons/desarchivar.svg";
// import eliminarIcon from "v2/icons/eliminar.svg";
// import masIcon from "v2/icons/components/mas.png";
// import confIcon from "v2/icons/conf.png";
// import reenviarIcon from "v2/icons/reenviar.svg";
// import ocularIcon from "v2/icons/ocultar.svg";
// import verIcon from "v2/icons/ver.svg";
// import lockIcon from "v2/icons/lock.svg";
import DynamicImage from "components/DynamicImage";

const types = {
	editar: {label: "Editar", icon: "v2/icons/editar.svg"},
	archivar: {label: "Archivar", icon: "v2/icons/archivar.svg"},
	desarchivar: {label: "Desarchivar", icon: "v2/icons/desarchivar.svg"},
	eliminar: {label: "Eliminar", icon: "v2/icons/eliminar.svg"},
	mas: {label: "Más", icon: "v2/icons/components/mas.png"},
	configuracion: {label: "Configuración", icon: "v2/icons/conf.png"},
	reenviar: {label: "Reenviar", icon: "v2/icons/reenviar.svg"},
	ocultar: {label: "Ocultar", icon: "v2/icons/ocultar.svg"},
	ver: {label: "Ver", icon: "v2/icons/ver.svg"},
	locked: {label: "", icon: "v2/icons/lock.svg"},
};

export default function WhiteButton({label, onClick, style, img, type, styles={}}){
	const t = React.useMemo(()=>types[type],[type]);
	return	<Tooltip title={t?.label || label}>
		<Box style={{ ...style, margin:0}} onClick={onClick}>
			<div className="box">
				{(img || t?.icon) && <DynamicImage style={{...styles.icon}} className="icon" src={t?.icon || img} />}
			</div>
		</Box>
	</Tooltip>;
}
const Box = styled.div`
	margin:0;
	width:36px;
		height:36px;
	.box{
		margin: 0px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: white;
		// padding: 8px 9px;
		border-radius: 5px;
		box-shadow: 0px 10px 15px -3px rgb(0 0 0 / 10%);
		
	}
	.icon{
		height:36px;
	}
`;