import React from "react";
import ReactToPrint from "react-to-print";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PrintIcon from "@mui/icons-material/Print";
import PropTypes from "prop-types";

const useStyles = makeStyles({
	root: {
		background: "#f8f8f8",
	},
});

export default function PrintButton(props){
	const classes = useStyles();
	const afterPrint = (...args) => {
		if(props.onAfterPrint){
			props.onAfterPrint(...args);
		}
	};
	return (
		<>
			<ReactToPrint
				onAfterPrint={afterPrint}
				onBeforeGetContent={props.onBeforePrint}
				trigger={() => <Button classes={{root: classes.root}} variant="contained" component="span" startIcon={<PrintIcon />}>Imprimir</Button> }
				content={() => props.printRef.current} 
			/>
		</>
	)
}	

PrintButton.propTypes = {
	printRef: PropTypes.object,
	type: PropTypes.string
};
