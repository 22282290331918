import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { RECEIPE, RECEIPE_TAG, RECEIPE_CATEGORY} from "../constants";
import { all } from "redux-saga/effects";

const loadReceipes = loadRequest(RECEIPE);
const loadReceipeTags = loadRequest(RECEIPE_TAG);
const loadReceipeCategories = loadRequest(RECEIPE_CATEGORY);

const insertReceipe = insertRequest(RECEIPE);
const insertReceipeTag = insertRequest(RECEIPE_TAG);
const insertReceipeCategory = insertRequest(RECEIPE_CATEGORY);

const updateReceipe = updateRequest(RECEIPE);
const updateReceipeTag = updateRequest(RECEIPE_TAG);
const updateReceipeCategory = updateRequest(RECEIPE_CATEGORY);

const removeReceipe = removeRequest(RECEIPE);
const removeReceipeTag = removeRequest(RECEIPE_TAG);
const removeReceipeCategory = removeRequest(RECEIPE_CATEGORY);

const trashReceipe = trashRequest(RECEIPE);
const trashReceipeTag = trashRequest(RECEIPE_TAG);
const trashReceipeCategory = trashRequest(RECEIPE_CATEGORY);

const untrashReceipe = untrashRequest(RECEIPE);
const untrashReceipeTag = untrashRequest(RECEIPE_TAG);
const untrashReceipeCategory = untrashRequest(RECEIPE_CATEGORY);

function* rootSaga() {
	yield all([
		loadReceipes(),
		insertReceipe(),
		updateReceipe(),
		removeReceipe(),
		loadReceipeTags(),
		insertReceipeTag(),
		updateReceipeTag(),
		removeReceipeTag(),
		loadReceipeCategories(),
		insertReceipeCategory(),
		updateReceipeCategory(),
		removeReceipeCategory(),
		trashReceipe(),
		trashReceipeTag(),
		trashReceipeCategory(),
		untrashReceipe(),
		untrashReceipeCategory(),
		untrashReceipeTag(),
	]);
}
export default rootSaga;