import React, {useState} from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { PATIENTS } from "store/constants";
import { useRouteMatch } from "react-router-dom";
import PropTypes from "prop-types";

import Logo from "assets/img/logo/logo_nombre.png";

import "./HeaderPrint.scss";

export default function HeaderPrint(props){
	const me = useSelector(state=>state.CUSTOMER);
	const personalizado = me.data.personalizacion?.activa;
	const [widthImage, setWidthImage] = useState(0)
	let match = useRouteMatch();
	const dataPatient = useSelector(state=>state[PATIENTS.name].data.find(n=>n._id===match.params.idPaciente));

	const sizeImage = ({target:img}) => {
		if(img.naturalWidth/img.naturalHeight > 1.5){
			setWidthImage("70%");
			return;
		}
		setWidthImage("170px");
	};
	return(
		<>
			{props.data &&
			<div className="container-header-print">
				<div className="left">
					
					<p className="titulo">Fecha</p>
					<p>{moment().format("DD/MM/YYYY")}</p>
					{dataPatient &&
					<>
						<p className="titulo">Nombre paciente</p>
						<p>{dataPatient.nombre}</p>
					</>
					}
				</div>
				<div className="center">
					{props.data.recomendaciones &&
					<>
						<p className="titulo text-center">RECOMENDACIONES</p>
						{props.data.recomendaciones.split("\n").map(i => (
							<p key={i}>{i}</p>
						))}
					</>
					}
				</div>
				<div className="right">					
					{personalizado ?
						<img onLoad={sizeImage} src={`${process.env.REACT_APP_FILE_URL}${me.data.personalizacion?.logo?.path}`} width={widthImage} />
						:
						<img onLoad={sizeImage} src={Logo} alt="logo" width={widthImage} />
					}
				</div>
			</div>
			}
		</>
	);
}

HeaderPrint.propTypes = {
	data: PropTypes.object,
};