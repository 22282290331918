import React from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Password = props => {
	const [showPassword, setShowPassword] = React.useState(false);
	const [password, setPassword] = React.useState(props.value);
	const [confirmPassword, setConfirmPassword] = React.useState(props.value);

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};
	React.useEffect(()=>{
		setPassword(props.value);
		setConfirmPassword(props.value);
	},[props.value]);
	React.useEffect(()=>{
		if(password===confirmPassword) {
			props.onChange(password, props.name);
			props.setValid && props.setValid(true);
		}
		else{
			props.setValid && props.setValid(false);
		}
	},[password, confirmPassword]);


	return (
		<div>
			<TextField
				label="Contraseña"
				variant="outlined"
				value={password||""}
				id="standard-adornment-password"
				type={showPassword ? "text" : "password"}
				style={{
					margin:"10px 0"
				}}
				onChange={event => {
					setPassword(event.target.value);
				}}
				// endAdornment={
				// 	<InputAdornment position="end">
				// 		<IconButton
				// 			aria-label="toggle password visibility"
				// 			onClick={handleClickShowPassword}
				// 			onMouseDown={e=>e.preventDefault()}
				// 		>
				// 			{showPassword ? <Visibility /> : <VisibilityOff />}
				// 		</IconButton>
				// 	</InputAdornment>
				// }
			/>
			{props.confirmPassword && 
					<>
						<TextField
							label="Confirmar Contraseña"
							variant="outlined"
							id="standard-adornment-password"
							type={showPassword ? "text" : "password"}
							value={confirmPassword}
							error={password === confirmPassword ? false : true}
							onChange={event => {
								setConfirmPassword(event.target.value);
							}}
							// endAdornment={
							// 	<InputAdornment position="end">
							// 		<IconButton
							// 			aria-label="toggle password visibility"
							// 			onClick={handleClickShowPassword}
							// 			onMouseDown={e=>e.preventDefault()}
							// 		>
							// 			{showPassword ? <Visibility /> : <VisibilityOff />}
							// 		</IconButton>
							// 	</InputAdornment>
							// }
						/>
					</>  
			}
		</div>
	);
};

export default Password;
