import React from "react";
import Header from "components/Header/Header";
// import ModalPacientes from "./ModalPacientes";

import Box from "components/Box/Box";
import { get, put } from "api/index";
import "./Configuraciones.scss";

const Configuraciones = () => {
	const [configurations, setConfigurations] = React.useState({})
	React.useState(()=>{
		get("/config").then(({data})=>setConfigurations(data))
	},[])

	const onChangeData = async(name, value) => {
		put("/config", {[name]: value}).then(({data})=>setConfigurations(data));
	}


	return (
		<>
		<Header title="Configuración" />
		<div className="container-configuraciones">
			<Box label="Cupones" style={{width: "50%"}}>
				<label htmlFor="coupon.newUsers">Registros</label>
				<input name="coupon.newUsers" type="text" value={configurations?.coupon?.newUsers} onChange={(e)=>{onChangeData(e.target.name, e.target.value)}} />
				<label htmlFor="coupon.renovation">Renovaciones</label>
				<input name="coupon.renovation" type="text" value={configurations?.coupon?.renovation} onChange={(e)=>{onChangeData(e.target.name, e.target.value)}} />
			</Box>
		</div>

			
		</>
	);
};

export default Configuraciones;
