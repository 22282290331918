import { loadRequest,insertRequest, updateRequest, trashRequest, untrashRequest, removeRequest } from "./generators";
import { ONLINE_CONSULTATION } from "../constants";
import { all } from "redux-saga/effects";

const loadOnlineConsultations = loadRequest(ONLINE_CONSULTATION);
const insertOnlineConsultation = insertRequest(ONLINE_CONSULTATION);
const updateOnlineConsultation = updateRequest(ONLINE_CONSULTATION);
const trashOnlineConsultation = trashRequest(ONLINE_CONSULTATION);
const untrashOnlineConsultation = untrashRequest(ONLINE_CONSULTATION);
const removeOnlineConsultation = removeRequest(ONLINE_CONSULTATION);

function* rootSaga() {
	yield all([
		loadOnlineConsultations(),
		insertOnlineConsultation(),
		updateOnlineConsultation(),
		trashOnlineConsultation(),
		untrashOnlineConsultation(),
		removeOnlineConsultation(),
	]);
}
export default rootSaga;