import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { EQUIVALENT_DIET, MENU_DIET, COLUMNS_DIET, OPTIONS_DIET,PERSONALIZED_DIET, EQUIVALENT_SMAE_DIET, MENU_SELECT_DIET, MENU_SELECT_LIST } from "../constants";
import { all } from "redux-saga/effects";

const loadEquivalentDietsSmae = loadRequest(EQUIVALENT_SMAE_DIET);
const insertEquivalentDietSmae = insertRequest(EQUIVALENT_SMAE_DIET);
const updateEquivalentDietSmae = updateRequest(EQUIVALENT_SMAE_DIET);
const removeEquivalentDietSmae = removeRequest(EQUIVALENT_SMAE_DIET);
const trashEquivalentDietSmae = trashRequest(EQUIVALENT_SMAE_DIET);
const untrashEquivalentDietSmae = untrashRequest(EQUIVALENT_SMAE_DIET);

const loadEquivalentDiets = loadRequest(EQUIVALENT_DIET);
const insertEquivalentDiet = insertRequest(EQUIVALENT_DIET);
const updateEquivalentDiet = updateRequest(EQUIVALENT_DIET);
const removeEquivalentDiet = removeRequest(EQUIVALENT_DIET);
const trashEquivalentDiet = trashRequest(EQUIVALENT_DIET);
const untrashEquivalentDiet = untrashRequest(EQUIVALENT_DIET);

const loadMenuDiets = loadRequest(MENU_DIET);
const insertMenuDiet = insertRequest(MENU_DIET);
const updateMenuDiet = updateRequest(MENU_DIET);
const removeMenuDiet = removeRequest(MENU_DIET);
const trashMenuDiet = trashRequest(MENU_DIET);
const untrashMenuDiet = untrashRequest(MENU_DIET);

const loadColumnsDiets = loadRequest(COLUMNS_DIET);
const insertColumnsDiet = insertRequest(COLUMNS_DIET);
const updateColumnsDiet = updateRequest(COLUMNS_DIET);
const removeColumnsDiet = removeRequest(COLUMNS_DIET);
const trashColumnsDiet = trashRequest(COLUMNS_DIET);
const untrashColumnsDiet = untrashRequest(COLUMNS_DIET);

const loadOptionsDiets = loadRequest(OPTIONS_DIET);
const insertOptionsDiet = insertRequest(OPTIONS_DIET);
const updateOptionsDiet = updateRequest(OPTIONS_DIET);
const removeOptionsDiet = removeRequest(OPTIONS_DIET);
const trashOptionsDiet = trashRequest(OPTIONS_DIET);
const untrashOptionsDiet = untrashRequest(OPTIONS_DIET);

const loadPersonalizedDiets = loadRequest(PERSONALIZED_DIET);
const insertPersonalizedDiets = insertRequest(PERSONALIZED_DIET);
const updatePersonalizedDiets = updateRequest(PERSONALIZED_DIET);
const removePersonalizedDiets = removeRequest(PERSONALIZED_DIET);
const trashPersonalizedDiets = trashRequest(PERSONALIZED_DIET);
const untrashPersonalizedDiets = untrashRequest(PERSONALIZED_DIET);

const loadMenuSelectDiets = loadRequest(MENU_SELECT_DIET);
const insertMenuSelectDiet = insertRequest(MENU_SELECT_DIET);
const updateMenuSelectDiet = updateRequest(MENU_SELECT_DIET);
const removeMenuSelectDiet = removeRequest(MENU_SELECT_DIET);
const trashMenuSelectDiet = trashRequest(MENU_SELECT_DIET);
const untrashMenuSelectDiet = untrashRequest(MENU_SELECT_DIET);

const loadMenuSelectList = loadRequest(MENU_SELECT_LIST);
const insertMenuSelectList = insertRequest(MENU_SELECT_LIST);
const updateMenuSelectList = updateRequest(MENU_SELECT_LIST);
const removeMenuSelectList = removeRequest(MENU_SELECT_LIST);
const trashMenuSelectList = trashRequest(MENU_SELECT_LIST);
const untrashMenuSelectList = untrashRequest(MENU_SELECT_LIST);



function* rootSaga() {
	yield all([
		loadEquivalentDiets(),
		insertEquivalentDiet(),
		updateEquivalentDiet(),
		removeEquivalentDiet(),
		loadMenuDiets(),
		insertMenuDiet(),
		updateMenuDiet(),
		removeMenuDiet(),
		loadColumnsDiets(),
		insertColumnsDiet(),
		updateColumnsDiet(),
		removeColumnsDiet(),
		loadOptionsDiets(),
		insertOptionsDiet(),
		updateOptionsDiet(),
		removeOptionsDiet(),
		trashEquivalentDiet(),
		trashMenuDiet(),
		trashColumnsDiet(),
		trashOptionsDiet(),
		untrashEquivalentDiet(),
		untrashMenuDiet(),
		untrashColumnsDiet(),
		untrashOptionsDiet(),
		loadPersonalizedDiets(),
		insertPersonalizedDiets(),
		updatePersonalizedDiets(),
		trashPersonalizedDiets(),
		untrashPersonalizedDiets(),
		removePersonalizedDiets(),

		loadEquivalentDietsSmae(),
		insertEquivalentDietSmae(),
		updateEquivalentDietSmae(),
		removeEquivalentDietSmae(),
		trashEquivalentDietSmae(),
		untrashEquivalentDietSmae(),
		loadMenuSelectDiets(),
		insertMenuSelectDiet(),
		updateMenuSelectDiet(),
		removeMenuSelectDiet(),
		trashMenuSelectDiet(),
		untrashMenuSelectDiet(),

		loadMenuSelectList(),
		insertMenuSelectList(),
		updateMenuSelectList(),
		removeMenuSelectList(),
		trashMenuSelectList(),
		untrashMenuSelectList(),
	]);
}
export default rootSaga;