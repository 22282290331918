import React from "react";
import {
	Select,
	FormControl,
	TextField,
	makeStyles
} from "@mui/material";
import {	withStyles } from "@mui/styles"
import ClearIcon from "@mui/icons-material/Clear";
import PropTypes from "prop-types";
import "./SelectOrInput.scss";
import { useTranslation } from "react-i18next";

const NoPaddingSelect = withStyles({
	root: {
		"&&[class*=\"MuiSelect-outlined\"]": {
			padding: 10
		}
	}
})(Select);

const SelectOrInput = ({value, options, preventAutoChange, name, filter, variant, disabled, onChange}) => {
	const {t} = useTranslation("common");
	const [labelWidth] = React.useState(0);
	const [customValue, setCustomValue] = React.useState(false);
	
	React.useEffect(()=>{
		if(value===undefined && options && !preventAutoChange){
			onChange(options[0].value, name);
		}
	},[value]);

	React.useEffect(()=>{
		if(value && !options.find(o=>o.value===value)) setCustomValue(true);
	}, []);

	const fOnChange = (e) => {
		e.persist();    
		let val = e.target.value;
		val = filter==="boolean" && val!=="" ? val === "true" : val;
		if(val === "otro"){
			setCustomValue(true);
			onChange("", name);
			return;
		}
		onChange(val, name);

	};

	return (
		<div className="cont-select-or-input">

			{customValue ? 
				<TextField
					className="input"
					variant="outlined"
					size="small"
					required
					fullWidth
					id="custom"
					name="pais"
					value={value}
					onChange={fOnChange}
					placeholder={t("escribe_aqui")}
					InputProps={{
						endAdornment:<ClearIcon onClick={()=>setCustomValue(false)} fontSize="small" style={{color:"#3B4C4B", cursor: "pointer"}} />
					}}
				/> 
	
				:
				<FormControl variant={variant ?? "outlined"} >
					<NoPaddingSelect
						native
						style={{minWidth:"150px"}}
						value={value}
						labelId="label"
						labelWidth={labelWidth}
						disabled={disabled}
						onChange={fOnChange}>
						{options?.map((option,i)=>
							<option value={option.value} key={name + option.value + String(i)}>{option.label}</option>
						)}
						<option value="otro">Otro</option>
					</NoPaddingSelect> 
				</FormControl>
			}
		</div>
	);
};

export default SelectOrInput;

SelectOrInput.propTypes = {
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	options: PropTypes.array,
	name: PropTypes.string,
	onChange: PropTypes.func
};