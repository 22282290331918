import React, { useState, useEffect } from "react";
import { useImmer } from "use-immer";
import { Checkbox, FormGroup, Typography, FormControl, FormControlLabel } from "@mui/material";
import Radio from "components/FormComponents/Radio/Radio";
import ButtonAddTiempo from "../../ButtonAddTiempo";
import TiempoComidaVertical from "../../TiempoComidaVertical";
import PropTypes from "prop-types";
import Box from "components/Box/Box";
import {smae} from "misc/grupos";
import { PieChart } from "react-minimal-pie-chart";

import "./DietaEquivalenciasSmae.scss";

const EquivalenciasSmae = (props) => {

	const [resultados, setResultados] = useState({});	
	const [dieta, setDieta] = useImmer(props.value ? props.value : {
		nombre: "",
		recomendaciones: "",
		lista_equivalencias: "",
		distribuida: false,
		vis_pac:{
			ene: false,
			con_hid_car: false,
		},
		tiempos_comida: [
			{
				nombre_tiempo: {
					name: "desayuno",
					label: "Desayuno",
					img: "desayuno",
					color: "#EDC940"
				},
				c: {sg: undefined, cg: undefined}, p: {mb: undefined, mo: undefined, b: undefined, a: undefined}, g: {cp: undefined, sp: undefined}, f: undefined, v: undefined, l: {d: undefined, sd: undefined, e: undefined, ca: undefined}, azu: {cg: undefined, sg: undefined}, ag: undefined, leg: undefined,
			},
			{
				nombre_tiempo: {
					name: "colacion1",
					label: "Colación",
					img: "colacion1",
					color: "#54BDE9"
				},
				c: {sg: undefined, cg: undefined}, p: {mb: undefined, mo: undefined, b: undefined, a: undefined}, g: {cp: undefined, sp: undefined}, f: undefined, v: undefined, l: {d: undefined, sd: undefined, e: undefined, ca: undefined}, azu: {cg: undefined, sg: undefined}, ag: undefined, leg: undefined,
			},
			{
				nombre_tiempo: {
					name: "comida",
					label: "Comida",
					img: "comida",
					color: "#72BEB2"
				},
				c: {sg: undefined, cg: undefined}, p: {mb: undefined, mo: undefined, b: undefined, a: undefined}, g: {cp: undefined, sp: undefined}, f: undefined, v: undefined, l: {d: undefined, sd: undefined, e: undefined, ca: undefined}, azu: {cg: undefined, sg: undefined}, ag: undefined, leg: undefined,
			},
			{
				nombre_tiempo: {
					name: "colacion2",
					label: "Colación",
					img: "colacion2",
					color: "#4868AC"
				},
				c: {sg: undefined, cg: undefined}, p: {mb: undefined, mo: undefined, b: undefined, a: undefined}, g: {cp: undefined, sp: undefined}, f: undefined, v: undefined, l: {d: undefined, sd: undefined, e: undefined, ca: undefined}, azu: {cg: undefined, sg: undefined}, ag: undefined, leg: undefined,
			},
			{
				nombre_tiempo: {
					name: "cena",
					label: "Cena",
					img: "cena",
					color: "#9563A3"
				},
				c: {sg: undefined, cg: undefined}, p: {mb: undefined, mo: undefined, b: undefined, a: undefined}, g: {cp: undefined, sp: undefined}, f: undefined, v: undefined, l: {d: undefined, sd: undefined, e: undefined, ca: undefined}, azu: {cg: undefined, sg: undefined}, ag: undefined, leg: undefined,
			}
		]
	});

	var [totales, setTotales] = useState({});

	useEffect(() => {
		calcTotal();
		props.onChange(dieta.tiempos_comida, "tiempos_comida");
		props.onChange(dieta.distribuida, "distribuida");
		props.onChange(dieta.vis_pac, "vis_pac");
	}, [dieta]);

	const calcTotal = () => {
		let total = dieta.tiempos_comida.reduce((totales, next) => {
			totales.c.sg += next.c.sg ?? 0;
			totales.c.cg += next.c.cg ?? 0;
			totales.p.mb += next.p.mb ?? 0;
			totales.p.mo += next.p.mo ?? 0;
			totales.p.b += next.p.b ?? 0;
			totales.p.a += next.p.a ?? 0;
			totales.g.cp += next.g.cp ?? 0;
			totales.g.sp += next.g.sp ?? 0;
			totales.l.d += next.l.d ?? 0;
			totales.l.sd += next.l.sd ?? 0;
			totales.l.e += next.l.e ?? 0;
			totales.l.ca += next.l.ca ?? 0;
			totales.azu.cg += next.azu.cg ?? 0;
			totales.azu.sg += next.azu.sg ?? 0;
			totales.c.total = totales.c.sg + totales.c.cg;
			totales.p.total = totales.p.mb + totales.p.mo + totales.p.b + totales.p.a;
			totales.g.total = totales.g.cp + totales.g.sp;
			totales.l.total = totales.l.d + totales.l.sd + totales.l.e + totales.l.ca;
			totales.azu.total = totales.azu.cg + totales.azu.sg;
			totales.f.total += next.f ?? 0;
			totales.v.total += next.v ?? 0;
			totales.ag.total += next.ag ?? 0;
			totales.leg.total += next.leg ?? 0;
			return totales;
		},
		{
			c: {total: 0, sg: 0, cg: 0},
			p: {total: 0, mb: 0, mo: 0, b: 0, a: 0 },
			g: {total: 0, cp: 0, sp: 0},
			f: {total: 0},
			v: {total: 0}, 
			l: {total: 0, d: 0, sd: 0, e: 0, ca: 0}, 
			azu: {total: 0, cg: 0, sg: 0}, 
			ag: {total: 0}, 
			leg: {total: 0},
		});

		const resultados = smae.reduce((acc, g)=>{
			if(g.subcat){
				Object.entries(g.subcat).forEach(([key, value]) => {
					acc.cal += total[g.name][key] * +value.aporte.cal;
					acc.proteinas += total[g.name][key] * +value.aporte.proteinas;
					acc.lipidos += total[g.name][key] * +value.aporte.lipidos;
					acc.hidratos_de_carbono += total[g.name][key] * +value.aporte.hidratos_de_carbono;
					acc.conteo_hc += total[g.name][key] * +value.aporte.conteo_hc;
				});
			} else {
				acc.cal += total[g.name].total * +g.aporte.cal;
				acc.proteinas += total[g.name].total * +g.aporte.proteinas;
				acc.lipidos += total[g.name].total * +g.aporte.lipidos;
				acc.hidratos_de_carbono += total[g.name].total * +g.aporte.hidratos_de_carbono;
				acc.conteo_hc += total[g.name].total * +g.aporte.conteo_hc;
			}
			return acc;
		},{
			cal: 0,
			proteinas: 0,
			lipidos: 0,
			hidratos_de_carbono: 0,
			conteo_hc: 0
		});

		total = Object.entries(total).reduce((acc, [key, val])=>{
			if(val.total>0)return {...acc, [key]:val};
			return acc;
		},{});

		resultados.conteo_hc = resultados.conteo_hc.toFixed(2);

		const cien = resultados.proteinas + resultados.lipidos + resultados.hidratos_de_carbono;
		if(cien !== 0){
			resultados.proteinas_porcentaje = Number((((resultados.proteinas * 4) / resultados.cal) * 100).toFixed(0));
			resultados.lipidos_porcentaje = Number((((resultados.lipidos * 9) / resultados.cal) * 100).toFixed(0));
			resultados.hidratos_de_carbono_porcentaje = Number((((resultados.hidratos_de_carbono * 4) / resultados.cal) * 100).toFixed(0));
		}

		setResultados(resultados);
		setTotales(total);
		
	};

	const addTiempoComida = (idx_tiempo) => {
		setDieta(draft => {
			draft.tiempos_comida.splice(idx_tiempo, 0, {
				nombre_tiempo: {
					name: "extra",
					label: "",
					img: "",
					color: "#A5DD72"
				},
				c: {sg: undefined, cg: undefined}, p: {mb: undefined, mo: undefined, b: undefined, a: undefined}, g: {cp: undefined, sp: undefined}, f: undefined, v: undefined, l: {d: undefined, sd: undefined, e: undefined, ca: undefined}, azu: {cg: undefined, sg: undefined}, ag: undefined, leg: undefined,
			});
		});
	};

	const deleteTiempo = (idx_tiempo) => {
		setDieta(draft => {
			draft.tiempos_comida.splice(idx_tiempo,1);
		});
	};

	const onChangeTiempoComida = (name, value, idx) => {
		setDieta(draft => {
			draft.tiempos_comida[idx].nombre_tiempo[name] = value;
		});
	};

	return (
		<div className="container-dieta-equivalentes-smae printDiv">
			<FormGroup>
				<div className="avoid-print">

					<div style={{marginTop:"20px"}}>Selecciona como quieres que visualice los equivalentes tu paciente</div>
					<Radio 
						row
						value={dieta.distribuida}
						parse
						onChange={(v)=>{
							setDieta(draft=>{
								draft.distribuida = JSON.parse(v);
							});}} 
						options={[{label:"Distribuir por comidas",value:true},{label:"Total por día",value:false}]} />

				</div>
				<div className="results-box">
					<div style={{display:"flex",height:"60px", justifyContent:"space-between",background: "#F6F6F6",padding:"10px"}}>
						<div className="resultado-eq">
							<span className="resultado">{resultados.cal}</span>
							<Typography>ENERGÍA (KCal)</Typography>
						</div>
						<div className="resultado-eq">
							<span className="resultado">{resultados.proteinas}</span>
							<Typography>PROTEINAS (G)</Typography>
						</div>
						<div className="resultado-eq">
							<span className="resultado">{resultados.lipidos}</span>
							<Typography>LÍPIDOS (G)</Typography>
						</div>
						<div className="resultado-eq">
							<span className="resultado">{resultados.hidratos_de_carbono}</span>
							<Typography>HIDRATOS DE CARBONO (G)</Typography> 
						</div>
						<div className="resultado-eq">
							<span className="resultado">{resultados.conteo_hc}</span>
							<Typography>CONTEO DE HIDRATOS DE CARBONO</Typography>
						</div>
					</div>
				</div>
				<div className="results-box">
					<div className="container-chart">
						<div className="description-chart">
							<Typography style={{fontSize: "16pt", fontWeight: "boldS"}}>Distribución de macronutrientes % total VET</Typography>
							<Typography>PROTEÍNA {resultados.proteinas_porcentaje}%</Typography>
							<Typography>GRASA {resultados.lipidos_porcentaje}%</Typography>
							<Typography>HIDRATOS DE CARBONO {resultados.hidratos_de_carbono_porcentaje}%</Typography>
						</div>
						<div className="chart">
							<PieChart
								data={[
									{ title: "PROTEÍNA", value: resultados.proteinas_porcentaje ?? 1, color: "#E77067" },
									{ title: "GRASA", value: resultados.lipidos_porcentaje ?? 1, color: "#F7D542" },
									{ title: "HIDRATOS DE CARBONO", value: resultados.hidratos_de_carbono_porcentaje ?? 1, color: "#7AA9F9" },
								]}
							/>
						</div>
					</div>
				</div>
				<div className="avoid-print" style={{textAlign: "center"}}>
					<h2>Selecciona los conteos que tu paciente podrá ver en la app</h2>
					<FormControlLabel 
						control={<Checkbox color="primary" checked={dieta?.vis_pac?.ene} onChange={e=>setDieta(draft=>{draft.vis_pac.ene = e.target?.checked;})} value={dieta?.vis_pac?.ene} />}
						label="Energía (conteo calorías)"
					/>
					<FormControlLabel 
						control={<Checkbox color="primary" checked={dieta?.vis_pac?.con_hid_car} onChange={e=>setDieta(draft => {draft.vis_pac.con_hid_car = e.target?.checked;})} value={dieta?.vis_pac?.con_hid_car} />}
						label="Conteo de hidratos de carbono"
					/>
				</div>
				<Box label={dieta.distribuida ? "Distribución de equivalentes por comida" : "Distribución de equivalentes total por día"}>
					<div className="content-table">
						{dieta.distribuida && (
								
							<div className="header" style={{paddingLeft:"20%", display:"flex", alignItems:"flex-end"}}>
								<div className="row-table" style={{width: "90%", display: "inline-flex", paddingRight:"10px"}}>
									<div className="container-add-tiempo-comida avoid-print">
										<ButtonAddTiempo addTiempoComida={addTiempoComida} inde	x={0} text="+"/>
									</div>
									{dieta.tiempos_comida.map((value, i) => (
										<div key={i} style={{display:"flex", width:`${100/dieta.tiempos_comida.length}%`}}>
											<TiempoComidaVertical tiempoComida={value} index={i} onChange={onChangeTiempoComida} del={deleteTiempo}/>
											{/* <SelectIcon tiempoComida={value} index={i} onChange={onChangeTiempoComida }/>
										<SelectTitle tiempoComida={value} index={i} onChange={onChangeTiempoComida }/>
										<div className="container-add-tiempo-comida avoid-print">
											<ButtonAddTiempo addTiempoComida={addTiempoComida} index={i} text="+" />
										</div> */}
										
											<div className="container-add-tiempo-comida avoid-print" style={{width: "20%"}}>
												<ButtonAddTiempo addTiempoComida={addTiempoComida} index={i+1} text="+" />
											</div>
										</div>
									))}
								</div>
								<div className="totales">
									<div className="total total-grupo">
										<p>TOTAL POR GRUPO</p>
									</div>
									<div className="total total-general">
										<p>TOTAL GENERAL</p>
									</div>
								</div>
							</div>
						)}
						<div className="body">
							{smae.map((grupo, idx_grupo) => (
								<div className="row" key={idx_grupo} style={{background:grupo.color+"19", display: "flex", alignItems: "center", justifyContent:"space-between"}}>
									<div style={{width:dieta.distribuida ? "20%" : "75%", display: "inline-block"}}>
										<div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
											<div style={{display: "flex", alignItems: "center", width: "60%", marginRight: "7px"}}>
												<div className="imagen-alimento">
													<img
														src={require(`assets/img/${grupo.photo}.png`)}
														alt={grupo.label}
														style={{padding:"7px"}}
														className="img-category"
													/>
												</div>
												<div
													className="nombre-alimento"
													style={{
														textAlign:"left",
														// background: grupo.color,
														color:grupo.color, 
													}}
												>
													{grupo.label}
												</div>
											</div>
										{grupo.subcat &&
											<div className="subcategoria subcategoria-names">
												<>
													{Object.values(grupo.subcat).map(value=>(
														<>
															<p style={{color: grupo.color, whiteSpace: "nowrap"}}>{value.label}</p>
														</>
													))}
												</>
											</div>
												}
										</div>
									</div>
									{dieta.distribuida? (
										<>
											<div style={{width:"70%", display: "inline-block"}}>
												<div style={{display: "flex", alignItems: "center"}}>
													{dieta.tiempos_comida.map((tiempo, idx_tiempo) => (
														<div key={idx_tiempo} style={{display: "flex", justifyContent: "center"}}>
															<div className="subcategoria" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
																{grupo.subcat ?
																	<>
																		{Object.keys(grupo.subcat).map((key)=>(
																			<>
																				<input
																					className="eqInput"
																					type="number"
																					id=""
																					placeholder={0}
																					value={tiempo[grupo.name][key]||""}
																					onChange={(e) => {
																						e.persist();
																						setDieta(draft=>{
																							draft.tiempos_comida[idx_tiempo][grupo.name][key] = +e.target.value;
																						});
																					}}
																					// style={{padding: 0}}
																				/>
																			</>
																		))}
																	</>
																	:
																	<input
																		className="eqInput"
																		type="number"
																		id=""
																		placeholder={0}
																		value={tiempo[grupo.name]||""}
																		onChange={(e) => {
																			e.persist();
																			setDieta(draft=>{
																				draft.tiempos_comida[idx_tiempo][grupo.name] = +e.target.value;
																			});
																		}}
																	/>
																}
															</div>
														</div>
													))}
												</div>
											</div>
											<div className="totales container-totales-input" >
												{grupo.subcat &&
												<>
													<div className="total total-grupo">
														{Object.keys(grupo.subcat).map((key)=>(
															<>
																<div style={{textAlign:"center"}}>
																	{totales[grupo.name]?.[key]||0}
																</div>
															</>
														))}
													</div>
												</>}
												<div className="total total-general">
													<div style={{textAlign:"center"}}>
														{totales[grupo.name]?.total||0}
													</div>
												</div>
											</div>
										</>
									) : (
										<>
											<div style={{width:"25%", textAlign:"center", padding:"0 5px"}}>
												<div className="subcategoria" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
													{grupo.subcat ?
														<>
															{Object.keys(grupo.subcat).map((key)=>(
																<>
																	<input
																		className="eqInput"
																		style={{width:"100%"}}
																		type="number"
																		id=""
																		placeholder={0}
																		value={totales[grupo.name]?totales[grupo.name][key]:""}
																		onChange={(e) => {
																			e.persist();
																			setDieta(draft=>{
																				draft.tiempos_comida.map(tiempo=>{
																					tiempo[grupo.name][key] = undefined;
																				});
																				draft.tiempos_comida[0][grupo.name][key] = +e.target.value;
															
																			});
																		}}
																	/>
																</>
															))}
														</>
														:
														<input
															className="eqInput"
															style={{width:"100%"}}
															type="number"
															id=""
															placeholder={0}
															value={totales[grupo.name]?totales[grupo.name]?.total:""}
															onChange={(e) => {
																e.persist();
																setDieta(draft=>{
				
																	draft.tiempos_comida.map(tiempo=>{
																		tiempo[grupo.name] = undefined;
																	});
																	draft.tiempos_comida[0][grupo.name] = +e.target.value;
																			
																});
															}}
														/>
													}
												</div>
												
											</div>
											<div style={{margin: "0 5px", width: "20px", textAlign: "center"}}>
												{grupo.subcat &&
													<p>{totales[grupo.name] ? totales[grupo.name].total : 0}</p>
												}
											</div>
										</>
									)}
								</div>
							))}
						</div>
					</div>
				</Box>
			</FormGroup>
		</div>
	);
};


export default EquivalenciasSmae;

EquivalenciasSmae.propTypes = {
	value: PropTypes.object,
	onChange: PropTypes.func
};


