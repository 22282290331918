// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
const PrivateRoute = ({ component: Component, ...rest }) => {
	const me = useSelector(state=>state.CUSTOMER);
	const token = window.localStorage.getItem("nutrify:super");

	const isLoggedIn =  !!token;//true//me.session.logged;
	// const isLoggedIn =  true;
	const isActive = me.data.subscripcion?.activo;
	const isLoaded = me.view.status==="LOADED";
	return (
		<Route
			{...rest}
			render={props =>
				isLoggedIn ? (
				// (!isActive && isLoaded) ?  (<Redirect to={{ pathname: '/contratar', state: { from: props.location } }} />) :
					<Component {...props} /> 
				) : (
					<Redirect to={{ pathname: "/login", state: { from: props.location } }} />
				)
			}
		/>
	);
};

export default PrivateRoute;