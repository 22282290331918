import React from "react";
import Select from "components/FormComponents/Select/Select";
import moment from 'moment'

// This is a custom filter UI for selecting
// a unique option from a list
export default function GratuitoFilterCell({
	column: { filterValue, setFilterData, preFilteredRows, id, filterOptions },
}) {
	const onChange = (val, name) => {
		const newData = preFilteredRows.filter(row => {
			if(val === "paga") return (row.subscripcion?.id_plan !== "5ec1da89d20c904e60c4617e" && (moment().isBefore(moment(row.subscripcion?.vencimiento)) || moment().isBefore(moment(row.subscripcion?.proxima_fecha_pago))));
			else if(val === "gratis") return row.subscripcion?.id_plan === "5ec1da89d20c904e60c4617e"
			else return true
		})
		setFilterData(newData);
	}
	return (
		<Select options={filterOptions} onChange={onChange} />
	);
}
  