import React from "react";
import Equivalencias from "./Equivalencias";
import CrudTable from "components/Table/CrudTable/CrudTable";
import EquivalenciaCell from "../EquivalenciaCell/EquivalenciaCell";
import MapCell from "components/Table/CellTypes/MapCell/MapCell";
import SelectFilter from "components/Table/FilterTypes/SelectFilter/SelectFilter";
import { useSelector } from "react-redux";
import { EQUIVALENT } from "store/constants";
import {medidas, catEquivalencias, catEquivalenciasMap} from "misc/grupos";

// customSort:(rowA, rowB, columnId) => {
// 	let a = Object.keys(catEquivalenciasMap).indexOf(rowA.values["categoria"]);
// 	let b = Object.keys(catEquivalenciasMap).indexOf(rowB.values["categoria"]);
// 	return a-b;
// }}}

const TabEquivalencias = () => {
	const data = useSelector(state => state[EQUIVALENT.name]);

	const columns = React.useMemo(
		() => [
			{
				accessor: "categoria",
				Header: "Grupo",
				Cell:MapCell,
				map:catEquivalenciasMap,
				style: {textTransform:"capitalize"},
			},
			{
				Header: "Alimento",
				accessor: "nombre"
			},
			{
				accessor: "medida",
				Header: "Unidad",
				Filter:SelectFilter,
				filterOptions:[{label:"Todos",value:""},...medidas]
			},
			{
				accessor: "cantidad",
				Header: "Cantidad"
			},
			{
				accessor: "equivalencia",
				Header: "Equivalente",
				Cell:EquivalenciaCell,
				disableFilters:true,
				disableSortBy: true
			}
		],
		[]
	);
	const filters = React.useMemo(
		() => [
			
			{	
				Header: "Grupo",
				accessor: "categoria",
				filter:"select",
				filterOptions:[{label:"Todos",value:""},...catEquivalencias],
			},
			{
				Header: "Alimento",
				accessor: "nombre"
			},
			{
				accessor: "medida",
				Header: "Unidad",
				filter:"select",
				filterOptions:[{label:"Todos",value:""},...medidas]
			},
			{
				accessor: "cantidad",
				Header: "Cantidad"
			}
		],
		[]
	);
	return (
		<>
			<>
				<CrudTable
					data={data.data}
					status={data.view}
					columns={columns}
					type={EQUIVALENT}
					modal={Equivalencias}
					filters={filters}
					options={{ orderBy: true, saveAs:true}}

				/>
			</>
		</>
	);
};

export default TabEquivalencias;
