import React from "react";
import Rating from '@mui/lab/Rating';


const StarRating = props => {
  return (
    <React.Fragment>
      <Rating
				name={props.name}
        value={props.value}
        onChange={(event, newValue) => props.onChange(newValue, props.name)}
      />
    </React.Fragment>
  );
};

export default StarRating;
