import React, { useState } from 'react'
import { Button } from '@mui/material'

function ResetPassword(props) {
  const [clicked, setClicked] = useState(false)
  return (
    <>
    {
    !clicked ?
    <Button
    color="primary"
    variant="contained"
    onClick={() => {
      props.onChange("123", props.name);
      setClicked(true);
    }}
    >Restablecer contraseña</Button>
    :
    <p>Contraseña restablecida; guarda para aplicar los cambios</p>
}
</>
  )
}

export default ResetPassword