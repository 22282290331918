import React from "react";
import styled from "styled-components";
import ChartMacronutrimentos from "./ChartMacronutrimentos";
import { catMicronutrimentosMap } from "constants/index";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
// import Final from "./Final";
import { MENU_SELECT_LIST } from "store/constants";
import { useTranslation } from "react-i18next";
import {useQuery} from "react-query";

function Micronutrimentos({alimento, onClose, options}) {
	const {t} = useTranslation("common");
	const menuSelectList = useSelector(state => state[MENU_SELECT_LIST.name].data);
	// const [Final, setFinal] = React.useState({});
	// React.useEffect(()=>{
	// 	fetch("/Final.json").then(
	// 		function(res){
	// 			return res.json();
	// 		}).then(function(data){
	// 			console.log({data})
	// 		setFinal(data);
	// 	}).catch(
	// 		function(err){
	// 			console.log(err, ' error')
	// 		}
	// 	)

	// 	// fetch("/Final.json").then(r=>console.log(r));
	// },[]);
	const { data:Final } = useQuery("equivalenciasMenu", () => fetch("/Final.json").then((res)=>res.json()) );

	const alimentoFiltrado = (Object.entries(Final).map(([,val])=>val).concat(menuSelectList).flat()).find(val=>val.id === alimento.id);
	let micronutrimentos = {};
	Object.entries(alimentoFiltrado?.micronutr ?? {}).filter(([,val])=>val !== "").forEach(([key, val])=>{
		micronutrimentos[key] = + (((alimento.gram * val) / alimentoFiltrado.medidas[0].gram)?.toFixed(2)||0);
	});
	return (
		<MicronutrimentosContainer>
			<div className="cont-header">
				<p style={{fontWeight: "bold", textAlign: "center", marginBottom: 5}}>{alimento?.cant} {alimento.unidad || ""} {alimento.nombre} ({alimento.kcal} Kcal)</p>
			</div>
			<div className="cont-chart">
				<ChartMacronutrimentos 
					data={{pieChartData: {
						prot: alimento.prot,
						lip: alimento.lip,
						hidr: alimento.hidr,
					}}}
					options={{...options.chart}}
				/>
			</div>
			<div style={{maxWidth: 320, margin: "0 auto"}}>
				{micronutrimentos && Object.entries(micronutrimentos).map(([key, val])=>(
					<div key={key} style={{display: "flex", justifyContent: "space-between", fontWeight: "bold", fontSize: "14px", marginBottom: "5px"}}>
						<p>{catMicronutrimentosMap[key]}</p>
						<p>{val}</p>
					</div>
				))}
			</div>
			<div style={{textAlign: "right", marginTop: "10px"}}>
				<Button onClick={onClose} variant="contained">{t("cerrar")}</Button>
			</div>
			
		</MicronutrimentosContainer>
	);
}

export default Micronutrimentos;

const MicronutrimentosContainer = styled.div`
	.apexcharts-legend-series{
		text-align: left;
	}
	.cont-chart{
		margin-bottom: 10px;
	}
`;


