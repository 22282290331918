import React from "react";

// did this because I was getting an error when I tried to use the overide styles with sx in material ui

export const AddToDietaButton = ({text, onClick}) => {
	return (
		<button 
			style={{
				"color": "#6896f9",
				"background-color": "transparent",
				"border": "1px solid #6896f9",
				"border-radius": "4px",
				"padding": "4px 16px",
				"font-weight": 400,
				"font-size": "14px",
				"margin-top": "10px",
				// "line-height": 1.75,
				"cursor": "pointer",
				"transition": "all 0.2s ease-in-out",
				"width": "100%",
				// "text-transform": "uppercase",
				// "letter-spacing": "0.02857em",
			}}
			onClick={onClick}
		>
			{text}
		</button>
	);
};
