import React from "react";
import CrudTable from "components/Table/CrudTable/CrudTable";
import Plan from "./Plan";
import { useSelector } from "react-redux";
import {PLAN} from 'store/constants'
import Header from "components/Header/Header";

const TablaPlanes = props => {
  const data = useSelector(state => state["PLAN"]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Nombre",
        accessor: "nombre"
      }
    ],
    []
  );

  return (
    <>
			<Header title="Planes" />
      <CrudTable
        data={data.data}
        status={data.view}
        columns={columns}
        type={PLAN}
        modal={Plan}
      />
    </>
  );
};

export default TablaPlanes;
