import React from "react";
import CrudTable from "../../components/Table/CrudTable/CrudTable";
import ColorCell from "../../components/Table/CellTypes/ColorCell/ColorCell";
import { useSelector } from "react-redux";
import Sellos from "./Sellos";
import { RECEIPE_TAG } from "store/constants";

const TabRecetas = () => {
	const data = useSelector(state => state[RECEIPE_TAG.name]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Etiqueta",
				accessor: "nombre"
			},
			{
				Header: "Color",
				accessor: "color",
				Cell: ColorCell,
				disableFilters: true
			}
		],
		[]
	);
	const filters = React.useMemo(
		() => [
			{
				Header: "Etiqueta",
				accessor: "nombre"
			}
		],
		[]
	);

	return (
		<>
			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				type={RECEIPE_TAG}
				modal={Sellos}
				filters={filters}
				options={{ orderBy: true }}
			/>
		</>
	);
};

export default TabRecetas;
