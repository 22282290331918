export default [
	{ value: "verduras", name: "verduras", label: "Verduras", color: "#8EBF64", "cal":25, "proteinas":2, "lipidos":0, "hidratos_de_carbono":4, "conteo_hc":0.3 },
	{ value: "frutas", name: "frutas", label: "Frutas", color: "#E572A9", "cal":60, "proteinas":0, "lipidos":0, "hidratos_de_carbono":15, "conteo_hc":1 },
	{ value: "cereales", name: "cereales", label: "Cereales y tubérculos", color: "#A27C4F", "cal":70, "proteinas":2, "lipidos":0, "hidratos_de_carbono":15, "conteo_hc":1 },
	{ value: "leguminosas", name: "leguminosas", label: "Leguminosas", color: "#474997", "cal":120, "proteinas":8, "lipidos":1, "hidratos_de_carbono":20, "conteo_hc":1.3 },
	{ value: "proteinas", name: "proteinas", label: "Alimentos de origen animal", color: "#D96761", "cal":75, "proteinas":7, "lipidos":5, "hidratos_de_carbono":0, "conteo_hc":0 },
	{ value: "lacteos", name: "lacteos", label: "Leche", color: "#59BAC9", "cal":110, "proteinas":9, "lipidos":4, "hidratos_de_carbono":12, "conteo_hc":0.8 },
	{ value: "grasas", name: "grasas", label: "Aceites y grasas", color: "#E7C043", "cal":45, "proteinas":0, "lipidos":5, "hidratos_de_carbono":0, "conteo_hc":0 },
	{ value: "azucares", name: "azucares", label: "Azúcares", color: "#EA754F", "cal":40, "proteinas":0, "lipidos":0, "hidratos_de_carbono":10, "conteo_hc":0.7 },
	{ value: "agua", name: "agua", label: "Agua", color: "#49BADD", "cal":0, "proteinas":0, "lipidos":0, "hidratos_de_carbono":0, "conteo_hc":0 },
	// { name: "preparados", label: "Alimentos preparados", color: "#474997" }
];

export const colorByCategory = {
	verduras: "#70DC55",
	frutas: "#FC75BE",
	cereales: "#D79B57",
	leguminosas: "#627BF5",
	proteinas: "#627BF5",
	lacteos: "#E46964",
	grasas: "#3FBDD9",
	azucares: "#F6D75D",
	agua: "#FF784D",
};

export const smae = [
	{ value: "v", name: "v", label: "Verduras", photo: "verduras", color: "#8EBF64", aporte: {cal: 25, proteinas: 2, lipidos: 0, hidratos_de_carbono: 4, conteo_hc: 0.3}},
	{ value: "f", name: "f", label: "Frutas", photo: "frutas", color: "#E572A9", aporte: {cal: 60, proteinas: 0, lipidos: 0, hidratos_de_carbono: 15, conteo_hc: 1}},
	{ value: "c", name: "c", label: "Cereales y tubérculos", photo: "cereales", color: "#A27C4F", subcat: {
		sg: { value: "sg", name: "sg", label: "Sin grasa", aporte: {cal: 70, proteinas: 2, lipidos: 0, hidratos_de_carbono: 15, conteo_hc: 1}},
		cg: { value: "cg", name: "cg", label: "Con grasa", aporte: {cal: 115, proteinas: 2, lipidos: 5, hidratos_de_carbono: 15, conteo_hc: 1}}
	}},
	{ value: "leg", name: "leg", label: "Leguminosas", photo: "leguminosas", color: "#474997", aporte: {cal: 120, proteinas: 8, lipidos: 1, hidratos_de_carbono: 20, conteo_hc: 1.3}},
	{ value: "p", name: "p", label: "Alimentos de origen animal", photo: "proteinas", color: "#D96761", subcat: {
		mb: { value: "mb", name: "mb", label: "Muy bajo", aporte: {cal: 40, proteinas: 7, lipidos: 1, hidratos_de_carbono: 0, conteo_hc: 0}},
		b: { value: "b", name: "b", label: "Bajo", aporte: {cal: 55, proteinas: 7, lipidos: 3, hidratos_de_carbono: 0, conteo_hc: 0}},
		mo: { value: "mo", name: "mo", label: "Moderado", aporte: {cal: 75, proteinas: 7, lipidos: 5, hidratos_de_carbono: 0, conteo_hc: 0}},
		a: { value: "a", name: "a", label: "Alto", aporte: {cal: 100, proteinas: 7, lipidos: 8, hidratos_de_carbono: 0, conteo_hc: 0}},
	}},
	{ value: "l", name: "l", label: "Leche", photo: "lacteos", color: "#59BAC9", subcat: {
		d: { value: "d", name: "d", label: "Descremada", aporte: {cal: 95, proteinas: 9, lipidos: 2, hidratos_de_carbono: 12, conteo_hc: 0.8}},
		sd: { value: "sd", name: "sd", label: "Semidescremada", aporte: {cal: 110, proteinas: 9, lipidos: 4, hidratos_de_carbono: 12, conteo_hc: 0.8}},
		e: { value: "e", name: "e", label: "Entera", aporte: {cal: 150, proteinas: 9, lipidos: 8, hidratos_de_carbono: 12, conteo_hc: 0.8}},
		ca: { value: "ca", name: "ca", label: "Con azúcar", aporte: {cal: 200, proteinas: 8, lipidos: 5, hidratos_de_carbono: 30, conteo_hc: 2}},
	}},
	{ value: "g", name: "g", label: "Aceites y grasas", photo: "grasas", color: "#E7C043", subcat: {
		sp: { value: "sp", name: "sp", label: "Sin proteína", aporte: {cal: 45, proteinas: 0, lipidos: 5, hidratos_de_carbono: 0, conteo_hc: 0}},
		cp: { value: "cp", name: "cp", label: "Con proteína", aporte: {cal: 70, proteinas: 3, lipidos: 5, hidratos_de_carbono: 3, conteo_hc: 0.2}},
	}},
	{ value: "azu", name: "azu", label: "Azúcares", photo: "azucares", color: "#EA754F", subcat: {
		sg: { value: "sg", name: "sg", label: "Sin grasa", aporte: {cal: 40, proteinas: 0, lipidos: 0, hidratos_de_carbono: 10, conteo_hc: 0.7}},
		cg: { value: "cg", name: "cg", label: "Con grasa", aporte: {cal: 85, proteinas: 0, lipidos: 5, hidratos_de_carbono: 10, conteo_hc: 0.7}}
	}},
	{ value: "ag", name: "ag", label: "Agua", photo: "agua", color: "#49BADD", aporte: {cal: 0, proteinas: 0, lipidos: 0, hidratos_de_carbono: 0, conteo_hc: 0}},
];

export const subcatSmae = {
	c: {
		sg: { value: "sg", name: "sg", label: "Sin grasa", aporte: {cal: 70, proteinas: 2, lipidos: 0, hidratos_de_carbono: 15, conteo_hc: 1}},
		cg: { value: "cg", name: "cg", label: "Con grasa", aporte: {cal: 115, proteinas: 2, lipidos: 5, hidratos_de_carbono: 15, conteo_hc: 1}}
	},
	p:{
		mb: { value: "mb", name: "mb", label: "Muy bajo", aporte: {cal: 40, proteinas: 7, lipidos: 1, hidratos_de_carbono: 0, conteo_hc: 0}},
		b: { value: "b", name: "b", label: "Bajo", aporte: {cal: 55, proteinas: 7, lipidos: 3, hidratos_de_carbono: 0, conteo_hc: 0}},
		mo: { value: "mo", name: "mo", label: "Moderado", aporte: {cal: 75, proteinas: 7, lipidos: 5, hidratos_de_carbono: 0, conteo_hc: 0}},
		a: { value: "a", name: "a", label: "Alto", aporte: {cal: 100, proteinas: 7, lipidos: 8, hidratos_de_carbono: 0, conteo_hc: 0}},
	},
	l:{
		d: { value: "d", name: "d", label: "Descremada", aporte: {cal: 95, proteinas: 9, lipidos: 2, hidratos_de_carbono: 12, conteo_hc: 0.8}},
		sd: { value: "sd", name: "sd", label: "Semidescremada", aporte: {cal: 110, proteinas: 9, lipidos: 4, hidratos_de_carbono: 12, conteo_hc: 0.8}},
		e: { value: "e", name: "e", label: "Entera", aporte: {cal: 150, proteinas: 9, lipidos: 8, hidratos_de_carbono: 12, conteo_hc: 0.8}},
		ca: { value: "ca", name: "ca", label: "Con azúcar", aporte: {cal: 200, proteinas: 8, lipidos: 5, hidratos_de_carbono: 30, conteo_hc: 2}},
	},
	g:{
		sp: { value: "sp", name: "sp", label: "Sin proteína", aporte: {cal: 45, proteinas: 0, lipidos: 5, hidratos_de_carbono: 0, conteo_hc: 0}},
		cp: { value: "cp", name: "cp", label: "Con proteína", aporte: {cal: 70, proteinas: 3, lipidos: 5, hidratos_de_carbono: 3, conteo_hc: 0.2}},
	},
	azu:{
		sg: { value: "sg", name: "sg", label: "Sin grasa", aporte: {cal: 40, proteinas: 0, lipidos: 0, hidratos_de_carbono: 10, conteo_hc: 0.7}},
		cg: { value: "cg", name: "cg", label: "Con grasa", aporte: {cal: 85, proteinas: 0, lipidos: 5, hidratos_de_carbono: 10, conteo_hc: 0.7}}
	}
};

export const subcatSmaeFilter = {
	c: ["sg", "cg"],
	p: ["mb", "b", "mo", "a"],
	l: ["d", "sd", "e", "ca"],
	g: ["sp", "cp"],
	azu: ["sg", "cg"],
}


export const catEquivalenciasSmae = [
	{ value: "v", name: "v", label: "Verduras", photo: "verduras", color: "#8EBF64", aporte: {cal: 25, proteinas: 2, lipidos: 0, hidratos_de_carbono: 4, conteo_hc: 0.3}},
	{ value: "f", name: "f", label: "Frutas", photo: "frutas", color: "#E572A9", aporte: {cal: 60, proteinas: 0, lipidos: 0, hidratos_de_carbono: 15, conteo_hc: 1}},
	{ value: "c", name: "c", label: "Cereales y tubérculos", photo: "cereales", color: "#A27C4F", subcat: {
		sg: { value: "sg", name: "sg", label: "Sin grasa", aporte: {cal: 70, proteinas: 2, lipidos: 0, hidratos_de_carbono: 15, conteo_hc: 1}},
		cg: { value: "cg", name: "cg", label: "Con grasa", aporte: {cal: 115, proteinas: 2, lipidos: 5, hidratos_de_carbono: 15, conteo_hc: 1}}
	}},
	{ value: "leg", name: "leg", label: "Leguminosas", photo: "leguminosas", color: "#474997", aporte: {cal: 120, proteinas: 8, lipidos: 1, hidratos_de_carbono: 20, conteo_hc: 1.3}},
	{ value: "p", name: "p", label: "Alimentos de origen animal", photo: "proteinas", color: "#D96761", subcat: {
		mb: { value: "mb", name: "mb", label: "Muy bajo", aporte: {cal: 40, proteinas: 7, lipidos: 1, hidratos_de_carbono: 0, conteo_hc: 0}},
		b: { value: "b", name: "b", label: "Bajo", aporte: {cal: 55, proteinas: 7, lipidos: 3, hidratos_de_carbono: 0, conteo_hc: 0}},
		mo: { value: "mo", name: "mo", label: "Moderado", aporte: {cal: 75, proteinas: 7, lipidos: 5, hidratos_de_carbono: 0, conteo_hc: 0}},
		a: { value: "a", name: "a", label: "Alto", aporte: {cal: 100, proteinas: 7, lipidos: 8, hidratos_de_carbono: 0, conteo_hc: 0}},
	}},
	{ value: "l", name: "l", label: "Leche", photo: "lacteos", color: "#59BAC9", subcat: {
		d: { value: "d", name: "d", label: "Descremada", aporte: {cal: 95, proteinas: 9, lipidos: 2, hidratos_de_carbono: 12, conteo_hc: 0.8}},
		sd: { value: "sd", name: "sd", label: "Semidescremada", aporte: {cal: 110, proteinas: 9, lipidos: 4, hidratos_de_carbono: 12, conteo_hc: 0.8}},
		e: { value: "e", name: "e", label: "Entera", aporte: {cal: 150, proteinas: 9, lipidos: 8, hidratos_de_carbono: 12, conteo_hc: 0.8}},
		ca: { value: "ca", name: "ca", label: "Con azúcar", aporte: {cal: 200, proteinas: 8, lipidos: 5, hidratos_de_carbono: 30, conteo_hc: 2}},
	}},
	{ value: "g", name: "g", label: "Aceites y grasas", photo: "grasas", color: "#E7C043", subcat: {
		sp: { value: "sp", name: "sp", label: "Sin proteína", aporte: {cal: 45, proteinas: 0, lipidos: 5, hidratos_de_carbono: 0, conteo_hc: 0}},
		cp: { value: "cp", name: "cp", label: "Con proteína", aporte: {cal: 70, proteinas: 3, lipidos: 5, hidratos_de_carbono: 3, conteo_hc: 0.2}},
	}},
	{ value: "azu", name: "azu", label: "Azúcares", photo: "azucares", color: "#EA754F", subcat: {
		sg: { value: "sg", name: "sg", label: "Sin grasa", aporte: {cal: 40, proteinas: 0, lipidos: 0, hidratos_de_carbono: 10, conteo_hc: 0.7}},
		cg: { value: "cg", name: "cg", label: "Con grasa", aporte: {cal: 85, proteinas: 0, lipidos: 5, hidratos_de_carbono: 10, conteo_hc: 0.7}}
	}},
	{ value: "alcohol", name: "alcohol", label: "Alcohol", color: "#49BADD", "cal":0, "proteinas":0, "lipidos":0, "hidratos_de_carbono":0, "conteo_hc":0 },
	{ value: "libres", name: "libres", label: "Libres", color: "#49BADD", "cal":0, "proteinas":0, "lipidos":0, "hidratos_de_carbono":0, "conteo_hc":0 },
	{ value: "preparados", name: "preparados", label: "Alimentos preparados", color: "#49BADD", "cal":0, "proteinas":0, "lipidos":0, "hidratos_de_carbono":0, "conteo_hc":0 },
];

export const subcatEquivalenciasSmae = [
	{ value: "sg", name: "sg", label: "Sin grasa"},
	{ value: "cg", name: "cg", label: "Con grasa"},
	{ value: "mb", name: "mb", label: "Muy bajo"},
	{ value: "b", name: "b", label: "Bajo"},
	{ value: "mo", name: "mo", label: "Moderado"},
	{ value: "a", name: "a", label: "Alto"},
	{ value: "d", name: "d", label: "Descremada"},
	{ value: "sd", name: "sd", label: "Semidescremada"},
	{ value: "e", name: "e", label: "Entera"},
	{ value: "ca", name: "ca", label: "Con azúcar"},
	{ value: "sp", name: "sp", label: "Sin proteína"},
	{ value: "cp", name: "cp", label: "Con proteína"},
];

export const catMicronutrimentos = {
	v: {micronutrimentos: {
		fib: undefined, vit_a: undefined, ac_asc: undefined, ac_fol: undefined, h_no_hem: undefined, pot: undefined, i_gli: undefined, car_gli: undefined,
	}},
	f: {micronutrimentos: {
		fib: undefined, vit_a: undefined, ac_asc: undefined, ac_fol: undefined, h_no_hem: undefined, pot: undefined, azu_e: undefined, i_gli: undefined, car_gli: undefined,
	}},
	c: {micronutrimentos: {
		fib: undefined, ac_fol: undefined, cal: undefined, hie: undefined, sod: undefined, azu_e: undefined, i_gli: undefined, car_gli: undefined
	}},
	leg: {micronutrimentos: {
		fib: undefined, h_no_hem: undefined, se: undefined, sod: undefined, fos: undefined, pot: undefined, azu_e: undefined, i_gli: undefined, car_gli: undefined,
	}},
	p: {micronutrimentos: {
		col: undefined, vit_a: undefined, cal: undefined, hie: undefined, sod: undefined, se: undefined,
	}},
	l: {micronutrimentos: {
		col: undefined, vit_a: undefined, cal: undefined, sod: undefined,
	}},
	g: {micronutrimentos: {
		ag_sat: undefined, ag_mono: undefined, ag_poli: undefined, col: undefined, sod: undefined,
	}},
	azu: {micronutrimentos: {
		sod: undefined, azu_e: undefined, i_gli: undefined, car_gli: undefined,
	}},
	alcohol: {micronutrimentos: {et: undefined}},
	libres: {micronutrimentos: {sod: undefined}}
};

export const subcatSmaeMap = {
	sg: "Sin grasa",
	cg: "Con grasa",
	mb: "Muy bajo",
	b: "Bajo",
	mo: "Moderado",
	a: "Alto",
	d: "Descremada",
	sd: "Semidescremada",
	e: "Entera",
	ca: "Con azúcar",
	sp: "Sin proteína",
	cp: "Con proteina",
};

export const catEquivalenciasSmaeMap = {
	v: "Verduras", 
	f:"Frutas",
	c: "Cereales y tubérculos",
	leg:"Leguminosas",
	p:"Alimentos de origen animal",
	l:"Leche",
	g:"Aceites y grasas",
	azu:"Azúcares",
	alcohol:"Alcohol", 
	libres:"Libres",
	preparados:"Alimentos preparados"
};

export const catMicronutrimentosMap = {
	fib:  "Fibra (g)",
	vit_a:  "Vitamina A (µg RE)",
	ac_asc:  "Ácido Ascórbico (mg)",
	ac_fol:  "Ácido Fólico (mg)",
	h_no_hem:  "Hierro No HEM (mg)",
	pot:  "Potasio (mg)",
	i_gli:  "Índice glicémico",
	car_gli:  "Carga glicémica",
	azu_e:  "Azúcar por equivalente (g)",
	cal:  "Calcio (mg)",
	hie:  "Hierro (mg)",
	sod:  "Sodio (mg)",
	se:  "Selenio (µg)",
	fos:  "Fosforo (mg)",
	col:  "Colesterol (mg)",
	ag_sat:  "AG Saturados (g)",
	ag_mono:  "AG monoinstaurados (g)",
	ag_poli:  "AG poliinstaurados (g)",
	et: "Etanol",
	lib: "Libres"
}


















export const catEquivalencias = [
	{ value: "verduras", name: "verduras", label: "Verduras", color: "#8EBF64", "cal":25, "proteinas":2, "lipidos":0, "hidratos_de_carbono":4, "conteo_hc":0.3 },
	{ value: "frutas", name: "frutas", label: "Frutas", color: "#E572A9", "cal":60, "proteinas":0, "lipidos":0, "hidratos_de_carbono":15, "conteo_hc":1 },
	{ value: "cereales", name: "cereales", label: "Cereales y tubérculos", color: "#A27C4F", "cal":70, "proteinas":2, "lipidos":0, "hidratos_de_carbono":15, "conteo_hc":1 },
	{ value: "leguminosas", name: "leguminosas", label: "Leguminosas", color: "#474997", "cal":120, "proteinas":8, "lipidos":1, "hidratos_de_carbono":20, "conteo_hc":1.3 },
	{ value: "proteinas", name: "proteinas", label: "Alimentos de origen animal", color: "#D96761", "cal":75, "proteinas":7, "lipidos":5, "hidratos_de_carbono":0, "conteo_hc":0 },
	{ value: "lacteos", name: "lacteos", label: "Leche", color: "#59BAC9", "cal":110, "proteinas":9, "lipidos":4, "hidratos_de_carbono":12, "conteo_hc":0.8 },
	{ value: "grasas", name: "grasas", label: "Aceites y grasas", color: "#E7C043", "cal":45, "proteinas":0, "lipidos":5, "hidratos_de_carbono":0, "conteo_hc":0 },
	{ value: "azucares", name: "azucares", label: "Azúcares", color: "#EA754F", "cal":40, "proteinas":0, "lipidos":0, "hidratos_de_carbono":10, "conteo_hc":0.7 },
	{ value: "alcohol", name: "alcohol", label: "Alcohol", color: "#49BADD", "cal":0, "proteinas":0, "lipidos":0, "hidratos_de_carbono":0, "conteo_hc":0 },
	{ value: "libres", name: "libres", label: "Libres", color: "#49BADD", "cal":0, "proteinas":0, "lipidos":0, "hidratos_de_carbono":0, "conteo_hc":0 },
	{ value: "preparados", name: "preparados", label: "Alimentos preparados", color: "#49BADD", "cal":0, "proteinas":0, "lipidos":0, "hidratos_de_carbono":0, "conteo_hc":0 },
	// { name: "preparados", label: "Alimentos preparados", color: "#474997" }
];
export const catEquivalenciasMap = {
	verduras: "Verduras", 
	frutas:"Frutas",
	cereales: "Cereales y tubérculos",
	leguminosas:"Leguminosas",
	proteinas:"Alimentos de origen animal",
	lacteos:"Leche",
	grasas:"Aceites y grasas",
	azucares:"Azúcares",
	alcohol:"Alcohol", 
	libres:"Libres",
	preparados:"Alimentos preparados"
};

export const medidas = [
	{ value: "ninguna", label: "Ninguna" },
	{ value: "paquete", label: "Paquete" },
	{ value: "sobre", label: "Sobre" },
	{ value: "bolsa", label: "Bolsa" },
	{ value: "bolsita", label: "Bolsita" },
	{ value: "bote", label: "Bote" },
	{ value: "hojas", label: "Hojas" },
	{ value: "orejon", label: "Orejón" },
	{ value: "frasco", label: "Frasco" },
	{ value: "lata", label: "Lata" },
	{ value: "rebanada", label: "Rebanada" },
	{ value: "filete", label: "Filete" },
	{ value: "barra", label: "Barra" },
	{ value: "pieza", label: "Pieza" },
	{ value: "taza", label: "Taza" },
	{ value: "envase", label: "Envase" },
	{ value: "vaso", label: "Vaso" },
	{ value: "vasito", label: "Vasito" },
	{ value: "capsula", label: "Cápsula" },
	{ value: "cucharada", label: "Cucharada" },
	{ value: "cucharita", label: "Cucharita" },
	{ value: "disparo", label: "Disparo" },
	{ value: "gramos", label: "Gramos" },
	{ value: "ml", label: "Mililitros" },
	{ value: "copa", label: "Copa" },
	{ value: "tarro", label: "Tarro" },
];
