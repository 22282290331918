import React, { useEffect } from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";

const ModalPacientes = props => {
	const elements = React.useMemo(()=>[
		{
			type: "text",
			name: "nombre",
			label: "Nombre",
		},
		{
			type: "text",
			name: "correo",
			label: "Correo",
		}
	],[]);
	return (
		<>
			<ModalForm elements={elements} onChange={props.onChange} data={props.data} {...props}>
				<p className="text-center">
					Al seleccionar GUARDAR se enviará una invitación a tu paciente para que descargue la App de Nutrify.
				</p>
			</ModalForm>
		</>
	);
}

export default ModalPacientes;