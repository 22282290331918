import React from "react";
import PropTypes from "prop-types";
import ModalForm from "../../components/FormComponents/ModalForm/ModalForm";

const Sellos = props => {
	const elements = [
		{
			type: "text",
			name: "nombre",
			label: "Nombre",
		},
		{
			type: "color",
			name: "color",
			label: "Color",
		}
	];
	return (
		<>
			<ModalForm elements={elements} onChange={props.onChange} data={props.data}></ModalForm>
		</>
	);
};

export default Sellos;

Sellos.propTypes = {
	onChange: PropTypes.func,
	data: PropTypes.object
};
