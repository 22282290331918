import { loadRequest, untrashRequest, updateRequest, insertRequest, removeRequest, trashRequest } from "./generators";
import { PLAN, LICENCE_COUPON} from "../constants";
import { all } from "redux-saga/effects";

const loadPlans = loadRequest(PLAN);
const insertPlan = insertRequest(PLAN);
const updatePlan = updateRequest(PLAN);
const trashPlan = trashRequest(PLAN);
const untrashPlan = untrashRequest(PLAN);
const removePlan = removeRequest(PLAN);

const loadCupon = loadRequest(LICENCE_COUPON);
const insertCupon = insertRequest(LICENCE_COUPON);
const updateCupon = updateRequest(LICENCE_COUPON);
const trashCupon = trashRequest(LICENCE_COUPON);
const untrashCupon = untrashRequest(LICENCE_COUPON);
const removeCupon = removeRequest(LICENCE_COUPON);

function* rootSaga() {
	yield all([
		loadPlans(),
		insertPlan(),
		updatePlan(),
		trashPlan(),
		untrashPlan(),
		removePlan(),
		loadCupon(),
		insertCupon(),
		updateCupon(),
		trashCupon(),
		removeCupon(),
		untrashCupon()
	]);
}
export default rootSaga;