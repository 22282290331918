import rootSaga from "../sagas/index";
import createSagaMiddleware from "redux-saga";
import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import crudReducer,{makeSubReducer} from "./crudReducer";
import {produce} from "immer";
import {
	INFOGRAFIA,
	RECEIPE,
	RECEIPE_TAG,
	RECEIPE_CATEGORY,
	EQUIVALENT_DIET,
	EQUIVALENT,
	EQUIVALENT_LIST,
	EQUIVALENT_SUBCAT,
	EQUIVALENT_SMAE_DIET,
	EQUIVALENT_SMAE,
	EQUIVALENT_SMAE_LIST,
	EQUIVALENT_SMAE_SUBCAT,
	MENU_DIET,
	MENU_SELECT_DIET,
	MENU_SELECT_LIST,
	COLUMNS_DIET,
	OPTIONS_DIET,
	PERSONALIZED_DIET,
	PATIENTS,
	PRIZE,
	CONSULTATION,
	MESSAGE,
	CUSTOMER,
	PLAN,
	APPOINTMENT,
	NUTRITIONIST,
	SUCURSAL,
	PRODUCT,
	PRODUCT_CATEGORY,
	PRODUCT_TAG,
	DIA_INHABIL,
	DISCOUNT,
	ONLINE_CONSULTATION,
	ONLINE_PATIENT,
	LICENCE_COUPON,
	NUTRITIONIST_MESSAGE,
	NUTRITIONIST_NOTIFICATION,
	NUTRITIONIST_NOTIFICATION_TAGS,
	TUTORIAL
} from "../constants";
const getIdxElement = (arr, id) => arr.findIndex(element => element._id === id);

var emptyReducer = () => ({});
var consultationReducer = produce((draft, action) => {
	switch (action.type) {
	case "SET_CONSULTATION": {
		draft.data[action.idPaciente] = action.payload;
		break;
	}
	case "UPDATE_L_CONSULTATION": {
		console.log(action);
		let idx = getIdxElement(draft.data[action.payload.id_paciente], action.payload._id);

		draft.data[action.payload.id_paciente][idx] = action.payload;

		// draft.data[action.idPaciente][action.idConsulta] = action.payload;
		break;
	}
	case "CONSULTATION_STATUS":
		draft.view = action.payload;
		return;
	case "INSERT_L_CONSULTATION": {
		draft.data[action.idPaciente].push(action.payload);
		break;
	}
	default:
		break;
	}
},{
	data: {},
	view: {}
});
var customerReducer = (draft, action) => {
	switch(action.type){
	case "SET_SESSION": {
		draft.session = action.payload;
		break;
	}
	default:
		break;
	}
};
const consultations = consultationReducer;

const receipes = crudReducer(emptyReducer, RECEIPE.name, {
	data: [],
	view: {}
});
const receipeTags = crudReducer(emptyReducer, RECEIPE_TAG.name, {
	data: [],
	view: {}
});
const receipeCategory = crudReducer(emptyReducer, RECEIPE_CATEGORY.name, {
	data: [],
	view: {}
});
const onlineConsultation = crudReducer(emptyReducer, ONLINE_CONSULTATION.name, {
	data: [],
	view: {}
});
const onlinePatient = crudReducer(emptyReducer, ONLINE_PATIENT.name, {
	data: [],
	view: {}
});

const products = crudReducer(emptyReducer, PRODUCT.name, {
	data: [],
	view: {}
});
const productTags = crudReducer(emptyReducer, PRODUCT_TAG.name, {
	data: [],
	view: {}
});
const productCategory = crudReducer(emptyReducer, PRODUCT_CATEGORY.name, {
	data: [],
	view: {}
});

const appointments = crudReducer(emptyReducer, APPOINTMENT.name, {
	data: [],
	view: {}
});

const equivalentDiet = crudReducer(emptyReducer, EQUIVALENT_DIET.name, {
	data: [],
	view: {}
});
const equivalent = crudReducer(emptyReducer, EQUIVALENT.name, {
	data: [],
	view: {}
});
const equivalentList = crudReducer(emptyReducer, EQUIVALENT_LIST.name, {
	data: [],
	view: {}
});
const equivalentSubcat= crudReducer(emptyReducer, EQUIVALENT_SUBCAT.name, {
	data: [],
	view: {}
});

// EQUIVALENT SMAE
const equivalentSmaeDiet = crudReducer(emptyReducer, EQUIVALENT_SMAE_DIET.name, {
	data: [],
	view: {}
});
const equivalentSmae = crudReducer(emptyReducer, EQUIVALENT_SMAE.name, {
	data: [],
	view: {}
});
const equivalentSmaeList = crudReducer(emptyReducer, EQUIVALENT_SMAE_LIST.name, {
	data: [],
	view: {}
});
const equivalentSmaeSubcat= crudReducer(emptyReducer, EQUIVALENT_SMAE_SUBCAT.name, {
	data: [],
	view: {}
});

const menuDiet = crudReducer(emptyReducer, MENU_DIET.name, {
	data: [],
	view: {}
});
const menuSelectDiet = crudReducer(emptyReducer, MENU_SELECT_DIET.name, {
	data: [],
	view: {}
});
const menuSelectList = crudReducer(emptyReducer, MENU_SELECT_LIST.name, {
	data: [],
	view: {}
});

const personalizedDiet = crudReducer(emptyReducer, PERSONALIZED_DIET.name, {
	data: [],
	view: {}
});

const optionsDiet = crudReducer(emptyReducer, OPTIONS_DIET.name, {
	data: [],
	view: {}
});
const columnsDiet = crudReducer(emptyReducer, COLUMNS_DIET.name, {
	data: [],
	view: {}
});

const patientPrize = makeSubReducer("PATIENT_PRIZE", "premios");
const patients = crudReducer(patientPrize, PATIENTS.name, {
	data: [],
	view: {}
});

const prizes = crudReducer(emptyReducer, PRIZE.name, {
	data: [],
	view: {}
});

const messages = crudReducer(emptyReducer, MESSAGE.name, {
	data: [],
	view: {}
});

const sucursales = makeSubReducer(SUCURSAL);
const customer = crudReducer([customerReducer, sucursales], CUSTOMER.name, {
	data: [],
	view: {},
	session: {}
});
const plans = crudReducer(emptyReducer, PLAN.name, {
	data: [],
	view: {}
});
const cuponPlan = crudReducer(emptyReducer, LICENCE_COUPON.name, {
	data: [],
	view: {}
});
const discounts = crudReducer(emptyReducer, DISCOUNT.name, {
	data: [],
	view: {}
});

const  diaInhabil = makeSubReducer(DIA_INHABIL);
console.log(diaInhabil);
const nutitionists = crudReducer([diaInhabil], NUTRITIONIST.name, {
	data: [],
	view: {}
});
const nutitionistMessages = crudReducer([diaInhabil], NUTRITIONIST_MESSAGE.name, {
	data: [],
	view: {}
});
const nutritionistNotification = crudReducer(emptyReducer, NUTRITIONIST_NOTIFICATION.name, {
	data: [],
	view: {}
});
const nutritionistNotificationTags = crudReducer(emptyReducer, NUTRITIONIST_NOTIFICATION_TAGS.name, {
	data: [],
	view: {}
});
const infografia = crudReducer(emptyReducer, INFOGRAFIA.name, {
	data: [],
	view: {}
});
const tutorial = crudReducer(emptyReducer, TUTORIAL.name, {
	data: [],
	view: {}
});

const sagaMiddleware = createSagaMiddleware();

const app = combineReducers({
	[RECEIPE.name]:receipes,
	[RECEIPE_TAG.name]: receipeTags,
	[RECEIPE_CATEGORY.name]:receipeCategory,

	[PRODUCT.name]:products,
	[PRODUCT_TAG.name]: productTags,
	[PRODUCT_CATEGORY.name]:productCategory,

	[PRIZE.name]: prizes,
	[APPOINTMENT.name]:appointments,
	[EQUIVALENT_DIET.name]:equivalentDiet,
	[EQUIVALENT.name]:equivalent,
	[EQUIVALENT_LIST.name]:equivalentList,
	[EQUIVALENT_SUBCAT.name]:equivalentSubcat,
	[EQUIVALENT_SMAE_DIET.name]:equivalentSmaeDiet,
	[EQUIVALENT_SMAE.name]:equivalentSmae,
	[EQUIVALENT_SMAE_LIST.name]:equivalentSmaeList,
	[EQUIVALENT_SMAE_SUBCAT.name]:equivalentSmaeSubcat,
	
	[MENU_DIET.name]:menuDiet,
	[MENU_SELECT_DIET.name]:menuSelectDiet,
	[MENU_SELECT_LIST.name]:menuSelectList,
	
	[OPTIONS_DIET.name]:optionsDiet,
	[PERSONALIZED_DIET.name]:personalizedDiet,
	[COLUMNS_DIET.name]:columnsDiet,
	[PATIENTS.name]:patients,
	[CONSULTATION.name]:consultations,
	[MESSAGE.name]:messages,
	[CUSTOMER.name]:customer,
	[PLAN.name]:plans,
	[LICENCE_COUPON.name]:cuponPlan,
	[NUTRITIONIST.name]:nutitionists,
	[DISCOUNT.name]:discounts,
	[ONLINE_CONSULTATION.name]:onlineConsultation,
	[ONLINE_PATIENT.name]:onlinePatient,
	[NUTRITIONIST_MESSAGE.name]:nutitionistMessages,
	[NUTRITIONIST_NOTIFICATION.name]: nutritionistNotification,
	[NUTRITIONIST_NOTIFICATION_TAGS.name]: nutritionistNotificationTags,
	[INFOGRAFIA.name]: infografia,
	[TUTORIAL.name]: tutorial
	
	

});
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const composeEnhancers =
//   typeof window === 'object' &&
//   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//       // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
//     }) : compose;
const composerEnhancers = composeWithDevTools({
	name: "Redux",
	realtime: true,
	trace: true,
	traceLimit: 25
});
const enhancer = composerEnhancers(
	applyMiddleware(sagaMiddleware),
	// other store enhancers if any
);
const store = createStore(app, enhancer);

// var store = createStore(app, applyMiddleware(sagaMiddleware),window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
sagaMiddleware.run(rootSaga);

export default store;
