import React from "react";
import moment from "moment";

export default ({ cell }) => {
	return (
		<>
			<div>
				{cell.value && moment(cell.value).format(cell.column?.format || "DD/MM/YYYY")}
			</div>
		</>
	);
};