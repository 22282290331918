import React,{useState} from "react";
import { Button, Tabs, Tab, Typography, Box, CircularProgress } from "@mui/material";
import withPreventLeave from "components/HOCs/withPreventLeave";
import { useDispatch } from "react-redux";
import swal from "@sweetalert/with-react";

import SelectableTable from "components/Table/SelectableTable/SelectableTable";
import useSave from "Hooks/useSave";
import DietaMenus from "pages/Dietas/DietaMenus/DietaMenus";
import DietaColumnas from "pages/Dietas/DietaColumnas/DietaColumnas";
import DietaOpciones from "pages/Dietas/DietaOpciones/DietaOpciones";
import DietaPersonalizada from "pages/Dietas/DietaPersonalizada/DietaPersonalizada";
import DietaEquivalencias from "pages/Dietas/DietaEquivalencias/Dieta/DietaEquivalencias";

import styles from "Styles.module.scss";
import { useWindowSize } from "hooks";
import {EQUIVALENT_DIET, MENU_DIET, COLUMNS_DIET, OPTIONS_DIET, PERSONALIZED_DIET, CONSULTATION} from "store/constants";
const dietas = {
	equivalencias: DietaEquivalencias,
	menu: DietaMenus,
	columnas: DietaColumnas,
	opciones: DietaOpciones,
	personalizada: DietaPersonalizada
};
const typesDietas = {
	equivalencias: EQUIVALENT_DIET,
	menu: MENU_DIET,
	columnas: COLUMNS_DIET,
	opciones: OPTIONS_DIET,
	personalizada: PERSONALIZED_DIET
};
function TabPanel(props) {
	const { children, value, index, ...other } = props;
  
	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</Typography>
	);
}

const SeleccionarDieta = props => {
	const dispatch = useDispatch();
	// const {data, onChange, onFile, remove, save, status} = useSave({type:CONSULTATION, autoSave:true, data:props.data, parent_id:props.idPaciente});

	// useEffect(()=>{

	// 	setDieta(data.dieta);
	// },[data]);

	// React.useEffect(()=>{
	// 	if(status.status!=="LOADED"){
	// 		props.canLeave(false);
	// 	}else{
	// 		props.canLeave(true);
	// 	}
	// },[status.status]);
	
	const {innerWidth} = useWindowSize();
	const [dieta, setDieta] = React.useState(props.data.dieta);
	const [isChanging, setChanging] = React.useState(0);
  
	const [value, setValue] = React.useState(false);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const selectDieta = tipo => dieta => {
		setChanging(false);
		const {_id, ...newD} = dieta;
		setDieta(newD);
		props.onChange(newD, "dieta");
	};
	const changeDieta = (dieta, d) => {
		setDieta(dieta);
		props.onChange(dieta, "dieta");
	};

	const [statusSaveDieta, setStatusSaveDieta] = useState({});
	const saveDieta = () => {
		setStatusSaveDieta({status:"SAVING"});
		const type = typesDietas[dieta.tipo];
		console.log(dieta);
		dispatch({
			type: `INSERT_${type.name}`,
			payload: dieta,
			callback: ()=>{
				setStatusSaveDieta({status:"SAVED"});
				swal({
					text: "Dieta guardada exitósamente (podrás ver esta versión en la sección de Dieta general)",
					icon: "success",
				});
			},
			errorCallback: (error)=>{
				setStatusSaveDieta({status:"ERROR",errorMsg:error});
				swal({
					text: "Error al guardar la dieta",
					icon: "warning",
				});
			},
		});
	};

	const columns = React.useMemo(
		() => [
			{
				Header: <i>Nombre</i>,
				accessor: "nombre"
			}
		],
		[]
	);

	return (
		<>
			{!isChanging && dieta && dietas[dieta.tipo] ? (
				<>
					<div style={{marginBottom:"20px"}}>
						<Button variant="contained" color="secondary" onClick={() => setChanging(true)}>Cambiar Dieta</Button>
						{statusSaveDieta.status==="SAVING"
							?
							<CircularProgress />
							:
							<Button 
								variant="contained" 
								style={{backgroundColor:"#2D3C3A",color:"white",marginLeft:"10px"}} 
								onClick={saveDieta}
							>
								Guardar como
							</Button>
						}
					</div>
					
					{React.createElement(dietas[dieta.tipo], {
						data: dieta,
						onChange: changeDieta,
						select: true
					})}
				</>
			) : (
				<>
					<div style={{fontWeight:"bold",fontSize:"1.2em"}}>Elige la dieta que deseas asignarle a tu paciente</div>
					<Tabs
						value={value}
						onChange={handleChange}
						variant={innerWidth < 769 ? "scrollable" : "fullWidth"}
						scrollButtons={innerWidth < 769 ? "on" : "auto"}
						className={styles.flexContainer}
						indicatorColor="primary"
					>
						<Tab label="Por equivalentes" />
						<Tab label="Por menús" />
						<Tab label="Por columnas" />
						<Tab label="Por opciones" />
						<Tab label="Personalizada" />
					</Tabs>
					<TabPanel value={value} index={0}>
						<SelectableTable
							onSelect={selectDieta("equivalencias")}
							columns={columns}
							type={EQUIVALENT_DIET}
						/>
					</TabPanel>
					<TabPanel value={value} index={1}>
						<SelectableTable
							onSelect={selectDieta("menu")}
							columns={columns}
							type={MENU_DIET}
						/>
					</TabPanel>
					<TabPanel value={value} index={2}>
						<SelectableTable
							onSelect={selectDieta("columnas")}
							columns={columns}
							type={COLUMNS_DIET}
						/>
					</TabPanel>
					<TabPanel value={value} index={3}>
						<SelectableTable
							onSelect={selectDieta("opciones")}
							columns={columns}
							type={OPTIONS_DIET}
						/>
					</TabPanel>
					<TabPanel value={value} index={4}>
						<SelectableTable
							onSelect={selectDieta("personalizada")}
							columns={columns}
							type={PERSONALIZED_DIET}
						/>
					</TabPanel>
				</>
			)}
		</>
	);
};
export default withPreventLeave(SeleccionarDieta);