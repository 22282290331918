import React from "react";
import CrudTable from "components/Table/CrudTable/CrudTable";
import Cliente from "./Cliente";
import { useSelector } from "react-redux";
import {CUSTOMER, PLAN} from 'store/constants'
import Header from "../../components/Header/Header";
import DateCell from "components/Table/CellTypes/DateCell/DateCell";
import LinkCell from "components/Table/CellTypes/LinkCell/LinkCell";
import {get} from "api";
import RefCell from "components/Table/CellTypes/RefCell/RefCell";
import { useQuery } from 'react-query'
import {crudFunctions} from "api";

const TablaClientes = props => {
  // const [data, setData] = React.useState([]);

	const actions = crudFunctions(CUSTOMER);
	const {data, status} = useQuery([CUSTOMER.name, "mensuales"], ()=>get("admin/monthly-customers/"), {keepPreviousData:true});
	


	// React.useEffect(() => {

	// 	(async function(){
	// 		let d = await get("admin/monthly-customers/");
	// 		setData(d.data);
	// 	})()
		
	// }, [])


  const columns = React.useMemo(
    () => [
      {
        Header: "Clave",
        accessor: "clave"
      },
			{
				Header: "Plan",
				accessor: "subscripcion.id_plan",
				type: PLAN,
				Cell: RefCell,
				filter:"ref",
				exportType: "ref"
			},
			{
        Header: "Status",
				accessor: "subscripcion.status_pago",
				filter:"select",
				filterOptions:[{value:"", label:"Todos"},{value:"fallido",label:"Fallido"},{value:"pagado",label:"Pagado"},{value:"cancelado",label:"Cancelado"}],
				sortType: "number"
			},
			{
        Header: "Proxima fecha de pago",
				accessor: "subscripcion.proxima_fecha_pago",
				Cell:DateCell,
				filter:"daterange",
				sortType: "date"
			},
			{
        Header: "Proximo intento de pago",
				accessor: "subscripcion.proximo_intento_pago",
				Cell:DateCell,
				filter:"daterange",
				sortType: "date"
			},
			{
        Header: "Num. intentos",
				accessor: "subscripcion.num_intentos",
				filter:"number",
				sortType: "number"
			},
			{
        Header: "Link stripe",
				accessor: "subscripcion.payment_intent",
				link: (value) => `https://dashboard.stripe.com/payments/${value}`,
				filter:null,
				Cell:LinkCell,
				label:"Ver"
			},
			{
        Header: "Correo",
				accessor: "subscripcion.link_pago",
				link: (value, data) => `mailto:${data.correo}?subject=Error%20con%20tu%20pago%20Nutrify&body=Hola%20${data.nombre}%0D%0A%0D%0ANos%20comunicamos%20contigo%20de%20Nutrify%20del%20%C3%A1rea%20de%20servicio%20al%20cliente.%0D%0A%0D%0ANo%20pudimos%20completar%20tu%20pago%20mensual%2C%20te%20pedimos%20ingresar%20al%20siguiente%20enlace%20para%20intentarlo%20de%20nuevo%20o%20a%C3%B1adir%20una%20nueva%20forma%20de%20pago%3A%0D%0A%0D%0A${data.subscripcion.hosted_invoice_url}%0D%0A%0D%0APara%20cualquier%20duda%20o%20aclaración%20por%20favor%20contáctanos%2055%208795%206985`,
				filter:null,
				Cell:LinkCell,
				label:"Enviar"
			},
			{
        Header: "Whatsapp",
				accessor: "subscripcion.link_pago2",
				link: (value, data) => `https://api.whatsapp.com/send?phone=521${data.telefono}&text=Hola%20${data.nombre}%0A%0ANos%20comunicamos%20contigo%20de%20Nutrify%20del%20%C3%A1rea%20de%20servicio%20al%20cliente.%0A%0ANo%20pudimos%20completar%20tu%20pago%20mensual%2C%20te%20pedimos%20ingresar%20al%20siguiente%20enlace%20para%20intentarlo%20de%20nuevo%20o%20a%C3%B1adir%20una%20nueva%20forma%20de%20pago%3A%0A${data.subscripcion.hosted_invoice_url}%0A%0APara%20cualquier%20duda%20o%20aclaración%20por%20favor%20contáctanos%2055%208795%206985`,
				filter:null,
				Cell:LinkCell,
				label:"Enviar"
			},
			
			
    ],
    []
	);

	const dataChanged = (data) => {
		const total = data.reduce((acc, val)=>acc+(val?.subscripcion?.cantidad_pagando||0), 0);
		const totalArr = data.map(val=>val?.subscripcion?.cantidad_pagando);
		console.log({total, data, totalArr})
	}
	

	
  

  return (
    <>
			<Header title="Clientes" />
			<CrudTable
       	data={data?.data || []}
				status={status}

				columns={columns}
				filters={columns}
        type={CUSTOMER}
        modal={Cliente}
        options={{loadBeforeEdit:true, orderBy:true}}
				onFilteredDataChange={dataChanged}

      />

    </>
  );
};

export default TablaClientes;
