import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import { ONLINE_CONSULTATION, NUTRITIONIST, ONLINE_PATIENT } from "store/constants";

const Premio = props => {
	const elements = [
		{
			type: "ref",
			label: "Paciente",
			name: "id_paciente",
			selector: (state)=>state[ONLINE_PATIENT.name].data,
			readOnly:true
		},
		{
			type: "ref",
			label: "Nutriologo",
			name: "id_nutriologo",
			selector: (state)=>state[NUTRITIONIST.name].data,
			readOnly:true
		},
		// {
		// 	type: "calendar",
		// 	label: "Fecha inicio",
		// 	name: "fecha_inicio"
		// },
		{
			type: "text",
			subtype: "number",
			label: "Duracion",
			name: "duracion",
			readOnly:true
		},
		{
			type: "select",
			name: "status",
			label: "Status",
			options:[
				{label:"creada",value:"creada"},
				{label:"buscando",value:"buscando"},
				{label:"aceptado",value:"aceptado"},
				{label:"no_encontrado",value:"no_encontrado"},
				{label:"cancelado",value:"cancelado"},
				{label:"finalizado",value:"finalizado"},
				{label:"devuelto",value:"devuelto"},
				{label:"sin_finalizar",value:"sin_finalizar"},
				{label:"sin_nutriologos",value:"sin_nutriologos"},
				{label:"agendado",value:"agendado"},
			]
		},
		{
			type: "text",
			label: "Calificacion",
			name: "calificacion",
			readOnly:true
		},
		{
			type: "text",
			label: "Review",
			name: "review",
			readOnly:true
		},
		{
			type: "text",
			label: "Total",
			name: "total",
			readOnly:true
		},
		{
			type: "text",
			multiline:true,
			label: "Notas",
			name: "notas"
		}
	];
	return (
		<>
			<ModalForm elements={elements} onChange={props.onChange} data={props.data}></ModalForm>
		</>
	);
};

export default Premio;