import React from "react";
import ChartGrupos from "./ChartGrupos";
import ChartMacronutrimentos from "./ChartMacronutrimentos";
import { catMicronutrimentosMap } from "constants/index";
import { Button } from "@mui/material";
// import Final from "./Final";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { MENU_SELECT_LIST } from "store/constants";
import { useTranslation } from "react-i18next";
import {useQuery} from "react-query";

function DetalleDia({alimentos: alim, onClose, nombres_tiempo, avgSum}) {
	const {t} = useTranslation("common");
	const alimentos = alim.flat();
	const [chartData, setChartData] = React.useState({});
	const menuSelectList = useSelector(state => state[MENU_SELECT_LIST.name].data);
	// const [Final, setFinal] = React.useState({});
	// React.useEffect(()=>{
	// 	fetch("/Final.json").then(
	// 		function(res){
	// 			return res.json();
	// 		}).then(function(data){
	// 			console.log({data})
	// 		setFinal(data);
	// 	}).catch(
	// 		function(err){
	// 			console.log(err, ' error')
	// 		}
	// 	)

	// 	// fetch("/Final.json").then(r=>console.log(r));
	// },[]);

	let { data:Final } = useQuery("equivalenciasMenu", () => fetch("/Final.json").then((res)=>res.json()));
	
	const alimentosFiltrados = (Object.entries(Final||{}).map(([,val])=>val).concat(menuSelectList).flat()).filter(val=>alimentos.map(val2=>val2.id).includes(val.id));
	let micronutrimentos = {};
	React.useEffect(() => {
		if(avgSum){
			const reduc = alimentos.reduce((acc,val)=>{
				acc.kcal += ((val.prot||0)*4) + ((val.lip||0)*9) + ((val.hidr||0)*4);
				acc.prot += (val.prot||0);
				acc.lip += (val.lip||0);
				acc.hidr += (val.hidr||0);
				return acc;
			},{kcal: 0, prot: 0, lip: 0, hidr: 0});
			setChartData(reduc);
		}
		else{
			const reduc = alimentos.reduce((acc,val)=>{
				acc.kcal += (val.kcal||0);
				acc.prot += (val.prot||0);
				acc.lip += (val.lip||0);
				acc.hidr += (val.hidr||0);
				return acc;
			},{kcal: 0, prot: 0, lip: 0, hidr: 0});
			setChartData(reduc);
		}
		
	}, []);

	const listaAlimentos = (Object.entries(Final||{}).map(([,val])=>val).concat(menuSelectList).flat())
	alimentos.forEach(val=>{
		// console.log({val})
		if(val.alimentos){
			val.alimentos.map(al=>{
				const alimento = listaAlimentos.find(val2=>val2.id===al.id);
				Object.entries(alimento?.micronutr ?? {}).filter(([,val])=>val !== "").forEach(([key, val3])=>{
					const result = (micronutrimentos[key] || 0) + +((al.gram * val3) / alimento.medidas[0].gram);
					micronutrimentos[key] = +result.toFixed(2);
				});
			})

		}else{
			const alimento = alimentosFiltrados.find(val2=>val2.id===val.id);
			Object.entries(alimento?.micronutr ?? {}).filter(([,val])=>val !== "").forEach(([key, val3])=>{
				const result = (micronutrimentos[key] || 0) + +((val.gram * val3) / alimento.medidas[0].gram);
				micronutrimentos[key] = +result.toFixed(2);
			});
		}
		
	});

	return (
		<>
			<div style={{display:"flex", flexDirection:"column"}}>
				<div>
					{/* <div>
						<p style={{fontWeight: "bold", fontSize: "12pt"}}>{t("dieta_menu_select_detalle_dia_total_diario")}</p>
						<ChartGrupos alimentos={alimentos} />
					</div> */}
					<DistribucionKcal alimentos={alim} nombres_tiempo={nombres_tiempo}/>
				</div>
				<div>
					<div className="cont-chart"  style={{display:"flex", justifyContent:"space-between"}}>
						<div className="chart" style={{ display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
							<p style={{fontWeight: "bold", fontSize: "12pt"}}>{t("dieta_menu_select_distribucion_macros")}</p>

							<div style={{marginBottom: 20}}>
								<p style={{fontSize: "16pt", fontWeight: "bold", marginBottom: "10px"}}>{chartData.kcal?.toFixed(2)} Kcal</p>
								<div style={{marginBottom: "10px"}}>
									<p style={{color: "#E77067", fontWeight: "bold"}}>Proteinas</p>
									<p style={{fontWeight: "bold"}}>{chartData.prot?.toFixed(2)} gr</p>
								</div>
								<div style={{marginBottom: "10px"}}>
									<p style={{color: "#F7D542", fontWeight: "bold"}}>Lípidos</p>
									<p style={{fontWeight: "bold"}}>{chartData.lip?.toFixed(2)} gr</p>
								</div>
								<div style={{marginBottom: "10px"}}>
									<p style={{color: "#7BAAFB", fontWeight: "bold"}}>Hidratos de carbono</p>
									<p style={{fontWeight: "bold"}}>{chartData.hidr?.toFixed(2)} gr</p>
								</div>
							</div>
						</div>

						<ChartMacronutrimentos 
							data={{pieChartData: chartData}}
						/>
					</div>
					<div style={{maxWidth: 320,}}>
						
						{micronutrimentos && Object.entries(micronutrimentos).map(([key, val])=>(
							<div key={key} style={{display: "flex", justifyContent: "space-between", fontWeight: "bold", fontSize: "14px", marginBottom: "5px"}}>
								<p>{catMicronutrimentosMap[key]}</p>
								<p>{val}</p>
							</div>
						))}
					</div>
				</div>
			</div>
			<div style={{textAlign: "right", marginTop: "15px"}}>
				<Button color="secondary" variant="outlined" onClick={()=>onClose()} >{t("cerrar")}</Button>
			</div>
		</>
	);
}

export default DetalleDia;

const DistribucionKcal = ({alimentos: alim, nombres_tiempo}) => {
	const {t} = useTranslation("common");
	const alimentos = alim.map(val=> val.reduce((acc, val2)=>acc += val2.kcal,0).toFixed(2) );	
	// let options = {
	// 	chart: { type: "bar", height: 300, toolbar:{ show: false } },
	// 	plotOptions: { bar: { distributed: true, dataLabels: { position: "top" }} },
	// 	xaxis: { categories: nombres_tiempo, axisBorder: { show: false }, axisTicks: { show: false, } },
	// 	yaxis: { show: false },
	// 	colors: ["#A5DD72"],
	// 	dataLabels: { enabled: true, formatter: function(value, { seriesIndex, dataPointIndex, w }) { return value != 0 ? w.config.series[seriesIndex].data[dataPointIndex].toFixed(2) : ""; }, style: { fontSize: "14px", colors: ["#3b4c4b"], } },
	// 	legend: { show: false },
	// 	fill: { opacity: 1 },
	// 	grid: { show: false },
	// 	tooltip: { enabled: false },
	// };
	let options = {
    chart: { 
        type: "bar",
        height: 300,
        toolbar: { show: false }
    },
    plotOptions: {
        bar: {
            horizontal: false,
            distributed: false,
            // Adjust bar width (percentage of the available space)
            barHeight: '70%', // You can adjust this value as needed
            // Round the corners of the bars
            borderRadius: 5,
						columnWidth: '15%' ,
            dataLabels: {
                position: "top"
            }
        }
    },
    xaxis: {
        categories: nombres_tiempo,
        axisBorder: { show: false },
        axisTicks: { show: false }
    },
    yaxis: {
        show: false
    },
    colors: ["#33414E"], // Adjust the color code as per the image
    dataLabels: {
        enabled: true,
				offsetY: -20,
        formatter: function(value, { seriesIndex, dataPointIndex, w }) {
            return value != 0 ? w.config.series[seriesIndex].data[dataPointIndex] + ' kcal' : "";
        },
        style: {
            fontSize: "14px",
            colors: ["#33414E"]
        },
    },
    legend: { show: false },
    fill: {
        opacity: 1
    },
    grid: {
        show: false
    },
    tooltip: {
        enabled: false
    },
};

	return(
		<>
			<p style={{fontWeight: "bold", fontSize: "12pt", marginTop: 15}}>{t("dieta_menu_select_detalle_dia_distribucion_kcal")}</p>
			<Chart
				options={{...options}}
				series={[{data:alimentos}]}
				type="bar"
				height="300"
			/>
		</>
	);
};