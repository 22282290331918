import React, {useState} from "react";
import MultiSelectTable from "components/Table/MultiSelectTable/MultiSelectTable";
import { useTranslation } from "react-i18next";
import RefCell from "components/Table/CellTypes/RefCell/RefCell";
import FileCell from "components/Table/CellTypes/FileCell/FileCell";
import { useSelector } from "react-redux";
import { RECEIPE, RECEIPE_CATEGORY, RECEIPE_TAG } from "store/constants";
import { IconButton } from "@mui/material";
import SaveModal from "components/SaveModal/SaveModal";
import Receta from "pages/Recetas/Receta";
import { useDispatch } from "react-redux";
import editarIcon from "assets/img/icons/editar.svg";

export const SelectableRecipeTable = (props) => {
	
	const {t} = useTranslation("common");
	const dispatch = useDispatch();
	const data = useSelector(state => state[RECEIPE.name]);
	const [openedModal, setOpenModal] = useState(false);
	const [editingData, setEditingData] = useState({});
	
	const handleEdit = (recipe) => {
		setEditingData(recipe);
		setOpenModal(true);
	};
	

	const columns = React.useMemo(
		() => [
			{
				Header: t("constants_categoria"),
				accessor: "categorias",
				type: RECEIPE_CATEGORY,
				Cell: RefCell
			},
			{
				Header: t("nombre"),
				accessor: "nombre"
			},
			{
				Header: t("type_file_cell_imagen"),
				accessor: "foto[0]",
				Cell: FileCell,
				disableSortBy: true,
				disableFilters: true,
			},
			{
				Header: t("editar"), // Replace "actions.edit" with the translation key for the edit action
				accessor: "edit",
				disableSortBy: true,
				disableFilters: true,
				Cell: ({ row }) => (
					<IconButton
						onClick={() => handleEdit(row.original)}
						color="secondary"
						aria-label={"editar"}
					>
						<img src={editarIcon} style={{width: 50, height: 50, padding:"0 1px 0 1px", objectFit:"contain", color:"white", borderRadius:3, marginLeft:5}} />
					</IconButton>
				),
			},
		],
		[]
	);
	const filters = React.useMemo(
		() => [
			{
				Header: t("constants_categoria"),
				accessor: "categorias",
				type: RECEIPE_CATEGORY,
				filter: "ref"
			},
			{
				Header: t("nombre"),
				accessor: "nombre"
			},
			{
				Header: t("constants_etiqueta"),
				accessor: "sellos",
				type: RECEIPE_TAG,
				filter: "ref"
			}
		],
		[]
	);

	const change = value => {
		props.propsForAll.setSelectedList({
			...props.propsForAll.selectedList,
			recipes: value
		});
	};

	return (
		<>
			<MultiSelectTable
				columns={columns}
				data={data.data}
				selected={props.propsForAll.selectedList.recipes}
				onChange={change}
				type={RECEIPE}
				filters={filters}
				options={{ orderBy: true, saveAs:true, saveAsNameKey:"nombre" }}
			/>
			{ openedModal && 
			<SaveModal 
				{...props.options} 
				fullWidth={props.fullWidth} 
				parent_id={props._id} 
				// onInsert={onInsert}
				modal={Receta} 
				forceNew={false}
				type={RECEIPE} 
				status={props.status}
				viewOnly={false}
				data={editingData}
				saveAs
				// selectedButton={selectedButton}
				handleModal={()=>{
					setOpenModal(false);
					// setViewOnly(false);
					// props.onCloseModal && props.onCloseModal();
					const name = RECEIPE.tail_name ? `${RECEIPE.name}_${RECEIPE.tail_name}` : RECEIPE.name;
					dispatch({
						type: `${name}_STATUS`,
						payload: {status:"LOADED"}
					});
				}} 
			/>
			}
		</>
	);
};