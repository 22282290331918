import React, { useState, useEffect } from "react";
import DefaultFilter from "components/Table/FilterTypes/DefaultFilter/DefaultFilter";
import DateRange from "components/Table/FilterTypes/DateRange/DateRange";
import SelectFilter from "components/Table/FilterTypes/SelectFilter/SelectFilter";
import RefFilter from "components/Table/FilterTypes/RefFilter/RefFilter";
import SmaeFilter from "components/Table/FilterTypes/SmaeFilter/SmaeFilter";
import NumberRange from "components/Table/FilterTypes/NumberRange/NumberRange";
import BoolFilter from "components/Table/FilterTypes/Bool/Bool";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import _ from "lodash";
// import RefFilter from "./RefFilter";
import "./Filter.scss";
const moment = require("moment");
var e = React.createElement;


const f = {
	text: (rows, id, filterValue, filterType) => {
		if (filterValue==="")return rows;
		filterValue = String(filterValue).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
		return rows.filter(row => {
			let rowValue = _.get(row, id);
			if(rowValue===undefined)return false;
			rowValue = String(rowValue).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
			return rowValue !== undefined
				? (filterType !== "smae" ? rowValue.includes(filterValue) : rowValue == filterValue)
				: true;
		});

	},
	select: (rows, id, filterValue, filterType) => {
		if (filterValue==="")return rows;
		filterValue = filterValue.filter(f=>f).map(s=>String(s).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));

		return rows.filter(row =>{
			let rowValue = _.get(row, id);
			return filterValue.every(filter => rowValue?.includes(filter))
		});



	},
	daterange:(rows, id, filter) => {
		if (!filter[0] || !filter[1]) {
			return rows;
		}
		return rows.filter(row=>{
			if (!_.get(row, id) || !moment(_.get(row, id)).isValid())return false;
			if (moment(_.get(row, id)).isBetween(filter[0], filter[1] ,"days","[]") ) {
				return true;
			}
		});
	},
	number:(rows, id, filter) => {
		if (!filter[0] || !filter[1]) {
			return rows;
		}
		return rows.filter(row=>{
			console.log(parseFloat(_.get(row, id))>=parseFloat(filter[0]) && parseFloat(_.get(row, id))<= parseFloat(filter[1]), parseFloat(_.get(row, id)),parseFloat(filter[0]),parseFloat(filter[1]) )
			if(parseFloat(_.get(row, id))>=parseFloat(filter[0]) && parseFloat(_.get(row, id))<= parseFloat(filter[1]))return true;
			return false;
		});
	}
};
const m = {
	daterange: DateRange,
	number:NumberRange,
	select: SelectFilter,
	ref: RefFilter,
	smae: SmaeFilter,
	bool: BoolFilter
};
const Filter = props => {
	const [filterData, setFilterData] = useState({});

	const calcFilter = (filtro) => (val) => {
		if(val===undefined)val="";
		if(filtro.deleteAllOnDelete){setFilterData({[filtro.accessor]: val}); return;}
		setFilterData((state)=>({...state, [filtro.accessor]:val}));
	};

	useEffect(()=>{
		let newData = props.data;
		Object.entries(filterData).map(([key,val])=>{
			let filtro = props.filtros.find(f=>f.accessor===key);
			if(filtro.filter==="select" && !filtro.multiple){
				newData = f.text(newData, filtro.accessor, val, filtro.filter);
			}else{
				newData = f[filtro.filter] ? f[filtro.filter](newData, filtro.accessor, val) : f.text(newData, filtro.accessor, val, filtro.filter);
			}
		});
		props.filteredData(newData);
		props.gotoPage && props.gotoPage(0);

	},[filterData]);

	useEffect(()=>{
		const f = Object.fromEntries(Object.entries(filterData).filter(([k, v])=>{
			if(typeof v==="string" && v.length==0)return false;
			return true
		}));
		props.onFilterChange && props.onFilterChange(f);
	},[filterData]);


	const FilterForType = (filtro) =>{
		if(filtro.Filtro)return filtro.Filtro;
		return m[filtro.filter]? m[filtro.filter]: DefaultFilter;
	};
	return (
		<div className="filtros-base-table">
			<div style={{marginBottom: "5px"}}>
				<h3>Filtrar</h3>
			</div>
			<div className="row-filter">
				{props.filtros.filter(f=>f.filter!==null).map(filtro => (
					<React.Fragment key={filtro.accessor}>
						<div className="container-input" style={{marginRight:"10px"}}>
							<p className="header" style={{fontWeight:"bold",marginBottom:"3px"}}>
								{filtro.Header}
							</p>
							<div className="container-type-filter">
								{e(FilterForType(filtro), {
									column:{
										setFilter: calcFilter(filtro), 
										filterValue: filterData[filtro.accessor],
										preFilteredRows: props.data,
										setFilterData: props.filteredData,
										filterData,
										...filtro
									},
									
								})}								
								{filterData[filtro.accessor] &&
									<IconButton color="secondary" onClick={()=>calcFilter(filtro)(undefined)} style={{padding: 0}}>
										<ClearIcon />
									</IconButton>
								}
							</div>
						</div>
					</React.Fragment>
				))}
			</div>
		</div>
	);
};

export default Filter;

Filter.propTypes = {
	data: PropTypes.array,
	type: PropTypes.object,
	filtros: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
