import EquivalenciaCell from "../EquivalenciaCell/EquivalenciaCell";
import MapCell from "components/Table/CellTypes/MapCell/MapCell";
import {medidas, catEquivalenciasSmae, catEquivalenciasSmaeMap, subcatSmaeMap, subcatEquivalenciasSmae} from "misc/grupos";
export default [
	{
		Header: "Grupo",
		accessor: "categoria",
		Cell: MapCell,
		map: catEquivalenciasSmaeMap,
		style: {textTransform:"capitalize"},
	},
	{
		Header: "Subcategoria",
		accessor: "subcategoria",
		Cell: MapCell,
		map: subcatSmaeMap,
		style: {textTransform:"capitalize"},
		disableSortBy: true
	},
	{
		Header: "Alimento",
		accessor: "nombre",
		disableSortBy: true
	},
	{
		Header: "Unidad",
		accessor: "medida",
		disableSortBy: true
	},
	{
		Header: "Cantidad",
		accessor: "cantidad",
		disableSortBy: true
	},
	{
		Header: "Equivalente",
		accessor: "equivalencia",
		disableSortBy: true,
		Cell: EquivalenciaCell,
	},
	{
		Header: "Fibra",
		accessor: "micronutrimentos.fib",
		disableSortBy: true
	},
	{
		Header: "Vitamina A (µg RE)",
		accessor: "micronutrimentos.vit_a",
		disableSortBy: true
	},
	{
		Header: "Ácido Ascórbico (mg)",
		accessor: "micronutrimentos.ac_asc",
		disableSortBy: true
	},
	{
		Header: "Ácido Fólico (mg)",
		accessor: "micronutrimentos.ac_fol",
		disableSortBy: true
	},
	{
		Header: "Hierro No HEM (mg)",
		accessor: "micronutrimentos.h_no_hem",
		disableSortBy: true
	},
	{
		Header: "Potasio (mg)",
		accessor: "micronutrimentos.pot",
		disableSortBy: true
	},
	{
		Header: "Índice glicémico",
		accessor: "micronutrimentos.i_gli",
		disableSortBy: true
	},
	{
		Header: "Carga glicémica",
		accessor: "micronutrimentos.car_gli",
		disableSortBy: true
	},
	{
		Header: "Azúcar por equivalente (g)",
		accessor: "micronutrimentos.azu_e",
		disableSortBy: true
	},
	{
		Header: "Calcio (mg)",
		accessor: "micronutrimentos.cal",
		disableSortBy: true
	},
	{
		Header: "Hierro (mg)",
		accessor: "micronutrimentos.hie",
		disableSortBy: true
	},
	{
		Header: "Sodio (mg)",
		accessor: "micronutrimentos.sod",
		disableSortBy: true
	},
	{
		Header: "Selenio (µg)",
		accessor: "micronutrimentos.se",
		disableSortBy: true
	},
	{
		Header: "Fosforo (mg)",
		accessor: "micronutrimentos.fos",
		disableSortBy: true
	},
	{
		Header: "Colesterol (mg)",
		accessor: "micronutrimentos.col",
		disableSortBy: true
	},
	{
		Header: "AG Saturados (g)",
		accessor: "micronutrimentos.ag_sat",
		disableSortBy: true
	},
	{
		Header: "AG monoinstaurados (g)",
		accessor: "micronutrimentos.ag_mono",
		disableSortBy: true
	},
	{
		Header: "AG poliinstaurados (g)",
		accessor: "micronutrimentos.ag_poli",
		disableSortBy: true
	},
	{
		Header: "Etanol",
		accessor: "micronutrimentos.et",
		disableSortBy: true
	},
	{
		Header: "Libres",
		accessor: "micronutrimentos.lib",
		disableSortBy: true
	}
];