import React from "react";
import DietaColumnas from "./DietaColumnas";
import CrudTable from "../../../components/Table/CrudTable/CrudTable";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {COLUMNS_DIET}  from "store/constants";
import AppView from "components/AppView/AppView";
const TabDietas = () => {
	const data = useSelector(state => state[COLUMNS_DIET.name]);
	const columns = React.useMemo(
		() => [
			{
				Header: "Nombre",
				accessor: "nombre"
			}
		],
		[]
	);
	const filters = React.useMemo(
		() => [
			
			{	
				Header: "Nombre",
				accessor: "nombre",
			}
		],
		[]
	);

	return (
		<>
			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				type={COLUMNS_DIET}
				modal={DietaColumnas}
				filters={filters}
				fullWidth
				options={{ orderBy: true, saveAs:true, loadBeforeEdit:true }}
				headerActions={[<AppView key="app_view" img="dieta-columnas" />]}
			/>
		</>
	);
};

export default TabDietas;