import React from "react";
import PropTypes from "prop-types";

import {medidas, catEquivalencias} from "misc/grupos";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
const Equivalencias = (props) => {
	const elements = React.useMemo(()=>[
		{
			type: "bool",
			name: "u",
			label: "Unlocked",
		},
		{
			type:"select",
			name:"categoria",
			label:"Grupo",
			options:catEquivalencias
		},
		{
			type:"if",
			name:["categoria","subcategoria"],
			formula:(val)=>val==="preparados",
			field:{
				type:"ref",
				name:"subcategoria",
				label:"Subcategoria",
				selector:(state)=>state.EQUIVALENT_SUBCAT.data
			}
		},
		{
			type: "text",
			name: "nombre",
			label: "Alimento",
		},
		{
			type: "select",
			name: "medida",
			label: "Unidad",
			options:medidas
		},
		{
			type: "text",
			name: "cantidad",
			label: "Cantidad",
		},
		{
			type: "equivalencia",
			name: "equivalencia",
			label: "Equivalentes"
		}
	],[]);
	
	return (
		<>
			<ModalForm elements={elements} onChange={props.onChange} data={props.data} {...props}>
			</ModalForm>

		</>
	);
};

export default Equivalencias;

Equivalencias.propTypes = {
	onChange: PropTypes.func,
	data: PropTypes.object
};
