import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";

const Premio = props => {
	const elements = [
		{
			type:"text",
			name:"nombre",
			label:"Nombre"
		},
		{
			type:"richText",
			name:"mensaje",
			label:"Mensaje"
		},
		{
			type: "multiGroup",
			name: "acciones",
			label: "Acciones",
			fields:[
				{
					type:'select',
					name:'collection',
					options:[
						{label:'Dieta de menus',value:'OPTIONS_DIET'},
						{label:'Dieta de columnas',value:'COLUMNS_DIET'},
					]
				},
				{
					type:'dynamicRef',
					name:'ids'
				},
			]
		},
		
	];
	return (
		<>
			<ModalForm elements={elements} onChange={props.onChange} data={props.data}></ModalForm>
		</>
	);
};

export default Premio;