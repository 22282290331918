import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useImmer } from "use-immer";
import { EQUIVALENT_SMAE, EQUIVALENT_SMAE_SUBCAT } from "store/constants";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import MultiSelectTable from "components/Table/MultiSelectTable/MultiSelectTable";
import EquivalenciaCell from "../EquivalenciaCell/EquivalenciaCell";
import MapCell from "components/Table/CellTypes/MapCell/MapCell";
import SelectFilter from "components/Table/FilterTypes/SelectFilter/SelectFilter";
import {medidas, catEquivalenciasSmae, catEquivalenciasSmaeMap, subcatSmaeMap, subcatEquivalenciasSmae, subcatSmaeFilter} from "misc/grupos";
const ListaEquivalentes = props => {
	// const data = useSelector(state => state[props.type]);
	const equivalencias = useSelector(state => state[EQUIVALENT_SMAE]);
	let subcat = useSelector(state => state[EQUIVALENT_SMAE_SUBCAT.name].data);
	let filterSubcat = subcat;
	subcat = subcat.map(val=>({[val._id]: val.nombre}));
	filterSubcat = filterSubcat.filter(val=>!val?._trash);
	filterSubcat = filterSubcat.map(val=>({value: val._id, label: val.nombre, type: "preparados"}));

	const [lista, setLista] = useImmer(props.data?props.data:
		{
			nombre: "",
			equivalencias: []
		}
	);

	const change = value => {
		setLista(draft => {
			draft.equivalencias = value;
		});
	};

	const columns = React.useMemo(
		() => [
			{
				accessor: "categoria",
				Header: "Grupo",
				Cell: MapCell,
				map: catEquivalenciasSmaeMap,
				style: {textTransform:"capitalize"},
			},
			{
				accessor: "subcategoria",
				Header: "Subcategoria",
				Cell: MapCell,
				map: Object.assign({},subcatSmaeMap, ...subcat),
				style: {textTransform:"capitalize"},
			},
			{
				Header: "Alimento",
				accessor: "nombre"
			},
			{
				accessor: "medida",
				Header: "Unidad",
				Filter:SelectFilter,
				options:[{label:"Todos",value:""},...medidas]
			},
			{
				accessor: "cantidad",
				Header: "Cantidad"
			},
			{
				accessor: "equivalencia",
				Header: "Equivalente",
				Cell:EquivalenciaCell,
				disableFilters:true,
				disableSortBy: true
			}
		],
		[]
	);
	const filters = React.useMemo(
		() => [
			{
				accessor: "categoria",
				Header: "Grupo",
				filter:"smae",
				filterOptions:[{label:"Todos",value:""},...catEquivalenciasSmae],
				deleteAllOnDelete: true
			},
			{	
				Header: "Subgrupo",
				accessor: "subcategoria",
				filter:"smae",
				filterOptions:[{label:"Todos",value:""},...subcatEquivalenciasSmae, ...filterSubcat],
				filterOptionsMap: subcatSmaeFilter,
				specialFilter: "subcatSmae",
			},
			{
				Header: "Alimento",
				accessor: "nombre"
			},
			{
				accessor: "medida",
				Header: "Unidad",
				filter:"select",
				filterOptions:[{label:"Todos",value:""},...medidas]
			},
			{
				accessor: "cantidad",
				Header: "Cantidad"
			}
		],
		[]
	);


	
	useEffect(() => {
		props.onChange(lista.equivalencias, "equivalencias");
	}, [lista.equivalencias]);

	const elements = React.useMemo(()=>[
		{
			type:"text",
			name:"nombre",
			label:"Nombre"
		},
	]);
	return (
		<>
			<ModalForm fullWidth elements={elements} onChange={props.onChange} data={lista} {...props}>
				<MultiSelectTable
					columns={columns}
					data={equivalencias?.data}
					selected={lista.equivalencias}
					onChange={change}
					type={EQUIVALENT_SMAE}
					filters={filters}
					options={{ orderBy: true, saveAs:true }}
				/>
			</ModalForm>

			{/* <FormGroup>
				<TextField
					type="text"
					label="Nombre de la lista"
					value={lista.nombre}
					onChange={e =>
						setLista(draft => {
							draft.nombre = e.target.value;
						})
					}
				/>
				
			</FormGroup> */}
		</>
	);
};

export default ListaEquivalentes;

ListaEquivalentes.propTypes = {
	data: PropTypes.object,
	onChange: PropTypes.func	
};