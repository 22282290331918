import React,{useState} from "react";
import { useHistory } from "react-router-dom";

import MultiSelectTable from "components/Table/MultiSelectTable/MultiSelectTable";
import BaseTable from "components/Table/BaseTable/BaseTable";
import { useSelector } from "react-redux";
import { PATIENTS, PRIZE } from "store/constants";
import { Checkbox, Modal, Fade, Button } from "@mui/material";
import AutoSaveStatus from "components/AutoSaveStatus/AutoSaveStatus";

import styles from "Styles.module.scss";

const Premios = (props) => {
	// const patient = useSelector(state => state[PATIENTS.name].data.find(data => data._id === props.idPaciente));

	const prizes = useSelector(state => state[PRIZE.name]);
	const [openModal,setOpenModal] = useState(false);
	const handleModal = () => {
		setOpenModal((state)=>setOpenModal(!state));
	};

	const columns = React.useMemo(
		() => [
			{
				Header: "Premio",
				accessor: "nombre"
			},
			{
				Header: "Lo obtiene cuando",
				accessor: "obtiene"
			}
		],
		[]
	);
	const handleCheck = (cell) => {
		const premios = props.data.premios.map(premio=>{
			if(cell.row.original.id_premio===premio.id_premio){
				return {...premio, obtenido:!premio.obtenido};
			}
			else return premio;
		});
		props.onChange(premios,"premios");
	};
	const handleDelete = (cell) => {
		const premios = props.data.premios.filter(premio=>cell.row.original.id_premio!==premio.id_premio);
		props.onChange(premios,"premios");
	};
	// const onSelect = ({data, index}) => {
	// 	const premios = [...patient.premios, {nombre: data.nombre, obtiene:data.obtiene, obtenido:false, id_premio:data._id}];
	// 	props.onChange(premios,"premios");
	// };
	// const onUnselect = ({data, index}) => {
	// 	// props.removeElement(index,'premios')
	// 	const premios = patient.premios.filter((p)=>p.id_premio!==data._id);
	// 	props.onChange(premios,"premios");
	// };
	const onChange = (ids) =>{
		// ids.map()

		let myIds = ids.filter(id=>id);
		const premios = myIds.map(id=>{
			let prize = prizes.data.find(p=>{
				return p._id===id
			});
			let premioPaciente = props.data.premios.find(p=>p.id_premio===id);

			return {
				nombre: prize.nombre, 
				obtiene:prize.obtiene, 
				obtenido:premioPaciente?.obtenido ? true: false,
				id_premio:prize._id
			};
		})

		props.onChange(premios, "premios");
		handleModal();
		


	};

  
	const actionsColumn = [{
		Header: "Obtenido",
		Cell: function CheckCell({ cell }){
			return <>
				<Checkbox
					checked={cell.row.original.obtenido}
					color="primary"
					onChange={()=>handleCheck(cell)}
					inputProps={{ "aria-label": "primary checkbox" }}
				/>
			</>;
		},
		accessor: "id",
		id: "actions2",
		disableSortBy: true
	},{
		Header: <Button variant="contained" color="primary" onClick={handleModal}>Asignar Premios</Button>,
		Cell: function DeleteCell({ cell }){
			return <>
				<Button variant="contained" color="secondary" onClick={()=>handleDelete(cell)}>Eliminar</Button>
			</>;
		},
		accessor: "id",
		id: "actions",
		disableSortBy: true
	}];
  

	const [tempIds, setTempIds] = useState([]);

	return (
		<>
			<AutoSaveStatus status={props.status} save={props.save} />
      
			
			<BaseTable
				columns={[...columns, ...actionsColumn]}
				data={props.data?.premios || []}
				// options={options}
			/>

			<Modal open={openModal} onClose={handleModal} className={styles.modal}>
				<Fade in={openModal}>
					<div className={styles.paper}>
						<MultiSelectTable
							data={prizes.data || []}
							onChange={setTempIds}
							selected={props.data.premios?.map((premio)=>premio.id_premio) || []}
							columns={columns}
							type={PATIENTS}
							// onSelect={onSelect}
							// onUnselect={onUnselect}
              filters={columns}
							options={{ filter: true }}
							fullWidth
						/>
						<Button variant="contained" color="primary" style={{float:"right"}} onClick={()=>onChange(tempIds)}>Guardar</Button>

					</div>
				</Fade>
			</Modal>
      
		</>
	);
};

export default Premios;
