import React, {useState, useEffect} from "react";
import Modal from "components/Modal/Modal";
import { useSelector, useDispatch } from "react-redux";

import cx from "classnames";

import { Button, TextField, Tooltip } from "@mui/material";
import {Autocomplete} from "@mui/lab";
import PropTypes from "prop-types";
import ModalListaCompras from "pages/ListaCompras/ModalListaCompras";
import {Edit} from "@mui/icons-material";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import SaveAsModal from "components/Modals/SaveAs";
import {useDialog} from "context/DialogContext";
import { Alert } from "@mui/lab";
import WhiteButton from "components/Buttons/WhiteButton";
import Select from "components/Select/Select"

const RefListaCompras = props => {
	const {t} = useTranslation("common");
	const dispatch = useDispatch();
	const [openDialog, closeDialog] = useDialog();
	const [status, setStatus] = useState({});
	const [openModal, setOpenModal] = useState(false);
	const [dataModal, setDataModal] = useState(props.value);
	const shoppingList = useSelector(state=>state.SHOPPING_LIST.data);
	let options = [...(props.dieta ? [{nombre: "Crear lista de compras automaticamente", id_lista: "1"}]: []), {nombre: "Nueva lista de compras", id_lista: "0"}];
	options = [...options, ...shoppingList.map(val=>{
		// eslint-disable-next-line no-unused-vars
		// const {_id, _created_date, lista, ...rest} = val;
		// let listaFormateada = {...lista}
		// Object.entries(lista).forEach(([key, alimentos]) => {listaFormateada[key] = alimentos.map(producto => ({producto}))})
		// return {...rest, lista: listaFormateada, id_lista: _id};
		const {_id, _created_date, id_cliente, ...rest} = val;
		return {...rest, id_lista:_id};
	})];
	const handleChange = (value) =>  {
		if(value?.id_lista === "0"){
			props.onChange(undefined, props.name);
			setOpenModal(true);
			setDataModal(null)

			return;
		}
		if(value?.id_lista === "1"){

			const lista = convertTiemposComidaToShoppingList(props.dieta.tiempos_comida)
			setDataModal({lista})
			setOpenModal(true);
			return;
		}
		props.onChange(value, props.name);
	};

	const onChangeListaCompras = (value) => {
		setDataModal(value);
	};

	useEffect(() => {
		setDataModal(props.value);
	}, [props.value]);

	return <ContainerRefListaCompras>
		<Select
			cacheOptions
			options={options || []}
			value={props.value?.id_lista}
			onChange={handleChange}
			classNamePrefix="react-select"
			// filterOption={filterOptions}
			style={{ width: "100%" }}
			placeholder={"Elige una opción"}
			getOptionLabel={option => option.nombre}
			labelAccessor="nombre"
			accessor="id_lista"
			optionStyle={(styles, props) => {
				const { data, isDisabled, isFocused, isSelected,  } = props;
				if(data.id_lista === "0"){
					return {
						...styles,
						background: "#bace8288", 
						textDecoration: "underline"
					};
				}
				if(data.id_lista==="1"){
					return {
						...styles,
						background: "linear-gradient(0deg, rgba(149,104,249,1) 0%, rgba(199,104,249,1) 100%)", 
						color:"white",
						textDecoration: "underline"
					};
					
				}
				return {
					...styles,
				};
			}}
		/>

		{props.value?.lista && <WhiteButton type="editar" onClick={()=>setOpenModal(true)} />}
		<Modal open={openModal} onClose={()=>setOpenModal(false)} onClickClose={()=>setOpenModal(false)} widthPaper="60%">
			<ModalListaCompras onChange={onChangeListaCompras} data={dataModal} />
			{
				(status.status === "UPDATE_FAILED" || status.status === "INSERT_FAILED" ) &&
			<Alert severity="error">{JSON.stringify(status.errorMsg)}</Alert>
			}
			{props?.value?.id_lista && <p style={{margin: "5px 0", textAlign: "right"}}>Esta modificación solo se asignará para esta dieta</p>}
			<div style={{textAlign: "right"}}>
				<Button color="primary" variant="contained" onClick={()=>{
					setStatus({});
					if(!props?.value?.id_lista){
						
						dispatch({
							type: "INSERT_SHOPPING_LIST",
							payload: dataModal,
							callback: ({data})=> {
								props.onChange({...data, id_lista:data._id}, "lista_compras");
								setOpenModal(false);
							},
							errorCallback: (error)=>{
								setStatus({status:"INSERT_FAILED", errorMsg:error});
								return;
							},
						});
					}else{
						setOpenModal(false);
					}
					props.onChange(dataModal, "lista_compras");
				}}>{t("guardar")}</Button>
				{props?.value?.id_lista &&
				<Button color="secondary" variant="contained" onClick={()=>{
					setStatus({});
					openDialog({
						onClose:(data)=>{
							if(data){
								let uploads = {...dataModal};
								uploads.nombre = data;
								dispatch({
									type: "INSERT_SHOPPING_LIST",
									payload: uploads,
									callback: ({data})=>{
										props.onChange(data, "lista_compras");
										setOpenModal(false);
									},
									errorCallback: (error)=>setStatus({status:"INSERT_FAILED", errorMsg:error}),
								});
							}else{
								return;
							}
						},
						Content: SaveAsModal
						
					});
					
				}}>{t("save_modal_guardar_como_nuevo")}</Button>
				}
			</div>
		</Modal>
	</ContainerRefListaCompras>;
};

export default RefListaCompras;

const ContainerRefListaCompras = styled.div`
	display: flex;
	& + div{
		width: 100%;
	}
	.button-edit{
		height: 100%;
		width: 30px;
		background-color: #a5dd72;
		padding: 0 1px 0 1px;
		color: white;
		border-radius: 0 5px 5px 0;
	}
	.option-autocomplete{
		background: #bace8288 !important;
	}
`;

RefListaCompras.propTypes = {
	selector: PropTypes.func,
	extraItems: PropTypes.array,
	onChange: PropTypes.func,
	name: PropTypes.string,
	hidden: PropTypes.bool,
	readOnly: PropTypes.bool
};


//Create shopping list
const convertTiemposComidaToShoppingList = (tiemposComida) => {
	const shoppingList = {
		frut_verd: [],
		cer_leg: [],
		lac: [],
		prot: [],
		bebidas: [],
		otros: [],
	};

	// Helper function to add item to the correct category
	const addItemToCategory = (item, category) => {
		const categoryMapping = {
			'frutas': 'frut_verd',
			'verduras': 'frut_verd',
			'cereales': 'cer_leg',
			'leguminosas': 'cer_leg',
			'lacteos': 'lac',
			'proteinas': 'prot',
			'preparados': 'otros', // Assuming 'preparados' are 'otros'
			// Add more mappings as needed
		};

		const listCategory = categoryMapping[category];
		if (listCategory) {
			// Check if the item is already in the list
			if (!shoppingList[listCategory].some(product => product.producto === item)) {
				shoppingList[listCategory].push({ producto: item });
			}
		} else {
			// If category is not mapped, add to 'otros', checking for duplicates
			if (!shoppingList.otros.some(product => product.producto === item)) {
				shoppingList.otros.push({ producto: item });
			}
		}
	};
	console.log({tiemposComida})

	tiemposComida.forEach(tiempoComida => {
		tiempoComida.menu.forEach(menu => {
			menu.forEach(item => {
				if (item.alimentos && item.alimentos.length > 0) {
					item.alimentos.forEach(alimento => {
						addItemToCategory(alimento.nombre, alimento.categoria);
					});
				} else {
					addItemToCategory(item.nombre, item.categoria);
				}
			});
		});
	});
	console.log({shoppingList})

	return shoppingList;
};


