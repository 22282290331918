import React, { useEffect } from "react";


import { TextField } from "@mui/material";
import {Autocomplete} from "@mui/lab";
import PropTypes from "prop-types";


const RefSmae = props => {
	let options = props.options;
	if(props.parentValues?.categoria) {
		options = [props.options[props.parentValues?.categoria]];
		options = Object.values(options[0]).map(val=>val);
	};

	const getLabel = (value) => {
		const obj = options?.find(o=>o.value===value);
		return obj ? obj.label : "";
	};

	const handleChange = (event, value) =>  {
		if(value===null)value="";
		props.onChange(value, props.name);
	};
	
	return <Autocomplete
		id="combo-box-demo"
		openOnFocus
		size="small"
		value={props.value || ""}
		options={options?.map(value=>value.value) || []}
		getOptionLabel={option => getLabel(option)}
		style={{ width: "100%" }}
		onChange={handleChange}
		renderInput={params => (
			<>
				<TextField
					{...params}
					variant="outlined"
					fullWidth
				/>
			</>
		)}
	/>;
    
};

export default RefSmae;

RefSmae.propTypes = {
	selector: PropTypes.func,
	extraItems: PropTypes.array,
	onChange: PropTypes.func,
	name: PropTypes.string,
	hidden: PropTypes.bool,
	readOnly: PropTypes.bool
};