import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { NUTRITIONIST_MESSAGE } from "../constants";

const loadSagas = loadRequest(NUTRITIONIST_MESSAGE);
const insertSaga = insertRequest(NUTRITIONIST_MESSAGE);
const updateSaga = updateRequest(NUTRITIONIST_MESSAGE);
const trashAppointMent = trashRequest(NUTRITIONIST_MESSAGE);
const untrashSaga = untrashRequest(NUTRITIONIST_MESSAGE);
const removeSaga = removeRequest(NUTRITIONIST_MESSAGE);

function* rootSaga() {
	yield all([
		loadSagas(),
		insertSaga(),
		updateSaga(),
		removeSaga(),
		trashAppointMent(),
		untrashSaga()
	]);
}
export default rootSaga;