import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { PRODUCT, PRODUCT_TAG, PRODUCT_CATEGORY } from "../constants";
import { all } from "redux-saga/effects";

const loadProducts = loadRequest(PRODUCT);
const loadProductTags = loadRequest(PRODUCT_TAG);
const loadProductCategories = loadRequest(PRODUCT_CATEGORY);

const insertProduct = insertRequest(PRODUCT);
const insertProductTag = insertRequest(PRODUCT_TAG);
const insertProductCategory = insertRequest(PRODUCT_CATEGORY);

const updateProduct = updateRequest(PRODUCT);
const updateProductTag = updateRequest(PRODUCT_TAG);
const updateProductCategory = updateRequest(PRODUCT_CATEGORY);

const removeProduct = removeRequest(PRODUCT);
const removeProductTag = removeRequest(PRODUCT_TAG);
const removeProductCategory = removeRequest(PRODUCT_CATEGORY);

const trashProduct = trashRequest(PRODUCT);
const trashProductTag = trashRequest(PRODUCT_TAG);
const trashProductCategory = trashRequest(PRODUCT_CATEGORY);

const untrashProduct = untrashRequest(PRODUCT);
const untrashProductTag = untrashRequest(PRODUCT_TAG);
const untrashProductCategory = untrashRequest(PRODUCT_CATEGORY);

function* rootSaga() {
	yield all([
		loadProducts(),
		insertProduct(),
		updateProduct(),
		removeProduct(),
		loadProductTags(),
		insertProductTag(),
		updateProductTag(),
		removeProductTag(),
		loadProductCategories(),
		insertProductCategory(),
		updateProductCategory(),
		removeProductCategory(),
		trashProduct(),
		trashProductTag(),
		trashProductCategory(),
		untrashProduct(),
		untrashProductCategory(),
		untrashProductTag()
	]);
}
export default rootSaga;