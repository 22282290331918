import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import AssignMacros from "./AssignMacros";
import "./DietaMenus.scss";
const Dieta = ({conf: configuracion, onClose, idx_dia, isAi}) => {
	const {t} = useTranslation("common");
	const conf = Object.entries(configuracion).reduce((acc, [key, val])=>{
		if(key !== "kcal"){
			acc.percentage[key] = ((val / configuracion.kcal) * 100) * (key === "lip" ? 9 : 4);
		}
		return acc;
	}, {...configuracion, percentage: {prot: 0, hidr: 0, lip: 0}});
	const dias = [
		{ name: "monday", label: t("modal_dias_laborales_lunes") },
		{ name: "tuesday", label: t("modal_dias_laborales_martes") },
		{ name: "wednesday", label: t("modal_dias_laborales_miercoles") },
		{ name: "thursday", label: t("modal_dias_laborales_jueves") },
		{ name: "friday", label: t("modal_dias_laborales_viernes") },
		{ name: "saturday", label: t("modal_dias_laborales_sabado") },
		{ name: "sunday", label: t("modal_dias_laborales_domingo") },
	];
	
	// const guardar = () => {
	// 	onClose(kcal);
	// };
	return (
		<>
			<p style={{fontWeight: "bold", textAlign: "center", marginBottom: 5}}>{t("dieta_menu_select_edit_calorias_elige", {dia: dias[idx_dia].label})}</p>
			<AssignMacros hideData day onSave={onClose} prevValue={conf} isAi={isAi} />
		</>);
};

export default Dieta;

Dieta.propTypes = {
	value: PropTypes.array,
	onChange: PropTypes.func
};
