import { useState, useEffect } from 'react';

//Returns value if window.innerWidth that changes when window resizes
//const [innerWidth, innerHeight] = useWindowSize();
export const useWindowSize = () => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  
  const handleWindowResize = () => {
    setInnerWidth(window.innerWidth);
    setInnerHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
    // eslint-disable-next-line
  }, []);

  return { innerWidth, innerHeight };
};