
import React from "react";
// import runA from "pages/Dietas/DietaMenusSelect/ai";
import DietaMenusSelect from "./DietaMenusSelect"
import {get} from "api";
// import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import { useQuery } from 'react-query'
import AssignMacros from "./AssignMacros";
import {smae} from "constants/index";
import "pages/Dietas/DietaSmae/Dieta/DietaEquivalenciasSmae.scss"
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import { Button, LinearProgress } from "@mui/material";
import Text from "components/FormComponents/Text/Text";
import Radio from "components/FormComponents/Radio/Radio";
import SetMacros from "./Chart/SetMacros";
import aiIcon from "assets/img/ai-white.png"
import Lottie from "lottie-react";
import aiAnimation from "assets/animation/ai.json";
import {Alert} from "@mui/lab"
import SelectAlimento from "./SelectAlimento/SelectAlimento";
import {tiposPlanes} from "constants/ai";
// import Worker from "../aipuntos.worker.js" 
import useAlimentosAI from "Hooks/useAlimentosAI";
import ChartMacronutimentos from "./ChartMacronutrimentos";
import {sumValues} from "functions/general";
import DynamicImage from "components/DynamicImage";

// let worker = new Worker();

 
const textos = ["Buscando alimentos","Creando plan"]
export default function AIDiet(props){
	// const worker = useWorker(createWorker);
	const {getAlimentosFiltrados} = useAlimentosAI();

	const [step, setStep] = React.useState(1);
	const [data, setData] = React.useState({restricciones:[],restricciones_alimentos:"", distribucion_macros:{}, distribucion_menu:[], vis_pac:{
		ene_dia: false,
		ene_alim: false,
	}});
	const [dieta, setDieta] = React.useState();
	const [localError, setLocalError] = React.useState();


	const onChangeEquivalencias= (v, distribucion_macros)=>{ 
		setData(d=>({...d, distribucion_eq:v, distribucion_macros}));
	};
	const selectPlan = (v)=>{
		const distribucion_menu = planes?.opciones.find(o=>o.value===v).slots;
		setData(d=>({...d, plan:v, distribucion_menu}));
	};
	const onChangeRestricciones = (v, n)=>{
		if(data.restricciones.includes(v)){
			const r = data.restricciones.filter(item => item !== v);
			setData(d=>({...d, restricciones: r}));
		}else{
			setData(d=>({...d, restricciones: d.restricciones.concat(v)}))
		}
	};
	const onChangeRestriccionesAlimentos = (v)=>{
		setData(d=>({...d, restricciones_alimentos: v}))
		
	}; 
	const planes = React.useMemo(()=>{
		const kcal = +data.distribucion_macros.kcal;
		const tipos = tiposPlanes.find(tipoPlan=>tipoPlan.from<=kcal && tipoPlan.to>=kcal);
		return tipos;
	},[data.distribucion_macros.kcal]);


	const [progress, setProgress] = React.useState(0);
	// const p = React.useRef(0)
	const day = React.useRef(0)

	const [t, setT]  =React.useState(textos[0]);
	function calcularDieta(){
		console.log("new diet")
		setStep(4);
		setT(textos[0])


 
		const alimentos = getAlimentosFiltrados();

		
		const interval = setInterval(()=>{
			setProgress(p=>{
				if(p+1>(day.current+1)*14){
					//nada
					return p;
				}
				else{
					return p+= 1.5;
				}
			});
			
			
		},1000);

		
	} 

	const goToStep = step=>{
		if(step===2){
			if(data.distribucion_macros.kcal < 800){
				setLocalError("Por favor asigna más equivalencias");
				return;
			}
			if(data.distribucion_macros.kcal > 3500 ){
				setLocalError("Por favor asigna menos equivalencias");
				return;
			}
			
		}

		if(step===3 && !data.plan  ){
			setLocalError("Por favor selecciona algún plan");
			return;
		}
		setLocalError(null);
		setStep(step);
	};
	React.useEffect(()=>{
		props.onStepChange && props.onStepChange(step);
		props.setHideSaveButton && props.setHideSaveButton(step!==5);
	},[step]);


	return <>
		{step===1 &&<>
			
			<div style={{fontWeight:"bold", fontSize:"1.2em"}}>Paso 1.</div>
			<div style={{color:"gray", fontSize:"1.2em"}}>Asigna la distribución de equivalentes.</div>
			<div style={{marginTop:20}}>
				<Puntos onChange={onChangeEquivalencias} />
				{/* <div style={{fontWeight:"bold", marginBottom:10}}>Kcal totales</div>
				<Text value={data?.distribucion_macros?.kcal} onChange={(v)=>setData(d=>({...d, distribucion_macros:{...d.distribucion_macros, kcal:v}}))} name="nombre" />
				<div style={{fontWeight:"bold", marginTop:20, marginBottom:10}}>Distribución de macronutrientes</div>
				<SetMacros kcal={data.distribucion_macros.kcal} value={data?.distribucion_macros} onChange={onChangeMacros} /> */}
				<Button style={{marginTop:25, float:"right",color:"white", background: "linear-gradient(0deg, rgba(149,104,249,1) 0%, rgba(199,104,249,1) 100%)"}} onClick={()=>goToStep(2)}>Siguiente</Button>
			</div>
		</>}
		{step===2 && <>
			<div style={{fontWeight:"bold", fontSize:"1.2em"}}>Paso 2</div>
			<div style={{color:"gray", fontSize:"1.2em"}}>Selecciona el tipo de plan que deseas crear:</div>
			<Radio options={planes?.opciones} onChange={selectPlan} value={data.plan} />
			<div style={{marginTop:20, display:"flex", justifyContent:"space-between"}}>
				<Button variant="outlined" color="secondary" onClick={()=>goToStep(1)}>Anterior</Button>
				<Button style={{color:"white", background: "linear-gradient(0deg, rgba(149,104,249,1) 0%, rgba(199,104,249,1) 100%)"}} onClick={()=>goToStep(3)}>Siguiente</Button>
			</div>
		</>}
		{step===3 &&<>
			<div style={{fontWeight:"bold", fontSize:"1.2em"}}>Paso 3</div>
			<div style={{color:"gray", fontSize:"1.2em"}}>Selecciona las restricciones:</div>
			<Restricciones onChange={onChangeRestricciones} onChangeRestriccionesAlimentos={onChangeRestriccionesAlimentos} restricciones={data.restricciones} />
			<div style={{marginTop:20, display:"flex", justifyContent:"space-between"}}>
				<Button variant="outlined" color="secondary" onClick={()=>goToStep(2)}>Anterior</Button>
				<Button onClick={calcularDieta} style={{border:0, borderRadius:5,padding:10, display:"flex",alignItems:"center", color:"white", background: "linear-gradient(0deg, rgba(149,104,249,1) 0%, rgba(199,104,249,1) 100%)"}}><img style={{height:25, marginRight:5, padding:5}} src={aiIcon} />Crear plan</Button>
			</div>
		</>}
		{step===4 &&<>
			<div style={{maxWidth:500}}>
				<Lottie animationData={aiAnimation} loop={true} />
				<LinearProgress variant="determinate" value={progress} style={{marginBottom:10}} />
				{t}
				{/*  */}
			</div>
		</>}
		{step===5 && <>
			{!!dieta && <DietaMenusSelect new onFile={props.onFile} removeFile={props.removeFile} onRemoveExistingFile={props.onRemoveExistingFile}  __deleted_files={props.__deleted_files} onChange={props.onChange} data={{...dieta, ...data, subtipo:"eq", ai:true, mostrar_equivalentes: "smae", lista_equivalencias_smae:"-1",mostrar_intercambios_automaticos_app:true}} alimentos={getAlimentosFiltrados()} />}
			{/* restricciones={data.restricciones} distribucion_macros={data.distribucion_macros} distribucionMenu={data.slots} */}
		</>}
		{localError && <Alert severity="error" style={{marginTop:20}}>{localError}</Alert>}
	</>;
}

const tagsDietasAutomaticas = [
	{value:"ll", label:"Libre de lácteo"},
	{value:"lg", label:"Libre de glúten"},
	{value:"vt", label:"Vegetariana"},
	{value:"vg", label:"Vegana"},
	{value:"ba", label:"Bajo en azúcar"},
	{value:"bg", label:"Bajo en grasa"},
	{value:"k", label:"Kosher"},
];

function Restricciones({onChange, restricciones=[], onChangeRestriccionesAlimentos}){
	const [alimentos, setAlimentos] = React.useState();
	const addAlimento = (v) => {
		console.log({v})
		setAlimentos(v);
		onChangeRestriccionesAlimentos(v);
	}
	return <div>
		<FormControl component="fieldset">
			{tagsDietasAutomaticas.map(tag=><>
				<FormGroup>
					<FormControlLabel
						control={<Checkbox checked={restricciones.includes(tag.value)} onChange={()=>onChange(tag.value)} name="gilad" />}
						label={tag.label}
					/>
				</FormGroup>
			
			</>)}	
		</FormControl>
		<div style={{fontWeight:"bold", fontSize:"1em", marginTop:10}}>Selecciona los alimentos que deseas excluir de tu plan</div>
		<div style={{color:"gray"}}>Escribe los ingredientes que deseas excluir separados por una coma:</div>

		<Text style={{marginTop:5}} placeholder="queso, mayonesa, leche" isMulti value={alimentos} onChange={addAlimento} />
	</div>;
}

// TODO: next line may be used for backend purposes, uncomment if needed, delete if no issues where found on prod after a few days:
// window.store= store;


export const Puntos = ({value, onChange, onClose, showWarning}) => {

	var [totales, setTotales] = React.useState(value||{});
	React.useEffect(()=>{
		onChange && onChange(totales, chartData);
	},[totales]);
	

	const chartData= React.useMemo(()=>{
		const tempTotales = Object.entries(totales);
		let macros = {prot:0, lip:0, hidr:0, kcal:0};
		for(let i=0;i<tempTotales.length;i++){
			const totalesGrupo = tempTotales[i];
	
			const grupo = smae.find(s=>s.value===totalesGrupo[0]);
			if(typeof totalesGrupo[1] === "number"){
				macros.prot += grupo.aporte.proteinas * totalesGrupo[1];
				macros.lip += grupo.aporte.lipidos * totalesGrupo[1];
				macros.hidr += grupo.aporte.hidratos_de_carbono * totalesGrupo[1];
				macros.kcal += grupo.aporte.cal * totalesGrupo[1];
			}else{
				const tempTotalesSubgrupo = Object.entries(totalesGrupo[1]);

				for(let i=0;i<tempTotalesSubgrupo.length;i++){
					const totalesSubGrupo = tempTotalesSubgrupo[i];
			
					const subgrupo = grupo.subcat[totalesSubGrupo[0]]
					macros.prot += subgrupo.aporte.proteinas * totalesSubGrupo[1];
					macros.lip += subgrupo.aporte.lipidos * totalesSubGrupo[1];
					macros.hidr += subgrupo.aporte.hidratos_de_carbono * totalesSubGrupo[1];
					macros.kcal += subgrupo.aporte.cal * totalesSubGrupo[1];
					
				}
				// grupo;.subcat[]
			}
		}
		return macros;
	
	},[totales])


	// { value: "v", name: "v", label: i18n.t("common:grupos_verduras"), photo: "verduras", color: "#8EBF64", aporte: {cal: 25, proteinas: 2, lipidos: 0, hidratos_de_carbono: 4, conteo_hc: 0.3}},



	return <div className="bodyx">
		<div style={{textAlign:"center", marginBottom:10}}>
			<p style={{fontWeight: "bold", fontSize: "12pt"}}>Kcal totales</p>
			<div>{chartData?.kcal}kcal</div>
		</div>
		
		<ChartMacronutimentos data={{pieChartData: chartData}} options={{ legend:{show:true}}} />

		{smae.map((grupo, idx_grupo) => <div className="row" key={idx_grupo} style={{background:grupo.color+"19", display: "flex", alignItems: "center", justifyContent:"space-between", padding:"5px 0"}}>
				
			<div style={{width: "75%", display: "inline-block", minWidth: 190}}>
				<div style={{display: "flex", alignItems: "center", width: "100%"}}>
					<div style={{display: "flex", alignItems: "center", width: 300, marginRight: "7px"}}>
						<div className="imagen-alimento">
							<DynamicImage
								src={`${grupo.photo}.png`}
								alt={grupo.label}
								style={{padding:"7px", width:30}}
								className="img-category"
							/>
						</div>
						<div
							className="nombre-alimento"
							style={{
								textAlign:"left",
								// background: grupo.color,
								color:grupo.color, 
							}}
						>
							{grupo.label}
						</div>
					</div>
					{grupo.subcat &&
						<div className="subcategoria subcategoria-names" style={{gap:5, display:"flex", flexDirection:"column"}}>
							<>
								{Object.values(grupo.subcat).map(value=>(
									<>
										<p style={{color: grupo.color, whiteSpace: "nowrap"}}>{value.label}</p>
									</>
								))}
							</>
						</div>
					}
				</div>
			</div>

			<div style={{width:"25%", textAlign:"center", padding:"0 5px"}}>
				<div className="subcategoria" style={{display: "flex", flexDirection: "column", alignItems: "center", gap:5}}>
					{grupo.subcat ?
						<>
							{Object.keys(grupo.subcat).map((key)=>(
								<>
									<input
										className="eqInput"
										style={{width:120, padding:5}}
										type="number"
										id=""
										placeholder={0}
										onWheel={(e) => e.target.blur()}
										value={totales[grupo.name]?.[key] ? totales[grupo.name][key]:""}
										onChange={(e) => {
											e.persist();
											setTotales(t=>({
												...t,
												[grupo.name]:{
													...t[grupo.name],
													[key]: +e.target.value
												}
											}));
										}}
									/>
								</>
							))}
						</>
						:
						<input
							className="eqInput"
							style={{width:120,padding:5}}
							type="number"
							id=""
							placeholder={0}
							value={totales[grupo.name] ? totales[grupo.name]:""}
							onWheel={(e) => e.target.blur()}
							onChange={(e) => {
								e.persist();
								setTotales(t=>({
									...t,
									[grupo.name]: +e.target.value
								}));
							}}
						/>
					}
				</div>
				
			</div>
			<div style={{margin: "0 5px", width: "20px", textAlign: "center"}}>
				{grupo.subcat &&
					<p>{totales[grupo.name] ? totales[grupo.name].total : 0}</p>
				}
			</div>

		</div>)}
		{showWarning && <Alert severity="warning">Al guardar se recalculara toda la dieta del dia reemplazando todos los alimentos de ese dia</Alert>}
		
		
		{onClose && <div style={{float:"right", marginTop:20, display:"flex", gap:10}}>
			<Button variant="outlined" color="secondary" onClick={()=>onClose()}>Cancelar</Button>
			<Button variant="contained" color="secondary" onClick={()=>onClose(totales)}>Guardar</Button>
		</div>}
	</div>;
};
