import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import * as immutable from "object-path-immutable";
import PropTypes from "prop-types";
import useSkipFirstRender from "./useSkipFirstRender";
import {useDialog} from "Contexts/DialogContext";
import SaveAsModal from "components/Modals/SaveAs";

const _ = require("lodash");
var shortid = require("shortid");

const useSave = (props) => {
	// eslint-disable-next-line no-unused-vars
	const [openDialog, closeDialog] = useDialog();

	const [state, setState] = useState(props.data);
	const [changes, setChanges] = useState();
	const [status, setStatus] = useState({status:"LOADED"});
	const [isValid, setValid] = useState(true);
	const timerId = useRef(-1);
	//useSelector(state=>state[props.type?.name]?.view);

	useEffect(()=>{
		if(props.data || changes){
			setState(()=>{
				let data ={...props.data};
				_.merge(data, changes);
				return data;
			});
		}
	},[props.data]);
	
	const dispatch = useDispatch();

	const onChange = React.useMemo(
		() => (value, name) => {
			if (name === undefined) {
				setState(value);
				setChanges((state)=>({ ...state, ...value }));
			} else {
				setState(state=>{
					return immutable.set(state, name, value);
				});
				setChanges(state=>{
					return immutable.set(state, name, value);
				});
				// setChanges((state)=>({ ...state, [name]: value }));
			}
		},[]);
	
	useSkipFirstRender(()=>{
		if(props.autoSave){
			
			// eslint-disable-next-line no-unused-vars
			const {_id, parent_id, ...rest} = changes;
			if(Object.keys(rest).filter(k=>{
				return rest[k]!==undefined;
			}).length>0){
				setStatus({status:"SAVING"});
				clearInterval(timerId.current);
				timerId.current = setInterval(saveData, 2000);
			}
		}
	},[changes]);

	
	const addFile = (files, field) => {

		if(Array.isArray(files.files)){
			const mappedFiles = files.files.map(file=>{
				const id=shortid.generate();
				return {
					name:field, 
					file:file, 
					_id:id 
				};
			});

			setChanges((state)=>{
				return {
					...state, 
					"files": [...((state && state.files) || []), ...mappedFiles]
				};
			});
			setState((state)=>{
				return {
					...state, 
					"files": [...((state && state.files) || []), ...mappedFiles]
				};
			});
		}
		else{

			const id=shortid.generate();


			setChanges((state)=>({
				...state, 
				"files":
					{
						name:field, 
						file:files.files, 
						_id:id 
					}
			}));
			setState((state)=>({
				...state, 
				"files":
					{
						name:field, 
						file:files.files, 
						_id:id 
					}
			}));
		}
	};

	const removeFile = (file) => {
		const mappedFiles = changes.files.filter(f=>f.file != file);	
		setChanges((state)=>{
			return {
				...state, 
				"files": mappedFiles
			};
		});
		setState((state)=>{
			return {
				...state, 
				"files": mappedFiles
			};
		});
	};

	// eslint-disable-next-line no-unused-vars
	const remove = (idx, field, single) => {
		setChanges((state)=>immutable.del(state, `${field}.${idx}`));
		setState((state)=>immutable.del(state, `${field}.${idx}`));
	};

	const _insert = (name, data) => {
		dispatch({
			type: `INSERT_${name}`,
			payload: data,
			callback: (data)=>{
				setStatus({status:"INSERTED"});
				props.onInsert && props.onInsert(data.data);
			},
			errorCallback: (error)=>setStatus({status:"INSERT_FAILED", errorMsg:error}),
		});
	};

	const saveData = (options={}) => {

		clearInterval(timerId.current);

		let changesWithIds = {...changes, _id:props.data?._id, parent_id:props.parent_id};

		const uploadChanges = Object.keys(changesWithIds).reduce((acc, item)=>{
			_.set(acc, item, changesWithIds[item]);
			return acc;
		},{});
		const name = props.type.tail_name ? `${props.type.name}_${props.type.tail_name}` : props.type.name;

		// console.log({uploadChanges, options, props});
		setStatus({status:"SAVING"});

		if(options.saveAs){
			
			if(props.saveAsNameKey){
				openDialog({
					onClose:(data)=>{
						// console.log({data});
						if(data){
							let uploads = {...state, parent_id:props.parent_id};
							uploads[props.saveAsNameKey] = data;
							_insert(name, uploads);
						}else{
							setStatus({status:"LOADED"});
						}
					},
					Content: SaveAsModal
					
				});
			}else{
				let uploads = {...state, parent_id:props.parent_id};
				_insert(name, uploads);
			}
			
				
		}
		else if(props.forceNew || !props.data){
			const uploads = {...state, parent_id:props.parent_id};
			_insert(name, uploads);

		}
		else{
			const uploads = {...uploadChanges, parent_id:props.parent_id, _id:props.data._id};

			dispatch({
				type: `UPDATE_${name}`,
				payload: uploads,
				callback: (data)=>{
					setChanges({});
					setStatus({status:"UPDATED"});
					props.onUpdate && props.onUpdate(data.data);
				},
				errorCallback: (error)=>setStatus({status:"UPDATE_FAILED", errorMsg:error}),
			});
		} 
	};

	return {data:state, onChange, onFile:addFile, removeFile, remove, save:saveData, status, setValid, isValid};

};
export default useSave;

useSave.propTypes = {
	data: PropTypes.object,
	autoSave: PropTypes.bool,
	parent_id: PropTypes.string,
	onInsert: PropTypes.func,
	forceNew: PropTypes.bool,
	onUpdate: PropTypes.func,
	type: PropTypes.object,
	saveAs: PropTypes.bool
};