import React from "react";
import DateFnsUtils from "@date-io/date-fns";
// import { MuiPickersUtilsProvider, DatePicker } from "@mui/x-date-pickers";

import { DatePicker,  DateTimePicker} from '@mui/x-date-pickers';


import { IconButton, TextField } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import es from "date-fns/locale/es";

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function DateRange(props) {
	const { column: { filterValue = [], setFilter, dateFormat } } = props;
	return (
		<div style={{display: "flex"}}>
				<>
					<DatePicker
						renderInput={(inputProps) => <TextField {...inputProps} size="small" disabled variant="outlined" />}
						style={{paddingRight:"5px",width:"120px"}}
						inputProps={{style:{color:"gray"}}}
						size="small"
						inputVariant="outlined"
						autoOk
						emptyLabel="es"
						format="dd/MM/yyyy"
						views={dateFormat||['year', 'month', 'day']}//,
						value={filterValue[0] || null}
						onChange={(date) => {
							setFilter([date ? date : undefined, filterValue[1]]);
						}}
					/>

					<DateTimePicker
						renderInput={(inputProps) => <TextField {...inputProps} size="small" variant="outlined" />}
						style={{width:"120px"}}
						size="small"
						inputProps={{style:{color:"gray"}}}
						inputVariant="outlined"
						emptyLabel="Hasta"
						autoOk
						format="dd/MM/yyyy"
						views={dateFormat||['year', 'month', 'day']}
						value={filterValue[1] || null}
						onChange={date => {
							setFilter([filterValue[0], date ?  date : undefined]);
						}}
					/>
					{(filterValue[0] || filterValue[1]) && <IconButton style={{padding:0}} onClick={()=>setFilter(() => [undefined, undefined])}><ClearIcon /></IconButton>}
				</>
			{/* </MuiPickersUtilsProvider> */}
		</div>
	);
}
export default DateRange;