import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useImmer } from "use-immer";
import { EQUIVALENT } from "store/constants";
import {medidas, catEquivalencias} from "misc/grupos";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import MultiSelectTable from "components/Table/MultiSelectTable/MultiSelectTable";
import EquivalenciaCell from "../EquivalenciaCell/EquivalenciaCell";
import SelectFilter from "components/Table/FilterTypes/SelectFilter/SelectFilter";
const ListaEquivalentes = props => {
	// const data = useSelector(state => state[props.type]);
	const equivalencias = useSelector(state => state[EQUIVALENT]);
	const [lista, setLista] = useImmer(props.data?props.data:
		{
			nombre: "",
			equivalencias: []
		}
	);

	const change = value => {
		setLista(draft => {
			draft.equivalencias = value;
		});
	};

	const columns = React.useMemo(
		() => [
			{
				accessor: "categoria",
				Header: "Grupo",
				style: {textTransform:"capitalize"},
				Filter:SelectFilter,
				options:[{label:"Todos",value:""},...catEquivalencias]
			},
			{
				Header: "Alimento",
				accessor: "nombre"
			},
			{
				accessor: "medida",
				Header: "Unidad",
				Filter:SelectFilter,
				options:[{label:"Todos",value:""},...medidas]
			},
			{
				accessor: "cantidad",
				Header: "Cantidad"
			},
			{
				accessor: "equivalencia",
				Header: "Equivalente",
				Cell:EquivalenciaCell,
				disableFilters:true,
				disableSortBy: true
			}
		],
		[]
	);
	const filters = React.useMemo(
		() => [
			{
				accessor: "categoria",
				Header: "Grupo",
				filter:"select",
				filterOptions:[{label:"Todos",value:""},...catEquivalencias]
			},
			{
				Header: "Alimento",
				accessor: "nombre"
			},
			{
				accessor: "medida",
				Header: "Unidad",
				filter:"select",
				filterOptions:[{label:"Todos",value:""},...medidas]
			},
			{
				accessor: "cantidad",
				Header: "Cantidad"
			}
		],
		[]
	);


	
	useEffect(() => {
		props.onChange(lista.equivalencias, "equivalencias");
	}, [lista.equivalencias]);

	const elements = React.useMemo(()=>[
		{
			type:"text",
			name:"nombre",
			label:"Nombre"
		},
	]);
	return (
		<>
			<ModalForm fullWidth elements={elements} onChange={props.onChange} data={lista} {...props}>
				<MultiSelectTable
					columns={columns}
					data={equivalencias?.data}
					selected={lista.equivalencias}
					onChange={change}
					type={EQUIVALENT}
					filters={filters}
					options={{ orderBy: true, saveAs:true }}
				/>
			</ModalForm>

			{/* <FormGroup>
				<TextField
					type="text"
					label="Nombre de la lista"
					value={lista.nombre}
					onChange={e =>
						setLista(draft => {
							draft.nombre = e.target.value;
						})
					}
				/>
				
			</FormGroup> */}
		</>
	);
};

export default ListaEquivalentes;

ListaEquivalentes.propTypes = {
	data: PropTypes.object,
	onChange: PropTypes.func	
}