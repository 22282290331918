import React from "react";
import { useSelector, useDispatch } from "react-redux";

const Home = () => {
  return (
    <div>
      <h1>Home</h1>
      <button
        onClick={() => {
        }}
      >
        Prueba Loading
      </button>
    </div>
  );
};

export default Home;
