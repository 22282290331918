import React from "react";
import ModalForm from "../../components/FormComponents/ModalForm/ModalForm";
import PropTypes from "prop-types";

const Producto = props => {
	const elements = React.useMemo(()=>[
		{
			type:"multiref",
			name:"categorias",
			label:"Categorías",
			selector:(state)=>state.PRODUCT_CATEGORY.data.filter((r)=>!r._trash)
		},
		{
			type: "text",
			name: "nombre",
			label: "Nombre",
		},
		{
			type: "text",
			name: "ingredientes",
			label: "Ingredientes",
			multiline:true
		},
		{
			type: "text",
			name: "porcion",
			label: "Tamaño de la porción",
		},
		{
			type: "checkbox",
			name: "equivalencias_activas",
			label: "Equivalentes",
			options: [
				{label: "Activar equivalentes simplificados", value: "equivalencia", default: true},
				{label: "Activar equivalentes SMAE", value: "equivalencia_smae"}
			]
		},
		{
			type:"if",
			name:["equivalencias_activas.equivalencia", "equivalencia"],
			formula:(val)=>val,
			field:{
				type:"equivalencia",
				name:"equivalencia",
				label:"Equivalentes simplificado"
			}
		},
		{
			type:"if",
			name:["equivalencias_activas.equivalencia_smae", "equivalencia_smae"],
			formula:(val)=>val,
			field:{
				type:"equivalenciaSmae",
				name:"equivalencia_smae",
				label:"Equivalentes SMAE"
			}
		},
		{
			type: "text",
			name: "de_venta_en",
			label: "De venta en",
			multiline:true
		},
		{
			type: "text",
			name: "link",
			label: "Link",
		},
		{
			type:"multiref",
			name:"sellos",
			label:"Etiquetas",
			selector:(state)=>state.PRODUCT_TAG.data
		},
		{
			type: "file",
			name: "foto",
			label: "Imágenes",
			multipleFiles: true
		}
	],[]);
	return (
		<>
			<ModalForm elements={elements} onChange={props.onChange} data={props.data} {...props}></ModalForm>
		</>
	);
};

export default Producto;

Producto.propTypes = {
	onChange: PropTypes.func,
	data: PropTypes.object
};