import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import {useLocation, Redirect, useHistory} from "react-router-dom";

import { put, post } from "api";
import {getNutitionistId} from "api/loader";
import { NUTRITIONIST } from "store/constants";

const nutId = getNutitionistId();

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export default function ConnectStripe(props){

	const history = useHistory();
  
	var data = useSelector(state=>state[NUTRITIONIST.name].data.find(n=>n._id===nutId));
	const [status, setStatus] = useState("Cargando...");
  
	let query = useQuery();
	const stripe_id = query.get("code");
  
	useEffect(() => {
		if(data){
			setId(data.id_cliente).then(()=>{
				setStatus("Vinculación exitosa, redirigiendo a página de configuración");
			}).catch(()=>{        
				setStatus("Error en la vinculación, porfavor vuelve a intentarlo");
			}).finally(()=>{
				setTimeout(() => {    
					history.push("/configuracion");
				}, 3000);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);
  
	const setId = async (id_cliente) => {
		await post("customers/me/connect-account",{stripe_id_consultas_online: stripe_id});
	};
  
  
	return(
		<>
			{status}
		</>
	);
}