import React, { useState, useEffect } from "react";
import { useImmer } from "use-immer";
import Box from "components/Box/Box";
import {smae} from "misc/grupos";
import PropTypes from "prop-types";

import "./TableEquivalenciasSmae.scss";

const TableEquivalencias = (props) => {
	const [equivalencias, setEquivalencias] = useImmer((props.value && Object.keys(props.value).length > 0) ? props.value : {
		c: {sg: undefined, cg: undefined}, p: {mb: undefined, mo: undefined, b: undefined, a: undefined}, g: {cp: undefined, sp: undefined}, f: undefined, v: undefined, l: {d: undefined, sd: undefined, e: undefined, ca: undefined}, azu: {cg: undefined, sg: undefined}, ag: undefined, leg: undefined,
	});
	const [aportacion, setAportacion] = useState({cal: 0, proteinas: 0, lipidos: 0, hidratos_de_carbono: 0, conteo_hc: 0});

	useEffect(() => {
		props.onChange(equivalencias, props.name);

		const aportes = smae.reduce((acc,v)=>{
			if(v.subcat){
				Object.entries(v.subcat).forEach(([key, value]) => {
					acc.cal += (equivalencias?.[v.name]?.[key] ?? 0) * +value.aporte.cal;
					acc.proteinas += (equivalencias?.[v.name]?.[key] ?? 0) * +value.aporte.proteinas;
					acc.lipidos += (equivalencias?.[v.name]?.[key] ?? 0) * +value.aporte.lipidos;
					acc.hidratos_de_carbono += (equivalencias?.[v.name]?.[key] ?? 0) * +value.aporte.hidratos_de_carbono;
					acc.conteo_hc += (equivalencias?.[v.name]?.[key] ?? 0) * +value.aporte.conteo_hc;
				});
			} else {
				acc.cal += (equivalencias?.[v.name] ?? 0) * +v.aporte.cal;
				acc.proteinas += (equivalencias?.[v.name] ?? 0) * +v.aporte.proteinas;
				acc.lipidos += (equivalencias?.[v.name] ?? 0) * +v.aporte.lipidos;
				acc.hidratos_de_carbono += (equivalencias?.[v.name] ?? 0) * +v.aporte.hidratos_de_carbono;
				acc.conteo_hc += (equivalencias?.[v.name] ?? 0) * +v.aporte.conteo_hc;
			}
			return acc;
		},{
			cal: 0,
			proteinas: 0,
			lipidos: 0,
			hidratos_de_carbono: 0,
			conteo_hc: 0
		});
		
		setAportacion(aportes);

	}, [equivalencias]);
	
	return (
		<div className="container-equivalencias-smae">
			<Box>
				<table className="content-table-equivalencias">
					<tbody>
						{smae.map((grupo, idx_grupo) => (
							<div className="row" key={idx_grupo} style={{background:grupo.color+"19", display: "flex", alignItems: "center", justifyContent:"space-between"}}>
								{/* <div style={{width:dieta.distribuida ? "25%" : "75%", display: "inline-block"}}> */}
								<div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "90%"}}>
									<div style={{display: "flex", alignItems: "center", width: "60%"}}>
										<div className="imagen-alimento">
											<img
												src={require(`assets/img/${grupo.photo}.png`)}
												alt={grupo.label}
												style={{padding:"7px"}}
												className="img-category"
											/>
										</div>
										<div
											className="nombre-alimento"
											style={{
												textAlign:"left",
												// background: grupo.color,
												color:grupo.color, 
											}}
										>
											{grupo.label}
										</div>
									</div>
									{grupo.subcat &&
									<div className="subcategoria subcategoria-names">
										<>
											{Object.values(grupo.subcat).map((value, idx)=>(
												<>
													<p key={idx} style={{color: grupo.color}}>{value.label}</p>
												</>
											))}
										</>
									</div>
									}
								</div>
								<>
									<div style={{width: "10%", padding: "5px"}}>
										<div style={{display: "flex", flexDirection: "column"}}>
											{grupo.subcat ?
												<>
													{Object.keys(grupo.subcat).map((key, idx)=>(
														<>
															<input
																className="eqInput" 
																key={idx}
																type="number"
																id=""
																value={equivalencias?.[grupo.name]?.[key] || ""}
																placeholder={0}
																style={{width: "100%"}}  
																onChange={e => {
																	e.persist();
																	setEquivalencias(draft => {
																		if(!draft[grupo.name]) draft[grupo.name] = {};
																		draft[grupo.name][key] = (isFinite(e.target.value) && Number(e.target.value)!==0) ? Number(e.target.value) : 0;
																	});
																}}
															/>
														</>
													))}
												</>
												:
												<input
													className="eqInput"
													type="number"
													id=""
													style={{width: "100%"}}
													value={equivalencias?.[grupo.name] || ""}
													placeholder={0}
													onChange={e => {
														e.persist();
														setEquivalencias(draft => {
															draft[grupo.name] = (isFinite(e.target.value) && Number(e.target.value)!==0) ? Number(e.target.value) : 0;
														});
													}}
												/>
											}
										</div>
									</div>
								</>
							</div>
						))}
					</tbody>
				</table>
			</Box>
			<div className="container-aportaciones">
				<div className="aportacion">Energía Total<div>{aportacion.cal.toFixed(2)} Cal</div></div>
				<div className="aportacion">Proteína (G)<div>{aportacion.proteinas.toFixed(2)} g</div></div>
				<div className="aportacion">Lípidos (G)<div>{aportacion.lipidos.toFixed(2)} g</div></div>
				<div className="aportacion">Hidratos de carbono (G)<div>{aportacion.hidratos_de_carbono.toFixed(2)} g</div></div>
				<div className="aportacion">Conteo de hidratos de carbono (G)<div>{aportacion.conteo_hc.toFixed(2)} </div></div>
			</div>
		</div>
	);
};

export default TableEquivalencias;

TableEquivalencias.propTypes = {
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
	onChange: PropTypes.func,
	name: PropTypes.string
};