
import React, {useState, useRef,useEffect} from "react";


import {
	TextField,
	Checkbox
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {Autocomplete} from "@mui/lab";
import { useSelector } from "react-redux";
import Select from "components/Select/Select";
// const NoPaddingAutocomplete = withStyles({
//   inputRoot: {
//     '&&[class*="MuiOutlinedInput-root"]': {
//       padding: 1
//     }
//   },
//   input: {}
// })(Autocomplete);

function useTraceUpdate(props) {
	const prev = useRef(props);
	useEffect(() => {
		const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
			if (prev.current[k] !== v) {
				ps[k] = [prev.current[k], v];
			}
			return ps;
		}, {});
		if (Object.keys(changedProps).length > 0) {
			// console.log("Changed props:", changedProps);
		}
		prev.current = props;
	});
}
const Text = props => {
	useTraceUpdate(props);
	const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
	const checkedIcon = <CheckBoxIcon fontSize="small" />;
	const options = useSelector(eval(props.selector));
	const getLabel = (id) => {
		const obj = options.find(o=>o._id===id);
		return obj ? obj.nombre : "";
	};
	const handleChange = value =>  {
		props.onChange(value?.map(d=>d._id)||[], props.name);
	};
	return <Select
		name="tags"
		accessor="_id"
		labelAccessor="nombre"
		multiSelect
		preventAutoChange
		options={options}
		value={props.value}
		onChange={handleChange}
	/>

    
};

export default Text;



