import React from "react";
import {Tabs, Tab} from "@mui/material";
import TabPanel from "../../TabPanel/TabPanel";


import Element from "../Element";
import styles from "./MultiGroupTabs.css";
import { Grid, Typography, Button } from "@mui/material";

const MultiGroupTabs = props => {
	const [selected, setSelected] = React.useState(0);

  
	const handleNew = () => {
		props.onChange([...props.value ||{}, {} ], props.name);
		setSelected(props.value?.length);
	};

  
	const handleDelete = (idx) => {
		setSelected(idx-1);

		const newVal = props.value.filter((obj,index)=> idx!==index);

		props.onChange(newVal, props.name);
	};

	const handleChange = (name, val, idx) => {
		const changed = props.value.map((item, index)=>{
			if(index===idx){
				return {...item, [name]:val};
			}
			return item;
		});
		props.onChange(changed, props.name);
	};

	const handleChangeTab = (event, newValue) => {
		setSelected(newValue);
	};
  
	return <>
		<div style={{textAlign:"right"}}>
			<Button color="primary" variant="contained" onClick={handleNew}>Agregar</Button>
		</div>

		<Tabs
			value={selected}
			onChange={handleChangeTab}
			variant="standard"
			scrollButtons='auto'
			className={styles.flexContainer}
			indicatorColor="primary">
			{props.value?.map((tab, idx)=><Tab label={idx+1} />)}
		</Tabs>


		{props.value?.map((item, idx)=>
			<React.Fragment key={idx}>
				<TabPanel value={selected} index={idx}>
					{props.element.fields.map((field, index)=>            <Grid key={field.name} item style={{
						display: "inline-block",
						width: "100%",
						margin:"0 0 20px 0"
					}}>
						<Grid
							container
							alignItems="center"
							justify="space-between"
							spacing={2}
						>
							{field.label &&
                  <Grid item style={{width: "100%"}}>
                  	<Typography style={{fontWeight:"bold"}}>{field.label}</Typography>
                  </Grid>
							}
							<Grid item style={{width: "100%"}}>
								<Element
									element={field}
									value={item[field.name]}
									onChange={(val)=>handleChange(field.name, val, idx)}
									// showLabel={false}
								></Element>
							</Grid>
						</Grid>
					</Grid>
					)}
					<Button size="small" variant="contained" style={{backgroundColor:"#2D3C3A",color:"white", ...props.style}} onClick={()=>handleDelete(idx)}>
            Eliminar
					</Button>
				</TabPanel>
			</React.Fragment>
    )}
	</>;
};
export default MultiGroupTabs;