import React from "react";
let imageCache = {}
function DynamicImage({src, ...rest}){
	const [img, setImg] = React.useState();
	// async function loadImage(imgName) {
	// 	const basePath = "assets/img/";
	// 	if(imageCache[imgName]){
	// 		setImg(imageCache[imgName]);
	// 		return;
	// 	}
	// 	const module = await import(`../${basePath}${imgName}`).catch(e=>{
	// 		console.log({e})
	// 	});
	// 	imageCache[imgName] = module?.default;
	// 	setImg(module?.default);
	// }
	// React.useEffect(()=>{
	// 	loadImage(src);
	// },[src]);
	return <img src={src} {...rest} />
}
export default DynamicImage;