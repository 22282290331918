import React from "react";

export default function FileCell({ cell }){
	if(Array.isArray(cell.value)){
		return (
			<>
				{cell.value.map(archivo=><div>
					{
						archivo.tipo?.includes("image") 
							? 
							<img style={{maxHeight:"40px",maxWidth:"50px"}} src={`${process.env.REACT_APP_FILE_URL}${archivo.path}`} />
	
							: 
							archivo.tipo?.split("/")[1]
					}
				</div>)}
			</>
		);
	}
	return (
		<>
			<div>
				{
					cell.value?.tipo?.includes("image") 
						? 
						<img style={{maxHeight:"40px",maxWidth:"50px"}} src={`${process.env.REACT_APP_FILE_URL}${cell.value.path}`} />

						: 
					cell.value?.tipo?.split("/")[1]
				}
			</div>
		</>
	);
};