import React, {useState} from "react";
import store from "store/reducers/index";
import { Button } from "@mui/material";

const CerrarSesionButton = props => {

	const logOut = () => {
		window.localStorage.removeItem("nutrify:super");
		store.dispatch({type:"SET_SESSION",payload:{logged:false}});
	};

	return (
		<>
			<Button 
			 	className="button-header"
				style={{background: "#fafafa", fontSize:"9pt", fontWeight:"bold"}}
				variant="contained" 
				onClick={()=>logOut()}>
        Cerrar sesión
			</Button>
		</>
	);
};

export default CerrarSesionButton;