import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { FormGroup, TextareaAutosize, Button, Tooltip, TextField } from "@mui/material";
import {Edit, Clear, FileCopy, Assignment} from "@mui/icons-material";
import { useImmer } from "use-immer";
import { useTranslation } from "react-i18next";

import Box from "components/Box/Box";
import TiempoComidaHorizontal from "../TiempoComidaHorizontal";
import ButtonAddTiempo from "../ButtonAddTiempo";
import useSkipFirstRender from "Hooks/useSkipFirstRender";
import {useDialog} from "Contexts/DialogContext";
import { AddToDietaButton } from "pages/Dietas/Components/AddToDietaButton/AddToDietaButton";
import {SelectProductOrRecipeModal} from "pages/Dietas/Components/SelectProductOrRecipeModal/SelectProductOrRecipeModal";
import { ListOfAddedProductsAndRecipes } from "pages/Dietas/Components/ListOfAddedProductsAndRecipes/ListOfAddedProductsAndRecipes";

import "./DietaOpciones.scss";

const _ = require("lodash");

const Dieta = props => {
	const {t} = useTranslation("common");
	const [openDialog, closeDialog] = useDialog();
	const [openModal, setOpenModal] = useState(false);
	const [currentlyEditing, setCurrentlyEditing] = useState({
		idx_tiempo: null,
		idx_opcion: null,
		list: {
			recipes: [],
			products: [],
		},
	});

	var tiempos_comida = [
		{
			nombre_tiempo: {
				name: "desayuno",
				label: t("carpeta_dietas_desayuno"),
				img: "desayuno",
				color: "#F8D239"
			},
			columnasLabel: [],
			opciones: [""],
			recetas: [[]],
			productos: [[]],
		},
		{
			nombre_tiempo: {
				name: "colacion1",
				label: t("carpeta_dietas_colacion"),
				img: "colacion1",
				color: "#52DEFF"
			},
			columnasLabel: [],
			opciones: [""],
			recetas: [[]],
			productos: [[]],
		},
		{
			nombre_tiempo: {
				name: "comida",
				label: t("carpeta_dietas_comida"),
				img: "comida",
				color: "#46f2bd"
			},
			columnasLabel: [],
			opciones: [""],
			recetas: [[]],
			productos: [[]],
		},
		{
			nombre_tiempo: {
				name: "colacion2",
				label: t("carpeta_dietas_colacion"),
				img: "colacion2",
				color: "#6077E9"
			},
			columnasLabel: [],
			opciones: [""],
			recetas: [[]],
			productos: [[]],
		},
		{
			nombre_tiempo: {
				name: "cena",
				label: t("carpeta_dietas_cena"),
				img: "cena",
				color: "#BD70D9"
			},
			columnasLabel: [],
			opciones: [""],
			recetas: [[]],
			productos: [[]],
		}
	];
	const [dieta, setDieta] = useImmer(
		props.value
			? props.value
			: tiempos_comida
	);

	const [widthTextarea, setWidthTextarea] = useState(100);
	const [copyValue, setCopyValue] = useState(false);
	const [copyContent, setCopyContent] = useState({
		opcion: "",
		productos: [],
		recetas: []
	});
	
	const addOption = (i) => {
		setDieta(draft => {
			draft[i].opciones.push("");

			draft[i].recetas = draft[i].recetas || [];
			draft[i].recetas.push([]);

			draft[i].productos = draft[i].productos || [];
			draft[i].productos.push([]);
		});
	};

	const deleteOption = (i, j) => {
		setDieta(draft => {
			draft[i].opciones.splice(j, 1);
			if(draft[i].columnasLabel){
				draft[i].columnasLabel.splice(j, 1);
			}
		});
	};

	const onChangeTextArea = (e, i, j) => {
		//e: event
		e.persist();
		setDieta(draft => {
			draft[i].opciones[j] = e.target.value;
		});
	};

	

	const debounceChange = React.useRef(
		_.debounce((d) => {
			props.onChange(d, "tiempos_comida");
		}, 1000)
	);

	useSkipFirstRender(() => {
		debounceChange.current(dieta);
	}, [dieta]);

	const addTiempoComida = (idx_tiempo) => {
		setDieta(draft => {
			draft.splice(idx_tiempo, 0, {
				nombre_tiempo: {
					name: "extra",
					label: "",
					img: "",
					color: "#A5DD72"
				},
				columnasLabel: [],
				opciones: [""],
				recetas: [[]],
				productos: [[]],
			});
		});
	};

	const deleteTiempo = (idx_tiempo) => {
		setDieta(draft => {
			draft.splice(idx_tiempo,1);

		});
	};

	const onChangeTiempoComida = (name, value, idx) => {
		setDieta(draft => {
			draft[idx].nombre_tiempo[name] = value;
		});
	};
	
	const editLabel = (tiempo, opcion) => {
		openDialog({
			onClose:(label)=>{
				if(label){
					setDieta(draft => {
						if(draft[tiempo].columnasLabel) draft[tiempo].columnasLabel[opcion] = label;
						else {
							draft[tiempo].columnasLabel = [];
							draft[tiempo].columnasLabel[opcion] = label;
						}
					})
				}
			},
			Content: EditLabel,
			label: dieta[tiempo].nombre_tiempo.label,
			width:"sm",
			opcion: opcion + 1,
			
		});
	}

	const handleAddToDietaButtonClick = (idx_tiempo, idx_opcion) => {
		setCurrentlyEditing({
			idx_tiempo: idx_tiempo,
			idx_opcion: idx_opcion,
			list: {
				recipes: dieta[idx_tiempo].recetas ? dieta[idx_tiempo].recetas[idx_opcion] : [],
				products: dieta[idx_tiempo].productos ? dieta[idx_tiempo].productos[idx_opcion] : []
			}
		});
		setOpenModal(true);
	};

	const handleRemoveProduct = (idx_tiempo, idx_opcion, productIdtoRemove) => {
		const filtered = dieta[idx_tiempo].productos[idx_opcion].filter(productId => productId !== productIdtoRemove);
		setDieta(draft => {
			draft[idx_tiempo].productos[idx_opcion] = filtered;
		});
	};

	const handleRemoveRecipe = (idx_tiempo, idx_opcion, receipeIdtoRemove) => {
		const filtered = dieta[idx_tiempo].recetas[idx_opcion].filter(recipeId => recipeId !== receipeIdtoRemove);
		setDieta(draft => {
			draft[idx_tiempo].recetas[idx_opcion] = filtered;
		});
	};

	const handleRemoveEverything = (idx_tiempo, idx_opcion) => {
		setDieta(draft => {
			draft[idx_tiempo].recetas[idx_opcion] = [];
			draft[idx_tiempo].productos[idx_opcion] = [];
		});
	};

	const setProductsAndRecepiesInDieta = (list, idx_tiempo, idx_opcion) => {
		// console.log("===> list: ", list)

		setDieta(draft => {
			// initalize products with arrays to the left of idx:
			if (dieta[idx_tiempo].productos === undefined){
				draft[idx_tiempo].productos = [];
			}
			for (let i=0; i<=idx_opcion; i++){
				if (!draft[idx_tiempo].productos[i]){
					draft[idx_tiempo].productos[i] = [];
				}
			}

			// initalize recepies with arrays to the left of idx:
			if (dieta[idx_tiempo].recetas === undefined){
				draft[idx_tiempo].recetas = [];
			}
			for (let i=0; i<=idx_opcion; i++){
				if (!draft[idx_tiempo].recetas[i]){
					draft[idx_tiempo].recetas[i] = [];
				}
			}

			// set actual list of products and recepies:
			draft[idx_tiempo].productos[idx_opcion] = list.products;
			draft[idx_tiempo].recetas[idx_opcion] = list.recipes;
		});
	};

	const handleClosedSelectProductOrRecipeModal = (list) => {
		// console.log("===> selected list in parent: ", list);
		// console.log("===> currently editing: ", currentlyEditing);

		if (list !== null){
			setProductsAndRecepiesInDieta(list, currentlyEditing.idx_tiempo, currentlyEditing.idx_opcion);
		}

		setOpenModal(false);
	};

	return (
		<>		
		<FormGroup>
			{console.log("===> dieta: ", dieta)}

			<Box label={t("opciones_dieta_box_label")} fullWidth>
				<div className="container-dieta-opciones printDiv">
					<div className="container-add-tiempo-comida avoid-print">
						<ButtonAddTiempo addTiempoComida={addTiempoComida} index={0} />
					</div>
					{dieta.map((tiempo_comida, idx_tiempo) => (
						<>
							<div className="break-end-page" key={tiempo_comida.nombre_tiempo} style={{display:"flex",minHeight:"150px", marginBottom:"20px" }}>
							
								<TiempoComidaHorizontal tiempoComida={tiempo_comida} index={idx_tiempo} onChange={onChangeTiempoComida} del={deleteTiempo} />
								<div className="panelTiempo">
									{widthTextarea > (100 / tiempo_comida.opciones.length) && setWidthTextarea(100 / tiempo_comida.opciones.length) }
									{/* {console.log(tiempo_comida.opciones,tiempo_comida.opciones.length)} */}
									{tiempo_comida?.opciones.map((opcion, idx_opcion) => (
										<div className="columna" style={
											props.isGoingToPrint 
												? { width: `${widthTextarea}%` }
												: {} 
										} key={idx_opcion}>
											<div style={{fontWeight: "bold", display: "flex"}}>
												<div className="cont-columna-label">
													{/* <p className="columna-label">Opción {idx_opcion+1}</p> */}
													<p className="columna-label">{tiempo_comida?.columnasLabel?.[idx_opcion] || `Opción ${idx_opcion+1}`}</p>
													<div className="avoid-print cont-buttons">
													{tiempo_comida.opciones.length > 1 && <Tooltip title="Eliminar opción"><Clear onClick={() =>deleteOption(idx_tiempo,idx_opcion)} fontSize="small" style={{color:"#3B4C4B"}} /></Tooltip>}
													<Tooltip className="cont-buttons"  title="Editar título de la opción">
														<Edit style={{width:20, height:20, background:"#A5DD72", padding:"0 1px 0 1px", color:"white", borderRadius:3, margin:"0 5px"}} onClick={()=>editLabel(idx_tiempo, idx_opcion)} />
													</Tooltip>
													
													</div>
												</div>
											</div>
											<div className="textarea-opciones" style={{ marginTop: "10px", flexGrow:1}}>
												<div className="allow-print">
													<div className="opcion">
														
														{opcion.split("\n").map((i, indice) => (
															<div key={indice}>
																<p className="print-text">{i}</p>
															</div>
														))}	

														<ListOfAddedProductsAndRecipes products={tiempo_comida.productos ? tiempo_comida.productos[idx_opcion]: []} recipes={tiempo_comida.recetas ? tiempo_comida.recetas[idx_opcion] : []} 
															handleRemoveProduct={handleRemoveProduct} handleRemoveRecipe={handleRemoveRecipe} idx_tiempo={idx_tiempo} 
															idx_y={idx_opcion}
														/>

													</div>
													{idx_opcion + 1 < tiempo_comida.opciones.length && <div className="o"> O </div>}
												</div>
												<div style={{width: "100%"}} className="avoid-print">
													<TextareaAutosize
														rowsMin={7}
														type="text"
														value={opcion}
														inputProps={{style:{borderRadius:0}}}
														placeholder={t("escribe_aqui")}
														onChange={e => {
															onChangeTextArea(e, idx_tiempo, idx_opcion);
														}}
														className="text-area avoid-print"
													
													/>
													<div className="avoid-print">
														<AddToDietaButton text={t("dietas_menu_anadir_receta_o_producto")} onClick={() => handleAddToDietaButtonClick(idx_tiempo, idx_opcion)}/>
														<ListOfAddedProductsAndRecipes products={tiempo_comida.productos ? tiempo_comida.productos[idx_opcion]: []} recipes={tiempo_comida.recetas ? tiempo_comida.recetas[idx_opcion] : []} 
															handleRemoveProduct={handleRemoveProduct} handleRemoveRecipe={handleRemoveRecipe} idx_tiempo={idx_tiempo} 
															idx_y={idx_opcion}
														/>
													</div>
												</div>
												
												<div className="avoid-print show-on-hover">
													<Tooltip title={t("dieta_menu_select_dieta_copiar")}>
														<FileCopy
															onClick={() => {
																setCopyContent({
																	opcion: opcion,
																	productos: tiempo_comida.productos ? tiempo_comida.productos[idx_opcion]: [],
																	recetas: tiempo_comida.recetas ? tiempo_comida.recetas[idx_opcion] : []
																})
																setCopyValue(true)
															}}
															fontSize="small"
															style={{ background: "#A5DD72", color: "white", borderRadius: 3, marginBottom: "4px" }}
														/>
													</Tooltip>
													{copyValue &&
														<Tooltip title="Pegar">
															<Assignment
																onClick={() => {
																	setDieta(draft => {
																		draft[idx_tiempo].opciones[idx_opcion] += `\n${copyContent.opcion}`;

																		// if (draft[idx_tiempo].productos === undefined) {
																		// 	draft[idx_tiempo].productos = [];
																		// 	draft[idx_tiempo].productos[idx_opcion] = copyContent.productos;
																		// } else if (draft[idx_tiempo].productos[idx_opcion] === undefined){
																		// 	draft[idx_tiempo].productos[idx_opcion] = copyContent.productos;
																		// } else {
																		// 	draft[idx_tiempo].productos[idx_opcion] = [...new Set([...draft[idx_tiempo].productos[idx_opcion], ...copyContent.productos])];
																		// }

																		// if (draft[idx_tiempo].recetas === undefined) {
																		// 	draft[idx_tiempo].recetas = [];
																		// 	draft[idx_tiempo].recetas[idx_opcion] = copyContent.recetas;
																		// } else if (draft[idx_tiempo].recetas[idx_opcion] === undefined) {
																		// 	draft[idx_tiempo].recetas[idx_opcion] = copyContent.recetas;
																		// } else {
																		// 	draft[idx_tiempo].recetas[idx_opcion] = [...new Set([...draft[idx_tiempo].recetas[idx_opcion], ...copyContent.recetas])];
																		// }

																		  // Initialize or update productos array
																		draft[idx_tiempo].productos = draft[idx_tiempo].productos || [];
																		draft[idx_tiempo].productos[idx_opcion] = [...new Set([...(draft[idx_tiempo].productos[idx_opcion] || []), ...copyContent.productos])];

																		// Initialize or update recetas array
																		draft[idx_tiempo].recetas = draft[idx_tiempo].recetas || [];
																		draft[idx_tiempo].recetas[idx_opcion] = [...new Set([...(draft[idx_tiempo].recetas[idx_opcion] || []), ...copyContent.recetas])];
																	})

																}}
																fontSize="small"
																style={{ background: "#A5DD72", color: "white", borderRadius: 3, marginBottom: "4px" }}
															/>
														</Tooltip>}
													<Tooltip  title="Borrar selección" className="header-actions">
														<span style={{ "cursor": "pointer"}} onClick={() => handleRemoveEverything(idx_tiempo, idx_opcion)}>
															<img src={require("assets/img/icons/del.png")} alt="no_data" style={{
																"width": "20px", 
																"height": "20px",
															}}/>
														</span>
													</Tooltip>
												</div>
											</div>
										</div>
									))}
								
									<div className="avoid-print">
										<Button 
											color="primary" 
											variant="contained" 
											onClick={() => addOption(idx_tiempo)}
											style={{color:"white"}}>
											{t("opciones_dieta_anadir_opcion")}
										</Button>
									</div>
								</div>
							</div>
							<div className="container-add-tiempo-comida avoid-print">
								<ButtonAddTiempo addTiempoComida={addTiempoComida} index={idx_tiempo+1} />
							</div>
						</>
					))}
				</div>
			</Box>
		</FormGroup>
		<SelectProductOrRecipeModal openModal={openModal} onClose={handleClosedSelectProductOrRecipeModal} previouslySelected={currentlyEditing.list}/>
		</>
	);
};

export default Dieta;

Dieta.propTypes = {
	isGoingToPrint: PropTypes.bool,
	value: PropTypes.array,
	onChange: PropTypes.func
};

const EditLabel = ({label, opcion, onClose}) => {
	const [value, setValue] = useState("");
	const {t} = useTranslation("common");
	return(
	<>
		<p style={{textAlign: "center", fontWeight: "bold", marginBottom: 5}}>¿Qué nombre deseas asignar a la opción {opcion} del tiempo de comida {label}?</p>
		<TextField 
			label={t("constants_etiqueta")} 
			type="text" 
			variant="outlined" 
			color="primary" 
			value={value}
			onChange={e=>setValue(e.target.value)} 
			style={{margin: "5px 0 10px 0"}}
		/>
		<div style={{textAlign: "right"}}>
			<Button onClick={()=>onClose(value)} variant="contained" color="primary">{t("guardar")}</Button>
		</div>
	</>
)
	}