import React from "react";
import CrudTable from "components/Table/CrudTable/CrudTable";
import Cliente from "./Cliente";
import { useSelector } from "react-redux";
import {CUSTOMER, PLAN} from 'store/constants'
import RefCell from "components/Table/CellTypes/RefCell/RefCell";
import {formatCurrency} from "currency";
import Header from "../../components/Header/Header";
import DateCell from "components/Table/CellTypes/DateCell/DateCell";
import {get} from "api";
import LinkCell from "components/Table/CellTypes/LinkCell/LinkCell";
import BoolCell from "components/Table/CellTypes/BoolCell/BoolCell";
import MapCell from "components/Table/CellTypes/MapCell/MapCell";
import Calendar from "components/FormComponents/Calendar/Calendar";
import {useQuery} from "react-query";
import moment from "moment";
const TablaClientes = props => {
  // const [data, setData] = React.useState([]);
  const [mesActual, setMesActual] = React.useState(new Date());
	const {data} = useQuery([CUSTOMER.name, "renovacion", mesActual ? moment(mesActual).format("YYYY/MM"):""], ({queryKey})=> get("admin/customers/renovaciones",{mes:queryKey[2]}));
	// React.useEffect(() => {

	// 	(async function(){
	// 		let d = await get("admin/customers/renovaciones");
	// 		setData(d.data);
	// 	})()
		
	// }, [])


  const columns = React.useMemo(
    () => [
      {
        Header: "Clave",
        accessor: "clave"
      },
			{
        Header: "Nombre",
        accessor: "nutritionist.nombre"
      },
			{
        Header: "Correo",
        accessor: "nutritionist.correo"
      },
			{
        Header: "Telefono",
        accessor: "nutritionist.telefono"
      },
			{
        Header: "Capacitacion",
        accessor: "fecha_capacitacion",
				Cell:BoolCell,
				labelOnTrue:(cell)=>`${moment(cell.row.original.fecha_capacitacion).format("DD/MMM/YYYY")}`
			},
			{
        Header: "Primera fecha de pago",
				accessor: "subscripcion.primera_fecha_pago",
				Cell:DateCell,
				filter:"daterange",
				sortType: "date",
				dateFormat:["year"]
			},
			{
        Header: "Ultima fecha de pago",
				accessor: "ultimo_pago.date",
				Cell:DateCell,
				filter:"daterange",
				sortType: "date",
				dateFormat:["year"]
			},
			{
        Header: "Ultimo pago",
				accessor: "ultimo_pago.amount",
				Cell:({cell})=>formatCurrency(cell.value/100),
				filter:"number",
				// op:(v)=>formatCurrency(v/100),
				sortType: "number"
			},
			{
        Header: "Proxima fecha pago",
				accessor: "subscripcion.proxima_fecha_pago",
				Cell:DateCell,
				filter:"daterange",
				sortType: "date",
			},
			{
        Header: "Tipo de pago",
				accessor: "subscripcion.tipo_pago",
				filter:"select",
				filterOptions:[
					{value:"", label:"Todos"},
					{value:"mensual", label:"Mensual"},
					{value:"anual", label:"Anual"},
					{value:"Manual", label:"Manual"},
				]
			},
			{
				Header: "Plan",
				accessor: "subscripcion.id_plan",
				type: PLAN,
				Cell: RefCell,
				filter:"ref",
				exportType: "ref"
			},
			{
        Header: "Asesor",
				accessor: "subscripcion.asesor",
				filter:"select",
				filterOptions:[
					{value:"", label:"Todos"},
					{value:"ericka", label:"Ericka"},
					{value:"brenda", label:"Brenda"},
					{value:"clara", label:"Clara"},
				]
			},
			{
        Header: "Status renovacion",
				accessor: "subscripcion.status_renovacion",
				Cell:MapCell,
				filter:"select",
				filterOptions:[
					{value:"", label:"Todos"},
					{label:"Va a renovar",value:"a_renovar"},
					{label:"No quiere renovar",value:"no_quiere_renovar"},
					{label:"Sin respuesta", value:"sin_respuesta"},
					{label:"Mas adelante", value:"mas_adelante"},
					{label:"Otros", value:"otro"},			
				],
				options:[
					{label:"Va a renovar",value:"a_renovar", color:"rgba(80, 159, 0, 1)",bg:"rgba(80, 159, 0, .2)"},
					{label:"No quiere renovar",value:"no_quiere_renovar",  color:"rgba(199, 15, 0, 1)",bg:"rgba(199, 15, 0, .2)"},
					{label:"Sin respuesta", value:"sin_respuesta", color:"rgba(223, 204, 0, 1)",bg:"rgba(223, 204, 0, .2)"},
					{label:"Mas adelante", value:"mas_adelante"},
					{label:"Otros", value:"otro"},				
				]
			},
			{
        Header: "Suscripcion activa",
				accessor: "suscripcion_activa",
				Cell:BoolCell,
				filter:"bool"
			},
			{
        Header: "Renovado",
				accessor: "renovado",
				Cell:BoolCell,
				filter:"bool"
			},
			{
				Header:"Notas",
				accessor:"subscripcion.notas_no_renovacion"
			},
			// {
			// 	Header:"Uso",
			// 	accessor:"sesiones",
			// 	Cell:ChartCell,
			// 	filter:null,
			// 	xAccessor:datum => moment(datum["desde"]).format("MM"),
			// 	yAccessor:datum => datum["tiempo_total"]/60/30,
			// },
			{
        Header: "Link stripe",
				accessor: "payments_user.external_id",
				link: (value) => `https://dashboard.stripe.com/customers/${value}`,
				filter:null,
				Cell:LinkCell,
				label:"Ver"
			},
			
			{
        Header: "Correo",
				accessor: "subscripcion.link_pago",
				link: (value, data) => `mailto:${data.nutritionist?.correo}`,
				filter:null,
				Cell:LinkCell,
				label:"Enviar"
			},
			{
        Header: "Whatsapp",
				accessor: "subscripcion.link_pago2",
				link: (value, data) => `https://api.whatsapp.com/send?phone=${data.nutritionist?.telefono}`,
				filter:null,
				Cell:LinkCell,
				label:"Enviar"
			},
			
    ],
    []
	);

	
	const [dataRenovar, setDataRenovar] = React.useState({});
	
  const dataChanged = (data) => {
		const total = data.reduce((acc, val)=>acc+(val.ultimo_pago?.amount||0), 0);
		const renovar = data.filter(d=>d.score>=3).map(val=>val.ultimo_pago?.amount);
		const noRenovar = data.filter(d=>d.score<3).map(val=>val.ultimo_pago?.amount);

		const totalRenovar = renovar.reduce((acc, val)=>acc+(val||0), 0);
		const totalNoRenovar = noRenovar.reduce((acc, val)=>acc+(val||0), 0);

		const clientes = data.map(d=>({score:d.score, clave:d.clave, total:d.ultimo_pago?.amount}));
		const clientesMap = Map.groupBy(clientes, d=>d.score>=5)
		setDataRenovar({totalRenovar, totalNoRenovar, total});
		console.log({total, data, totalRenovar, totalNoRenovar, clientesMap})
	}

	const [showData, setShowData] = React.useState(false);

  return (
    <>
			<Header title="Clientes" />
			Selecciona mes de renovaciones
			<Calendar value={mesActual} onChange={setMesActual}  />
      <CrudTable
        data={data?.data||[]}
				columns={columns}
				filters={columns}
        status={{}}
        type={CUSTOMER}
        modal={Cliente}
        options={{loadBeforeEdit:true, orderBy:true, disableInsert:true, disableDelete:true}}
				onFilteredDataChange={dataChanged}

      />
			<div style={{height:100,marginTop:100, color:showData?"black":"transparent"}} onClick={()=>setShowData(d=>!d)}>{dataRenovar.totalRenovar/100} renovar, {dataRenovar.totalNoRenovar/100} no renovar, {dataRenovar.total/100} total</div>
    </>
  );
};

export default TablaClientes;
