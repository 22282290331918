import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { all } from "redux-saga/effects";
import { EQUIVALENT_SMAE, EQUIVALENT_SMAE_LIST, EQUIVALENT_SMAE_SUBCAT } from "../constants";


const loadEquivalents = loadRequest(EQUIVALENT_SMAE);
const loadEquivalentLists = loadRequest(EQUIVALENT_SMAE_LIST);
const loadEquivalentSubCategories = loadRequest(EQUIVALENT_SMAE_SUBCAT);

const insertEquivalent = insertRequest(EQUIVALENT_SMAE);
const insertEquivalentList = insertRequest(EQUIVALENT_SMAE_LIST);
const insertEquivalentSubCategories = insertRequest(EQUIVALENT_SMAE_SUBCAT);


const updateEquivalent = updateRequest(EQUIVALENT_SMAE);
const updateEquivalentList = updateRequest(EQUIVALENT_SMAE_LIST);
const updateEquivalentSubCategories = updateRequest(EQUIVALENT_SMAE_SUBCAT);

const removeEquivalent = removeRequest(EQUIVALENT_SMAE);
const removeEquivalentList = removeRequest(EQUIVALENT_SMAE_LIST);
const removeEquivalentSubCategories = removeRequest(EQUIVALENT_SMAE_SUBCAT);

const trashEquivalent = trashRequest(EQUIVALENT_SMAE);
const trashEquivalentList = trashRequest(EQUIVALENT_SMAE_LIST);
const trashEquivalentSubCategories = trashRequest(EQUIVALENT_SMAE_SUBCAT);

const untrashEquivalent = untrashRequest(EQUIVALENT_SMAE);
const untrashEquivalentList = untrashRequest(EQUIVALENT_SMAE_LIST);
const untrashEquivalentSubCategories = untrashRequest(EQUIVALENT_SMAE_SUBCAT);

function* rootSaga() {
	yield all([
		loadEquivalents(),
		loadEquivalentLists(),
		insertEquivalent(),
		insertEquivalentList(),
		updateEquivalent(),
		updateEquivalentList(),
		removeEquivalent(),
		removeEquivalentList(),
		loadEquivalentSubCategories(),
		insertEquivalentSubCategories(),
		updateEquivalentSubCategories(),
		removeEquivalentSubCategories(),
		trashEquivalent(),
		trashEquivalentList(),
		trashEquivalentSubCategories(),
		untrashEquivalent(),
		untrashEquivalentList(),
		untrashEquivalentSubCategories(),
	]);
}
export default rootSaga;