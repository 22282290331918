import React from "react";
import CrudTable from "../../../../components/Table/CrudTable/CrudTable";
import CategoriaComidaPreparada from "./CategoriaComidaPreparada";
import { useSelector } from "react-redux";
import { EQUIVALENT_SMAE_SUBCAT } from "store/constants";

const TabCategoriaComidaPreparada = () => {
	const data = useSelector(state => state[EQUIVALENT_SMAE_SUBCAT.name]);
	const columns = React.useMemo(
		() => [
			{
				accessor: "nombre",
				Header: "Nombre del grupo"
			}
		],
		[]
	);
	return (
		<>
			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				modal={CategoriaComidaPreparada}
				type={EQUIVALENT_SMAE_SUBCAT}
				filters={columns}
				options={{ orderBy: true }}
			/>
		</>
	);
};

export default TabCategoriaComidaPreparada;
