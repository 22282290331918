import React from "react";
import { useHistory } from "react-router-dom";
import Header from "components/Header/Header";
import ModalPacientes from "./ModalPacientes";
import CrudTable from "../../components/Table/CrudTable/CrudTable";
import { useSelector } from "react-redux";
import { ONLINE_PATIENT, CUSTOMER, NUTRITIONIST } from "store/constants";

import calculos from "Hooks/useHelperFunctions";
import RefCell from "components/Table/CellTypes/RefCell/RefCell";
import DateCell from "components/Table/CellTypes/DateCell/DateCell";
import UserCell from "components/Table/CellTypes/UserCell/UserCell";
import BoolCell from "components/Table/CellTypes/BoolCell/BoolCell";
import DateRange from "components/Table/FilterTypes/DateRange/DateRange";
import RefFilter from "components/Table/FilterTypes/RefFilter/RefFilter";

const moment = require("moment");
const TabPacientes = () => {
	const history = useHistory();
	const data = useSelector(state => state[ONLINE_PATIENT.name]);
	const goToPatient = data => {
		console.log(data);
		return history.push(`/paciente/${data._id}`);
	};
	const {statusPlan} = calculos({type:CUSTOMER});
	const columns = React.useMemo(
		() => [
			
			{
				Header: "Nombre",
				accessor: "nombre",
				Cell: UserCell,
			},
			{
				Header: "Fecha última consulta",
				accessor: "ultima_consulta",
				Cell: DateCell,
			},
			{
				Header: "Nutriólogo",
				accessor: "id_nutriologo",
				type: NUTRITIONIST,
				Cell: RefCell,
			},
			{
				Header: "Grupo",
				accessor: "etiquetas"
			},
			{
				Header: "App activa",
				accessor: "app_activa",
				Cell: BoolCell,
				labelOnTrue: (cell)=> `FECHA INGRESO: ${moment(cell.value).format("DD/MM/YYYY")}`,
				labelOnFalse: ()=>"No ha ingresado"
			}
		],
		[]
	);
	const filters = React.useMemo(
		() => [
			
			{	
				Header: "Nombre",
				accessor: "nombre",
			},
			{
				Header: "Correo",
				accessor: "correo",
			},
			{
				Header: "Fecha última consulta",
				accessor: "ultima_consulta",
				filter: "daterange"
			},
			{
				Header: "Nutriólogo",
				accessor: "id_nutriologo",
				filter: "ref",
				type:NUTRITIONIST
			},
			{
				Header: "Grupo",
				accessor: "etiquetas"
			}
		],
		[]
	);
	return (
		<>
			<Header title="Pacientes" />

			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				type={ONLINE_PATIENT}
				modal={ModalPacientes}
				onInsert={goToPatient}
				editAction={(cell) => goToPatient(cell.row.original)}
				options={{ 
					filter: true, 
					orderBy: true, defaultSorting:[{
						id: "_id",
						desc: true
					}]
				}}
				filters={filters}
				papeleraLabel="Archivo muerto"
				archivarMessageTitle={(cell)=>`¿Enviar a ARCHIVO MUERTO al paciente ${cell.row.original.nombre}?`}
				archivarMessageBody={(cell)=>`Al enviar a tu paciente ${cell.row.original.nombre} al ARCHIVO MUERTO se desactivará el ingreso al App. Podrás acceder a su expediente y desarchivarlo en cualquier momento`}
				eliminarMessageTitle={(cell)=>`¿Eliminar al paciente ${cell.row.original.nombre}?`}
			/>
		</>
	);
};

export default TabPacientes;
