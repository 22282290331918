import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import PropTypes from "prop-types";

const Receta = props => {
	const elements = React.useMemo(()=>[
		{
			type: "bool",
			name: "u",
			label: "Unlocked",
		},
		{
			type:"multiref",
			name:"categorias",
			label:"Categorías",
			selector:(state)=>state.RECEIPE_CATEGORY.data.filter((r)=>!r._trash)
		},
		{
			type: "text",
			name: "nombre",
			label: "Nombre",
		},
		{
			type: "text",
			name: "slug",
			label: "Slug",
		},
		{
			type: "text",
			name: "tiempo",
			label: "Tiempo de preparación (minutos)",
		},
		{
			type: "select",
			name: "dificultad",
			label: "Dificultad",
			options: [
				{value:"facil",label:"Fácil"}, 
				{value:"medio",label:"Medio"}, 
				{value:"dificil",label:"Difícil"}
			]
		},
		{
			type: "text",
			name: "comensales",
			label: "Porciónes (comensales)",
		},
		{
			type: "text",
			name: "ingredientes",
			label: "Ingredientes",
			multiline:true
		},
		{
			type: "text",
			name: "preparacion",
			label: "Modo de preparación",
			multiline:true
		},
		{
			type: "text",
			name: "porcion",
			label: "Tamaño de la porción",
		},
		{
			type: "checkbox",
			name: "equivalencias_activas",
			label: "Equivalentes",
			options: [
				{label: "Activar equivalentes simplificados", value: "equivalencia", default: true},
				{label: "Activar equivalentes SMAE", value: "equivalencia_smae"}
			]
		},
		{
			type:"if",
			name:["equivalencias_activas.equivalencia", "equivalencia"],
			formula:(val)=>val,
			field:{
				type:"equivalencia",
				name:"equivalencia",
				label:"Equivalentes simplificado"
			}
		},
		{
			type:"if",
			name:["equivalencias_activas.equivalencia_smae", "equivalencia_smae"],
			formula:(val)=>val,
			field:{
				type:"equivalenciaSmae",
				name:"equivalencia_smae",
				label:"Equivalentes SMAE"
			}
		},
		{
			type:"multiref",
			name:"sellos",
			label:"Etiquetas",
			selector:(state)=>state.RECEIPE_TAG.data
		},
		{
			type: "text",
			name: "enlace",
			label: "Enlace (url)",
		},
		{
			type: "file",
			name: "foto",
			label: "Imágenes",
			multipleFiles: true
		}
	],[]);
	return (
		<>
			<ModalForm elements={elements} onChange={props.onChange} data={props.data} {...props}></ModalForm>
		</>
	);
};

export default Receta;

Receta.propTypes = {
	onChange: PropTypes.func,
	data: PropTypes.object
};