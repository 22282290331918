import React from "react";
import { useSelector } from "react-redux";
import { Button, Tooltip } from "@mui/material";
import {useLocation, Redirect, useHistory} from "react-router-dom";

import { NUTRITIONIST } from "store/constants";

import onIcon from "assets/img/icons/consultas-online/con-on-on.png";
import offIcon from "assets/img/icons/consultas-online/con-on-off.png";
import desIcon from "assets/img/icons/consultas-online/con-on-des.png";

import {getNutitionistId, refresh} from "api/loader";

import "./Header.scss";
import { put } from "api";

const nutId = getNutitionistId();

const ConsultasOnlineButton = props => {

	const history = useHistory();

	const dataNutritionist = useSelector(state=>state[NUTRITIONIST.name].data.find(n=>n._id===nutId));

	const onClick = async () => {
		if(!dataNutritionist?.online?.activo){
			history.push("/configuracion/3");
			return;
		}
		await put(`/nutritionists/${dataNutritionist._id}`, {online:{status:!dataNutritionist.online.status}});
		refresh(NUTRITIONIST);
	};

	return (
		<>
			{!dataNutritionist?.online?.activo ? 
				<Tooltip title="DESHABILITADO: DA CLICK PARA HABILITAR">
					<Button 
					 className="button-header"
						variant="contained"
						startIcon={
							<img src={desIcon} alt="icono" height="25px"/>
						}
						style={{background: "#fafafa",fontSize:"9pt"}}
						onClick={ (e) => onClick(e) }
					>
        Consultas Online
					</Button>
				</Tooltip>
				:
				<Tooltip title={dataNutritionist.online.status ? "ENCENDIDO: DA CLICK PARA APAGAR" : "APAGADO: DA CLICK PARA ENCENDER"}>
					<Button 
					 className="button-header"
						variant="contained"
						startIcon={
							<img src={dataNutritionist.online.status ? onIcon : offIcon} alt="icono" height="25px"/>
						}
						style={{background: "#fafafa",fontSize:"9pt", fontWeight:"bold"}}
						onClick={ (e) => onClick(e) }
					>
          Consultas Online
					</Button>
				</Tooltip>
			}
		</>
	);
};

export default ConsultasOnlineButton;
