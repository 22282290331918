import React from "react";
import Header from "../../components/Header/Header";

import TablaNotificaciones from "./Notificacion/TablaNotificaciones";
import TablaEtiquetas from "./Etiquetas/TablaEtiquetas";
import { useRouteMatch } from "react-router-dom";
import MyTabs from "components/Tabs/Tabs";


const Recetas = () => {
	let match = useRouteMatch();
	const tabs=[{
		label:"Notificaciones",
		// value:"notificaciones",
		component: TablaNotificaciones
	},{
		label:"Etiquetas",
		// value:"etiquetas",
		component: TablaEtiquetas
	}];
	return (
		<>
			<Header title="Notificaciones" />
			<MyTabs tabs={tabs} defSelected={match.params.seccion} urlKey="seccion" root={true} ></MyTabs>
		</>
	);
};

export default Recetas;
