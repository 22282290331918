import React from "react";
import Box from "../../Box/Box";
import Element from "./index";


export default React.memo(function BoxElement(props) {
	return (
		<>
			<Box hidden={props.element.hidden} fullWidth label={props.element.label} sublabel={props.element.subLabel} index={props.index}>
				<Element
					{...props}
					element={props.element}
					filterBy={props.filterBy}
					value={props.value}
					onChange={props.onChange} 
				/>
			</Box>
		</>
	);
});
