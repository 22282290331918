import React, {useEffect, useRef} from "react";
import RichText from "components/FormComponents/RichText/RichText";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import { useImmer } from "use-immer";
import useSkipFirstRender from "Hooks/useSkipFirstRender";
import JoditEditor from "jodit-react-reloaded";
import "jodit/build/jodit.min.css";
import HTMLEditor from "./HTMLEditor";
import PropTypes from "prop-types";
import Box from "components/Box/Box";
import "./DietaPersonalizada.css";
import { Authorization} from "api/index";
import {
	Grid,
	Slider
} from "@mui/material";

const DietaPersonalizada = props => {
	const editor = useRef(null);
	const [defaultValue] = React.useState(props.data?.dieta || "");
	// const [dieta, setDieta] = useImmer(props.data ? props.data : {zoom:50});
	// useSkipFirstRender(()=>{
	// 	props.onChange(dieta);
	// },[dieta]);

	// const localOnChange = (val,name) =>{
	// 	setDieta((draft)=>{
	// 		draft[name] = val;
	// 	});
	// };

	const onChange = React.useMemo(()=>(val)=>{
		props.onChange(val,"dieta");
	});

	const elements = React.useMemo(()=>[

		{
			type: "text",
			name: "nombre",
			label: "Nombre",
		},
		{
			type: "text",
			name: "recomendaciones",
			label: "Recomendaciones generales",
			multiline:true
		},
		{
			type: "radio",
			name: "mostrar_equivalentes",
			label: "Mostrar equivalentes",
			options: [
				{label: "No", value: "no"},
				{label: "Equivalentes simplificados", value: "simplificado"},
				{label: "Equivalentes SMAE", value: "smae"}
			],
			default: "no"
		},
		{
			type: "if",
			name: ["mostrar_equivalentes", "lista_equivalencias"],
			formula:(val)=>val === "simplificado",
			field:
				{
					type: "ref",
					name: "lista_equivalencias",
					label: "Lista de equivalencias",
					selector:(state)=>state.EQUIVALENT_LIST.data,
					extraItems:[{nombre:"Todos",_id:"-1"}],
					default:"-1"
				}
		},
		{
			type: "if",
			name: ["mostrar_equivalentes", "lista_equivalencias_smae"],
			formula:(val)=>val === "smae",
			field:
				{
					type: "ref",
					name: "lista_equivalencias_smae",
					label: "Lista de equivalencias SMAE",
					selector:(state)=>state.EQUIVALENT_SMAE_LIST.data,
					extraItems:[{nombre:"Todos",_id:"-1"}],
					default:"-1"
				}
		}
	],[]);	
	const config = React.useMemo(
		() => ({
			key:"9f953c25d7f8d0805d8dcbe856fd48ef",
			language: "es", 
			enableDragAndDropFileToEditor: true,
			uploader: {
				url: `${process.env.REACT_APP_API_URL}diets/personalized/file`,
				headers:{
					"Authorization": Authorization
				},
				isSuccess: function (resp) {
					return resp;
				},
				process: function (resp) { 
					return {
						files: resp.data.files,
						path: resp.data.path,
						baseurl: resp.data.baseurl,
						error: resp.data.error,
						message: resp.data.message
					};
				},
				defaultHandlerSuccess: function (data) {
					var i, field = "files";      
					if (data[field] && data[field].length) {
						for (i = 0; i < data[field].length; i += 1) {
							editor.current.current.selection.insertImage(data.baseurl + data[field][i], {}, 100);
						}
					}
				},
			},


			// tabIndex: 1,
			askBeforePasteHTML: false,
			askBeforePasteFromWord: false, 
			autoResize: false
			// triggerChangeEvent: false, 
			// processPasteFromWord: true
		}),
		[]
	);
	
	

	return (
		<>
			<ModalForm elements={elements} onChange={props.onChange} data={props.data}></ModalForm>

			<Grid container spacing={1} style={{marginTop:"20px"}}>
				<Grid item xs={12} md={8}>
					{/* <div>Para ver lo cambios en la App de tu paciente da click aquÃ­</div> */}
					{/* <FroalaEditor onModelChange={newContent => props.onChange(newContent,"dieta")} model={props.data?.dieta}  config={{
						toolbarButtons:['fullscreen', 'bold', 'italic', 'underline', 	'strikeThrough',
							'|', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor',
							'|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent',
							'|', 'insertLink', 'insertImage', 'insertVideo', 'insertTable',
							'|', 'emoticons', 'specialCharacters', 'insertHR', 'selectAll', 'clearFormatting',
							'|', 'print', 'spellChecker', '|', 'undo', 'redo'],
						language: 'es',
						imageUploadURL: `${process.env.REACT_APP_API_URL}diets/personalized/file`,
						requestHeaders: {
							"Authorization": Authorization
						},
						imageUploadMethod:'POST',
						key:'oC1C3G3F1pI1A1B1B5A1F6A4A1B3B2kWa1WDPTf1ZNRGb1OG1g1=='}}
					/> */}
					<HTMLEditor
						
						style={{display:"none"}}
						ref={editor}
						defaultValue={defaultValue}
						// value={props.data?.dieta}
						config={config}
						tabIndex={1} // tabIndex of textarea
						onChange={onChange} 
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<Grid>
						<Box label="App para pacientes" sublabel="Así verá el paciente su dieta">
							<p>Ajusta el tamaño:</p>
							<Slider min={25} max={100} step={1} value={props.data?.zoom} onChange={(event, value)=>{
								props.onChange(value, "zoom")
							}} aria-labelledby="continuous-slider" />
							<div style={{borderWidth:"1px",borderStyle:"solid",overflowY:"scroll",height:"600px"}}>
								<div className="outer" >
									<div style={{zoom:props.data?.zoom+"%"}}  dangerouslySetInnerHTML={{__html:props.data?.dieta}} ></div>
								</div>
							</div>
						</Box>
					</Grid>
				</Grid>
			</Grid>
			
		</>
	);
};

export default DietaPersonalizada;

DietaPersonalizada.propTypes = {
	onChange: PropTypes.func,
	data: PropTypes.object
};