import { all } from "redux-saga/effects";

import equivalents from "./equivalents";
import equivalentsSmae from "./equivalents-smae";
import diets from "./diets";
import receipes from "./receipes";
import appointments from "./appointments";
import patients from "./patients";
import prizes from "./prizes";
import messages from "./messages";
import customers from "./customers";
import plans from "./plans";
import nutritionists from "./nutritionists";
import products from "./products";
import discounts from "./discounts";
import onlineConsultation from "./onlineConsultation";
import onlinePatients from "./onlinePatients";
import nutritionistMessages from "./nutritionistMessages";
import nutritionistNotifications from "./nutritionistNotifications";
import infografias from "./infografias";
import tutorial from "./tutorial";

function* rootSaga() {
	yield all([equivalents(), receipes(), diets(), appointments(), patients(), prizes(), messages(), 
		customers(), plans(), nutritionists(), products(), discounts(), onlineConsultation(),onlinePatients(),
		equivalentsSmae(),nutritionistMessages(), nutritionistNotifications(), infografias(), tutorial()]);
}
export default rootSaga;
