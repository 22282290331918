import React from "react";
import Select from "components/FormComponents/Select/Select";

// This is a custom filter UI for selecting
// a unique option from a list
export default function SelectColumnFilter({
	column: { filterValue, setFilter, preFilteredRows, id, filterOptions, multiple, accessor },
}) {
	// Calculate the options for filtering
	// using the preFilteredRows
	// const options = React.useMemo(() => {
	// 	return [{value:"",label:"Todos"}, ...new Set(data.map(d=>({label:d.nombre, value:d._id})))];


	// 	const options = new Set();
	// 	preFilteredRows.forEach(row => {
	// 		options.add(row.values[id]);
	// 	});
	// 	return [...options.values()];
	// }, [id, preFilteredRows]);
  
	// Render a multi-select box
	const onChange = (v) => {
		if(multiple){
			setFilter([...(v||[]).map(f=>f?.value), ...(filterValue||[]).map(f=>f?.value)])
		}else{
			setFilter(v);
		}
	}
	return (
		<Select preventAutoChange options={filterOptions} multiSelect={multiple} onChange={onChange} value={filterValue} />
	);
}
  