import React from "react";
import Element from "../Element";

import { Card, Grid, Typography } from "@mui/material";
const Group = props => {
	return (
		<Card
			className={props.className}
			// variant="outlined"
			style={
				{
					/*height: "calc(100% - 30px)"*/
				}
			}
		>
			<div
				
				// direction={props.horizontal?"row":"column"}
				style={props.horizontal ? {padding: "20px", display: "flex", flexDirection:"row", justifyContent:"space-between"} : { padding: "20px" }}

				// justify="space-between"
				// spacing={2}
			>
				{props.element.fields.map(field => (
					<div key={field.id || field.name} style={{marginBottom:"8px"}}  >
						{/* <Grid
							container
							alignItems="center"
							// justify="space-between"
							spacing={2}
						>
							<Grid xs={12} md={6} item> */}
								<Typography style={{fontWeight:"bold"}}>{field.label}</Typography>
							{/* </Grid>
							<Grid xs={12} md={6} item> */}
								<Element
									element={field}
									value={props.value[field.name]}
									onChange={props.onChange}
									extraValue={props.value}
									// showLabel={false}
								></Element>
							{/* </Grid> */}
						{/* </Grid> */}
					</div>
				))}
			</div>
		</Card>
	);
};
export default Group;
