import React, {useState} from "react";
import { useSelector } from "react-redux";

import Header from "../../components/Header/Header";
import { Button, CircularProgress } from "@mui/material";

import TabMenus from "./DietaMenus/TabMenus";
import TabMenusSelect from "./DietaMenusSelect/Dieta/TabMenus";
import TableColumnas from "./DietaColumnas/TabColumnas";
import TableOpciones from "./DietaOpciones/TabOpciones";
import TablePersonalizada from "./DietaPersonalizada/TabPersonalizada";
import TabEquivalencias from "./DietaEquivalencias/Equivalencias/TabEquivalencias";
import TableDietaEquivalencias from "./DietaEquivalencias/Dieta/TableDietaEquivalencias";
import TabCategoriaComidaPreparada from "./DietaEquivalencias/CategoriaComidaPreparada/TabCategoriaComidaPreparada";
import TabListaEquivalentes from "./DietaEquivalencias/ListaEquivalentes/TabListaEquivalentes";

import TabEquivalenciasSmae from "./DietaSmae/Equivalencias/TabEquivalencias";
import TableDietaEquivalenciasSmae from "./DietaSmae/Dieta/TableDietaEquivalencias";
import TabCategoriaComidaPreparadaSmae from "./DietaSmae/CategoriaComidaPreparada/TabCategoriaComidaPreparada";
import TabListaEquivalentesSmae from "./DietaSmae/ListaEquivalentes/TabListaEquivalentes";
import TabSelectMenuLista from "./DietaMenusSelect/ListaAlimentos/TabListaAlimentos";

import { useRouteMatch } from "react-router-dom";
import MyTabs from "components/Tabs/Tabs";
import { EQUIVALENT_SMAE, EQUIVALENT_SMAE_SUBCAT, CUSTOMER } from "store/constants";
import {refresh} from "api/loader";
import {get} from "api/index";

import "./Dietas.scss";



const TabsSmae = () => {

	let match = useRouteMatch();	
	

	const tabs = [
		{label:"Dietas",component:TableDietaEquivalenciasSmae},
		{label:"Equivalentes y comida preparada",component:TabEquivalenciasSmae},
		{label:"Grupos de comida preparada",component:TabCategoriaComidaPreparadaSmae},
		{label:"Lista de equivalentes",component:TabListaEquivalentesSmae}];

	return (<>
		{<MyTabs tabs={tabs} defSelected={match.params.seccion} keys={["tipo"]} urlKey="seccion" />}
	</>); 
};
const TabsEquivalentes = () => {
	let match = useRouteMatch();

	const tabs = [
		{label:"Dietas",component:TableDietaEquivalencias},
		{label:"Equivalentes y comida preparada",component:TabEquivalencias},
		{label:"Grupos de comida preparada",component:TabCategoriaComidaPreparada},
		{label:"Lista de equivalentes",component:TabListaEquivalentes}];

	return (<>
		<MyTabs tabs={tabs} defSelected={match.params.seccion} keys={["tipo"]} urlKey="seccion" />
	</>); 
};

const TabsMenuSelect = () => {
	let match = useRouteMatch();
	
	const tabs = [
		{label: "Planes de alimentación", component: TabMenusSelect},
		{label: "Lista de alimentos", component: TabSelectMenuLista}
	];
		
	return (<>
		<MyTabs tabs={tabs} defSelected={match.params.seccion} keys={["tipo"]} urlKey="seccion" />
	</>); 
};

const headers = [
	{title:"Dietas", video:[{videoId:"a59Gp8eOj88", title: "Nueva dieta SMAE en Nutrify"}]},
	{title:"Dietas", video:[{videoId:"1CnBdxOcyrE", title: "Dietas por equivalentes simplificado - Nutrify"}]},
	{title:"Dietas", video:[{videoId:"1nVLRxUjuRw", title: "Dieta por menús - Nutrify"}] },
	{title:"Dietas", video:[{videoId:"WLBAPiip5-w", title: "Dieta por columnas - Nutrify"}]},
	{title:"Dietas", video:[{videoId:"mye6EHX1r60", title: "Dieta por opciones - Nutrify"}]},
	{title:"Dietas", video:[{videoId:"rxddTx4SR34", title: "Personaliza tu dieta en Nutrify	"}]}
];
const Dietas = () => {
	const [currentTab, setTab] = useState(0);
	let match = useRouteMatch();
	const tabs=[
		{
			label: 
				<React.Fragment>
					Selección de alimentos<br />
					<span style={{ fontSize: "x-small" }}>Cálculos automáticos</span>
				</React.Fragment>
			,
			// label:"Selección de alimentos",
			value: "menu-select",
			component: TabsMenuSelect
		},{
			label:"Smae",
			value:"smae",
			component:TabsSmae
		},
		{
			label:"Por equivalentes simplificado",
			value:"equivalentes",
			component:TabsEquivalentes
		},{
			label:"Por menús",
			value:"memus",
			component:TabMenus
		},{
			label:"Por columnas",
			value:"columnas",
			component:TableColumnas
		},{
			label:"Por opciones",
			value:"opciones",
			component:TableOpciones
		},{
			label:"Personalizada",
			value:"personalizada",
			component:TablePersonalizada
		}];
	return (
		<>
			<Header {...headers[currentTab]} />
			<MyTabs onChangeTab={(selected)=>setTab(selected)} tabs={tabs} defSelected={match.params.tipo} urlKey="tipo" root={true} ></MyTabs>
		</>
	);
};

export default Dietas;
