import { loadRequest,insertRequest, updateRequest, trashRequest, untrashRequest, removeRequest } from "./generators";
import { DISCOUNT } from "../constants";
import { all } from "redux-saga/effects";

const loadDiscounts = loadRequest(DISCOUNT);
const insertDiscount = insertRequest(DISCOUNT);
const updateDiscount = updateRequest(DISCOUNT);
const trashDiscount = trashRequest(DISCOUNT);
const untrashDiscount = untrashRequest(DISCOUNT);
const removeDiscount = removeRequest(DISCOUNT);

function* rootSaga() {
	yield all([
		loadDiscounts(),
		insertDiscount(),
		updateDiscount(),
		trashDiscount(),
		untrashDiscount(),
		removeDiscount(),
	]);
}
export default rootSaga;