import React from "react";
import { Tooltip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
const _ = require("lodash");

export default function BoolCell ({ cell }){
	return (
		<>
			<Tooltip title={cell.value && cell.column.labelOnTrue ? cell.column.labelOnTrue(cell) : cell.column.labelOnFalse && cell.column.labelOnFalse(cell)}>
				<div style={{display:"flex", justifyContent:"center"}}>
					{_.get(cell.row.original,cell.column.id) ? 
						<CheckCircleIcon style={{color:"#a5dd72"}} size="small" alt="status-app" />
						: 
						<CancelIcon style={{color:"red"}} size="small" alt="status-app" />
					}
				</div>
			</Tooltip>
		</>
	);
}