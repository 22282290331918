import React, { useState, useRef } from 'react';
import styled from "styled-components";
import ClearIcon from '@mui/icons-material/Clear';
import {Button} from '@mui/material';
import {useDialog} from "context/DialogContext";

const ImageUploader = ({ value:initialImages=[], CustomButtonComponent, onRemoveExistingFile, onFile, removeFile, name, __deleted_files }) => {
	const [selectedImages, setSelectedImages] = useState([]);
	const fileInputRef = useRef(null);

	React.useEffect(()=>{
		if(initialImages.length>0) setSelectedImages([]);
	},[initialImages]);
	const handleImageChange = (e) => {
		if (e.target.files) {
			let filesArray = Array.from(e.target.files)//.map(file => URL.createObjectURL(file));
			// onAddFile(filesArray, name);
			onFile({files: filesArray}, name, false);
			filesArray = Array.from(e.target.files).map(file => URL.createObjectURL(file));


			setSelectedImages((prevImages) => prevImages.concat(filesArray));
			e.target.value = ''; // Clear the input after files are selected
		}
	};


	const removeSelectedImage = (index) => {
		removeFile(selectedImages.filter((_, i) => i === index), name);

		setSelectedImages(selectedImages.filter((_, i) => i !== index));
	};

	const removeInitialImage = (index) => {
		onRemoveExistingFile(index, name)
		// Placeholder for removing initial images functionality
	};

	const triggerFileInput = () => {
		fileInputRef.current.click();
	};

	const renderButton = () => {
		// If CustomButtonComponent is provided, clone it and inject the onClick prop
		if (CustomButtonComponent) {
			// return React.cloneElement(CustomButtonComponent, { onClick: triggerFileInput });
		}
		// Otherwise, use a default button
		return <Button variant="outlined" color="secondary" onClick={triggerFileInput} className="image-upload-button">Cargar imágenes</Button>;
	};
	const [openDialog] = useDialog();
	const verImagen = (img) => {
		openDialog({
			Content:()=><><img src={img} style={{width:"100%"}} /></>,
			width:"md"
		});
	};


	return (
		<div>
			<div style={{fontWeight:"bold", marginBottom:5, marginTop:5}}>Carga imágenes para que aparezcan en las recomendaciones</div>
			
			<input
				type="file"
				accept="image/*"
				multiple
				onChange={handleImageChange}
				ref={fileInputRef}
				style={{ display: 'none' }}
			/>
			{renderButton()}

			<div style={{display:"flex", gap:10, marginTop:10}}>
				{initialImages.filter((img, idx)=>!__deleted_files?.[name]?.includes(idx)).map((img, index) => (
					<ImgContainer key={img.path}>
						<img onClick={()=>verImagen(`${process.env.REACT_APP_FILE_URL}${img.path}`)} style={{height:50}} src={`${process.env.REACT_APP_FILE_URL}${img.path}`} alt={`Uploaded ${index}`} />
						
						<ClearIcon className="button" onClick={() => removeInitialImage(index)} />
					</ImgContainer>
				))}
				{selectedImages.map((url, index) => (
					<ImgContainer key={url}>
						<img onClick={()=>verImagen(url)} style={{height:50}} src={url} alt={`Selected ${index}`} />
						<ClearIcon className="button" onClick={() => removeSelectedImage(index)} />
					</ImgContainer>
				))}
			</div>
			
		</div>
	);
};

export default ImageUploader;

const ImgContainer = styled.div`
	position:relative;
	.button{
		position:absolute;
		top:-10px;
		right:-10px;
		border:0;
		background:transparent;
		color:red;
		cursor:pointer;
		display:none;
	}
	&:hover .button{
		display:inherit;
	}
	img{
		cursor:pointer;
		height:50px;
	}
`;