import React,{useState} from "react";
import Box from "components/Box/Box";
import Text from "components/FormComponents/Text/Text";
import {Button} from "@mui/material";
import {Alert} from "@mui/lab";
export default function SaveAs({onClose}){
	const [name, setName] = useState("");
	const [error, setError] = useState();
	const onGuardar = ()=>{
		console.log(name);
		if(name.length){
			onClose(name);
		}else{
			setError("Por favor escribe el nuevo nombre");
		}
	};
	return <div>
		<Box style={{marginBottom:10}}>
			Para guardar como nuevo asigna un nuevo nombre:
			<Text onChange={v=>setName(v)} />
		</Box>
		{error && <Alert severity="error">{error}</Alert>}

		<div style={{float:"right", display:"flex", marginTop:10}}>
			<Button onClick={onGuardar} color="secondary"  variant="contained">Guardar</Button>
			<Button onClick={()=>onClose()} >Cancelar</Button>
		</div>
	</div>;
}