import React from "react";
import BoxForm from "components/FormComponents/BoxForm/BoxForm";
import {CONSULTATION, SUCURSAL, PATIENTS, BIOIMPEDANCIA} from "store/constants";
import DurninWomersley from "formulas/DurninWomersley";
import Carter from "formulas/Carter";
import SumatoriaPliegues from "formulas/SumatoriaPliegues";
import SlaughterNinos from "formulas/SlaughterNinos";
import SlaughterNinas from "formulas/SlaughterNinas";
import Bioimpedancia from "./Bioimpedancia";
import CrudTable from "components/Table/CrudTable/CrudTable";
import DateCell from "components/Table/CellTypes/DateCell/DateCell";


const elements = [
	{
		type: "group",
		name: "medidas_basica",
		label: "Medidas básicas",
		fields: [{
			type: "text",
			name: "hora_peso",
			label: "Horario de peso",
		},{
			type:"text",
			subtype: "number",
			name: "estatura",
			label: "Estatura (cm)"
		},{
			type: "text",
			subtype: "number",
			name: "peso_meta",
			label: "Peso meta (kg)"
		},{
			type: "text",
			subtype: "number",
			name: "peso",
			label: "Peso (kg)"
		},{
			type: "text",
			subtype: "number",
			name: "grasa",
			label: "Masa Grasa (kg)"
		},{
			type: "text",
			subtype: "number",
			name: "p_grasa",
			label: "Grasa (%)"
		},{
			type: "text",
			subtype: "number",
			name: "imc",
			label: "Índice de masa grasa (kg/m2)"
		},{
			type: "text",
			subtype: "number",
			name: "magra",
			label: "Masa magra (kg)"
		},{
			type: "text",
			subtype: "number",
			name: "p_magra",
			label: "Masa magra (%)"
		},{
			type: "text",
			subtype: "number",
			name: "im_magra",
			label: "Índice de masa magra (kg/m2)"
		}
		]
	},{
		type: "group",
		name: "musculo_esqueletico_por_segmento",
		label: "Músculo esquelético por segmentos",
		fields: [{
			type: "text",
			subtype: "number",
			name: "musculo_esqueletico_cuerpo_completo",
			label: "Cuerpo completo",
		},{
			type: "text",
			subtype: "number",
			name: "musculo_esqueletico_brazo_izquierdo",
			label: "Brazo izquierdo (kg)",
		},{
			type: "text",
			subtype: "number",
			name: "musculo_esqueletico_brazo_derecho",
			label: "Brazo derecho (kg)",
		},{
			type: "text",
			subtype: "number",
			name: "musculo_esqueletico_tronco",
			label: "Tronco (kg)",
		},{
			type: "text",
			subtype: "number",
			name: "musculo_esqueletico_pierna_izquierda",
			label: "Pierna izquierda (kg)",
		},{
			type: "text",
			subtype: "number",
			name: "musculo_esqueletico_pierna_derecha",
			label: "Pierna derecha (kg)",
		},{
			type: "text",
			subtype: "number",
			name: "musculo_esqueletico_otro",
			label: "Otro",
		}
		]
	},{
		type: "group",
		name: "masa_magra_por_segmento",
		label: "Masa magra por segmentos",
		fields: [{
			type: "text",
			subtype: "number",
			name: "masa_magra_cuerpo_completo",
			label: "Cuerpo completo",
		},{
			type: "text",
			subtype: "number",
			name: "masa_magra_brazo_izquierdo",
			label: "Brazo izquierdo (kg)",
		},{
			type: "text",
			subtype: "number",
			name: "masa_magra_brazo_derecho",
			label: "Brazo derecho (kg)",
		},{
			type: "text",
			subtype: "number",
			name: "masa_magra_tronco",
			label: "Tronco (kg)",
		},{
			type: "text",
			subtype: "number",
			name: "masa_magra_pierna_izquierda",
			label: "Pierna izquierda (kg)",
		},{
			type: "text",
			subtype: "number",
			name: "masa_magra_pierna_derecha",
			label: "Pierna derecha (kg)",
		},{
			type: "text",
			subtype: "number",
			name: "masa_magra_otro",
			label: "Otro",
		}
		]
	},{
		type: "group",
		name: "agua",
		label: "Agua",
		fields: [{
			type: "text",
			subtype: "number",
			name: "agua_corporal_total",
			label: "Agua corporal total (L)",
		},{
			type: "text",
			subtype: "number",
			name: "agua_extra_celular",
			label: "Agua extra celular (L)",
		},{
			type: "text",
			subtype: "number",
			name: "agua_intracelular",
			label: "Agua intracelular (L)",
		},{
			type: "text",
			subtype: "number",
			name: "agua_hidratacion",
			label: "Hidratación (%)",
		},{
			type: "text",
			subtype: "number",
			name: "agua_tercer_espacio",
			label: "Agua del tercer espacio (L)",
		}
		]
	},{
		type: "group",
		name: "pliegues",
		label: "Pliegues (mm)",
		fields: [{
			type: "text",
			subtype: "number",
			name: "pliegue_triceps",
			label: "Triceps",
		},{
			type: "text",
			subtype: "number",
			name: "pliegue_subescapular",
			label: "Subescapular",
		},{
			type: "text",
			subtype: "number",
			name: "pliegue_biceps",
			label: "Biceps",
		},{
			type: "text",
			subtype: "number",
			name: "pliegue_cresta_iliaca",
			label: "Cresta iliaca",
		},{
			type: "text",
			subtype: "number",
			name: "pliegue_supraespinal",
			label: "Supraespinal",
		},{
			type: "text",
			subtype: "number",
			name: "pliegue_abdominal",
			label: "Abdominal",
		},{
			type: "text",
			subtype: "number",
			name: "pliegue_muslo_frontal",
			label: "Muslo frontal",
		},{
			type: "text",
			subtype: "number",
			name: "pliegue_pantorrilla_medial",
			label: "Pantorrilla medial",
		},{
			component: SumatoriaPliegues,
			name: "sumatoria_pliegues",
			label: "Sumatoria de pliegues",
		},{
			component: DurninWomersley,
			name: "durnin_womersley",
			label: "Durnin Womersley",
		},{			
			component: Carter,
			name: "carter",
			label: "Carter (deportistas)",
		}
		]
	},{
		type: "group",
		name: "circunferencias",
		label: "Circunferencias (cm)",
		fields: [{
			type: "text",
			subtype: "number",
			name: "circ_icc",
			label: "ICC",
		},{
			type: "text",
			subtype: "number",
			name: "circ_cabeza",
			label: "Cabeza",
		},{
			type: "text",
			subtype: "number",
			name: "circ_cuello",
			label: "Cuello",
		},{
			type: "text",
			subtype: "number",
			name: "circ_brazo_relajado",
			label: "Brazo (relajado)",
		},{
			type: "text",
			subtype: "number",
			name: "circ_brazo_tensionado",
			label: "Brazo (flex y en tensión)",
		},{
			type: "text",
			subtype: "number",
			name: "circ_antebrazo",
			label: "Antebrazo (máximo)",
		},{
			type: "text",
			subtype: "number",
			name: "circ_muñeca ",
			label: "Muñeca (estiloide distal)",
		},{
			type: "text",
			subtype: "number",
			name: "circ_tórax ",
			label: "Tórax (mesosternal)",
		},{
			type: "text",
			subtype: "number",
			name: "circ_cintura ",
			label: "Cintura (mínima)",
		},{
			type: "text",
			subtype: "number",
			name: "circ_caderas ",
			label: "Caderas (máximas)",
		},{
			type: "text",
			subtype: "number",
			name: "circ_muslo_1cm_gluteo",
			label: "Muslo (1 cm de glúteo)",
		},{
			type: "text",
			subtype: "number",
			name: "circ_muslo_tronch",
			label: "Muslo (troch-tib-lat-medio)",
		},{
			type: "text",
			subtype: "number",
			name: "circ_pantorrilla ",
			label: "Pantorrilla (máxima)",
		},{
			type: "text",
			subtype: "number",
			name: "circ_tobillo ",
			label: "Tobillo (mínimo)",
		}]
	},{
		type: "group",
		name: "otros",
		label: "Otros",
		fields: [{
			type: "text",
			subtype: "number",
			name: "resistencia",
			label: "Resistencia (Ω)",
		},{
			type: "text",
			subtype: "number",
			name: "reactancia ",
			label: "Reactancia (Ω)",
		},{
			type: "text",
			subtype: "number",
			name: "grasa_visceral",
			label: "Grasa visceral (L)",
		},{
			type: "text",
			subtype: "number",
			name: "angulo_fase",
			label: "Ángulo de fase (º)",
		},{
			type: "text",
			subtype: "number",
			name: "p_angulo_fase",
			label: "Ángulo de fase percentil ",
		},{
			type: "text",
			subtype: "number",
			name: "grip",
			label: "Grip",
		},{
			type: "text",
			subtype: "number",
			name: "grip_percentila",
			label: "Grip percentila",
		},{
			type: "text",
			subtype: "number",
			name: "presion_arterial",
			label: "Presión arterial",
		},{
			type: "text",
			subtype: "number",
			name: "otros_otros",
			label: "Otros",
		}]
	},{
		type: "group",
		name: "ninos",
		label: "Niños",
		fields: [{
			type: "text",
			subtype: "number",
			name: "ninos_zscore",
			label: "Z-score",
		},{
			type: "text",
			subtype: "number",
			name: "ninos_talla_edad",
			label: "Talla para la edad",
		},{
			type: "text",
			subtype: "number",
			name: "ninos_peso_talla",
			label: "Peso para la talla",
		},{
			type: "text",
			subtype: "number",
			name: "ninos_peso_edad",
			label: "Peso para la edad",
		},{
			type: "text",
			subtype: "number",
			name: "ninos_imc_edad",
			label: "IMC para la edad",
		},{
			type: "text",
			subtype: "number",
			name: "ninos_circunferencia_craneo_edad",
			label: "Circunferencia de cráneo para la edad",
		},{
			type: "text",
			subtype: "number",
			name: "ninos_velocidad_crecimiento",
			label: "Velocidad de crecimiento",
		},{
			type: "text",
			subtype: "number",
			name: "ninos_circunferencia_media_brazo",
			label: "Circunferencia media brazo",
		},{
			type: "text",
			subtype: "number",
			name: "ninos_triceps",
			label: "Triceps",
		},{
			type: "text",
			subtype: "number",
			name: "ninos_pierna_media",
			label: "Pierna media",
		},{
			type: "text",
			subtype: "number",
			name: "ninos_otros",
			label: "Otros",
		},{
			component: SlaughterNinas,
			name: "ninos_slaughter_ninas",
			label: "Slaughter niñas (% grasa)",
		},{
			component: SlaughterNinos,
			name: "ninos_slaughter_ninos",
			label: "Slaughter niños (% grasa)",
		}]
	}
];

// const data = useSelector(state => state[CUSTOMER.name]);
// const [status, handleChange] = useAutoSave({url:"customers/me", data:data, type:CUSTOMER});

// const [openModal, setOpenModal] = useState(false);




const Antropometria = props => {
	const columns = React.useMemo(
		() => [
			{
				Header: "Titulo",
				accessor: "nombre"
			},
			{
				Header: "Fecha",
				accessor: "fecha",
				Cell: DateCell,
			},
			{
				Header: "Archivo",
				accessor: "archivo.tipo"
			}
		],
		[]
	);
	

	
	return (
		<>
			<BoxForm data={props.data} elements={elements} onChange={props.onChange} indexElements={true} ></BoxForm>
			<CrudTable
				data={props.data?.bioimpedancia || []}
				columns={columns}
				type={BIOIMPEDANCIA}
				modal={Bioimpedancia}
				status={{}}
				_id={props.idConsulta}
			/>
		</>
	);
};
export default Antropometria;