import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";

const Premio = props => {
	const elements = [
		{
			type:"text",
			name:"nombre",
			label:"Premio"
		},
		{
			type: "text",
			name: "obtiene",
			label: "Lo obtiene cuando",
		}
	];
	return (
		<>
			<ModalForm elements={elements} onChange={props.onChange} data={props.data}></ModalForm>
		</>
	);
};

export default Premio;
