import { loadRequest, updateRequest, insertRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { NUTRITIONIST} from "../constants";
import { all } from "redux-saga/effects";

const loadNutritionists = loadRequest(NUTRITIONIST);
const insertNutritionist = insertRequest(NUTRITIONIST);
const updateNutritionist = updateRequest(NUTRITIONIST);
const trashNutritionist = trashRequest(NUTRITIONIST);
const untrashNutritionist = untrashRequest(NUTRITIONIST);
const removeNutritionist = removeRequest(NUTRITIONIST);

function* rootSaga() {
	yield all([
		loadNutritionists(),
		insertNutritionist(),
		updateNutritionist(),
		trashNutritionist(),
		removeNutritionist(),
		untrashNutritionist()
	]);
}
export default rootSaga;