import React, {useEffect} from "react";

import { TextField, FormControlLabel, Checkbox } from "@mui/material";
import {catMicronutrimentos, catMicronutrimentosMap} from "misc/grupos";
import { useImmer } from "use-immer";
import PropTypes from "prop-types";
import './Micronutrimentos.scss';

const Micronutrimentos = props => {

	const [data, setData] = useImmer(props.value ?? { 
		activo: false, fib: undefined, vit_a: undefined, ac_asc: undefined, ac_fol: undefined, h_no_hem: undefined, pot: undefined, i_gli: undefined, car_gli: undefined, azu_e: undefined, cal: undefined, hie: undefined, sod: undefined, se: undefined, fos: undefined, col: undefined, ag_sat: undefined, ag_mono: undefined, ag_poli: undefined, et: undefined, lib: undefined
	});

	useEffect(() => {
		props.onChange(data, "micronutrimentos");
	}, [data]);
	return (
		<div className="container-micronutrimentos">
			{(props.categoria && Object.keys(catMicronutrimentos).includes(props.categoria)) && (
				<>
			
					<FormControlLabel 
						control={<Checkbox color="primary" checked={data.activo} onChange={e=>setData(draft => {draft.activo = e.target.checked;})} />}
						label="Activa los micronutrimentos para poder añadir los valores"
					/>
					{data.activo &&
					<>
						{Object.keys(catMicronutrimentos[props.categoria]?.micronutrimentos).map((key, idx) => (
							<div key={idx} className="container-inputs">
								<p>{catMicronutrimentosMap[key]}</p>
								<input
									// size="small"
									// variant="outlined"
									value={props.value[key]}
									// type="number"
									onChange={event => {
										setData(draft => {draft[key] = +event.target.value;});
									}}
									className="textfield"
									style={{textAlign: "center"}}
								/>
							</div>
						))}
					</>
					}
				</>
			)}
		</div>
	);
	// <FormControlLabel 
	// 	control={<Checkbox color="primary" key={o.value+"c"} checked={props.value[o.value]} onChange={handleChange(o)} value={o.value} />}
	// 	label={o.label}
	// />
};

export default Micronutrimentos;

Text.propTypes = {
	value: PropTypes.object,
	onChange: PropTypes.func,
	categoria: PropTypes.string
};