import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { MESSAGE } from "../constants";
import { all } from "redux-saga/effects";

const loadMessage = loadRequest(MESSAGE);
const insertMessage = insertRequest(MESSAGE);
const updateMessage = updateRequest(MESSAGE);
const removeMessage = removeRequest(MESSAGE);
const trashMessage = trashRequest(MESSAGE);
const untrashMessage = untrashRequest(MESSAGE);

function* rootSaga() {
	yield all([
		loadMessage(),
		insertMessage(),
		updateMessage(),
		removeMessage(),
		trashMessage(),
		untrashMessage()
	]);
}
export default rootSaga;