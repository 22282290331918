import React from "react";
import BoxForm from "components/FormComponents/BoxForm/BoxForm";
const elements = [
	{
		type: "checkbox",
		name: "prescripcion_dietetica",
		label: "Prescripción dietética",
		options: [
			{ value: "dieta_general_saludable", label: "Dieta general saludable" },
			{ value: "dieta_modificada", label: "Dieta modificada" },
			{ value: "nutricion_enteral", label: "Nutrición enteral" },
			{ value: "nutricion_parental", label: "Nutrición parenteral" },
		]
	},
  
	{
		type: "group",
		name: "datos_extras",
		label: "Datos extras",
		fields: [{
			type: "text",
			name: "alergias",
			label: "Alergias alimentarias"
		},{
			type: "text",
			name: "intolerancias",
			label: "Intolerancias alimentarias"
		},{
			type: "text",
			name: "restricciones",
			label: "Restricciones dietéticas"
		}]
	},
	{
		type: "text",
		name: "plan_de_nutricion",
		label: "Menciona los planes de nutrición o dietas que hayas llevado a cabo y los resultados que obtuviste en cada una",
		fullWidth: true,
		moreRows: true
	},
	{
		type: "multiGroupTabs",
		name: "recordatorios",
		label: "Recordatorio 24 horas",
		fullWidth: true,
		fields: [
			{
				type:"text",
				name:"desayuno",
				label: "Desayuno",
			},
			{
				type:"text",
				name:"colacion1",
				label: "1º colación",
			},
			{
				type:"text",
				name:"almuerzo",
				label: "Comida",
			},
			{
				type:"text",
				name:"colacion2",
				label: "2º colación",
			},
			{
				type:"text",
				name:"cena",
				label: "Cena",
			},
			{
				type:"text",
				name:"bebidas",
				label: "Bebidas",
			},
		]
	},
];
const Antecedentes = props => {
	return (
		<>
			<BoxForm data={props.data} elements={elements} onChange={props.onChange}  indexElements={true}></BoxForm>

		</>
	);
};

export default Antecedentes;
