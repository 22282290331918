import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import {smae, subcatSmaeMap} from "misc/grupos";

export default function EquivalenciaCell({ cell }) {
	let equivalencias = cell.row.original.equivalenciaSmae ?? cell.row.original.equivalencia;
	
	// useEffect(() => {
	// 	setEquivalencias({
	// 		v: equivalencias?.v ?? 0,
	// 		f: equivalencias?.f ?? 0,
	// 		c: (equivalencias?.c?.sg ?? 0) + (equivalencias?.c?.cg ?? 0),
	// 		leg: equivalencias?.leg ?? 0,
	// 		p: (equivalencias?.p?.mb ?? 0) + (equivalencias?.p?.b ?? 0) + (equivalencias?.p?.mo ?? 0) + (equivalencias?.p?.equivalencias ?? 0),
	// 		l: (equivalencias?.l?.d ?? 0) + (equivalencias?.l?.sd ?? 0) + (equivalencias?.l?.e ?? 0) + (equivalencias?.l?.ca ?? 0),
	// 		g: (equivalencias?.g?.sp ?? 0) + (equivalencias?.g?.cp ?? 0),
	// 		azu:  (equivalencias?.azu?.cg ?? 0) + (equivalencias?.azu?.sg ?? 0),
	// 		ag: equivalencias?.ag ?? 0
	// 	});
	// }, [equivalencias]);
	if(!equivalencias || equivalencias.length === 0 ) return <></>;
	return (
		<>
			<Grid container style={{justifyContent:"center"}}>
				{smae.map(grupo => <>
					{grupo.subcat ?
						<>
							{Object.keys(grupo.subcat).map(key=>(
								<>
									{equivalencias?.[grupo.name]?.[key] > 0 &&
								<Grid key={grupo.name} item>
									<Grid container direction="column" style={{margin: "0 5px"}}>
										<img
											style={{float:"left", objectFit: "contain",height:"20px",margin:"4px"}}
											src={require(`assets/img/${grupo.photo}.png`)}
											className="img-category"
										/>
										<p>{equivalencias[grupo.name][key]}</p>
										<p>{subcatSmaeMap[key]}</p>
									</Grid>
								</Grid>
									}
								</>
							))}
						</>
						:
						<>
							{equivalencias?.[grupo.name] > 0 &&
						<Grid key={grupo.name} item>
							<Grid container direction="column" style={{margin: "0 5px"}}>
								<img
									style={{float:"left", objectFit: "contain",height:"20px",margin:"4px"}}
									src={require(`assets/img/${grupo.photo}.png`)}
									className="img-category"
								/>
								{equivalencias[grupo.name]}
							</Grid>
						</Grid>
							}
						</>
					}
				</>)}
			</Grid>
		</>
	);
}


EquivalenciaCell.propTypes = {
	cell: PropTypes.object
};