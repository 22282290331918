import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import store from "store/reducers/index";
import { makeStyles } from "@mui/styles";
import {useLocation, Redirect, useHistory} from "react-router-dom";

import { Modal, Button, Backdrop, Fade } from "@mui/material";

import ConsultasOnlineButton from "./ConsultasOnlineButton";
import RefreshButton from "./RefreshButton";
import CerrarSesionButton from "./CerrarSesionButton";

import styles from "Styles.module.scss";
import { refresh } from "api/loader";
import { useWindowSize } from "../../hooks";

import "./Header.scss";
import ComenzarConsultaButton from "./ComenzarConsultaButton";


const useStyles = makeStyles({
	colorPrimary: {
		color: "white",
	},
});

const Header = props => {

	const history = useHistory();
	const classes = useStyles();

	const {innerWidth} = useWindowSize();

	const [openModal, setOpenModal] = useState(false);

	const handleModal = () => {
		setOpenModal(!openModal);
	};


	const aumentarPlan = () => {
		history.push("/configuracion/0");
	};

	return (
		<>
			<div className="container-header" style={{display: "flex", alignItems: "center"}}>
				<div className="left">
					<h2 className="title">{props.title}</h2>
					{/* {props.video && (
						<div className="video">
							<Button
								size="small"
								variant="contained"
								color="primary"
								onClick={handleModal}
							>
								<PlayArrowRoundedIcon style={{ color: "#ffffff" }} />
							</Button>
							<p>
								VER TUTORIAL <br /> {props.time}
							</p>
						</div>
					)} */}
				</div>
				{innerWidth > 769 &&
        
        <div className="right botones">
        	<RefreshButton />
        	<CerrarSesionButton />
        </div>
				}
			</div>
			<Modal
				open={openModal}
				onClose={handleModal}
				className={styles.modal}
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500
				}}
			>
				<Fade in={openModal}>
					<div className={styles.paper}>
						<p>Modal</p>
					</div>
				</Fade>
			</Modal>
		</>
	);
};

export default Header;
