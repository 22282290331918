import React, {useState} from "react";
import { Button, Icon } from "@mui/material";
import SaveModal from "components/SaveModal/SaveModal";


const NewButton = props => {
	const {variant="contained"} = props;
	const [openedModal, setOpenModal] = useState(false);
	return (
		<>
			<div style={{textAlign:"right", ...props.style}} onClick={()=>setOpenModal(true)}>
				{props.children ? props.children : 
					<Button style={{fontWeight:"bold",...props.buttonStyle}}  color="secondary" variant={variant} {...props.style} >
						+ Crear {props.label || props.type.label}
					</Button>
				}
			</div>
			{openedModal && 
				<SaveModal 
					{...props.options} 
					{...props}
					fullWidth={props.fullWidth===false ? false: true} 
					parent_id={props._id} 
					onInsert={props.onInsert} 
					modal={props.modal} 
					// forceNew={forceNew}
					type={props.type} 
					status={props.status} 
					handleModal={(id)=>{
						setOpenModal(false);
						if(props.getData && id) props.getData(id);
						// const name = type.tail_name ? `${type.name}_${type.tail_name}` : type.name;
						// dispatch({
						// 	type: `${name}_STATUS`,
						// 	payload: {status:"LOADED"}
						// });
					}} />
			}

		</>
	);
};

export default NewButton;

