import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const useHelperFunctions = (props) => {

	const data = useSelector(state=>state[props.type?.name].data);

	const aumentarPlan = (actual, key) => {
		const disponible = data?.subscripcion?.[key] - actual;
		return disponible;

	};
	return {statusPlan:aumentarPlan};

};
export default useHelperFunctions;