import React from "react";
import styled from "styled-components";
import {
	FormControl,
	withStyles
} from "@mui/material";
import PropTypes from "prop-types";
import Select from "components/Select/Select";
// const NoPaddingSelect = withStyles({
// 	root: {
// 		"&&[class*=\"MuiSelect-outlined\"]": {
// 			padding: 10
// 		}
// 	}
// })(Select);


const NoPaddingSelect = styled(Select)`
	"&&[class*=\"MuiSelect-outlined\"]": {
		padding: 10
	}
	height:40px;
	min-width:100px;

	select{
		::placeholder{
			opacity:1;
			color:rgb(117,117,117);
			font-weight: 351;
			font-size: 13px;
		}
		zIndex: 999;
		opacity:1;
		color:rgb(117,117,117);
		font-weight: 351;
		font-size: 13px;
		padding:10px;
		height: unset;

		
	}

`


const MySelect = props => {
	const [labelWidth] = React.useState(0);
	
	React.useEffect(()=>{
		if(props.value===undefined && props.options && !props.preventAutoChange){
			props.onChange(props.options[0].value, props.name);
		}
	},[props.value]);

	return <ContainerSelect style={{...props.style}}>
		<FormControl variant={props.variant ?? "outlined"} >
			<NoPaddingSelect
				native
				style={{minWidth:"150px"}}
				options={props.options}
				value={props.value}
				labelId="label"
				labelAccessor="label"
				accessor="value"
				labelWidth={labelWidth}
				placeholder={"Seleccionar opción"}
				disabled={props.disabled}
				menuPosition={props.position}
				multiSelect={props.multiSelect}
				onChange={(value)=>{
					if(props.multiSelect){
						props.onChange(value, props.name);

					}else{
						props.onChange(value.value, props.name);
					}
					// event.persist();   
					// let val = event.target.value;
					// val = props.filter==="boolean" && val!=="" ? val === "true" : val;
				}}>
				{/* {props.options?.map((option,i)=>
					<option value={option.value} disabled={option.disabled} key={props.name + option.value + String(i)}>{option.label}</option>
				)} */}
			</NoPaddingSelect>
		</FormControl>
	</ContainerSelect>;
};

export default MySelect;

MySelect.propTypes = {
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	options: PropTypes.array,
	name: PropTypes.string,
	onChange: PropTypes.func
};

const ContainerSelect = styled.div`
	width: 100%;
	max-width: 350px;
	min-width:200px;

	option{
    @media screen and (max-width: 1100px) {
			font-size: 30px;
    }
	}
`;