import React from "react";
import {ONLINE_PATIENT, MESSAGE} from "store/constants";
import DateCell from "components/Table/CellTypes/DateCell/DateCell";
import UserCell from "components/Table/CellTypes/UserCell/UserCell";
import DateRange from "components/Table/FilterTypes/DateRange/DateRange";
import useSave from "Hooks/useSave";

import { Button } from "@mui/material";
import Header from "../../components/Header/Header";

import ModalForm from "components/FormComponents/ModalForm/ModalForm";




const Mensaje = (props) => {
	const columnsPaciente = React.useMemo(
		() => [
			{
				Header: "Nombre",
				accessor: "nombre",
				Cell: UserCell
			},
			{
				Header: "Género",
				accessor: "genero",
				style:{textTransform:"capitalize"}
			},
			{
				Header: "Fecha de nacimiento",
				accessor: "fecha_nacimiento",
				Cell: DateCell,
				Filter: DateRange,
				filter: "daterange"
			},
			{
				Header: "Fecha última consulta",
				accessor: "ultima_consulta",
				Cell: DateCell,
				Filter: DateRange,
				filter: "daterange"
			}
		],
		[]
	);
	const filters = React.useMemo(
		() => [
			{
				Header: "Nombre",
				accessor: "nombre",
			},
			{
				Header: "Género",
				accessor: "genero",
				filter:"select",
				filterOptions:[
					{value:"",label:"Sin selección"},
					{value:"masculino",label:"Masculino"},
					{value:"femenino",label:"Femenino"},
				]
			},
			{
				Header: "Fecha de nacimiento",
				accessor: "fecha_nacimiento",
				filter: "daterange"
			},
			{
				Header: "Fecha última consulta",
				accessor: "ultima_consulta",
				filter: "daterange"
			}
		],
		[]
	);

	const elements = React.useMemo(()=>[
		{
			type:"multiSelect",
			name:"para",
			label:"Para",
			datatype:ONLINE_PATIENT,
			columns:columnsPaciente,
			filters:filters
		},
		{
			type: "text",
			name: "asunto",
			label: "Asunto",
		},
		{
			type: "text",
			name: "mensaje",
			label: "Mensaje",
			multiline:true
		},
		// {
		// 	type: "text",
		// 	name: "enlace",
		// 	label: "Enlace (url)",
		// },
		// {
		// 	type: "file",
		// 	name: "archivo",
		// 	label: "Archivo"
		// }
	],[]);
	const {data, onChange, remove, save, status} = useSave({type:MESSAGE });

	// React.useEffect(()=>{
	// 	if(!data?.para || data?.para?.length===0){
	// 		setValid(false);
	// 	}else{
	// 		setValid(true);
	// 	}
	// },[data]);
	return (
		<>
			<Header title="Mensajes" video time="5:35" />

			<ModalForm elements={elements} onChange={onChange} data={data} {...props}></ModalForm>
			<Button 
				variant="contained"
				onClick={save}
			>
				Enviar
			</Button>
		</>
	);
};

export default Mensaje;
