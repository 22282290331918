import React from "react";
import Header from "../../components/Header/Header";

import TablaRecetas from "./TabRecetas";
import TablaCategorias from "./TabCategorias";
import TablaSellos from "./TabSellos";
import { useRouteMatch } from "react-router-dom";
import MyTabs from "components/Tabs/Tabs";


const Recetas = () => {
	let match = useRouteMatch();
	const tabs=[{
		label:"Recetario",
		value:"recetario",
		component:TablaRecetas
	},{
		label:"Categorías",
		value:"categoria",
		component:TablaCategorias
	},{
		label:"Etiquetas",
		value:"etiquetas",
		component:TablaSellos
	}];
	return (
		<>
			<Header title="Recetario" video={[{videoId: "lGS3z6K4IE4", title: "¿Cómo crear tu propio recetario con Nutrify?"}]} />
			<MyTabs tabs={tabs} defSelected={match.params.seccion} urlKey="seccion" root={true} ></MyTabs>
		</>
	);
};

export default Recetas;
