import React from "react";
import CrudTable from "../../components/Table/CrudTable/CrudTable";
import Premio from "./ModalPremios";
import { useSelector } from "react-redux";
import Header from "../../components/Header/Header";
import { PRIZE } from "store/constants";

const Premios = () => {
	const data = useSelector(state => state[PRIZE.name]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Premio",
				accessor: "nombre"
			},
			{
				Header: "Lo obtiene cuando",
				accessor: "obtiene"
			}
		],
		[]
	);

	return (
		<>
			<Header title="Premios" video time="5:35" />

			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				filters={columns}
				type={PRIZE}
				modal={Premio}
				options={{filter: true,orderBy:true}}
			/>
		</>
	);
};

export default Premios;
