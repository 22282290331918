
import React from "react";
import {frutasCirIcon, verdurasCirIcon, leguminosasCirIcon, azucaresCirIcon, proteinasCirIcon, lacteosCirIcon, cerealesCirIcon, grasasCirIcon} from "assets/img/grupos";
import {deepMult} from "functions/general"
const icons ={
	f:{icon:frutasCirIcon, color:"#E6375E"},
	v:{icon:verdurasCirIcon, color:"#4AC237"},
	leg:{icon: leguminosasCirIcon, color:"#3E2ED1"},
	g:{icon:grasasCirIcon, color:"#E9AF11"},
	l:{icon:lacteosCirIcon, color:"#59BAC9"},
	c:{icon:cerealesCirIcon, color:"#A37241"},
	p:{icon:proteinasCirIcon, color:"#D54C48"},
	azu:{icon:azucaresCirIcon, color:"#D54C48"}
};
const sizes = {
	sm:{
		img:{
			width:23, height:23,
		},
		text:{
			fontSize:8, bottom:3.5, width:11, right:2
		}
	},
	md:{
		img:{
			width:30, height:30,
		},
		text:{
			fontSize:10, bottom:4.5, width:14, right:2
		}
	},
	lg:{
		img:{
			width:37, height:37,
		},
		text:{
			fontSize:12, bottom:5.5, width:19, right:2
		}
	}
}
export default function IconEq({eq, size="sm", cant=1, showTooltip=false}){
	const sortedEntries = Object.entries(eq || {})
		.map(([k, v]) => {
			return { key: k, total: sumObject(v) };
		}).filter(a=>a.total)
		.sort((a, b) => {
			// First sort alphabetically by key
			if (a.key < b.key) return -1;
			if (a.key > b.key) return 1;
			// If keys are equal, then sort by total
			return b.total - a.total;
		});


	return <div style={{display:"flex", gap:5}}>{sortedEntries.map(({ key, total }) => (
		<div key={key} style={{ position: "relative", ...sizes[size].img }}>
			<img src={icons[key].icon} style={{ ...sizes[size].img, objectFit: "contain" }} />
			<div style={{ position: "absolute", ...sizes[size].text, textAlign:"center", color: icons[key].color }}>
				{customToFixed(total*cant)}
			</div>
		</div>
	))}
	</div>;
}
const sumObject = (obj) => {
	if(typeof obj==="object")return Object.values(obj||{}).reduce((acc,v)=>acc+v,0);
	return obj;
};
function customToFixed(number) {
	// Check if the number is an integer
	if (Number.isInteger(number)) {
		return number.toString();
	} else {
		// If not, use toFixed with the specified precision
		return number.toFixed(1);
	}
}
