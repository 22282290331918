import React, { useState } from "react";
import {
	TextField,
	Modal,
	Fade,
	Button
} from "@mui/material";
import MultiSelectTable from "components/Table/MultiSelectTable/MultiSelectTable";
import styles from "Styles.module.scss";

const MultiSelect = (props) => {

	const [openModal, setOpenModal] = useState(false);
	const handleModal = () => {
		setOpenModal(!openModal);
	};
	const handleSelect = (selected) => {
		props.onChange(selected, props.name);
	};


	return (
		<>
			<div onClick={()=>setOpenModal(true)}>
				a
				<TextField
					type="text"
					disabled
					
					value={props.value ? `${props.value?.length} seleccionados`:"Seleccionar"}
				/>
			</div>
			
        
			<Modal open={openModal} onClose={handleModal} className={styles.modal}>
				<Fade in={openModal}>
					<div style={{width:"90%"}} className={styles.paper}>
				
						<MultiSelectTable 
							options={{ filter: true }} 
							selected={props.value}
							type={props.datatype}
							onChange={handleSelect} 
							columns={props.columns} 
							filters={props.filters}
							remote={props.remote}
						/>
						<Button variant="contained" color="primary" style={{float:"right"}} onClick={handleModal}>Seleccionar</Button>
					</div>
				</Fade>
			</Modal>
		</>
	);
};

export default MultiSelect;
