import React from 'react'
import CrudTable from "../../../components/Table/CrudTable/CrudTable";
import { useSelector } from "react-redux";
import { NUTRITIONIST_NOTIFICATION } from "store/constants";
import Notificacion from './Notificacion';
import CountCell from "components/Table/CellTypes/CountCell/CountCell";
import { Button } from "@mui/material";

function TablaNotificaciones() {
	const data = useSelector(state => state[NUTRITIONIST_NOTIFICATION.name]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Titulo",
				accessor: "titulo"
			},
			{
				Header: "Me gusta",
				accessor: "nut_mg",
				Cell: CountCell
			},
			{
				Header: "Leido",
				accessor: "nut_l",
				Cell: CountCell
			}
		],
		[]
	);

	return (
		<>
			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				filters={columns}
				type={NUTRITIONIST_NOTIFICATION}
				modal={Notificacion}
				options={{filter: true,orderBy:true, disableEdit:true}}
				actions={[({cell, setSelectedData, setOpenModal, setForceNew})=>{
					const open = ()=>{
						let {_created_date, nut_l, nut_e, nut_mg, ...obj} = cell.row.original;

						setForceNew(true);
						setSelectedData(obj);
						setOpenModal(true);
					};
					return<Button onClick={open} variant="contained" style={{background:"#F8F8F8",color:"#3B4C4B", margin:"5px"}}>Reenviar</Button>;
				}]}
			/>
		</>
	);
}

export default TablaNotificaciones
