import React from "react";
import PropTypes from "prop-types";

export default function MapCell({cell}){
	const option = React.useMemo(()=>{
		if(Array.isArray(cell.column?.options)){
			return cell.column?.options?.filter(d=>cell.value?.includes(d.value))?.map(o=>o?.label || cell.value).join(", ")
		}else{
			return cell.column?.options?.find(d=>d.value===cell.value)?.option?.label || cell.value
		}
	},[cell.column?.options, cell.value]);


	// const option = React.useMemo(()=>cell.column?.options?.find(d=>d.value===cell.value)||{},[cell.column?.options, cell.value]);
	// const value = React.useMemo(()=>option?.label || cell.value,[cell.value, option?.label]);

	// console.log({option, cell, value})
	return (
		<>
			<div style={{background:option.bg, color:option.color, padding:"5px 10px", borderRadius:20}} className="text-align inline">{option}</div>
		</>
	);
}

MapCell.propTypes = {
	cell: PropTypes.object
};