import React from "react";
import { useSelector } from "react-redux";
import { Modal, Fade, Button, CircularProgress } from "@mui/material";

import useSave from "Hooks/useSave";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import Box from 'components/Box/Box'
import { PATIENTS } from "store/constants";
import { useState } from "react";

const elementsAccessApp = [{
	type: "group",
	name: "datos_acceso",
	label: "Datos de accesos a la App",
	subLabel: "Crea el usuario para la App de tu paciente ingresando su correo y asignando una contraseña, con estos datos podrá iniciar sesión.",
	fullWidth: true,
	fields: [{
		type: "text",
		name: "correo",
		label: "Correo electrónico",
		labelAdornment: "@",
	},{
		type: "password",
		name: "password",
		confirmPassword: true,
	}]
}];
const e = [{
	type: "text",
	name: "correo",
	label: "Correo electrónico",
	labelAdornment: "@",
},{
	type: "password",
	name: "password",
	confirmPassword: true,
}];

const AccesoApp = props => {
	const {data, onChange, onFile, save, status} = useSave({data:props.data, type:PATIENTS, _id:props.idPaciente});
	const [isValid, setValid] = useState(true);
	return(
		<>
			<ModalForm fullWidth setValid={setValid} data={data} onChange={onChange} elements={e} label="Datos de accesos a la App" subLabel="Crea el usuario para la App de tu paciente ingresando su correo y asignando una contraseña, con estos datos podrá iniciar sesión">
				<Button disabled={!isValid} color="primary" variant="contained" onClick={save}>Guardar</Button>
			</ModalForm>
		</>
	);  
};

export default AccesoApp;