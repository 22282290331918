import React from "react"
import { Button } from "@mui/material";
import PropTypes from "prop-types";

const ButtonAddTiempo = ({addTiempoComida, index, text}) => (<Button variant="contained" color="secondary" onClick={() => addTiempoComida(index)}>{text || "Añadir Tiempo Comida"}</Button>);

export default ButtonAddTiempo;

ButtonAddTiempo.propTypes = {
	addTiempoComida: PropTypes.string.isRequired,
	index: PropTypes.number
}