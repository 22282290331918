import { loadRequest,insertRequest, updateRequest, trashRequest, untrashRequest, removeRequest } from "./generators";
import { ONLINE_PATIENT } from "../constants";
import { all } from "redux-saga/effects";

const loadOnlinePatients = loadRequest(ONLINE_PATIENT);
const insertOnlinePatient = insertRequest(ONLINE_PATIENT);
const updateOnlinePatient = updateRequest(ONLINE_PATIENT);
const trashOnlinePatient = trashRequest(ONLINE_PATIENT);
const untrashOnlinePatient = untrashRequest(ONLINE_PATIENT);
const removeOnlinePatient = removeRequest(ONLINE_PATIENT);

function* rootSaga() {
	yield all([
		loadOnlinePatients(),
		insertOnlinePatient(),
		updateOnlinePatient(),
		trashOnlinePatient(),
		untrashOnlinePatient(),
		removeOnlinePatient(),
	]);
}
export default rootSaga;