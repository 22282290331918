import React, {useState} from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

export default function TextCell({cell}){
	const [viewMore, setViewMore] = useState(false);
	const ref = React.useRef(null);
	const [sizeCell, setSizeCell] = useState(0);
	// const sizeCell = ref.current?.offsetHeight;
	let formatted = cell.value;
	
	React.useEffect(() => {
		setSizeCell(ref.current?.offsetHeight);
	}, [ref])
	return (
		<ContainerTextCell viewMore={viewMore} sizeCell={sizeCell}>
			<div className="cell" ref={ref} style={{...cell.column.style}}>{cell.column.prepend || ""}{formatted}</div>
			{(sizeCell > 47) && <span onClick={()=>setViewMore(!viewMore)}>{!viewMore ? "Ver más" : "Ver menos"}</span>}
		</ContainerTextCell>
	);
}

const ContainerTextCell = styled.div`
${props => props.sizeCell > 47 && css`
		.cell{
			display: ${props => !props.viewMore && "-webkit-box"};
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;  
			overflow: hidden;
			max-width: 50ch;
		}
		`}
	span{
		font-size: 10px;
		cursor: pointer;
		white-space: nowrap;
	}
`;

TextCell.propTypes = {
	cell: PropTypes.object
};