import React from "react";
import CrudTable from "components/Table/CrudTable/CrudTable";
import Cliente from "./Cliente";
import { useSelector } from "react-redux";
import {CUSTOMER} from 'store/constants'
import Header from "../../components/Header/Header";
import DateCell from "components/Table/CellTypes/DateCell/DateCell";
import {get} from "api";
import LinkCell from "components/Table/CellTypes/LinkCell/LinkCell";
import NumberCell from "components/Table/CellTypes/NumberCell/NumberCell";
import PercentageCell from "components/Table/CellTypes/PercentageCell/PercentageCell";
import { useQuery } from 'react-query'

const TablaClientes = props => {
  // const [data, setData] = React.useState([]);

	const {data, status} = useQuery([CUSTOMER.name, "calificaciones"], ()=>get("admin/customers/calificaciones", {keepPreviousData:true}));


	// React.useEffect(() => {

	// 	(async function(){
	// 		let d = await get("admin/customers/calificaciones");
	// 		setData(d.data);
	// 	})()
		
	// }, [])


  const columns = React.useMemo(
    () => [
      {
        Header: "Clave",
        accessor: "clave"
      },
			{
        Header: "Primera fecha de pago",
				accessor: "subscripcion.primera_fecha_pago",
				Cell:DateCell,
				filter:"daterange",
				sortType: "date"
			},
			{
        Header: "Vencimiento",
				accessor: "subscripcion.vencimiento",
				Cell:DateCell,
				filter:"daterange",
				sortType: "date"
			},
			{
        Header: "Ultima conexion",
				accessor: "ultima_conexion",
				Cell:DateCell,
				filter:"daterange",
				sortType: "date"
      },
			{
        Header: "Uso mes anterior",
        accessor: "uso_mes_anterior",
				sortType: "number",
				filter:"number",

				Cell:NumberCell,
				op:v=>`${Number(v/3600).toFixed(2)} hrs`
      },
			{
        Header: "Uso mes actual",
        accessor: "uso_mes_actual",
				sortType: "number",
				Cell:NumberCell,
				op:v=>`${Number(v/3600).toFixed(2)} hrs`
      },
			{
        Header: "Dif de uso",
        accessor: "dif_uso",
				sortType: "number",
				filter:"number",

				Cell:PercentageCell
      },
			
			{
        Header: "Correo",
				accessor: "subscripcion.link_pago",
				link: (value, data) => `mailto:${data.correo}`,
				filter:null,
				Cell:LinkCell,
				label:"Enviar"
			},
			{
        Header: "Whatsapp",
				accessor: "subscripcion.link_pago2",
				link: (value, data) => `https://api.whatsapp.com/send?phone=${data.telefono}`,
				filter:null,
				Cell:LinkCell,
				label:"Enviar"
			},
			
    ],
    []
	);

	

	
  

  return (
    <>
			<Header title="Clientes" />
			<CrudTable
       	data={data?.data || []}
				status={status}

				columns={columns}
				filters={columns}
        type={CUSTOMER}
        modal={Cliente}
        options={{loadBeforeEdit:true, orderBy:true}}
      />

    </>
  );
};

export default TablaClientes;
