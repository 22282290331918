export const frutasIcon = require("./frutas.png");
export const verdurasIcon = require("./verduras.png");
export const cerealesIcon = require("./cereales.png");
export const proteinasIcon = require("./proteinas.png");
export const grasasIcon = require("./grasas.png");
export const azucaresIcon = require("./azucares.png");
export const lacteosIcon = require("./lacteos.png");
export const leguminosasIcon = require("./leguminosas.png");

export const frutasCirIcon = require("./fr_cir.png");
export const verdurasCirIcon = require("./ver_cir.png");
export const cerealesCirIcon = require("./cer_cir.png");
export const proteinasCirIcon = require("./prot_cir.png");
export const grasasCirIcon = require("./grasa_cir.png");
export const azucaresCirIcon = require("./azu_cir.png");
export const lacteosCirIcon = require("./lac_cir.png");
export const leguminosasCirIcon = require("./leg_cir.png");
