import React, { useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { FormGroup, Button, TextareaAutosize, IconButton, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import PropTypes from "prop-types";
import Box from "components/Box/Box";
import TiempoComidaHorizontal from "../TiempoComidaHorizontal";
import ButtonAddTiempo from "../ButtonAddTiempo";
import useSkipFirstRender from "Hooks/useSkipFirstRender";

import "./DietaColumnas.scss";
const _ = require("lodash");


const Dieta = props => {
	
	const [dieta, setDieta] = useImmer(
		props.value
			? props.value
			: tiempos_comida
	);

	const debounceChange = React.useRef(
		_.debounce((d) => {
			props.onChange(d, "tiempos_comida");
		}, 1000)
	);
	useSkipFirstRender(() => {
		debounceChange.current(dieta);
	}, [dieta]);


	const addOption = (idx_tiempo, idx_columna) => {
		setDieta(draft => {
			draft[idx_tiempo].columnas[idx_columna].push("");
		});
	};
	const deleteOption = (idx_tiempo, idx_columna, idx_fila) => {
		console.log("PRE",dieta, dieta[idx_tiempo].columnas[idx_columna], idx_tiempo, idx_columna, idx_fila);
		setDieta(draft => {
			draft[idx_tiempo].columnas[idx_columna].splice(
				idx_fila,
				1
			);
		});
		console.log("POST",dieta, dieta[idx_tiempo].columnas[idx_columna], idx_tiempo, idx_columna, idx_fila);
	};

	const addColumn = (idx_tiempo, idx_columna) => {
		setDieta(draft => {
			draft[idx_tiempo].columnas.push([""]);
		});
	};

	const deleteColumn = (idx_tiempo, idx_columna) => {
		setDieta(draft => {
			draft[idx_tiempo].columnas.splice(idx_columna, 1);
		});
	};

	
	const change = (e, idx_tiempo, idx_columna, idx_fila) => {
		
		let v =  e.target.value;
		setDieta(draft => {
			if (idx_fila) {
				draft[idx_tiempo].columnas[idx_columna][idx_fila] = v;
			} else {
				draft[idx_tiempo].columnas[idx_columna][0] = v;
			}
		});
	};
	
	const addTiempoComida = (idx_tiempo) => {
		setDieta(draft => {
			draft.splice(idx_tiempo, 0, {
				nombre_tiempo: {
					name: "extra",
					label: "",
					img: "",
					color: "#A5DD72"
				},
				columnas: [[""]]
			});
		});
	};

	const deleteTiempo = (idx_tiempo) => {
		setDieta(draft => {
			draft.splice(idx_tiempo,1);
		});
	};

	const onChangeTiempoComida = (name, value, idx) => {
		setDieta(draft => {
			draft[idx].nombre_tiempo[name] = value;
		});
	};
	return (
		<>
			<FormGroup>

				<Box label="Dieta columnas" fullWidth>
					<div className="container-dieta-columnas printDiv">
						<div className="container-add-tiempo-comida avoid-print">
							<ButtonAddTiempo addTiempoComida={addTiempoComida} index={0} />
						</div>
						{dieta.map((dieta, idx_tiempo) => (
							<>
								<div className="break-end-page" key={dieta.nombre_tiempo.name} style={{display:"flex",minHeight:"150px", marginBottom:"20px"}}>
									{/* <div className="more-options">
										<IconButton onClick={()=>deleteTiempo(idx_tiempo)}>
											<Delete />
										</IconButton>
									</div> */}
									<TiempoComidaHorizontal tiempoComida={dieta} index={idx_tiempo} onChange={onChangeTiempoComida} del={deleteTiempo} />
									<div className="panelTiempo">
										{dieta?.columnas.map((columna, idx_columna) => (<>
											<div className="columna" key={idx_tiempo + idx_columna}>
												<div style={{height: "100%"}}>
											
													<div style={{fontWeight:"bold", display:"inline"}}>Columna {idx_columna+1}</div>
													{dieta.columnas.length > 1 && (
													// <IconButton size="small" style={{backgroundColor: "#D32F2F", borderRadius:"0", width:"15px", height:"15px", marginLeft:"10px"}} onClick={()=>deleteColumn(idx_tiempo, idx_columna)}>
														<div className="avoid-print">
															<ClearIcon  onClick={()=>deleteColumn(idx_tiempo, idx_columna)} fontSize="small" style={{color:"red"}} />
														</div>
													// </IconButton>
													)}
													<div className="elements-columna">

														<div style={{ marginTop: "10px" }}>
															<>
																{columna.map((comida, idx_fila) => (
																	<>
																		<div style={{display:"flex",marginBottom:"10px", alignItems:"center"}} key={idx_tiempo + idx_columna + idx_fila}>
																			<div className="allow-print">
																				<div className="columna-print">
																					{comida.split("\n").map((i, idx) => (
																						<div key={i + idx + idx_tiempo + idx_columna + idx_fila}>
																							<p className="print-text">{i}</p>
																						</div>
																					))}	
																				</div>
																			</div>
																			<TextareaAutosize
																				type="text"
																				value={comida}
																				placeholder="Escribe aquí"
																				onChange={e => {
																					change(e,idx_tiempo,idx_columna,idx_fila);
																				}}
																				className="text-area avoid-print"
																			/>
																			<div className="avoid-print">
																				<ClearIcon onClick={()=>deleteOption(idx_tiempo,idx_columna, idx_fila)} fontSize="small" style={{color:"red"}} />
																			</div>

																			{/* <IconButton size="small" style={{backgroundColor: "#D32F2F", borderRadius:"0", width:"25px", height:"25px", marginLeft:"10px"}} onClick={()=>deleteOption(idx_tiempo,idx_columna, idx_fila)}>
															<ClearIcon fontSize="small" style={{color:"#ffffff"}} />
														</IconButton> */}
																		</div>
																	</>
																))}
															</>
														</div>
													</div>
													<div className="avoid-print">
														<Button variant="contained" style={{backgroundColor:"#2D3C3A",color:"white"}} onClick={() => addOption(idx_tiempo, idx_columna)} >
											Anadir opción
														</Button>
													</div>
										
												</div>
												<div className="allow-print">
													<div style={{display: "flex", alignItems: "flexStart"}}>
														{idx_columna + 1 < dieta.columnas.length &&
																<div className="o">
																		+
																</div>
														}
													</div>
												</div>
											</div>
											<div className="allow-print">
												<div style={{display: "flex", alignItems: "flexStart"}}>
													{idx_columna + 1 < dieta.columnas.length &&
																<div className="o">
																		+
																</div>
													}
												</div>
											</div>
										</>
										))}
										<div className="avoid-print">
											<Button 
												color="primary" 
												variant="contained" 
												onClick={() => addColumn(idx_tiempo)}
												style={{color:"white"}}
											>
                Añadir Columna
											</Button>
										</div>
									</div>
								</div>
								<div className="container-add-tiempo-comida avoid-print">
									<ButtonAddTiempo addTiempoComida={addTiempoComida} index={idx_tiempo+1} />
								</div>
							</>
						))}
					</div>
				</Box>
			</FormGroup>
		</>
	);
};

export default Dieta;

Dieta.propTypes = {
	value: PropTypes.array, 
	onChange: PropTypes.func
};



var tiempos_comida = [
	{
		nombre_tiempo: {
			name: "desayuno",
			label: "Desayuno",
			img: "desayuno",
			color: "#F8D239"
		},
		columnas: [[""]]
	},
	{
		nombre_tiempo: {
			name: "colacion1",
			label: "Colación",
			img: "colacion1",
			color: "#52DEFF"
		},
		columnas: [[""]]
	},
	{
		nombre_tiempo: {
			name: "comida",
			label: "Comida",
			img: "comida",
			color: "#37FC98"
		},
		columnas: [[""]]
	},
	{
		nombre_tiempo: {
			name: "colacion2",
			label: "Colación",
			img: "colacion2",
			color: "#6077E9"
		},
		columnas: [[""]]
	},
	{
		nombre_tiempo: {
			name: "cena",
			label: "Cena",
			img: "cena",
			color: "#BD70D9"
		},
		columnas: [[""]]
	}
];