import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
const Receta = props => {
    const elements = React.useMemo(()=>[
    {
        type: "text",
        name: "nombre",
        label: "Nombre",
		},
		{
        type: "text",
        name: "descripcion",
        label: "Descripcion",
    },
    {
        type: "text",
        name: "codigo",
        label: "Codigo",
    },
    {
        type: "select",
        name: "tipo_pago",
        label: "Tipo de pago",
        options:[
          {label:"Anual",value:"year"},
          {label:"Mensual",value:"month"},
        ]
    },
    {
				type: "text",
				subtype: "number",
        name: "p_descuento",
        label: "% Descuento",
		},
		{
			type: "calendar",
			name: "fecha_inicio",
			label: "Fecha inicio",
		},
		{
			type: "calendar",
			name: "fecha_fin",
			label: "Fecha Fin",
		},
    {
        type: "bool",
        name: "solo_clientes_nuevos",
        label: "Solo usuarios nuevos",
    },
		{
				type: "text",
				subtype: "number",
        name: "max_usos_cliente",
        label: "Max usos cliente",
		},
		{
				type: "text",
				subtype: "number",
        name: "max_usos",
        label: "Max usos totales",
		}
  ],[]);

  return (
    <>
      <ModalForm elements={elements} onChange={props.onChange} data={props.data} {...props}></ModalForm>
    </>
  );
};

export default Receta;
