import React from "react";
import { useSelector } from "react-redux";
import DietaOpciones from "./DietaOpciones";
import CrudTable from "../../../components/Table/CrudTable/CrudTable";
import { OPTIONS_DIET } from "store/constants";
import AppView from "components/AppView/AppView";

const TabOpciones = props => {
	const data = useSelector(state => state[OPTIONS_DIET.name]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Nombre",
				accessor: "nombre"
			}
		],
		[]
	);
	const filters = React.useMemo(
		() => [
			
			{	
				Header: "Nombre",
				accessor: "nombre",
			}
		],
		[]
	);

	return (
		<>
			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				type={OPTIONS_DIET}
				modal={DietaOpciones}
				filters={filters}
				options={{ orderBy: true, saveAs:true, loadBeforeEdit:true}}
				fullWidth
				headerActions={[<AppView key="app_view" img="dieta-opciones" />]}
			/>
		</>
	);
};

export default TabOpciones;
