import React, { useEffect } from "react";
import { NavLink, generatePath } from "react-router-dom";
import { Button } from "@mui/material";

import "./Button.scss";

const ButtonComponent = props => {
	useEffect(() => {}, []);
	const style = {
		// width: props.width ? props.width : "100px" ,
		// height: props.height ? props.height : "100px"
	};


	return (
		<div style={style} className="button">
			{props.link && (
				<NavLink
					to={props.path}
					className="nav-button"
					activeClassName="selected-link"
					onClick={props.onClick}
				>
					<div className="image-nav-button">
						<div style={{width:"30px", textAlign:"center",marginRight:"10px"}}><img style={{height:"20px", objectFit:"contain",width:"auto",margin:"auto"}} src={props.image} alt={props.text} /></div>
						<p style={{fontSize:"1em"}}>{props.text}</p>
					</div>
				</NavLink>
			)}
			{props.button && (
				<Button onClick={props.onClick} style={props.style}>
					{props.text}
				</Button>
			)}
		</div>
	);
};

export default ButtonComponent;
