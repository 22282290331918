import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import {CUSTOMER} from "store/constants";

const Premio = props => {
	const columnsClientes = React.useMemo(
    () => [
      {
        Header: "Clave",
        accessor: "clave"
      },
      {
        Header: "Nombre",
        accessor: "nutritionist.nombre"
      },
      {
        Header: "Correo",
        accessor: "nutritionist.correo"
      },
    ],
    []
  );
	const elements = [
		{
			type:"multiSelect",
			name:"clientes",
			label: "Selecciona clientes (opcional)",
			datatype:CUSTOMER,
			columns:columnsClientes,
			filters:columnsClientes,
			remote:true,
		},
		{
			type:"text",
			name:"asunto",
			label:"Asunto"
		},
		{
			type:"text",
			name:"mensaje",
			label:"Mensjae",
			multiline:true
		},
		{
			type:"text",
			name:"enlace",
			label:"Enlace"
		},
		{
			type: "file",
			name: "archivos",
			label: "Imagen",
	},
	];
	console.log({props})
	return (
		<>
			<ModalForm elements={elements} onChange={props.onChange} data={props.data} onFile={props.onFile}></ModalForm>
		</>
	);
};

export default Premio;