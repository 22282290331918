import React,{useEffect} from "react";
import BaseTable from "../BaseTable/BaseTable";
import { useSelector, useDispatch } from "react-redux";
import {Checkbox, CircularProgress} from "@mui/material";
import PropTypes from "prop-types";
import { useImmer } from "use-immer";
import {crudFunctions} from "api";
import { useQuery } from 'react-query'
import "./MultiSelectTable.scss";

export default function MultiSelectTable(props) {
	const { onChange, columns, type, options, selected, onSelect, onUnselect, remote } = props;
	const actions = crudFunctions(type);
	const [filters, setFilters] = React.useState();
	const [sort, setSorting] = React.useState();
	const [page, setPage] = React.useState();
	let { data } = props;
	const selectorData = useSelector(state => state[type.name]);
	const dispatch = useDispatch();
	const [selectedIds, setSelectedIds] = useImmer(new Set(selected));

	const {data:remoteData} = useQuery([type.name, {page, filter:filters, sort}], actions.getPaginated, {keepPreviousData:true});

	if(type && !data){
		data = selectorData.data;
	}
	const regularData = React.useMemo(()=>data?.filter(d=>!d._trash),[data]);


	// data = data.filter(d=>!d._trash);

	const retry = () => {
		dispatch({type:`LOAD_${type.name}`});
	};
	const onLocalSelect = cell => event => {
		event.persist();
		setSelectedIds(draft=>{
			if(event.target.checked){
				onSelect && onSelect({data:cell.row.original,index:cell.row.index});
				draft.add(cell.row.original._id);
			}else{
				onUnselect && onUnselect({data:cell.row.original,index:cell.row.index});

				draft.delete(cell.row.original._id);
			}
		});
	};
	const onGlobalSelect = async (event, table) => {
		const checked = event.target.checked;
		event.persist();
		let set;
		if(remote){
			const ids = await actions.getIds({search:filters});
			set=new Set([...ids.data]);
		}

		
		setSelectedIds(draft=>{
			if(checked){
				if(remote){
					return set;
				}
				return new Set([...table.data.map(d=>d._id)]);


				// if(table.preFilteredRows.length===table.filteredRows.length){
				// 	draft.clear();
				// 	draft.add("*");
				// }
				// else{
				// 	return new Set([...draft, ...table.filteredRows.map(row=>row.original._id)]);
				// }

			}else{
				draft.clear();
			}
		});
	};


	useEffect(()=>{
		// console.log("selected size",selectedIds.size);
		onChange && onChange([...selectedIds]);
	},[selectedIds]);

	const actionsColumn = {
		Header:function Header(table){
			return <Checkbox
				onChange={(e)=>onGlobalSelect(e, table)}
				checked={selectedIds.size===table.totalRows}
				color="secondary"
				inputProps={{ "aria-label": "secondary checkbox" }}
			/>;
		},
		Cell: function SelectCheckbox({ cell }){
			return <>
				<Checkbox
					checked={selectedIds.has("*") || selectedIds.has(cell.row.original._id)}
					onChange={onLocalSelect(cell)}
					color="secondary"
					inputProps={{ "aria-label": "secondary checkbox" }}
				/>
				
			</>;
		},
		accessor: "id",
		id: "actions",
		disableSortBy: true,
		disableFilters: true
	};

	const finalColumns = React.useMemo(()=>[actionsColumn, ...columns]);

	return (
		<>
			{selectorData?.view?.status==="LOAD_FAILED" 
				?
				<div>LOAD FAILED<button onClick={retry}>Retry</button></div>
				:
				selectorData?.view?.status==="LOADING" 
					? 
					<CircularProgress />
					:
					<>
						{remote ? <BaseTable
								columns={finalColumns}
								// data={regularData}
								data={remoteData?.data||[]}
								options={options}
								filters={props.filters}
								onFilterChange={setFilters}
								onSortChanged={setSorting}
								onPaginationChange={setPage}

								totalRows={remoteData?.total}
								manualPagination
							/>
							:
							<BaseTable
								columns={finalColumns}
								data={regularData}
								options={options}
								filters={props.filters}
							/>
						}
					</>
					
			}
		</>
	);
}

MultiSelectTable.propTypes = {
	onChange: PropTypes.func,
	columns: PropTypes.array,
	type: PropTypes.object,
	options: PropTypes.object,
	selected: PropTypes.array,
	onSelect: PropTypes.func,
	onUnselect: PropTypes.bool,
	data: PropTypes.array,
	filters: PropTypes.array
};