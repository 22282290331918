import React, {useState} from "react";
import { useSelector } from "react-redux";
import { CUSTOMER } from "store/constants";

export default function useListaAlimentos() {
	const customer = useSelector(state => state[CUSTOMER.name].data);	
	const composicion = Object.entries(customer?.nutritionist?.tab_comp_alim||{}).filter(([, val])=>val).map(([key])=>key);

	const [listas, setListas] = useState([]);
	const [alimentos, setAlimentos] = useState([]);
  React.useEffect(() => {
    // Function to fetch data for each item in composicion and update state
    const fetchData = async () => {
      try {
				console.log({composicion})
        // Fetch data for each composition
        const promises = composicion.map(c => fetch("/listas/"+c + ".json").then(response => response.json()));
        // Await all fetches and set data state
        const results = await Promise.all(promises);
				setAlimentos(results.flatMap((_)=>_) )
        setListas(results);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, [customer]); // Dependency array ensures this runs only when composicion changes
	return {listas, alimentos}
}