import React, {useState} from "react";
import { Button } from "@mui/material";
import Modal from "components/Modal/Modal";
import PropTypes from "prop-types";

const ModalButton = props => {
	const [openedModal, setOpenModal] = useState(false);
	// console.log({props})
	return (
		<>
			<div style={{textAlign:"right", ...props.style}}>
				<div onClick={()=>setOpenModal(true)} color={props.color||"secondary"} {...props.style}>
					{props.label}
				</div>
			</div>

			<Modal 
				{...props.options} 
				open={openedModal}
				fullWidth={props.fullWidth} 
				onClickClose={()=>{
					setOpenModal(false);
				}}>
				<props.modal {...props.modalProps} closeModal={()=>setOpenModal(false)} />
			</Modal>
			

		</>
	);
};

export default ModalButton;

ModalButton.propTypes = {
	type: PropTypes.object,
	options: PropTypes.object,
	fullWidth: PropTypes.bool,
	parent_id: PropTypes.string,
	onInsert: PropTypes.func,
	modal: PropTypes.func,
	status: PropTypes.string
};
