import React, { useEffect } from "react";
import { Button, DialogTitle, Tooltip, IconButton } from "@mui/material";
import Box from "components/Box/Box";
import { useTranslation } from "react-i18next";
import i18n from "i18n";
import "./DietaMenus.scss";
import {useDialog} from "context/DialogContext";
import DragAndDropTable from "components/Table/DragAndDropTable/DragAndDropTable";
import editarIcon from "assets/img/v2/icons/components/editar.png";
import eliminarIcon from "assets/img/v2/icons/components/delete.png";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";

import AssignIntercambios from "./AssignIntercambios";
import ChartMacronutimentos from "./ChartMacronutrimentos";
import AddComida from "./AddComida";
import Micronutrimentos from "./Micronutrimentos";
import ChartGrupos from "./ChartGrupos";
import styled from "styled-components"
import chartIcon from "assets/img/charts.png";
import editIcon from "assets/img/edit.png";
import IconEq from "../IconEq";
var shortid = require("shortid");
const macros = [
	{ color:"#E77067", label: i18n.t("common:proteinas"), name:"prot", unit: "gr" },
	{ color:"#F7D542", label: i18n.t("common:lipidos"), name:"lip", unit: "gr" },
	{ color:"#7BAAFB", label: i18n.t("common:hidratos_carbono"), name:"hidr", unit: "gr" },
	{ color:"#3B4C4B", label: i18n.t("common:dieta_menu_select_energia"), name:"kcal", unit: "kcal" }
];

	
const dias = [
	{ name: "monday", label: i18n.t("common:modal_dias_laborales_lunes") },
	{ name: "tuesday", label: i18n.t("common:modal_dias_laborales_martes") },
	{ name: "wednesday", label: i18n.t("common:modal_dias_laborales_miercoles") },
	{ name: "thursday", label: i18n.t("common:modal_dias_laborales_jueves") },
	{ name: "friday", label: i18n.t("common:modal_dias_laborales_viernes") },
	{ name: "saturday", label: i18n.t("common:modal_dias_laborales_sabado") },
	{ name: "sunday", label: i18n.t("common:modal_dias_laborales_domingo") },
];

function AgregarAlimento({onClose, extraInfo, dieta, alimentos:alimentosProp, disableIntercambios}){
	const {t} = useTranslation("common");
	const [openDialog, closeDialog] = useDialog();

	const [alimentos, setAlimentos] = React.useState(alimentosProp || []);
	const [editValue, setEditValue] = React.useState({});
	const [chartData, setChartData] = React.useState({});

	useEffect(() => {
		const reduc = alimentos.reduce((acc,alimento)=>{
			const prot = isNaN(alimento.prot) ? 0 : alimento.prot;
			acc.prot += prot;
			const lip = isNaN(alimento.lip) ? 0 : alimento.lip;
			acc.lip += lip;
			const hidr = isNaN(alimento.hidr) ? 0 : alimento.hidr;
			acc.hidr += hidr;
			acc.kcal += alimento.kcal;
			return acc;
		},{kcal: 0, prot: 0, lip: 0, hidr: 0});
		setChartData(reduc);
	}, [alimentos]);

	const agregar = (alimento) => {
		setAlimentos(a=>[...a, alimento]);
		// setCurrent({id: "", alimento: ""});
	};
	
	const guardar = () => {
		console.log({onClose})
		onClose(alimentos.map(a=>({id: a._id || a.id, eq:a.eq, nombre:a.nombre, categoria: a.categoria, cant: a?.cant, kcal:a.kcal, prot:a.prot, lip:a.lip, hidr:a.hidr, equiv:a.equiv, unidad: a.unidad, gram: a.gram, shortid:a.shortid, alimentos:a.alimentos, tags:a.tags})));
		// onClose(alimentos.map(a=>({id: a.id, nombre:a.nombre, categoria: a.categoria, cant: a.cant, custEquiv: a.custEquiv, kcal:a.kcal, prot:a.prot, lip:a.lip, hidr:a.hidr, equiv:a.equiv, unidad: a.unidad, gram: a.gram})));
	};
	const editar = (cell) => {
		setEditValue(cell.row.original);
		eliminar(cell);
	};
	const eliminar = (cell) => {
		
		setAlimentos(alimentos=>alimentos.filter(a=>a.id !== cell.row.original.id));
	};
	const equivalencias = (cell) => {
		openDialog({
			onClose:(equiv)=>{
				if(equiv){
					setAlimentos(alimentos=>alimentos.map(a=>{
						if(a.id===cell.row.original.id){
							
							return {...a, equiv};
						}else{
							return a;
						}
					}));
				}
			},
			Content: AssignIntercambios,
			alimentos: cell.row.original,
			modalEquivalencias:true,
			width:"md"
		});
	};
	const micronutrientes = (cell) => {
		openDialog({
			onClose:()=>{},
			Content: Micronutrimentos,
			alimento: cell.row.original,
			width:"sm",
			options: {chart: {legend: {show: true, horizontalAlign: "left"}}}
		});
	};
	
	const tableRows = alimentos//React.useMemo(()=>alimentos.map(a=>({...a})),[alimentos]);

	console.log({alimentos})
	return <>
		<div style={{float:"right",marginTop:"-10px"}}>
			<IconButton onClick={()=>onClose(null)}><CloseIcon /></IconButton>
		</div>
		{extraInfo &&<p style={{fontWeight: "bold", textAlign: "center", fontSize: "18pt", marginBottom: "10px"}}>{dias[extraInfo.idx_dia].label} / {dieta[extraInfo.idx_tiempo].nombre_tiempo.label}</p>}
		
		<Container>
			<div>
				<AddComida newButtonsVariant="outlined" onSave={agregar} editValue={Object.keys(editValue).length > 0 ? editValue : undefined} />

				<p style={{marginTop: 30, marginBottom: 5, textAlign: "center", fontWeight: "bold", fontSize: "15pt"}}>{t("dieta_menu_select_dieta_alimentos_seleccionados")}</p>
				<DragAndDropTable onChange={setAlimentos} data={tableRows} columns={[
					{Header: t("nombre"), accessor: "nombre"}, 
					
					// eslint-disable-next-line react/display-name
					{Header: t("equivalentes_equivalencias_cantidad"), Cell: ({cell})=><>{cell.row.original?.cant}</>},
					// eslint-disable-next-line react/display-name
					{Header: t("equivalentes_equivalencias_unidad"), Cell: ({cell})=><>{cell.row.original.unidad ?? ""}</>},
					{
						Header: "Equivalencia", 
						// eslint-disable-next-line react/display-name
						Cell: ({cell})=> <IconEq eq={cell.row.original.eq} size="md" />
					},

					// {Header: t("dieta_menu_select_dieta_intercambios"), Cell:({cell})=>cell.row.original.equiv ? <>{cell.row.original.equiv.map(val=>val.nombre).join("; ")}</> : <></>}, 
					// eslint-disable-next-line react/display-name
					{Header: t("dieta_menu_select_dieta_acciones"), Cell:({ cell })=><div style={{display: "flex", gap:5, alignItems: "center", justifyContent: "center"}}>
						{/* {!disableIntercambios && <Button size="small" style={{fontSize: "8pt", whiteSpace: "nowrap"}} onClick={()=>equivalencias(cell)} variant="outlined" color="secondary">{t("dieta_menu_select_dieta_anadir_intercambios")}</Button>} */}
						<Tooltip title={t("dieta_menu_select_ver_nutrientes")}>
							<img src={chartIcon} style={{height:20, objectFit:"contain", padding:"0 1px 0 1px", color:"white", borderRadius:3}} onClick={()=>micronutrientes(cell)} />
						</Tooltip>
						<Tooltip title={t("editar")}>
							<img src={editIcon} style={{width: 20, height: 20, padding:"0 1px 0 1px", color:"white", borderRadius:3}} onClick={()=>editar(cell)} />
						</Tooltip>
						<Tooltip title={t("eliminar")}>
							{/* <img src={eliminarIcon} style={{width: 25, height: 25,  padding:"0 1px 0 1px", color:"white", borderRadius:3, marginLeft:5}} onClick={()=>eliminar(cell)} /> */}
							<ClearIcon style={{width: 25, height: 25,  padding:"0 1px 0 1px", color:"red", borderRadius:3}} onClick={()=>eliminar(cell)} />
						</Tooltip>
						{/* <Button onClick={()=>editar(cell)}>Editar</Button> */}
						{/* <Button onClick={()=>eliminar(cell)}>Eliminar</Button> */}
					</div>}
				]}
				/>
				
			</div>
			<div>
				<div className="chart" style={{paddingLeft: "10px", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
					<p style={{fontWeight: "bold", fontSize: "12pt"}}>{t("dieta_menu_select_distribucion_macros")}</p>
					<div style={{marginBottom: 20}}>
						<p style={{fontSize: "16pt", fontWeight: "bold", marginBottom: "10px"}}>{chartData.kcal?.toFixed(2)} Kcal</p>
						<div style={{marginBottom: "10px"}}>
							<p style={{color: "#E77067", fontWeight: "bold"}}>Proteinas</p>
							<p style={{fontWeight: "bold"}}>{chartData.prot?.toFixed(2)} gr</p>
						</div>
						<div style={{marginBottom: "10px"}}>
							<p style={{color: "#F7D542", fontWeight: "bold"}}>Lípidos</p>
							<p style={{fontWeight: "bold"}}>{chartData.lip?.toFixed(2)} gr</p>
						</div>
						<div style={{marginBottom: "10px"}}>
							<p style={{color: "#7BAAFB", fontWeight: "bold"}}>Hidratos de carbono</p>
							<p style={{fontWeight: "bold"}}>{chartData.hidr?.toFixed(2)} gr</p>
						</div>
					</div>
					<ChartMacronutimentos data={{pieChartData: chartData}} />
				</div>
			</div>


		</Container>
		<div style={{textAlign: "right", marginTop: "10px"}}>
			<Button style={{marginRight:10}} variant="outlined" color="secondary" onClick={()=>onClose()} >{t("cancelar")}</Button>
			<Button onClick={guardar} variant="contained" color="secondary" style={{marginRight: 5}}>{t("guardar")}</Button>

		</div>
		
	</>;
}

export default AgregarAlimento;

const Container = styled.div`
	display:flex;
	gap:50px;
	margin:auto;
	justify-content:center;
`;