import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { TUTORIAL } from "../constants";

const loadTutorials = loadRequest(TUTORIAL);
const insertTutorial = insertRequest(TUTORIAL);
const updateTutorial = updateRequest(TUTORIAL);
const trashTutorial = trashRequest(TUTORIAL);
const untrashTutorial = untrashRequest(TUTORIAL);
const removeTutorial = removeRequest(TUTORIAL);

function* rootSaga() {
	yield all([
		loadTutorials(),
		insertTutorial(),
		updateTutorial(),
		removeTutorial(),
		trashTutorial(),
		untrashTutorial()
	]);
}
export default rootSaga;