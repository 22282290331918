
import { call, put } from "redux-saga/effects";
import api from "api";
import { takeEvery, takeLatest, all } from "redux-saga/effects";
import { LOAD, INSERT, UPDATE, REMOVE } from "../constants";
import {
	LOADING,
	LOADED,
	LOAD_FAILED,

	INSERTING,
	INSERT_FAILED,
	INSERTED,
	UPDATING,
	UPDATED,
	UPDATE_FAILED,
	DELETING,
	DELETE_FAILED,
	TRASH,
	UNTRASH
} from "../constants";
const { post, get, put: update, remove, upload } = api;
export const loadRequest = (type) =>
	function* load() {
		const name = type.tail_name ? `${type.name}_${type.tail_name}` : type.name;

		yield takeLatest(LOAD + name, function* load(action) {
			const status = makeStatusFromType(type);
			try {
				yield put({ type: status, payload: {status: LOADING} });
				const url = `${type.url}/${action.payload.parent_id}/${type.url_tail}`;

				const response = yield call(get, url);
				yield put({ type: "SET_" + name, payload: response.data });
				yield put({ type: status, payload: {status: LOADED} });
			} catch (e) {
				console.error(e);
				yield put({ type: status, payload: {status: LOAD_FAILED} });
			}
		});
	};
export const insertRequest = (type) =>
	function* insert() {
		const name = type.tail_name ? `${type.name}_${type.tail_name}` : type.name;

		yield takeEvery(INSERT + name, function* insert(action) {
			const status = makeStatusFromType(type);
			try {
				yield put({ type: status, payload: {status: INSERTING} });
				const {files, ...payload} = action.payload;
				const url = `${type.url}/${action.payload.parent_id}/${type.url_tail}`;

				let response = yield call(post, url, payload);
				if(files && files.length>0){
					response = yield all(files.map((file) => call(upload, `${type.url}/${response.data.data._id}/${file.name}`, file.file)));
					response = response[response.length-1];
				}
				yield put({ type: "UPDATE_L_" + type.name, payload: response.data.data });

				yield put({ type: status, payload: {status:INSERTED, id:response.data.data._id} });
				if(action.callback){
					yield call(action.callback,response);
				}
			} catch (e) {
				yield put({ type: status, payload: {status: INSERT_FAILED, errorMsg: e} });
				if(action.errorCallback){
					yield call(action.errorCallback,e);
				}
			}
		});
	};


export const updateRequest = (type) =>
	function* insert() {
		const name = type.tail_name ? `${type.name}_${type.tail_name}` : type.name;
		yield takeEvery(UPDATE + name, function* insert(action) {
			const status = makeStatusFromType(type);
			const url = `${type.url}/${action.payload.parent_id}/${type.url_tail}/${action.payload._id}`;
			try {
				yield put({ type: status, payload: {status: UPDATING} });
				const {files, ...payload} = action.payload;
				let response = yield call(update, url, payload);
				console.log(response);
				if(files && files.length>0){
					response = yield all(files.map((file) => call(upload, `${url}/${file.name}`, file.file)));
					response = response[response.length-1];
				}
				yield put({ type: "UPDATE_L_" + type.name, payload: response.data.data });
				yield put({ type: status, payload: {status: UPDATED, id: response.data.data._id} });
				if(action.callback){
					yield call(action.callback,response);
				}
			} catch (e) {
				yield put({ type: status, payload: {status: UPDATE_FAILED, errorMsg: e} });
				if(action.errorCallback){
					yield call(action.errorCallback,e);
				}
			}
		});
	};
export const trashRequest = (type) =>
	function* trash() {
		const name = type.tail_name ? `${type.name}_${type.tail_name}` : type.name;

		yield takeEvery(TRASH + name, function* trash(action) {
			const status = makeStatusFromType(type);
			console.log(action.payload._id);
			const url = `${type.url}/${action.payload.parent_id}/${type.url_tail}/${action.payload._id}`;
			console.log(url);

			try {
				yield put({ type: status, payload: {status: UPDATING} });

				const payload = {_trash: true};
				const response = yield call(update, url, {...payload});
				yield put({ type: "UPDATE_L_" + type.name, payload: response.data.data });
				yield put({ type: status, payload: {status: LOADED} });
			} catch (e) {
				console.log(e);

				yield put({ type: status, payload: {status: UPDATE_FAILED} });
			}
		});
	};
export const untrashRequest = (type) =>
	function* insert() {
		const name = type.tail_name ? `${type.name}_${type.tail_name}` : type.name;

		yield takeEvery(UNTRASH + name, function* insert(action) {
			const status = makeStatusFromType(type);
			const url = `${type.url}/${action.payload.parent_id}/${type.url_tail}/${action.payload._id}`;
			try {
				yield put({ type: status, payload: {status: UPDATING} });
				const payload = {_trash: false};
				const response = yield call(update, url, payload);
				yield put({ type: "UPDATE_L_" + type.name, payload: response.data.data });
				yield put({ type: status, payload: {status: LOADED} });
			} catch (e) {
				yield put({ type: status, payload: {status: UPDATE_FAILED} });
			}
		});
	};
export const removeRequest = (type) =>
	function* insert() {
		const name = type.tail_name ? `${type.name}_${type.tail_name}` : type.name;

		yield takeEvery(REMOVE + name, function* insert(action) {
			const status = makeStatusFromType(type);
			const url = `${type.url}/${action.payload.parent_id}/${type.url_tail}/${action.payload._id}`;
			try {
				yield put({ type: status, payload: {status: DELETING} });
				const response = yield call(remove, url);
				yield put({ type: "UPDATE_L_" + type.name, payload: response.data.data });

				// yield put({ type: "UNSET_" + name, payload: { _id:action.payload._id, parent_id:action.payload.parent_id } });
				yield put({ type: status, payload: {status: LOADED} });
			} catch (e) {
				yield put({ type: status, payload: {status: DELETE_FAILED} });
			}
		});
	};



export const makeStatusFromType = (type) => type.base_name.toUpperCase() + "_STATUS";
export default {loadRequest,insertRequest,updateRequest,trashRequest,untrashRequest,removeRequest};