import React from "react";
import PropTypes from "prop-types";
import { Card, Typography } from "@mui/material";

export default function Box(props) {
	return (
		<Card
			variant="outlined"
			padding="20px"
			className={props.className}
			style={{
				height: "100%",
				background: "#F6F6F6",
				padding: "20px",
				margin:props.fullWidth?"unset":"auto",
				...props.style
			}}>
			<Typography variant="h5">{props.index} {props.label}</Typography>
			<Typography variant="subtitle1">
				{props.sublabel}
			</Typography>
			<Card
				variant="outlined"
				padding="20px"
				style={{ height: "calc(100% - 30px)", padding: "20px", width:"100%"}}>
				{props.children}
			</Card>
		</Card>
	);
}
Box.propTypes = {
	fullWidth: PropTypes.bool,
	label: PropTypes.string,
	subLabel: PropTypes.string,
	style: PropTypes.object,
	children: PropTypes.element.isRequired
};