import React from "react";
import PropTypes from "prop-types";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";

const CategoriaComidaPreparada = props => {
	const elements = React.useMemo(()=>[
		{
			type: "text",
			name: "nombre",
			label: "Nombre",
		}
	],[]);
	return (
		<>
			<ModalForm elements={elements} onChange={props.onChange} data={props.data}></ModalForm>
		</>
	);
};
export default CategoriaComidaPreparada;

CategoriaComidaPreparada.propTypes = {
	onChange: PropTypes.func,
	data: PropTypes.object
};