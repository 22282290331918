import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import {TUTORIAL} from "store/constants"
const Receta = props => {
	// seccion: Joi.string(),
	// subseccion: Joi.string(),
	// nombre:Joi.string(),
	// descripcion:Joi.string(),
	// video:fileModel,
	// relacionados:Joi.array(),
	// siguiente:Joi.string(),
	// anterior:Joi.string(),
    const elements = React.useMemo(()=>[
			{
				type: "select",
				name: "seccion",
				label: "Seccion",
				options:[
					{label:'Inicio',value:"inicio"},
					{label:'Guia de inicio',value:"guia_inicio"},
					{label:'Pacientes',value:"pacientes"},
					{label:'Consultas',value:"consultas"},
					{label:'Mensajes',value:'mensajes'},
					{label:'Citas',value:'citas'},
					{label:'Dietas general',value:'dietas'},
					{label:'Recetas',value:'recetas'},
					{label:'Productos',value:'productos'},
					{label:'Tienda',value:'tiendas'},
					{label:'Lista de compras',value:'lista_compras'},
					{label:'Configuracion',value:'configuracion'},
					{label:'Logros',value:'logros'},
					{label:'Selecciona una opción',value:""},
					{label:'Dieta seleccion alimentos',value:"dieta_seleccion"},
					{label:'Dieta equivalencias',value:'dieta_equivalencias'},
					{label:'Dieta smae',value:'dieta_smae'},
					{label:'Dieta menus',value:'dieta_menus'},
					{label:'Dieta columnas',value:'dieta_columnas'},
					{label:'Dieta opciones',value:'dieta_opciones'},
					{label:'Dieta personalizada',value:'dieta_personalizada'}
				],
		},
	// 	{
	// 		type: "select",
	// 		name: "subseccion",
	// 		label: "Subseccion",
	// 		options:[
				
	// 		],
	// },
    {
        type: "text",
        name: "nombre",
        label: "Nombre",
    },
    {
        type: "text",
        name: "descripcion",
        label: "Descripción",
    },
		{
			type: "text",
			name: "duracion",
			label: "Duracion",
		},
		{
			type: "text",
			subtype:"number",
			name: "orden",
			label: "Orden",
		},
		{
			type: "file",
			name: "image",
			label: "Img"
		},
		{
			type: "text",
			name: "video",
			label: "Video",
		},
		{
			type: "text",
			name: "link",
			label: "Link",
		},
		{
			type:"multiref",
			name:"relacionados",
			label:"Relacionados",
			selector:(state)=>state[TUTORIAL.name].data
		},
		// {
		// 	type:"ref",
		// 	name:"siguiente",
		// 	label:"Siguiente",
		// 	selector:(state)=>state[TUTORIAL.name].data
		// },
		// {
		// 	type:"ref",
		// 	name:"anterior",
		// 	label:"Anterior",
		// 	selector:(state)=>state[TUTORIAL.name].data
		// }

  ],[]);

  return (
    <>
      <ModalForm elements={elements} onChange={props.onChange} data={props.data} {...props}></ModalForm>
    </>
  );
};

export default Receta;
