import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import PropTypes from "prop-types";

const Categoria = props => {
	const elements = [
		{
			type: "text",
			name: "nombre",
			label: "Nombre",
		},
		{
			type: "color",
			name: "color",
			label: "Color",
		}
	];
	return (
		<>
			<ModalForm elements={elements} onChange={props.onChange} data={props.data}></ModalForm>
		</>
	);
};

export default Categoria;

Categoria.propTypes = {
	onChange: PropTypes.func,
	data: PropTypes.object
};