import React from "react";
import styled from "styled-components";
// import ProgressBar from "@ramonak/react-progress-bar";

const PB = styled.div`
	position: relative;
	height: 25px;
	width: 100%;
	border-radius: 50px;
	border: 1px solid transparent;
	background-color: #D6E1CD;    
	overflow:hidden;
	`;

const Filler = styled.div`
	background: ${props=>isFinite(props.percent) && props.percent > 100 ? "#CA3F34" : props.color};
	box-shadow: inset 0px 1px 3px;
	height: 100%;
	border-radius: 50px;
	transition: width .2s ease-in;
	width: ${(props)=>isFinite(props.percent) ? props.percent : 0}%;
	max-width:100%;
	`;
const Label = styled.p`
	width: ${(props)=>isFinite(props.percent) ? props.percent : 0}%;
	position:absolute;
	font-size:.7em;
	height: 100%;
	line-height:20px;
	max-width:100%;
	color: white;
	padding: 0 5px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	
	// display: flex;
	// align-items: center;
	text-align: right;
	// justify-content: flex-end;
`;

const ProgressText = styled.p`
	color: ${props => props.max < 0 && "#CA3F34"};
	font-size: 10pt;
	/* width: 30%; */
	font-weight: normal;
	margin-left: 2px;
`;
	
const ProgressBar = ({percent, color}) => {
	return (
		<PB>
			<Label percent={percent}>{isFinite(percent) ? percent.toFixed(1) : 0}%</Label>
			<Filler percent={percent} color={color} />
		</PB>
	);
};

export default function Chart({value, color, size, label, max, unit}){
	return <div className="avoid-print" style={{width:"100%", marginBottom: "5px"}}>
		<div style={{display: "flex", justifyContent: "space-between", alignItems: "center", margin: "6px 0 2px 0"}}>
			<p style={{fontSize: "11pt", fontWeight: "bold"}}>{label}</p>
			<ProgressText max={max-value}>{`${value.toFixed(2)}/${Number(max).toFixed(2)}${unit}`}</ProgressText>
		</div>
		<div style={{display: "flex", alignItems: "center"}}>
			<ProgressBar percent={value/max*100} color={color} labelSize={size==="sm" && "10px"} />
		</div>
	</div>;
}

