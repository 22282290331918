import React, { useState, useEffect } from "react";
import TabPanel from "components/TabPanel";
import { useHistory, useRouteMatch, generatePath } from "react-router-dom";
import styles from "Styles.module.scss";
import { useWindowSize } from "Hooks/index";
import PropTypes from "prop-types";
import styled from "styled-components";

export default function MyTabs(props){
	const {tabs, defSelected, urlKey, root, icon} = props;
	let match = useRouteMatch();
	let history = useHistory();
	const {innerWidth} = useWindowSize();
	const [selected, setSelected] = useState(defSelected ? Number(defSelected) : 0);


	const handleChange = (v) => {

		if(props.disableUrlParams){
			setSelected(v);
			return;
		}
		let prevParams = props.keys ? props.keys.reduce((acc, val)=>{
			return {...acc, [val]: match.params[val] ? match.params[val] : 0};
		},{}) : {};

		prevParams[urlKey] = v;

		const path = generatePath(match.path, prevParams);

		history.push(path);


	};

	useEffect(()=>{
		props.onChangeTab && props.onChangeTab(selected);
	},[selected]);

	useEffect(()=>{

		if (!props.disableUrlParams) {
			if(!match.params[urlKey]){
				// handleChange(0);
			}
	
			if(!match.params[urlKey]){
				if(!props.disableUrlParams){
					setSelected(0);
				}
			} else {
				setSelected(+match.params[urlKey]);
			}
		}



	},[match.params]);
	const Component = React.useMemo(()=>props.tabs.find((t, idx)=>idx===selected) || A(),[selected, props.tabs]);
	const EndComponent = React.useMemo(()=>props.tabs.find((t, idx)=>idx===selected)?.endTabComponent || A,[selected, props.tabs]);
  
	return <TabContainer>
		<h4 className="title_section">{props.title}</h4>

		<Tabs>
			<TabGroup>
				{tabs.map((tab, idx)=><>
					<Tab style={{height:root?"90px":"60px", fontWeight:"bold"}} key={tab.label} label={tab.label} selected={selected} idx={idx} onClick={()=>handleChange(idx)} />
				</>)}
			</TabGroup>
			<div style={{marginBottom:15}}>{EndComponent}</div>
			{/* <LongLine /> */}
		</Tabs>
		<ComponentContainer>
			<Component.component {...Component.props} propsForAll={props.propsForAll} />
		</ComponentContainer>

		
	</TabContainer>;
}
function Tab({label, selected, idx, onClick}){
	return <Step>
		<div selected={selected===idx} onClick={onClick}>
			<Label selected={selected===idx}>{label}</Label>
		</div>
		{selected===idx && <ArrowImg />}

		
	</Step>;
}
function A(){
	return {
		component: ()=><></>
	};
}
const TabContainer = styled.div`
	display:flex;
	flex-direction:column;
	width:100%;
`;
const ComponentContainer = styled.div`
  // margin-left:25%
	width:100%;
	// padding:10px;
`;
const TabGroup = styled.div`
	list-style-type: none;
	margin: 0;
	padding: 0;
	// width: 25%;
	// position: fixed;
	height: 100%;
	overflow: auto;
	// padding:20px;
	min-width:200px;
	font-size:.9em;
	position:relative;
	overflow:hidden;
	flex-direction:row;
	gap:20px;
	display:flex;

`;
const Tabs = styled.div`
	list-style-type: none;
	margin: 0;
	padding: 0;
	// width: 25%;
	// position: fixed;
	height: 100%;
	overflow: auto;
	// padding:20px;
	min-width:200px;
	font-size:.9em;
	position:relative;
	overflow:hidden;
	flex-direction:row;
	display:flex;

	justify-content: space-between;
	align-items: center;

	// align-items: baseline;
  height: 40px;
	border-bottom:2px solid #f5f6f6;
	margin-top:10px;
	overflow:visible;
	margin-bottom:10px;
	

`;
const Label = styled.div`
	color: rgba(59, 76, 75, .84);
	${props=>props.selected && "color:#3B4C4B;"}
	${props=>props.selected && "font-weight:bolder;"}
	font-size:1.4em;
	white-space:nowrap;
	// margin-bottom:10px;
`;
const Title = styled.div`
	font-weight:bold;
	font-size:1em;
	margin-bottom:10px;
`;


const Columns = styled.div`
	flex-direction:row;
	display:flex;
	align-items:flex-start;
	position:absolute;

	@media (max-width: 1100px) {
		display:none;
	}
`
const Step = styled.div`
	display:flex;
	flex-direction:column;
	position:relative;
	line-height:40px;
	margin-right:20px;
	cursor:pointer;

`;
const LongLine = styled.div`
	width:100%;
	background: #DEDFDF;
	position: absolute;
	bottom:0;
	height:2px;
`;
const Statuses = styled.div`
	flex-direction:column;
	display:flex;
	align-items: center;
	margin-left:20px;
	margin-top:40px
`
const ArrowImg = styled.div`
	position: absolute;
	left: 0px;
	bottom: 0;
	width: 100%;
	height: 3px;
	background: #3B4C4B;
	z-index: 9;
`;



MyTabs.propTypes = {
	children: PropTypes.element,
	tabs: PropTypes.array, 
	defSelected: PropTypes.string, 
	urlKey: PropTypes.string, 
	root: PropTypes.bool, 
	icon: PropTypes.bool,
	disableUrlParams: PropTypes.bool,
	keys: PropTypes.array,
};