import React from "react";
import BaseTable from "../BaseTable/BaseTable";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import "./SelectableTable.scss";

export default function SelectableTable(props) {
	const { onSelect, columns, type, options } = props;
	let { data } = props;
	const selectorData = useSelector(state => state[type.name]);
	const dispatch = useDispatch();

  if(type && !data){
    data = selectorData.data
	}
	data = data.filter(d=>!d._trash);


	if (selectorData.status !== "LOADED") {
		dispatch({ type: `LOAD_${type}` });
	}
	const actionsColumn = {
		Header: "Seleccionar",
		Cell: function SelectColumn({ cell }) {
			return <>
				<button
					className="button-selectable-table"
					onClick={() => onSelect(cell.row.original)}
				>
					Seleccionar
				</button>
			</>;
		},
		filterable:false,
		accessor: "id",
		id: "actions",
		disableSortBy: true,
		disableFilters: true
	};
	const retry = () => {
		dispatch({type:`LOAD_${type.name}`});
	};
	return (
		<>
			{selectorData?.view?.status==="LOAD_FAILED"&&<div>LOAD FAILED<button onClick={retry}>Retry</button></div>}
			{selectorData?.view?.status==="LOADING"&& <CircularProgress />}
			{(selectorData?.view?.status==="LOADED" || !selectorData) &&
				<>
					{/* <NewButton button={<button>insertar</button>} /> */}
					<BaseTable
						columns={[...columns, actionsColumn]}
						data={data}
						options={options}
					/>
				</>
			}
		</>
	);
}
