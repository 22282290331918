import React from "react";

import Header from "components/Header/Header";

import TablaRecetas from "./TabProductos";
import TablaCategorias from "./TabCategorias";
import TablaSellos from "./TabSellos";
import { useRouteMatch } from "react-router-dom";
import MyTabs from "components/Tabs/Tabs";

const Productos = () => {
	let match = useRouteMatch();
	const tabs=[{
		label:"Productos",
		value:"productos",
		component:TablaRecetas
	},{
		label:"Categorías",
		value:"categoria",
		component:TablaCategorias
	},{
		label:"Etiquetas",
		value:"etiquetas",
		component:TablaSellos
	}];
	return (
		<>
			<Header title="Productos" video={[{videoId: "OE6bU-tWG8E", title: "¿Cómo crear un catálogo de productos con Nutrify?"}]} />
			<MyTabs tabs={tabs} defSelected={match.params.tipo} urlKey="seccion" root={true} ></MyTabs>
		</>
	);
};

export default Productos;
