import React, {useState} from "react";
import SetMacros from "./Chart/SetMacros";
import Text from "components/FormComponents/Text/Text";
import { Button } from "@mui/material";
import Box from "components/Box/Box";
import ChartMacronutimentos from "./ChartMacronutrimentos";
import ChipComponent from "components/FormComponents/Chip/Chip";
import { useTranslation } from "react-i18next";
import {Alert} from "@mui/lab"
export default function AssignMacros({data, onChange: defaultChange, prevValue, hideData, day, onSave, isAi}){
	const {t} = useTranslation("common");
	const [kcal, setKcal] =  useState(prevValue?.kcal ?? undefined);
	const [configuracion, setConfiguracion] = useState([]);
	
	const onChange = (distribucion, key) => {
		if(defaultChange) defaultChange(distribucion, key);
		setConfiguracion(prev=>({...prev, [key]: distribucion}));
	}	;

	return <div style={{marginBottom:20}}>
		{/* {!hideData &&
		<Box style={{width: "100%"}}>
			<p style={{fontWeight: "bold", fontSize: "12pt", margin: "10px 0 5px 0"}}>{t("dieta_menu_select_assign_macros_nombre_dieta")}</p>
			<Text value={data?.nombre} onChange={onChange} name="nombre" />

			<p style={{fontWeight: "bold", fontSize: "12pt", margin: "10px 0 5px 0"}}>{t("carpeta_dietas_recomendaciones")}</p>
			<Text value={data?.recomendaciones} multiline onChange={onChange} name="recomendaciones" />

		</Box>
		} */}
		<div style={{width: "100%"}}>
			{/* <div style={{width: "100%"}}> */}
			<p style={{fontWeight: "bold", marginTop: 10, marginBottom: 5, fontSize: "14pt"}}>{t("kcal_dieta")}</p>
			<ChipComponent label={t("dieta_menu_select_assign_macros_establece_kcal")}/>
			<Text value={kcal || undefined} subtype ="number" onChange={setKcal} placeholder="Ej. 2000" />
			<ChipComponent label={t("dieta_menu_select_assign_macros_utiliza_grafica")} style={{marginTop: "20px"}}/>
			<div style={{marginTop: "5px"}}>
				<div>
					<p style={{fontWeight: "bold", fontSize: "14pt", marginBottom: 15}}>{t("dieta_menu_select_assign_macros_establece_distribucion")}</p>
					<ChipComponent label={t("dieta_menu_select_assign_macros_fija_porcentaje")}/>
					<SetMacros kcal={kcal} value={data?.conf_dias} prevValue={prevValue} onChange={onChange} name="conf_dias" day={day} />
				</div>
				<div style={{marginTop: "15px"}}>
					<ChartMacronutimentos data={configuracion} />
				</div>
			</div>
			{/* </div> */}
		</div>
		{onSave &&<>
			{isAi && <Alert severity="warning">Al guardar se recalculara toda la dieta del dia reemplazando todos los alimentos de ese dia</Alert>}
			<div className="cont-buttons" style={{marginTop: "15px", textAlign: "right"}}>
				{Object.values(configuracion?.pieChartData || []).filter(val=>val > 0).length > 0 && <Button variant="contained" color="primary" onClick={()=>onSave(configuracion)} style={{marginRight: "10px"}}>{t("guardar")}</Button>}
				<Button variant="contained" color="secondary" onClick={()=>onSave()}>{t("cancelar")}</Button>
			</div>
		</>
		}
	</div>;
}