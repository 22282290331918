import React, {useRef} from "react";
import BoxForm from "components/FormComponents/BoxForm/BoxForm";
import { useSelector } from "react-redux";
import {PATIENTS} from "store/constants";
import PrintButton from "components/Buttons/PrintButton/PrintButton";

const elements = [
	{
		type: "text",
		name: "motivo_consulta",
		label: "Describe el motivo principal de la consulta",
		fullWidth: true,
		moreRows: true
	},
	{
		type: "detailCheckbox",
		name: "antecedentes_familiares",
		label: "Antecedentes Familiares",
		labelInput: "Especifica",
		subLabel: "Selecciona las enfermedades que tu familia directa padecen o han padecido",
		options: [
			{ value: "diabetes", label: "Diabetes Mellitus" },
			{ value: "colesterol_alto", label: "Colesterol o triglicéridos altos" },
			{ value: "hipertension", label: "Hipertensión" },
			{
				value: "enfermedades_corazon",
				label: "Enfermedades del corazón, infartos o embolias"
			},
			{ value: "cancer", label: "Cáncer" },
			{ value: "problemas_tiroideos", label: "Problemas tiroideos" },
			{ value: "sobrepeso", label: "Sobrepeso u obesidad" },
			{
				value: "enfermedades_gastrointestinales",
				label: "Enfermedades gastrointestinales"
			},
			{ value: "problemas_psicologicos", label: "Problemas psicológicos o psiquiátricos" }
		]
	},
	{
		type: "detailCheckbox",
		name: "antecedentes_personales",
		label: "Menciona las enfermedades que padeces o has padecido",
		labelInput: "Especifica",
		options: [
			{ value: "diabetes", label: "Diabetes Mellitus" },
			{ value: "colesterol_alto", label: "Colesterol o triglicéridos altos" },
			{ value: "hipertension", label: "Hipertensión" },
			{
				value: "enfermedades_corazon",
				label: "Enfermedades del corazón, infartos o embolias"
			},
			{ value: "cancer", label: "Cáncer" },
			{ value: "problemas_tiroideos", label: "Problemas tiroideos" },
			{ value: "sobrepeso", label: "Sobrepeso u obesidad" },
			{
				value: "enfermedades_gastrointestinales",
				label: "Enfermedades gastrointestinales"
			},
			{ value: "problemas_psicologicos", label: "Problemas psicológicos o psiquiátricos" }
		]
	},
	{
		type: "boolDetail",
		name: "cirugias",
		label: "¿Te han realizado alguna cirugía?",
		showLabel: true,
	},
	{
		type: "text",
		name: "medicamentos",
		label: "Menciona los medicamentos que utilizas y la dosis",
		labelInput: "Especifica",
		showLabel: true,
	},
	{
		type: "text",
		name: "suplementos",
		label: "Menciona los suplementos que utilizas y la dosis",
		fullWidth: true,
	},
	{
		type: "boolDetail",
		name: "consumo_alcohol",
		label: "Consumo de alcohol",
		labelInput: "¿Qué tipo?, ¿Con que lo mezclas?, ¿Cuántas veces a la semana?",
		showLabel: true,
	},
	{
		type: "boolDetail",
		name: "fuma",
		label: "Consumo de tabaco",
		labelInput: "Número de cigarros al día",
		showLabel: true,
	},
	{
		type: "boolDetail",
		name: "embarazada",
		label: "¿Estás embarazada?",
		onTrueOptions:[
			{value:"primer", label: "Primer trimestre"},
			{value:"segundo", label: "Segundo trimestre"},
			{value:"tercer", label: "Tercer trimestre"},
			{value:"multiple", label: "Múltiple"},
		]
	},
	{
		type: "boolDetail",
		name: "amamantando",
		label: "¿Estás amamantando?",
		onTrueOptions:[
			{value:"exclusiva", label: "Lactancia exclusiva"},
			{value:"mixta", label: "Lactancia mixta"},
		]
	},
	{
		type: "radio",
		name: "actividad_fisica",
		label: "¿Realizas actividad física?",
		options: [
			{ value: "sedentario", label: "Sedentario (sin ejercicio)" },
			{
				value: "actividad_ligera",
				label: "Actividad Ligera (ejercicio 1-3 días por semana)"
			},
			{
				value: "actividad_moderada",
				label: "Actividad Moderada (ejercicio 3-5 días por semana)"
			},
			{
				value: "actividad_intensa",
				label: "Actividad Intensa (ejercicio 6-7 días por semana)"
			},
			{
				value: "actividad_muy_intensa",
				label:
          "Actividad Muy Intensa (ejercicio diario de mucha fuerza y agotamiento)"
			}
		]
	},
	{
		type: "multiGroup",
		name: "fiit",
		label: "Especifica (FIIT)",
		horizontal: true,
		labelAddButton: "AÑADIR EJERCICIO",
		fields: [
			{
				type:"text",
				name:"tipo",
				labelInput: "Tipo",
				showLabel: true,
			},
			{
				type:"text",
				name:"frecuencia",
				labelInput: "Frecuencia",
				showLabel: true,
			},
			{
				type:"text",
				name:"intensidad",
				labelInput: "Intensidad",
				showLabel: true,
			},
			{
				type:"text",
				name:"tiempo",
				labelInput: "Tiempo",
				showLabel: true,
			}
		]
	},
	{
		type: "group",
		name: "peso_min_max",
		label: "Menciona tu peso mínimo y máximo con la estatura actual que has tenido en los últimos 10 años",
		fields:[
			{
				type: "text",
				name: "peso_minimo",
				labelAdornment: "kg",
				label: "Peso mínimo",
				showLabel: true,
			},
			{
				type: "text",
				name: "peso_maximo",
				labelAdornment: "kg",
				label: "Peso máximo",
				showLabel: true,
			}
		]
	},
	{
		type: "text",
		name: "peso_habitual",
		label: "Menciona tu peso habitual (lo que normalmente pesas)",
		labelAdornment: "kg",
	},
	{
		type: "text",
		name: "peso_deseado",
		label: "¿Cuál es tu peso deseado?",
		labelAdornment: "kg",
	},
	{
		type: "text",
		name: "cambios_para_mejorar",
		label: "Menciona 3 cambios primordiales que tendrías que hacer para mejorar tu salud",
		multiline: true,
	},
	{
		type: "text",
		name: "comentarios",
		label: "¿Hay algo más que quisieras comentar?",
		multiline: true,
	},
	{
		type: "text",
		name: "etiquetas",
		label: "Etiqueta a tu paciente por patología o grupo",
	},
	{
		type: "text",
		name: "notas",
		label: "Notas",
		fullWidth: true,
		moreRows: true
	},
	{
		type: "multiGroup",
		name: "preguntas_extras",
		label: "Extras",
		fullWidth: true,
		flex: true,
		horizontal: true,
		labelAddButton: "AÑADIR PREGUNTA",
		fields: [
			{
				type:"text",
				name:"pregunta",
				labelInput: "Pregunta",
				showLabel: true,
			},
			{
				type:"text",
				name:"respuesta",
				labelInput: "Respuesta",
				showLabel: true,
			},
		]
	},
];
const HistoriaClinicaConsulta = props => {

	const data = useSelector(state => state["PATIENTS"]?.data.find(data => data._id === props.idPaciente)
	);
	
	const ref = useRef(null);

	return (
		<>			
		<div style={{ display:"flex", justifyContent: "flex-end", alignItems:"center", float:"right"}}>
		<PrintButton printRef={ref} />
	</div>
			<div style={{marginTop:"20px"}} className="printable" ref={ref}>
				<BoxForm data={props.data} elements={elements} onChange={props.onChange} indexElements={true} ></BoxForm>
			</div>
		</>
	);
};

export default HistoriaClinicaConsulta;
