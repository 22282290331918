import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store/reducers/index";
import { ThemeProvider } from '@mui/material/styles';
import {LocalizationProvider} from "@mui/x-date-pickers"
import Theme from "./Theme";
import api from "api/loader";
import { QueryClient, QueryClientProvider } from 'react-query'
import * as serviceWorker from "./serviceWorker";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/es';
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: Infinity,
		},
	},
});
api.init();
ReactDOM.render(
  <ThemeProvider theme={Theme}>
		<LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">

    <Provider store={store}>
			<QueryClientProvider client={queryClient}>
     	 <App />
			</QueryClientProvider>
    </Provider>
		</LocalizationProvider>
  </ThemeProvider>,
  document.getElementById("root")
);
const loadData = [
  "COLUMNS_DIET",
  "EQUIVALENT_DIET",
  "EQUIVALENT",
  "EQUIVALENT_LIST",
  "EQUIVALENT_SUBCAT",
  "PATIENTS",
  "PRIZE",
  "MENU_DIET",
  "OPTIONS_DIET",
  "RECEIPE",
  "RECEIPE_TAG",
  "RECEIPE_CATEGORY",
  // "CUSTOMER",
  "PLAN"
];
for (var i in loadData) {
  store.dispatch({ type: `LOAD_${loadData[i]}` });
}
// store.dispatch({type:'INSERT_MENU_DIET', payload:{nombre:"Prueba", recomendacion:"Prueba Recomendacion"}})
window.store = store;
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

serviceWorker.unregister();
