import React, {useState} from "react";
import BaseTable from "../BaseTable/BaseTable";
import { Grid, CircularProgress, Button } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
// import NewButton from "../../Buttons/NewButton/NewButton";
import SaveModal from "../../SaveModal/SaveModal";
import { useDispatch } from "react-redux";
import swal from "@sweetalert/with-react";
import {get} from "api/index";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "App.css";
import PropTypes from "prop-types";
import { useQueryClient } from 'react-query'

export default function CrudTable(props) {
	const {
		data,
		type,
		onInsert,
		columns,
		modal,
		options = {}
	} = props;
	const [openedModal, setOpenModal] = useState(false);
	const [showTrash, setShowTrash] = useState(false);
	const [selectedData, setSelectedData] = useState();
	const [viewOnly, setViewOnly] = useState(false);
	const [forceNew, setForceNew] = useState(false);
	const [selectedButton, setSelectedButton] = useState("")
	const dispatch = useDispatch();
	const canInsert = !options.disableInsert;
	const canEdit = !options.disableEdit;
	const canDelete = !options.disableDelete;

	const queryClient = useQueryClient()

	const handleTrash = (cell, event) => {
		if(event){
			event.stopPropagation();
		}
		swal({
			title: props.archivarMessageTitle ? props.archivarMessageTitle(cell) :"Confirmación",
			text: props.archivarMessageBody ? props.archivarMessageBody(cell) : `¿Deseas enviar a tu ${props.type.label} ${cell.row.original.nombre ? cell.row.original.nombre: ""} a la papelera?`,
			icon: "warning",
			buttons: ["Cancelar","Confirmar"],
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					const name = type.tail_name ? `${type.name}_${type.tail_name}` : type.name;
					dispatch({type:`TRASH_${name}`,payload:{_id:cell.row.original._id, parent_id:props._id}});
				}
			});
	};
	const handleEdit = async (cell, event, viewOnly) => {

		setViewOnly(viewOnly);
		if(event){
			event.stopPropagation();
		}

		let data = cell.row.original; 

		if(props.editAction){
			props.editAction(data);
		}else{
			// let data = cell.row.original; 
			if(options.loadBeforeEdit){
				let response = await get(type.url+"/"+cell.row.original._id);
				data = response.data;
			}
			setSelectedData(data);
			setOpenModal(true);
		}
		
	};
	const retry = () => {
		const name = type.tail_name ? `${type.name}_${type.tail_name}` : type.name;
		dispatch({type:`LOAD_${name}`});

	};
	const handleNew = async () => {
		setViewOnly(false);

		if(props.insertAction){
			props.insertAction();
		}
		else{
			setSelectedData(null);
			setOpenModal(true);
		}
	};
	const handleDelete = (cell) => {
		swal({
			title: props.eliminarMessageTitle ? props.eliminarMessageTitle(cell) :"Confirmación",
			text: "Al confirmar se eliminará toda la información de tu " + props.type.label + " " + (cell.row.original.nombre || ""),
			icon: "warning",
			buttons: ["Cancelar","Confirmar"],
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					const name = type.tail_name ? `${type.name}_${type.tail_name}` : type.name;
					dispatch({type:`REMOVE_${name}`,payload:{_id:cell.row.original._id, parent_id:props._id}});
				}
			});
	};
	const handleUntrash = (cell) => {
		const name = type.tail_name ? `${type.name}_${type.tail_name}` : type.name;
		dispatch({type:`UNTRASH_${name}`,payload:{_id:cell.row.original._id, parent_id:props._id}});
	};
	const actionsColumn = React.useMemo(()=>({
		Header: (
			<>
				{canInsert && 
					<Button color="primary" variant="contained" disabled={props.maxRows<=data?.length && props.maxRows!==-1} style={{float:"right"}} onClick={()=>{setSelectedButton("new"); handleNew()}} >
						{props.addLabel ?props.addLabel : `Añadir ${props.type.label}`}
					</Button>
				}
			</>
		),
		Cell: function ActionsColumn({ cell }){
			return <Grid
				container
				direction="row"
				justify="flex-end"
				alignItems="center"
			>
				{props.actions && props.actions.map(a=>a({cell, setSelectedData, setOpenModal, setForceNew}))}
				{(canEdit && (props.canEdit ? props.canEdit({cell}) : true)) && 
					<Button variant="contained" style={{background:"#F8F8F8",color:"#3B4C4B", margin:"5px"}} onClick={(event)=>{setSelectedButton("edit"); handleEdit(cell, event)}} edit>{props.editLabel ? props.editLabel : "Editar"}</Button>
				}
				{(props.canView ? props.canView({cell}) : false) && 
					<Button variant="contained" style={{background:"#F8F8F8",color:"#3B4C4B", margin:"5px"}} onClick={(event)=>{setSelectedButton("view"); handleEdit(cell, event, true)}}>{props.viewLabel ? props.viewLabel : "Ver"}</Button>
				}
				{canDelete && (
					<>
						{(props.canDelete && props.canDelete(cell) ? <></> : 
							<Button variant="contained"  color="secondary"  onClick={(event)=>{setSelectedButton("trash");handleTrash(cell, event)}}>Archivar</Button>
						)
						}
					</>
				)}
			</Grid>;
		},
		accessor: "id",
		id: "actions",
		filter: false,
		disableSortBy: true,
		disableFilters: true
	}),[props.data, props.maxRows]);
	
	const trashTableColumns = React.useMemo(()=>({
		Cell: function TrashColumn({ cell }){
			return <Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
			>
				<>
					{(canEdit && (props.canEdit ? props.canEdit({cell}) : true)) && 
						<Button variant="contained" style={{background:"#F8F8F8",color:"#3B4C4B", margin:"5px"}} onClick={(event)=>{setSelectedButton("edit"); handleEdit(cell, event)}} edit>{props.editLabel ? props.editLabel : "Editar"}</Button>
					}
					{(props.canView ? props.canView({cell}) : false) && 
						<Button variant="contained" style={{background:"#F8F8F8",color:"#3B4C4B", margin:"5px"}} onClick={(event)=>{setSelectedButton("view"); handleEdit(cell, event, true)}}>{props.viewLabel ? props.viewLabel : "Ver"}</Button>
					}
					<Button variant="contained" color="primary" style={{margin:"5px"}} onClick={()=>handleUntrash(cell)}>Desarchivar</Button>

					{canDelete && (
						<Button variant="contained" color="secondary" onClick={()=>handleDelete(cell)}>Eliminar</Button>
					)}
				</>
			</Grid>;
		},
		accessor: "id",
		id: "actions",
		filter: false,
		disableSortBy: true,
		disableFilters: true,
	}),[]);
	const trashIconColumn = React.useMemo(()=>({
		Cell: function DeletedColumn (){
			return <DeleteOutlineIcon />;
		},
		id: "icon",
		filter: false,
		disableSortBy: true,
		disableFilters: true
	}),[]);

	const finalColumns = React.useMemo(()=>[{
		Header: "id",
		accessor: "_id",
		disableFilters:true
	},...columns, actionsColumn]
	,[props]);
	const regularData = React.useMemo(()=>data?.filter(d=>!d._trash),[data]);
	const PapeleraComponent = React.useMemo(()=><Button variant="contained" color="secondary" style={{float:"right",width:"200px"}} onClick={()=>setShowTrash(true)}>{props.papeleraLabel ? props.papeleraLabel : "Papelera"}</Button>,[]);

	return (
		<>
			{
				props.status.status==="LOAD_FAILED"
					?
					<div>LOAD FAILED<button onClick={retry}>Retry</button></div>
					:
					props.status.status==="LOADING"
						?
						<CircularProgress />
						:
						<>
							{!showTrash && 
							<BaseTable
								{...props}
								filters={props.filters}
								type={type}
								columns={finalColumns}
								data={regularData}
								options={options}
								footerComponent={PapeleraComponent}
								onClick={props.options?.editOnRowClick ? handleEdit: undefined}
							/>
							}
					
							{showTrash && 
								<>
									<Button variant="contained" color="secondary" onClick={()=>setShowTrash(false)} startIcon={<ArrowBackIcon />}> Regresar</Button>

									<BaseTable
										{...props}
										columns={[trashIconColumn,{
											Header: "id",
											accessor: "_id",
											disableFilters:true
										}, ...columns, trashTableColumns]}
										data={data?.filter(d=>d._trash)}
										options={options}
									/>
								</>
							}
						</>
			}

			{openedModal && 
			<SaveModal 
				{...props.options} 
				fullWidth={props.fullWidth} 
				parent_id={props._id} 
				onInsert={onInsert} 
				modal={modal} 
				forceNew={forceNew}
				type={type} 
				status={props.status} 
				viewOnly={viewOnly}
				data={selectedData} 
				selectedButton={selectedButton}
				handleModal={()=>{
					setOpenModal(false);
					const name = type.tail_name ? `${type.name}_${type.tail_name}` : type.name;
					queryClient.invalidateQueries({ queryKey: [type.name] })

					dispatch({
						type: `${name}_STATUS`,
						payload: {status:"LOADED"}
					});
				}} />}
				
		</>
	);
}

CrudTable.propTypes = {
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	type: PropTypes.object,
	onInsert: PropTypes.func,
	columns: PropTypes.array,
	modal: PropTypes.func,
	options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	archivarMessageBody: PropTypes.func,
	archivar: PropTypes.func,
	_id: PropTypes.string,
	editAction: PropTypes.func,
	insertAction: PropTypes.func,
	eliminarMessageTitle: PropTypes.func,
	addLabel: PropTypes.string,
	actions: PropTypes.array,
	maxRows: PropTypes.number,
	status: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	filters: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	fullWidth: PropTypes.bool
};