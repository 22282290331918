
import React, {useState, useEffect} from "react";


import {
	TextField,
	Checkbox
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {Autocomplete} from "@mui/lab";
import { useSelector } from "react-redux";
import Select from "components/Select/Select"
function usePrevious(value) {
  const ref = React.useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Ref = props => {
	const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
	const checkedIcon = <CheckBoxIcon fontSize="small" />;
	let options = useSelector(state=>state[props.extraValue?.coleccion]?.data) || [];

	options = options?.filter(o=> o._id && !o._trash );

	if(props.extraItems){
		options = [...props.extraItems, ...options];
	}
	
	
	const prevCollection = usePrevious(props.extraValue.collection);
	useEffect(()=>{
		if(props.extraValue.collection !== prevCollection && prevCollection){
			props.onChange([], props.name);
		}
	},[props, prevCollection]);
	

	const handleChange = (value) =>  {
		const a= [...(props.value?.[props.extraValue.coleccion] || []), ...value.map(v=>v._id)];
		props.onChange(a, `${props.name}`)
	};
	return <Select multiSelect options={options} value={props.value} accessor="_id" labelAccessor="nombre" onChange={handleChange} />
	
    
};

export default Ref;



