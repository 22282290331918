import React from "react";
import CrudTable from "components/Table/CrudTable/CrudTable";
import Cliente from "./Cliente";
import { useSelector } from "react-redux";
import {CUSTOMER} from 'store/constants'
import Header from "../../components/Header/Header";
import DateCell from "components/Table/CellTypes/DateCell/DateCell";
import { useQuery } from 'react-query'
import {crudFunctions} from "api";
const TablaClientes = props => {
  // const data = useSelector(state => state["CUSTOMER"]);
	const [page, setPage] = React.useState();
	const [sort, setSort] = React.useState();
	const [filterData, setFilterData] = React.useState({});
	const actions = crudFunctions(CUSTOMER);
	const {data, status} = useQuery([CUSTOMER.name, {page, filter:filterData, sort}], actions.getPaginated, {keepPreviousData:true});


  const columns = React.useMemo(
    () => [
			// {
			// 	accessor: "_id",
			// 	Header: "Id",
			// },
      {
        Header: "Clave",
        accessor: "clave"
      },
      {
        Header: "Nombre",
        accessor: "nutritionist.nombre"
      },
      {
        Header: "Telefono",
        accessor: "nutritionist.telefono"
      },
      {
        Header: "Correo",
        accessor: "nutritionist.correo"
			},
			{
        Header: "Vencimiento",
				accessor: "subscripcion.vencimiento",
				Cell:DateCell,
				filter:"daterange"
			},
			{
        Header: "Ultima conexion",
				accessor: "ultima_conexion",
				Cell:DateCell,
				filter:"daterange"
      }
    ],
    []
	);
	
	
  

  return (
    <>
			<Header title="Clientes" />

      <CrudTable
       	data={data?.data || []}
				status={status}

				columns={columns}
				filters={columns}
        type={CUSTOMER}
        modal={Cliente}
        options={{loadBeforeEdit:true, orderBy:true}}

				onFilterChange={setFilterData}
				onPaginationChange={setPage}
				onSortChanged={(s)=>setSort(s[0])}
				totalRows={data?.total}
				manualPagination
      />
    </>
  );
};

export default TablaClientes;
