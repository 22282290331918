import React, {useRef, useEffect} from "react";
import Filter from "components/Filter/Filter";

// import styled from 'styled-components'
import { useTable, useFilters, useSortBy, usePagination, useBlockLayout } from "react-table";
// A great library for fuzzy filtering/sorting items
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import IconButton from "@mui/material/IconButton";

import TextCell from "../CellTypes/TextCell/TextCell";
import DefaultColumnFilter from "../FilterTypes/DefaultFilter/DefaultFilter";
import "./BaseTable.scss";

const moment = require("moment");

export default React.memo(function Table(props) {

	const filterTypes = React.useMemo(
		() => ({
			text: (rows, id, filterValue) => {
				filterValue = String(filterValue).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
				return rows.filter(row => {
					const rowValue = String(row.values[id]).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
					
					return rowValue !== undefined
						? rowValue.includes(filterValue)
						: true;
				});
			},
			daterange:(rows, id, filter) => {
				if (!filter[0] || !filter[1]) {
					return rows;
				}
				return rows.filter(row=>{
					if (moment(row.values[id]).isBetween(filter[0], filter[1] ,"days","[]") ) {
						return true;
					}
				});
			}
		}),
		[]
	);
	const sortTypes = React.useMemo(
		() => ({
			basic: (rowA, rowB, columnId) => {
				let a = rowA.values[columnId];
				let b = rowB.values[columnId];
				a = a ? String(a).toLowerCase() : a;
				b = b ? String(b).toLowerCase() : b;
				return a === b ? 0 : a > b ? 1 : -1;
			},
			date: (rowA, rowB, columnId) => {
				let a = rowA.values[columnId];
				let b = rowB.values[columnId];
				a = moment(a).format("YYYYMMDD");
				b = moment(b).format("YYYYMMDD");
				return a === b ? 0 : a > b ? 1 : -1;
			},
			customSort:props.options?.customSort
			
		}),
		[props.options]
	);
	const defaultColumn = React.useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter,
			Cell:TextCell,
			filter:"text",
			sortType: "basic"
		}),
		[]
	);

	const { columns, options, data } = props;
	const isOrderable = options && options.orderBy;
	const defaultSorting = (options && options.defaultSorting) ? options.defaultSorting : undefined ;
	const hiddenColumns = (options && options.hiddenColumns) ? options.hiddenColumns : [] ;

	const [totalRows, setTotalRows] = React.useState(props.totalRows || data.length);
	let refTable = useRef(null);
	let initialState = {
		hiddenColumns:["_id", ...hiddenColumns]
	};

	if(defaultSorting){
		initialState.sortBy = [{
			id: defaultSorting.id,
			desc: defaultSorting.desc
		}];
	}


	const [filteredData, setFilteredData] = React.useState(data);
	const [scroll, setScroll] = React.useState(0);



	React.useEffect(()=>{
		props.onFilteredDataChange && props.onFilteredDataChange(filteredData);
		setTotalRows(props.totalRows || data.length);

	},[filteredData]);
	React.useEffect(()=>{
		setFilteredData(data);
	},[data]);

	const _onFilterChange = (...params) => {
		console.log("aaaa")
		props.onFilterChange && props.onFilterChange(...params);
		gotoPage(0);

	}
	useEffect(() => {
		if(props.getTable) {
			props.getTable(refTable);

		}
	}, [refTable]);


	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		pageOptions,
		page,
		state: { pageIndex, sortBy },
		previousPage,
		nextPage,
		gotoPage,
		canPreviousPage,
		canNextPage,		
	} = useTable(
		{
			columns: columns,
			data: filteredData,
			defaultColumn,
			filterTypes,
			initialState,
			autoResetPage: false,//props.skipPageReset==undefined ? true : props.skipPageReset,
			autoResetPageIndex:  props.skipPageReset==undefined ? true : props.skipPageReset,
			autoResetFilters: false,
			autoResetSortBy: false,
			sortTypes:sortTypes,
			totalRows:totalRows,
			pageCount:Math.ceil(totalRows/10),
			manualPagination:props.manualPagination,
			manualSortBy: props.manualPagination,
		},
		useFilters, // useFilters!
		useSortBy,
		usePagination,
		props.options?.useBlockLayout ? useBlockLayout : useSortBy,
	);
	const handleRowClick = (row)=>{
		if(props.onClick){
			props.onClick(row.cells[0]);
		}
	};
	React.useEffect(()=>{
		props.onPaginationChange && props.onPaginationChange(pageIndex);
	},[pageIndex]);
	React.useEffect(()=>{
		props.onSortChanged && props.onSortChanged(sortBy);
	},[sortBy]);
	

	const filters = <Filter type={props.type} gotoPage={gotoPage} data={data} filteredData={setFilteredData} filtros={props.filters} onFilterChange={_onFilterChange} />;
	return (
		<>
			<div>
				{props.filters && filters}
			</div>
			<div style={{ overflowX: "auto" }}>
				<div>
					{props?.showScroll?.status &&
							<div style={{display: "flex", justifyContent: "space-between", flexDirection: scroll < 0 ? "row" : "row-reverse", position: "sticky", top: 0, zIndex: 10000}}>
								{scroll < 0 && <IconButton style={{color: "white", background: "#a5dd72"}} onClick={()=>setScroll(scroll + (props.showScroll?.width / props.showScroll?.lengthColumns))}><ArrowBackIosIcon /></IconButton>}
								<IconButton style={{color: "white", background: "#a5dd72"}} onClick={()=>setScroll(scroll - (props.showScroll?.width / props.showScroll?.lengthColumns))}><ArrowForwardIosIcon /></IconButton>
							</div>
					}
					<table ref={refTable} {...getTableProps()} className="table" style={{transform: `translatex(${scroll}%)`}}>
						<thead className="header">
							{headerGroups.map((headerGroup,idx) => (
								<tr key={idx} {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map(column => (
										<th
											key={column.id}
											{...column.getHeaderProps(
												isOrderable ? column.getSortByToggleProps():undefined
											)}
											className={(column.render("id") === "actions") ? "actions-table" : undefined}
										>
											{column.render("Header")}
											{(isOrderable && !column.disableSortBy) && <span>
												{column.isSorted
													? column.isSortedDesc
														? <ArrowUpwardIcon  />
														: <ArrowDownwardIcon />
													: <ImportExportIcon />}
											</span>}
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()} className="body">
							{page.map((row, i) => {
								prepareRow(row);
								return (
									<tr onClick={(event)=>handleRowClick(row, event)} key={i} {...row.getRowProps()} className="container-element">
										{row.cells.map(cell => (
											<td key={cell.id} {...cell.getCellProps()} className={`element ${cell.column.render("id") === "actions" ? "actions-table":""}`}>
												{cell.render("Cell")}
											</td>	
										))}
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
			<div className="container-navigation-table">
				<button
					className="button previous-page"
					onClick={() => previousPage()}
					disabled={!canPreviousPage}
				>
					Anterior
				</button>
				<div className="actual-page">
					Página {" "}
					<em>
						{pageIndex + 1} de {pageOptions.length}
					</em>
				</div>
				<button
					className="button next-page"
					onClick={() => nextPage()}
					disabled={!canNextPage}
				>
					Siguiente
				</button>
				<div style={{float:"right"}}>{props.footerComponent}</div>
			</div>
		</>
	);
});
