import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { NUTRITIONIST_NOTIFICATION, NUTRITIONIST_NOTIFICATION_TAGS } from "../constants";

const loadNutritionistNotification = loadRequest(NUTRITIONIST_NOTIFICATION);
const loadNutritionistNotificationTags = loadRequest(NUTRITIONIST_NOTIFICATION_TAGS);

const insertNutritionistNotification = insertRequest(NUTRITIONIST_NOTIFICATION);
const insertNutritionistNotificationTags = insertRequest(NUTRITIONIST_NOTIFICATION_TAGS);

const updateNutritionistNotification = updateRequest(NUTRITIONIST_NOTIFICATION);
const updateNutritionistNotificationTags = updateRequest(NUTRITIONIST_NOTIFICATION_TAGS);

const trashAppointment = trashRequest(NUTRITIONIST_NOTIFICATION);
const trashNutritionistNotificationTags = trashRequest(NUTRITIONIST_NOTIFICATION_TAGS);

const untrashNutritionistNotification = untrashRequest(NUTRITIONIST_NOTIFICATION);
const untrashNutritionistNotificationTags = untrashRequest(NUTRITIONIST_NOTIFICATION_TAGS);

const removeNutritionistNotification = removeRequest(NUTRITIONIST_NOTIFICATION);
const removeNutritionistNotificationTags = removeRequest(NUTRITIONIST_NOTIFICATION_TAGS);


function* rootSaga() {
	yield all([
		loadNutritionistNotification(),
		loadNutritionistNotificationTags(),
		insertNutritionistNotification(),
		insertNutritionistNotificationTags(),
		updateNutritionistNotification(),
		updateNutritionistNotificationTags(),
		trashAppointment(),
		trashNutritionistNotificationTags(),
		untrashNutritionistNotification(),
		untrashNutritionistNotificationTags(),
		removeNutritionistNotification(),
		removeNutritionistNotificationTags(),
	]);
}
export default rootSaga;