import React from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";

function Micronutrimentos({data, options: optionsProp}) {
	const {t} = useTranslation("common");
	const options =  {
		chart: {type: "donut"},
		plotOptions: {pie: {dataLabels: {offset: -10}, donut: {size: "100%"}}},
		colors: ["#E77067", "#F7D542", "#7BAAFB"],
		labels: [t("proteinas"), t("lipidos"), t("hidratos_carbono")],
		legend: {show: false},
		tooltip: {enabled: true, y: {formatter: function(val) {return val.toFixed(2);}}},
		...optionsProp
		// tooltip: {y: { formatter: function(value, { series, seriesIndex, dataPointIndex, w }) { return value.toFixed(2); }}}
	};
	const series = [data?.pieChartData?.prot || 0, data?.pieChartData?.lip || 0, data?.pieChartData?.hidr || 0];
	return (
		<div>
			
			<div style={{maxWidth: "340px", margin: "0 auto"}}>
				<Chart
					options={options}
					series={series}
					type="pie"
					height="200"
				/>
			</div>
		</div>
	);
}

export default Micronutrimentos;
