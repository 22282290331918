import React from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { CONSULTATION } from "store/constants";

export default function Carter(props){
	let match = useRouteMatch();
	const dataConsulta = useSelector(state=>state[CONSULTATION.name].data[match.params.idPaciente].find(n=>n._id===match.params.idConsulta));

	const total = (0.1051*(dataConsulta.pliegue_triceps+dataConsulta.pliegue_subescapular+dataConsulta.pliegue_supraespinal+dataConsulta.pliegue_abdominal+dataConsulta.pliegue_muslo_frontal+dataConsulta.pliegue_pantorrilla_medial)+2.58);

	if(isNaN(total)) return (0);
	return (total).toFixed(2);

}