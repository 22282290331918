import React from "react";
import { useSelector } from "react-redux";
import DietaMenus from "./DietaMenus";
import CrudTable from "components/Table/CrudTable/CrudTable";
import { MENU_DIET } from "store/constants";
import AppView from "components/AppView/AppView";

const TabDietas = props => {
	const data = useSelector(state => state[MENU_DIET.name]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Nombre",
				accessor: "nombre"
			}
		],
		[]
	);
	const filters = React.useMemo(
		() => [
			
			{	
				Header: "Nombre",
				accessor: "nombre",
			}
		],
		[]
	);

	return (
		<>
			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				type={MENU_DIET}
				modal={DietaMenus}
				filters={filters}
				options={{ orderBy: true, saveAs:true, loadBeforeEdit:true }}
				fullWidth
				headerActions={[<AppView key="app_view" img="dieta-menus" />]}
			/>
		</>
	);
};

export default TabDietas;
