import React from "react";
import PropTypes from "prop-types";
import { FormGroup, Checkbox, Button } from "@mui/material";
import TiempoComidaVertical from "./TiempoComidaCopy";
import Box from "components/Box/Box";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import "./DietaMenus.scss";

const SelectCopy = styled.div`
	input{
		width: 100%;
	}
`;

const Dieta = ({dieta, onClose}) => {
	const {t} = useTranslation("common");
	const dias = [
		{ name: "monday", label: t("modal_dias_laborales_lunes") },
		{ name: "tuesday", label: t("modal_dias_laborales_martes") },
		{ name: "wednesday", label: t("modal_dias_laborales_miercoles") },
		{ name: "thursday", label: t("modal_dias_laborales_jueves") },
		{ name: "friday", label: t("modal_dias_laborales_viernes") },
		{ name: "saturday", label: t("modal_dias_laborales_sabado") },
		{ name: "sunday", label: t("modal_dias_laborales_domingo") },
	];

	const [checkedTiempos, setCheckedTiempos] = React.useState({});

	const handleCheck = (dia, tiempo) => (e) => {
		
		setCheckedTiempos(prev=>({...prev, [`${dia}-${tiempo}`]:e.target.checked}));
	};

	const handleCheckAllDay = (dia) => (e) => {
		e.persist();
		for (let i = 0; i < dias.length; i++) {
			setCheckedTiempos(prev=>({...prev, [`${i}-${dia}`]:e.target.checked}));
		}
	};

	const guardar = () => {
		let to = [];
		Object.entries(checkedTiempos).forEach(([k,v])=>{
			if(v){
				const dia = k.split("-")[0];
				const tiempo = k.split("-")[1];
				to.push([dia, tiempo]);
			}
		});
		onClose(to);
	};

	return (
		<SelectCopy className="cont-select-copy printDiv">
			
			<FormGroup>
				<Box label={t("dieta_menu_select_select_copy_selecciona")} fullWidth>
					<div>
						<div className="content-table" style={{paddingLeft: "10%"}}>
							<>
								<div className="row-table">
									
									{Object.values(dieta).map((value, i) => (
										<div key={i} style={{display:"flex", flexDirection: "column", alignItems: "center", width:`${100/Object.values(dieta).length}%`}}>
											<TiempoComidaVertical tiempoComida={value} index={i} />
											<div>
												<Checkbox
													onChange={handleCheckAllDay(i)}
													inputProps={{ "aria-label": "primary checkbox" }}
													checked={Object.entries(checkedTiempos).filter(([key, val])=>key.includes(`-${i}`)&&val).length === 7}
												/>
											</div>
										</div>
									))}
								</div>
							</>
						</div>
						<div className="container-table">
							<div>
								{dias.map((dia, idx_dia) => <Dia handleCheck={handleCheck} checkedTiempos={checkedTiempos} dia={dia} idx_dia={idx_dia} dieta={dieta} key={idx_dia} /> )}
							</div>
						</div>
					</div>
						
				</Box>
				<div className="cont-buttons" style={{marginTop: "15px", textAlign: "right"}}>
					<Button variant="contained" color="primary" onClick={guardar} style={{marginRight: "10px"}}>{t("guardar")}</Button>
					<Button variant="contained" color="secondary" onClick={()=>onClose()}>{t("cancelar")}</Button>
				</div>
			</FormGroup>
		</SelectCopy>
	);
};

export default Dieta;

Dieta.propTypes = {
	value: PropTypes.array,
	onChange: PropTypes.func
};


function Dia({idx_dia, dia, dieta, handleCheck, checkedTiempos}){
	return <div key={idx_dia} className="break-end-page" style={{display:"flex"}}>
		<div key={idx_dia} style={{width: "10%", height:"unset", display:"flex", alignItems:"center", justifyContent:"center"}}>
			<p>{dia.label}</p>
		</div>
		<div className="panelTiempo" style={{ width: "90%", display: "flex", margin: 0, justifyContent: "space-around"}}>
			{Object.values(dieta).map((tiempoComida, idx_tiempo) => <TiempoComida handleCheck={handleCheck} checkedTiempos={checkedTiempos} tiempoComida={tiempoComida.menu[idx_dia]} idx_tiempo={idx_tiempo} idx_dia={idx_dia} key={idx_tiempo} />)}
		</div>
	</div>;
}
function TiempoComida({idx_tiempo, idx_dia, handleCheck, checkedTiempos}){
	return <>
		<div style={{alignItems:"center", margin: "5px auto"}}>
			<Checkbox
				onChange={handleCheck(idx_dia, idx_tiempo)}
				inputProps={{ "aria-label": "primary checkbox" }}
				checked={checkedTiempos[`${idx_dia}-${idx_tiempo}`] ? true : false}
				// value={checkedTiempos[`${idx_dia}-${idx_tiempo}`]}
			/>
		</div>
	</>;
}
