import React, { useEffect, useState } from "react";
import styles from "Styles.module.scss";
import { Button } from "@mui/material";
import Modal from "components/Modal/Modal";
import MyTabs from "components/Tabs/Tabs";
import { useTranslation } from "react-i18next";
import NewButton from "components/Buttons/NewButton/NewButton";
import { RECEIPE, PRODUCT  } from "store/constants";
import Receta from "pages/Recetas/Receta";
import Producto from "pages/ProductosQueAmamos/Producto";
import { SelectableProductTable } from "pages/Dietas/Components/SelectableProductTable/SelectableProductTable";
import { SelectableRecipeTable } from "pages/Dietas/Components/SelectableRecipeTable/SelectableRecipeTable";

export const SelectProductOrRecipeModal = ({openModal, onClose, previouslySelected }) => {
	
	const {t} = useTranslation("common");
	const [selectedList, setSelectedList] = useState({
		recipes: [],
		products: [],
	});

	useEffect(() => {
		setSelectedList({
			recipes: previouslySelected.recipes || [],
			products: previouslySelected.products || [],
		});
	}, [previouslySelected]);

	const tabs=[{
		label: t("recetas_tabs_recetas"),
		value:"recetario",
		component: SelectableRecipeTable,
		endTabComponent:
			(<div className="end-tab-components">
				<NewButton type={RECEIPE} modal={Receta} />
			</div>),
		selectedList,
		
	},{
		label: t("productos_productos"),
		value:"productos",
		component:SelectableProductTable,
		endTabComponent:
		<div className="end-tab-components">
			<NewButton type={PRODUCT} modal={Producto} />
		</div>
	}];

	const handleSeleccionarButton = () => {
		// console.log("===> previouslySelected: ", previouslySelected);
		// console.log("===> selectedList: ", selectedList);
		onClose(selectedList);
		setSelectedList({
			recipes: [],
			products: [],
		});
	};

	const handleBorrarSeleccionButton = () => {
		// console.log("===> previouslySelected: ", previouslySelected);
		// console.log("===> selectedList: ", selectedList);
		setSelectedList({
			recipes: [],
			products: [],
		});
		onClose({
			recipes: [],
			products: [],
		});
	};

	const handleCloseModal = () => {
		setSelectedList({
			recipes: [],
			products: [],
		});
		onClose(null);
	};

	return (
		<Modal fullWidth open={openModal} onClose={handleCloseModal} onClickClose={handleCloseModal} className={styles.modal}>
			{/* {console.log("===> previouslySelected: ", previouslySelected)} */}
			{/* {console.log("===> selectedList: ", selectedList)} */}
			<div className="main-container">

				<h4 className="title_section">{t("dietas_menu_anadir_receta_o_producto")}</h4>

				<MyTabs tabs={tabs} urlKey="seccion" root={false} disableUrlParams={true} propsForAll={{selectedList, setSelectedList}}/>

				<div style={{ display: "flex", justifyContent: "flex-end", "margin-top": "25px" }}>
					{/* <Button variant="outlined" color="secondary" onClick={handleBorrarSeleccionButton} style={{ "marginRight": "15px", "color": "#d25757", "border-color": "#d25757"}}>
						{t("dietas_menu_borrar_seleccion")}
					</Button> */}
					<Button variant="outlined" color="secondary" onClick={handleCloseModal} style={{ marginRight: "15px"}}>
						{t("cancelar")}
					</Button>
					<Button disabled={selectedList.recipes == 0 && selectedList.products == 0} variant="contained" color="secondary" onClick={handleSeleccionarButton}>
						{t("seleccionar")}
					</Button>
				</div>

			</div>
		</Modal>
	);
};