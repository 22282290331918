
import React, {useState, useRef,useEffect} from "react";


import {
	TextField,
	Checkbox
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {Autocomplete} from "@mui/lab";
import { useSelector } from "react-redux";

function useTraceUpdate(props) {
	const prev = useRef(props);
	useEffect(() => {
		prev.current = props;
	});
}
const MultiRefListaAlimentos = props => {
	useTraceUpdate(props);
	const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
	const checkedIcon = <CheckBoxIcon fontSize="small" />;
	let options = props.options;
	// options = options?.filter(o=> o._id && !o._trash && o.nombre ) || [];
	// options = options?.sort((rowA, rowB) => {
	// 	let a = rowA.nombre;
	// 	let b = rowB.nombre;
	// 	a = a ? a.toLowerCase() : a;
	// 	b = b ? b.toLowerCase() : b;
	// 	return a === b ? 0 : a > b ? 1 : -1;
	// });

	const getLabel = (value) => {
		const obj = options.find(o=>o.value===value);
		return obj ? obj.label : "";
	};
	const handleChange = (event, value, reason, detail) =>  {
		props.onChange(value, reason, detail);
	};
	return <Autocomplete
		id="combo-box-demo"
		size="small"
		multiple
		defaultValue={props.value}
		value={props.value}
		disableCloseOnSelect
		options={options.map(o=>o.value)}
		getOptionLabel={option => getLabel(option)}
		renderOption={(props, option,{selected}) => (
			<div {...props}>
				<Checkbox
					icon={icon}
					checkedIcon={checkedIcon}
					style={{ marginRight: 8 }}
					checked={selected}
					color="primary"
				/>
				{getLabel(option)}
			</div>
		)}
		style={{ width: "100%" }}
		onChange={handleChange}
		renderInput={params => (
			<>
				<TextField
					{...params}
					variant="outlined"
					fullWidth
				/>
			</>
		)}
	/>;
    
};

export default MultiRefListaAlimentos;