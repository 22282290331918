import React, { useState, useEffect } from "react";
import { useImmer } from "use-immer";
import { FormGroup, Typography } from "@mui/material";
import Radio from "components/FormComponents/Radio/Radio";
import "./DietaEquivalencias.scss";
import ButtonAddTiempo from "../../ButtonAddTiempo";
import TiempoComidaVertical from "../../TiempoComidaVertical";
import PropTypes from "prop-types";
import Box from "components/Box/Box";
import grupo from "misc/grupos";

const Equivalencias = (props) => {
	const [resultados, setResultados] = useState({});	
	const [dieta, setDieta] = useImmer(
		props.value
			? props.value
			: {
				nombre: "",
				recomendaciones: "",
				lista_equivalencias: "",
				distribuida: false,
				tiempos_comida: [
					{
						nombre_tiempo: {
							name: "desayuno",
							label: "Desayuno",
							img: "desayuno",
							color: "#EDC940"
						},
						cereales: undefined, proteinas: undefined, grasas: undefined, frutas: undefined, 
						verduras: undefined, lacteos: undefined, azucares: undefined, agua: undefined, 
						leguminosas: undefined
					},
					{
						nombre_tiempo: {
							name: "colacion1",
							label: "Colación",
							img: "colacion1",
							color: "#54BDE9"
						},
						cereales: undefined, proteinas: undefined, grasas: undefined, frutas: undefined, 
						verduras: undefined, lacteos: undefined, azucares: undefined, agua: undefined, 
						leguminosas: undefined
					},
					{
						nombre_tiempo: {
							name: "comida",
							label: "Comida",
							img: "comida",
							color: "#72BEB2"
						},
						cereales: undefined, proteinas: undefined, grasas: undefined, frutas: undefined, 
						verduras: undefined, lacteos: undefined, azucares: undefined, agua: undefined, 
						leguminosas: undefined
					},
					{
						nombre_tiempo: {
							name: "colacion2",
							label: "Colación",
							img: "colacion2",
							color: "#4868AC"
						},
						cereales: undefined, proteinas: undefined, grasas: undefined, frutas: undefined, 
						verduras: undefined, lacteos: undefined, azucares: undefined, agua: undefined, 
						leguminosas: undefined
					},
					{
						nombre_tiempo: {
							name: "cena",
							label: "Cena",
							img: "cena",
							color: "#9563A3"
						},
						cereales: undefined, proteinas: undefined, grasas: undefined, frutas: undefined, 
						verduras: undefined, lacteos: undefined, azucares: undefined, agua: undefined, 
						leguminosas: undefined
					}
				]
			}
	);

	var [totales, setTotales] = useState({});

	useEffect(() => {
		calcTotal();
		props.onChange(dieta.tiempos_comida, "tiempos_comida");
		props.onChange(dieta.distribuida, "distribuida");
	}, [dieta]);

  
	const calcTotal = () => {
		let total = dieta.tiempos_comida.reduce(
			(totales, next) => {

				totales.cereales += next.cereales ? +next.cereales : 0;
				totales.proteinas += next.proteinas ? +next.proteinas : 0;
				totales.grasas += next.grasas ? +next.grasas : 0;
				totales.frutas += next.frutas ? +next.frutas: 0;
				totales.verduras += next.verduras ? +next.verduras: 0;
				totales.lacteos += next.lacteos ? +next.lacteos : 0;
				totales.azucares += next.azucares ? +next.azucares: 0;
				totales.agua += next.agua ? +next.agua : 0;
				totales.leguminosas += next.leguminosas ? +next.leguminosas : 0;
				return totales;
			},
			{
				cereales: 0,
				proteinas: 0,
				grasas: 0,
				frutas: 0,
				verduras: 0,
				lacteos: 0,
				azucares: 0,
				agua: 0,
				leguminosas: 0
			}
		);
		

		const resultados = grupo.reduce((acc, g)=>{
			acc.proteinas += total[g.name]*g.proteinas;
			acc.lipidos += total[g.name]*g.lipidos;
			acc.hidratos_de_carbono += total[g.name]*g.hidratos_de_carbono;
			acc.cal += total[g.name]*g.cal;
			return acc;
		},{
			proteinas: 0,
			lipidos: 0,
			hidratos_de_carbono: 0,
			cal: 0
		});
		total = Object.entries(total).reduce((acc, [key, val])=>{
			if(val>0)return {...acc, [key]:val};
			return acc;
		},{});


		var cien = resultados.proteinas + resultados.lipidos + resultados.hidratos_de_carbono;
		if(cien !== 0) {
			resultados.proteinas = (resultados.proteinas *100 / cien).toFixed(2);
			resultados.lipidos = (resultados.lipidos *100 / cien).toFixed(2);
			resultados.hidratos_de_carbono = (resultados.hidratos_de_carbono *100 / cien).toFixed(2);
			setResultados(resultados);	
		}
		

		setTotales(total);
	};
	const addTiempoComida = (idx_tiempo) => {
		setDieta(draft => {
			draft.tiempos_comida.splice(idx_tiempo, 0, {
				nombre_tiempo: {
					name: "extra",
					label: "",
					img: "",
					color: "#A5DD72"
				},
				cereales: undefined, proteinas: undefined, grasas: undefined, frutas: undefined, 
				verduras: undefined, lacteos: undefined, azucares: undefined, agua: undefined, 
				leguminosas: undefined
			});
		});
	};

	const deleteTiempo = (idx_tiempo) => {
		setDieta(draft => {
			draft.tiempos_comida.splice(idx_tiempo,1);
		});
	};

	const onChangeTiempoComida = (name, value, idx) => {
		setDieta(draft => {
			draft.tiempos_comida[idx].nombre_tiempo[name] = value;
		});
	};

	return (
		<div className="container-dieta-equivalentes printDiv">
			<FormGroup>
				<div className="avoid-print">

					<div style={{marginTop:"20px"}}>Selecciona como quieres que visualice los equivalentes tu paciente</div>
					<Radio 
						row
						value={dieta.distribuida}
						parse
						onChange={(v)=>{
							setDieta(draft=>{
								draft.distribuida = JSON.parse(v);
							});}} 
						options={[{label:"Distribuir por comidas",value:true},{label:"Total por día",value:false}]} />

				</div>
				<div className="results-box" style={{margin:"auto", marginBottom:"10px",width:"400px"}}>
					<div style={{display:"flex",height:"60px", justifyContent:"space-between",background: "#F6F6F6",padding:"30px 60px"}}>
						<div className="resultado-eq">
							<span className="resultado">{resultados.hidratos_de_carbono}</span>
							<Typography>CH (%)</Typography>
						</div>
						<div className="resultado-eq">
							<span className="resultado">{resultados.proteinas}</span>
							<Typography>P (%)</Typography>
						</div>
						<div className="resultado-eq">
							<span className="resultado">{resultados.lipidos}</span>
							<Typography>G (%)</Typography>
						</div>
						<div className="resultado-eq">
							<span className="resultado">{resultados.cal}</span>
							<Typography>CAL</Typography>
						</div>
					</div>
				</div>
				<Box label={dieta.distribuida ? "Distribución de equivalentes por comida" : "Distribución de equivalentes total por día"}>
					<div className="content-table">
						{dieta.distribuida && (
								
							<div className="header" style={{paddingLeft:"25%", display:"flex", alignItems:"flex-end"}}>
								<div className="row-table" style={{width: "95%", display: "inline-flex", paddingRight:"10px"}}>
									<div className="container-add-tiempo-comida avoid-print">
										<ButtonAddTiempo addTiempoComida={addTiempoComida} index={0} text="+"/>
									</div>
									{dieta.tiempos_comida.map((value, i) => (
										<div key={i} style={{display:"flex", width:`${100/dieta.tiempos_comida.length}%`}}>
											<TiempoComidaVertical tiempoComida={value} index={i} onChange={onChangeTiempoComida} del={deleteTiempo}/>
											{/* <SelectIcon tiempoComida={value} index={i} onChange={onChangeTiempoComida }/>
										<SelectTitle tiempoComida={value} index={i} onChange={onChangeTiempoComida }/>
										<div className="container-add-tiempo-comida avoid-print">
											<ButtonAddTiempo addTiempoComida={addTiempoComida} index={i} text="+" />
										</div> */}
										
											<div className="container-add-tiempo-comida avoid-print" style={{width: "20%"}}>
												<ButtonAddTiempo addTiempoComida={addTiempoComida} index={i+1} text="+" />
											</div>
										</div>
									))}
								</div>
								<div style={{display: "inline-block", width:"5%"}}>
                    TOTAL
								</div>
								
							</div>
						)}
						<div className="body">
							{grupo.map((grupo, idx_grupo) => (
								<div className="row" key={idx_grupo} style={{background:grupo.color+"19", display: "flex", alignItems: "center", justifyContent:"space-between"}}>
									<div style={{width:dieta.distribuida ? "25%" : "75%", display: "inline-block"}}>
										<div style={{display: "flex", alignItems: "center"}}>

											<div className="imagen-alimento">
												<img
													src={require(`assets/img/${grupo.name}.png`)}
													alt={grupo.label}
													style={{padding:"7px"}}
													className="img-category"
												/>
											</div>
											<div
												className="nombre-alimento"
												style={{
													textAlign:"left",
													// background: grupo.color,
													color:grupo.color, 
												}}
											>
												{grupo.label}
											</div>
										</div>
									</div>
									{dieta.distribuida? (
										<>
											<div style={{width:"70%", display: "inline-block"}}>
												<div style={{display: "flex", alignItems: "center"}}>

													{dieta.tiempos_comida.map((tiempo, idx_tiempo) => (
												
														<div key={idx_tiempo} style={{display: "flex", justifyContent: "center"}}>
															<input
																className="eqInput"
																type="number"
																id=""
																placeholder={0}
																value={tiempo[grupo.name]||""}
																onChange={(e) => {
																	e.persist();
																	setDieta(draft=>{
																		draft.tiempos_comida[idx_tiempo][grupo.name] = +e.target.value;
																	});
																}}
															/>
														</div>
													))}
												</div>
											</div>
											<div style={{width:"5%", display: "inline-block"}}>
												<div style={{textAlign:"center"}}>
													{totales[grupo.name]||0}
												</div>
											</div>
										</>
									) : (
										<>
											<div style={{width:"25%", textAlign:"center", padding:"0 5px"}}>
												<input
													className="eqInput"
													style={{width:"100%"}}
													type="number"
													id=""
													placeholder={0}
													value={totales[grupo.name]?totales[grupo.name]:""}
													onChange={(e) => {
														e.persist();
														setDieta(draft=>{

															draft.tiempos_comida.map(tiempo=>{
																tiempo[grupo.name] = undefined;
															});
															draft.tiempos_comida[0][grupo.name] = +e.target.value;
															
														});
													}}
												/>
											</div>
										</>
									)}
								</div>
							))}
						</div>
					</div>
				</Box>
			</FormGroup>
		</div>
	);
};


export default Equivalencias;

Equivalencias.propTypes = {
	value: PropTypes.object,
	onChange: PropTypes.func
};


