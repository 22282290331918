import React, { useState, useEffect } from "react";
import { RECEIPE, PRODUCT  } from "store/constants";
import { useSelector } from "react-redux";
import { Clear, FiberManualRecord } from "@mui/icons-material";
import "./ListOfAddedProductsAndRecipes.scss";
import { Tooltip, 
	// FormGroup, TextareaAutosize, Button, , TextField 
} from "@mui/material";
// import CrudTable from "components/Table/CrudTable/CrudTable";
// import { useTranslation } from "react-i18next";


const outlineStyle = {
	"background-color": "transparent",
	"border": "1px solid lightgrey",
	"border-radius": "4px",
	"padding": "8px 16px",
	"font-weight": 400,
	"font-size": "11px",
	"width": "100%",
	"margin-top": "5px",
};

export const ListOfAddedProductsAndRecipes = ({products = [], recipes = [], handleRemoveProduct, handleRemoveRecipe, idx_tiempo, idx_y}) => {

	// const {t} = useTranslation("common");
	const recipesData = useSelector(state => state[RECEIPE.name].data.filter(item => recipes?.includes(item._id)));
	if (recipesData.length > 0){
		// console.log("recipesData: ", recipesData);
	}
	const productsData = useSelector(state => state[PRODUCT.name].data.filter(item => products?.includes(item._id)));
	const empty = products?.length === 0 && recipes?.length === 0;

	return (
		<>
			{/* <div style={{display: "flex", "place-content": "space-between"}} className="container-list-of-added-products-and-recipes">
			</div> */}

			<h4 style={{marginTop: "10px"}} className="avoid-print"> Recetas y Productos: </h4>

			{!empty && <h4 style={{marginTop: "10px"}} className="allow-print"> Recetas y Productos: </h4>}


			{ !empty &&
			<div className="container-list-of-added-products-and-recipes">

				{recipesData.map(recipe => (
					<div className="list-item" key={recipe._id} style={{display: "flex", "align-items": "center", "white-space": "nowrap", "margin": "7px 0px"}}>
						{/* <img style={{maxHeight:"24px"}} src={`${process.env.REACT_APP_FILE_URL}${recipe.foto && recipe.foto[0] ? recipe.foto[0].path : ""}`} alt=" "/> */}
						{
							recipe.foto && recipe.foto[0] ? 
								<img style={{maxHeight:"24px", maxWidth: "26", objectFit: "cover"}} src={`${process.env.REACT_APP_FILE_URL}${recipe.foto[0].path}`} alt=" "/>
								: 
								<FiberManualRecord style={{"font-size": "0.5rem", "color": "grey"}}/>
						}
						<p style={{width: "100%", margin: "0px 7px", "overflow": "hidden"}}>{recipe.nombre}</p>
						<span className="remove-action" style={{"color": "#808080", "cursor": "pointer"}} onClick={() => handleRemoveRecipe(idx_tiempo, idx_y, recipe._id)}>
							<Clear fontSize="small"/>
						</span>
					</div>
				))}

				{productsData.map(product => (
					<div className="list-item" key={product._id} style={{display: "flex", "align-items": "center", "white-space": "nowrap", "margin": "7px 0px"}}>
						{
							product.foto && product.foto[0] ? 
								<img style={{maxHeight:"24px", maxWidth: "26", objectFit: "cover"}} src={`${process.env.REACT_APP_FILE_URL}${product.foto[0].path}`} alt=" "/>
								: 
								<FiberManualRecord style={{"font-size": "0.5rem", "color": "grey"}}/>
						}
						{/* <img style={{maxHeight:"24px"}} src={`${process.env.REACT_APP_FILE_URL}${product.foto && product.foto[0] ? product.foto[0].path : ""}`} alt=" "/> */}
						<p style={{width: "100%", margin: "0px 7px", "overflow": "hidden"}}>{product.nombre}</p>
						<span  className="remove-action" style={{"color": "#808080", "cursor": "pointer"}} onClick={() => handleRemoveProduct(idx_tiempo, idx_y, product._id)}>
							<Clear fontSize="small"/>
						</span>
					</div>
				))}


			</div>
			}

			{ empty &&
			<div style={outlineStyle} className="avoid-print">
				<div style={{"display": "flex", "justify-content": "center"}}>
					<img src={require("assets/img/icons/cubiertos.png")} alt="no_data" style={{
						"width": "20%", 
						"margin": "10px",
						"max-width": "30px"
					}}/>
				</div>

				<h4 style={{"text-align": "center", "max-width": "110px", "margin": "auto", "font-size": "12px" }}>
					Añade recetas o productos a tu plan.
				</h4>
			</div>
			}
		</>
	);
};
