import React from "react";
import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS, LineController, LineElement, PointElement, LinearScale, Title , ArcElement, Tooltip, Legend} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Pie } from 'react-chartjs-2';
import Calendar from "components/FormComponents/Calendar/Calendar"
import {CUSTOMER} from 'store/constants'
import {get} from "api";

import {useQuery} from "react-query";
import moment from "moment";
import { useSelector } from "react-redux";
import {PLAN} from 'store/constants'

const options= {
	plugins: {
			datalabels: {
					formatter: (value, ctx) => {
						let datasetSum = 0;
            let currentDataset = ctx.chart.data.datasets[ctx.datasetIndex].data;
            let label = ctx.chart.data.datasets[ctx.datasetIndex].label;
            currentDataset.forEach(data => {
                datasetSum += data;
            });
            
            let percentage = (value * 100 / datasetSum).toFixed(2) + "%";
            return label+":"+percentage;
					},
					color: 'black',
			}
	}
}
ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title, ArcElement, Tooltip, Legend, ChartDataLabels);
function processDates({data}={}, mesActual) {
	if(!data)return {}

	const result = {};
	// console.log({data})
	// data = data.filter(c=>{
	// 	console.log({primera:c.subscripcion.primera_fecha_pago, between:moment(mesActual).startOf("month").toISOString()})
	// 	return moment(c.subscripcion.primera_fecha_pago).isBetween(moment(mesActual).subtract(1, "year").startOf("month"), moment(mesActual).subtract(1, "year").endOf("month"))// || moment(c.subscripcion.vencimiento).isBetween(moment(mesActual).startOf("month"), moment(mesActual).endOf("month"))
	// })
	// console.log({data})


	for (const item of data) {
			const renovo = item.renovado;
			const primeraFechaPago = moment(item.subscripcion.primera_fecha_pago);
			const currentDate = moment(mesActual)

			// Calculate the difference in years, months, and days
			let yearsDiff = Math.round(moment(currentDate).diff(primeraFechaPago, "years",true)) //currentDate.getFullYear() - primeraFechaPago.getFullYear();
			// let monthsDiff = currentDate.getMonth() - primeraFechaPago.getMonth();
			// let daysDiff = currentDate.getDate() - primeraFechaPago.getDate();

			console.log({ yearsDiff, item, primera_fecha_pago:item.subscripcion.primera_fecha_pago, clave:item.clave})
			// // Adjust the year difference based on the month and day
			// if (monthsDiff < 0 || (monthsDiff === 0 && daysDiff < 0)) {
			// 		yearsDiff--;
			// }

			// // Round to the nearest year
			// if (monthsDiff >= 6) {
			// 		yearsDiff++;
			// }
			// if(yearsDiff===0){
			// 	console.log({item})
			// }

			if (!result[yearsDiff]) {
					result[yearsDiff] = [0, 0];
			}

			if (renovo) {
					result[yearsDiff][0]++;
			} else {
					result[yearsDiff][1]++;
			}
	}

	return result;
}

function processSubscriptions({data}={}, planes) {
	if(!data)return {}
	console.log({data, planes})
	const result = {};

	for (const item of data) {
			const idPlan = item.subscripcion.id_plan;
			const renovo = item.renovado;

			const plan = planes.data.find(p=>p._id===idPlan)?.nombre

			if (!result[plan]) {
					result[plan] = [0, 0];
			}

			if (renovo) {
					result[plan][0]++;
			} else {
					result[plan][1]++;
			}
	}

	return result;
}

export default function Analytics(){
  const planes = useSelector(state => state["PLAN"]);

	const [mesActual, setMesActual] = React.useState();
	const {data:clientes} = useQuery([CUSTOMER.name, "renovacion", moment(mesActual).format("YYYY/MM")], ({queryKey})=> get("admin/customers/renovaciones",{mes:queryKey[2]}));
	const d = processSubscriptions(clientes, planes);
	const dataAnos = processDates(clientes, mesActual);
	
	console.log({dataAnos})
	const dataSets = React.useMemo(()=>{
		// clientes.
	},[clientes]);
	const dataPlan = {
		title: {
      display: true,
      text: 'Por tipo de plan',
    },
		labels: ['Renovados', 'No Renovados'],
		datasets: Object.entries(d||{}).map(([k, v])=>(
			{
				label: k,
				data: v,
				backgroundColor: [
					'rgba(54, 162, 235, 0.2)',
					'rgba(255, 99, 132, 0.2)',
				],
				borderColor: [
					'rgba(54, 162, 235, 1)',
					'rgba(255, 99, 132, 1)',
				],
				borderWidth: 1,
			}))
	};
	const dataAno= {
		labels: ['Renovados', 'No Renovados'],
		datasets: Object.entries(dataAnos||{}).map(([k, v])=>(
			{
				label: `Año ${k}`,
				data: v,
				backgroundColor: [
					'rgba(54, 162, 235, 0.2)',
					'rgba(255, 99, 132, 0.2)',
				],
				borderColor: [
					'rgba(54, 162, 235, 1)',
					'rgba(255, 99, 132, 1)',
				],
				borderWidth: 1,
			})),
			plugins: [ChartDataLabels],
			
	};
	return <div>
			<Calendar value={mesActual} onChange={setMesActual} />
			<div style={{display:"grid", gridTemplateColumns:"50% 50%"}}>
				<div>
					Por tipo de plan
					<Pie data={dataPlan} options={options}  />
				</div>
		
				<div>
					Por año de contratación
					<Pie data={dataAno} options={options} />
				</div>
					
			</div>
		</div>
}