import React from "react";
import CrudTable from "components/Table/CrudTable/CrudTable";
import Cliente from "./Cliente";
import { useSelector } from "react-redux";
import {CUSTOMER, PLAN} from 'store/constants'
import RefCell from "components/Table/CellTypes/RefCell/RefCell";
import {formatCurrency} from "currency";
import Header from "../../components/Header/Header";
import DateCell from "components/Table/CellTypes/DateCell/DateCell";
import {get} from "api";
import LinkCell from "components/Table/CellTypes/LinkCell/LinkCell";
import BoolCell from "components/Table/CellTypes/BoolCell/BoolCell";
import MapCell from "components/Table/CellTypes/MapCell/MapCell";
import Calendar from "components/FormComponents/Calendar/Calendar";
import {useQuery} from "react-query";
import {crudFunctions} from "api";
import moment from "moment";


const TablaClientes = props => {
  // const [data, setData] = React.useState([]);
  const [mesActual, setMesActual] = React.useState(new Date());
	const [page, setPage] = React.useState();
	const [sort, setSort] = React.useState();
	const [filterData, setFilterData] = React.useState({});
	const actions = crudFunctions(CUSTOMER);
	const query = React.useMemo(()=>({
		"subscripcion.gratis":{$ne:true},
		"subscripcion.id_plan":{$nin:["5ec1da89d20c904e60c4617e","5ec1da89d20c904e60c4618d"]},
		"subscripcion.activo":true, 
		"subscripcion.primera_fecha_pago":{$gt: moment(mesActual).startOf("month").toDate(), $lt: moment(mesActual).endOf("month").toDate()}, 
	}),[mesActual]);

	const {data, status} = useQuery([CUSTOMER.name, "pagando", {page, filter:filterData, query, sort}], actions.getPaginated, {keepPreviousData:true});
	
	// ;const {data} = useQuery([CUSTOMER.name, "renovacion", mesActual ? moment(mesActual).format("YYYY/MM"):""], ({queryKey})=> get("admin/customers/renovaciones",{mes:queryKey[2]}));
	// React.useEffect(() => {

	// 	(async function(){
	// 		let d = await get("admin/customers/renovaciones");
	// 		setData(d.data);
	// 	})()
		
	// }, [])


  const columns = React.useMemo(
    () => [
      {
        Header: "Clave",
        accessor: "clave"
      },
			{
        Header: "Nombre",
        accessor: "nutritionist.nombre"
      },
			{
        Header: "Correo",
        accessor: "nutritionist.correo"
      },
			{
        Header: "Telefono",
        accessor: "nutritionist.telefono"
      },
			{
        Header: "Capacitacion",
        accessor: "fecha_capacitacion",
				Cell:BoolCell,
				labelOnTrue:(cell)=>`${moment(cell.row.original.fecha_capacitacion).format("DD/MMM/YYYY")}`
			},
			{
        Header: "Primera fecha de pago",
				accessor: "subscripcion.primera_fecha_pago",
				Cell:DateCell,
				filter:"daterange",
				sortType: "date",
				dateFormat:["year"]
			},
			{
        Header: "Tipo de pago",
				accessor: "subscripcion.tipo_pago",
				filter:"select",
				filterOptions:[
					{value:"", label:"Todos"},
					{value:"mensual", label:"Mensual"},
					{value:"anual", label:"Anual"},
					{value:"Manual", label:"Manual"},
				]
			},
			{
				Header: "Plan",
				accessor: "subscripcion.id_plan",
				type: PLAN,
				Cell: RefCell,
				filter:"ref",
				exportType: "ref"
			},
			{
        Header: "Correo",
				accessor: "subscripcion.link_pago",
				link: (value, data) => `mailto:${data.nutritionist.correo}`,
				filter:null,
				Cell:LinkCell,
				label:"Enviar"
			},
			{
        Header: "Whatsapp",
				accessor: "subscripcion.link_pago2",
				link: (value, data) => `https://api.whatsapp.com/send?phone=${data.nutritionist.telefono}`,
				filter:null,
				Cell:LinkCell,
				label:"Enviar"
			},
			
    ],
    []
	);

	

	
  const dataChanged = (data) => {
		const total = data.reduce((acc, val)=>acc+(val.ultimo_pago?.amount||0), 0);
		const totalArr = data.map(val=>val.ultimo_pago?.amount);
		console.log({total, data, totalArr})
	}

  return (
    <>
			<Header title="Clientes" />
			Selecciona mes de renovaciones
			<Calendar value={mesActual} onChange={setMesActual}  />
      <CrudTable
       	data={data?.data || []}
				status={status}

				columns={columns}
				filters={columns}
        type={CUSTOMER}
        modal={Cliente}
        options={{loadBeforeEdit:true, orderBy:true}}

				onFilterChange={setFilterData}
				onPaginationChange={setPage}
				onSortChanged={(s)=>setSort(s[0])}
				totalRows={data?.total}
				manualPagination
      />
    </>
  );
};

export default TablaClientes;
