import React from "react";
import CrudTable from "../../components/Table/CrudTable/CrudTable";
import RefCell from "../../components/Table/CellTypes/RefCell/RefCell";
import Producto from "./Producto";
import { useSelector } from "react-redux";
import { PRODUCT, PRODUCT_CATEGORY, PRODUCT_TAG } from "store/constants";
import FileCell from "components/Table/CellTypes/FileCell/FileCell";
import AppView from "components/AppView/AppView";

const TabProductos = () => {
	const data = useSelector(state => state[PRODUCT.name]);
  
	const columns = React.useMemo(
		() => [
			{
				Header: "Categoría",
				accessor: "categorias",
				type: PRODUCT_CATEGORY,
				Cell: RefCell
			},
			{
				Header: "Nombre",
				accessor: "nombre"
			},
			{
				Header: "Imagen",
				accessor: "foto[0]",
				Cell:FileCell,
				disableSortBy: true,
				disableFilters: true,
			}
		],
		[]
	);
	const filters = React.useMemo(
		() => [
			{
				Header: "Categoría",
				accessor: "categorias",
				type: PRODUCT_CATEGORY,
				filter: "ref"
			},
			{
				Header: "Nombre",
				accessor: "nombre"
			},
			{
				Header: "Etiqueta",
				accessor: "sellos",
				type: PRODUCT_TAG,
				filter: "ref"
			}
		],
		[]
	);

	return (
		<>
			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				type={PRODUCT}
				filters={filters}
				modal={Producto}
				options={{ orderBy: true }}
				headerActions={[<AppView key="app_view" img="productos" />]}
			/>
		</>
	);
};

export default TabProductos;
