import React from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { CONSULTATION } from "store/constants";

export default function SlaughterNinas(props){
	let match = useRouteMatch();
	const dataConsulta = useSelector(state=>state[CONSULTATION.name].data[match.params.idPaciente].find(n=>n._id===match.params.idConsulta));
	
	const total = (0.610*(dataConsulta.ninos_triceps+dataConsulta.ninos_pierna_media)+5.1);	

	if(isNaN(total)) return (0);
	return (total).toFixed(2);

}