import React from "react";
import Select from "components/FormComponents/Select/Select";
import PropTypes from "prop-types";

// This is a custom filter UI for selecting
// a unique option from a list
export default function SelectColumnFilter({
	column: { filterValue, setFilter, preFilteredRows, id, filterOptions, specialFilter, ...others },
}) {
	let specialFilt = "";
	if(specialFilter === "subcatSmae") specialFilt = others.filterData.categoria;
	if((specialFilt && others.filterOptionsMap[specialFilt]) || specialFilt === "preparados"){
		let a = filterOptions[0];
		if(specialFilt === "preparados"){
			filterOptions = filterOptions.filter(val => val.type === "preparados");
			filterOptions.unshift(a);
		}else{
			filterOptions = filterOptions.filter(val => others.filterOptionsMap[specialFilt].includes(val.value));
			filterOptions.unshift(a);
		}
	}
	return (
		<>
			{specialFilter === "subcatSmae" ? 
				<Select disabled={(Object.keys(others.filterOptionsMap).includes(specialFilt) || specialFilt === "" || specialFilt === "preparados") ? false : true} value={(Object.keys(others.filterOptionsMap).includes(specialFilt) || specialFilt === "" || specialFilt === "preparados") ? filterValue : ""} options={filterOptions} onChange={setFilter} />
				:
				<Select value={filterValue} options={filterOptions} onChange={setFilter} />

			}
		</>
	);
}