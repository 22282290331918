import React from "react";
import DateFnsUtils from "@date-io/date-fns";
// import { MuiPickersUtilsProvider, DatePicker, TimePicker, DateTimePicker } from "@material-ui/pickers";
import { TimePicker, DatePicker, DateTimePicker, LocalizationProvider  } from "@mui/x-date-pickers";

import es from "date-fns/locale/es";
import { IconButton, TextField } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

export default function CalendarPicker(props) {
	return (
		<div style={{position:"relative"}}>
			{/* <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>TODO */}
			{/* <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es"> */}

				{props.element?.subtype==="datetime" ?
					<DateTimePicker
						renderInput={(inputProps) => <TextField {...inputProps} size="small" disabled variant="outlined" />}
						size="small"
						inputVariant="outlined"
						format="dd/MM/yyyy HH:mm"
						minutesStep={5}
						ampm={false} 
						value={props.value?props.value:null}
						onChange={date => props.onChange(date, props.name)}
					/>


					:
					props.element?.subtype==="time" 
						?
						<TimePicker
							renderInput={(inputProps) => <TextField {...inputProps} size="small" disabled variant="outlined" />}
							size="small"
							inputVariant="outlined"
							autoOk
							ampm={false} 
							minutesStep={5}
							value={props.value?props.value:null}
							onChange={date => props.onChange(date, props.name)} />
						:
						<DatePicker
							renderInput={(inputProps) => <TextField {...inputProps} size="small" disabled variant="outlined" />}
							size="small"
							inputVariant="outlined"
							autoOk
							format="dd/MM/yyyy"
							views={['year', 'month', 'day']}
							value={props.value?props.value:null}
							onChange={date => props.onChange(date, props.name)} />
				}
				<IconButton style={{top:-5,right:-25,position:"absolute"}} onClick={()=>props.onChange(null,props.name)}><ClearIcon /></IconButton>
			{/* </LocalizationProvider> */}

			{/* </MuiPickersUtilsProvider> */}
		</div>
	);
}