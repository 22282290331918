import React, {useState} from "react";
import Equivalencias from "./Equivalencias";
import { Button, Modal, Fade } from "@mui/material";
import CrudTable from "components/Table/CrudTable/CrudTable";
import BaseTable from "components/Table/BaseTable/BaseTable";
import EquivalenciaCell from "../EquivalenciaCell/EquivalenciaCell";
import MapCell from "components/Table/CellTypes/MapCell/MapCell";
import SelectFilter from "components/Table/FilterTypes/SelectFilter/SelectFilter";
import { useSelector } from "react-redux";
import { EQUIVALENT_SMAE, EQUIVALENT_SMAE_SUBCAT } from "store/constants";

import {medidas, catEquivalenciasSmae, catEquivalenciasSmaeMap, subcatSmaeMap, subcatEquivalenciasSmae, subcatSmaeFilter} from "misc/grupos";
import allColumnsOrig from "./columnasTabla";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";

import styles from "Styles.module.scss";
import "./Equivalencias.scss";

const _ = require("lodash");

const TabEquivalencias = () => {
	const data = useSelector(state => state[EQUIVALENT_SMAE.name]);
	let subcat = useSelector(state => state[EQUIVALENT_SMAE_SUBCAT.name].data);
	let filterSubcat = subcat;
	subcat = subcat.map(val=>({[val._id]: val.nombre}));
	filterSubcat = filterSubcat.filter(val=>!val?._trash);
	filterSubcat = filterSubcat.map(val=>({value: val._id, label: val.nombre, type: "preparados"}));
	const [showModal, setShowModal] = useState(false);
	const [Etable, setETable] = useState(0);
	const myData = data.data || [];

	const columns = React.useMemo(
		() => [
			{
				accessor: "categoria",
				Header: "Grupo",
				Cell: MapCell,
				map: catEquivalenciasSmaeMap,
				style: {textTransform:"capitalize"},
			},
			{
				accessor: "subcategoria",
				Header: "Subcategoria",
				Cell: MapCell,
				map: Object.assign({},subcatSmaeMap, ...subcat),
				style: {textTransform:"capitalize"},
			},
			{
				Header: "Alimento",
				accessor: "nombre"
			},
			{
				accessor: "medida",
				Header: "Unidad",
				Filter: SelectFilter,
				filterOptions:[{label:"Todos",value:""},...medidas]
			},
			{
				accessor: "cantidad",
				Header: "Cantidad"
			},
			{
				accessor: "equivalencia",
				Header: "Equivalente",
				Cell: EquivalenciaCell,
				disableFilters: true,
				disableSortBy: true
			}
		],
	);
	const filters = React.useMemo(
		() => [
			
			{	
				Header: "Grupo",
				accessor: "categoria",
				filter:"smae",
				filterOptions:[{label:"Todos",value:""},...catEquivalenciasSmae],
				deleteAllOnDelete: true
			},
			{	
				Header: "Subgrupo",
				accessor: "subcategoria",
				filter:"smae",
				filterOptions:[{label:"Todos",value:""},...subcatEquivalenciasSmae, ...filterSubcat],
				filterOptionsMap: subcatSmaeFilter,
				specialFilter: "subcatSmae",
			},
			{
				Header: "Alimento",
				accessor: "nombre"
			},
			{
				accessor: "medida",
				Header: "Unidad",
				filter:"select",
				filterOptions:[{label:"Todos",value:""},...medidas]
			},
			{
				accessor: "cantidad",
				Header: "Cantidad"
			}
		],
	);

	let allColumns = new Set();
	for(let i=0;i<allColumnsOrig.length;i++){
		let columna = allColumnsOrig[i];
		for(let j=0;j<myData.length;j++){
			let consulta = myData[j];
			if(_.get(consulta, columna.accessor)){
				allColumns.add(columna);
			}
		}
	}

	allColumns = [...allColumns];

	function getTable(table){
		setETable(table);
	}

	return (
		<div className="container-equivalencias">
			<div className="container-button-micronutrimentos">
				<Button variant="contained" onClick={()=>setShowModal(true)}>Ver micronutrimentos</Button>
			</div>
			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				type={EQUIVALENT_SMAE}
				modal={Equivalencias}
				filters={filters}
				options={{ orderBy: true, saveAs:true }}
			/>
			<Modal open={showModal} onClose={()=>setShowModal(false)} className={styles.modal}>
				<Fade in={showModal}>
					<div className={styles.paper} style={{width:"90%"}}>
						<div style={{textAlign: "right", marginBottom: "5px"}}>
							<IconButton color="secondary" onClick={()=>setShowModal(false)} style={{padding: 0}}>
								<ClearIcon />
							</IconButton>
						</div>
						<div className="container-table-sticky">
							<BaseTable 
								getTable={getTable}
								data={myData.filter(d=>!d._trash)} 
								columns={allColumns} 
								filters={filters}
								showScroll={{status: true, lengthColumns: allColumns.length, width: 300}}
								options={{filter: true,orderBy:true, defaultSorting: [{id: "categoria",desc: false}]}}
							/>
							<div className="container-close-button" style={{width:Etable.current?.offsetWidth}}>
								<Button variant="contained" color="primary" onClick={()=>setShowModal(false)}>Cerrar</Button>
							</div>
						</div>
					</div>
				</Fade>
			</Modal>
		</div>
	);
};

export default TabEquivalencias;
