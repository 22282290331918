import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { APPOINTMENT } from "../constants";

const loadAppointments = loadRequest(APPOINTMENT);
const insertAppointment = insertRequest(APPOINTMENT);
const updateAppointment = updateRequest(APPOINTMENT);
const trashAppointMent = trashRequest(APPOINTMENT);
const untrashAppointment = untrashRequest(APPOINTMENT);
const removeAppointment = removeRequest(APPOINTMENT);

function* rootSaga() {
	yield all([
		loadAppointments(),
		insertAppointment(),
		updateAppointment(),
		removeAppointment(),
		trashAppointMent(),
		untrashAppointment()
	]);
}
export default rootSaga;