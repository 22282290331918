import React from "react";
import CrudTable from "components/Table/CrudTable/CrudTable";
import Cliente from "./Cliente";
import { useSelector } from "react-redux";
import {CUSTOMER} from 'store/constants'
import Header from "../../components/Header/Header";
import DateCell from "components/Table/CellTypes/DateCell/DateCell";
import {get} from "api";
import LinkCell from "components/Table/CellTypes/LinkCell/LinkCell";
import BoolCell from "components/Table/CellTypes/BoolCell/BoolCell";
import ChartCell from "components/Table/CellTypes/ChartCell/ChartCell";
import Calendar from "components/FormComponents/Calendar/Calendar";
import {useQuery} from "react-query";
import moment from "moment";
const TablaClientes = props => {
  // const [data, setData] = React.useState([]);
  const [mesActual, setMesActual] = React.useState(new Date());
	const {data} = useQuery([CUSTOMER.name, "plazos", moment(mesActual).format("YYYY/MM")], ({queryKey})=> get("admin/customers/plazos",{mes:queryKey[2]}));
	// React.useEffect(() => {

	// 	(async function(){
	// 		let d = await get("admin/customers/renovaciones");
	// 		setData(d.data);
	// 	})()
		
	// }, [])


  const columns = React.useMemo(
    () => [
      {
        Header: "Clave",
        accessor: "clave"
      },
			{
        Header: "Nombre",
        accessor: "nutritionist.nombre"
      },
			{
        Header: "Correo",
        accessor: "nutritionist.correo"
      },
			{
        Header: "Telefono",
        accessor: "nutritionist.telefono"
      },
			{
        Header: "Ultima fecha de pago",
				accessor: "ultimo_pago.date",
				Cell:DateCell,
				filter:"daterange",
				sortType: "date"
			},
			{
        Header: "Ultimo pago",
				accessor: "ultimo_pago.amount",
				// Cell:DateCell,
				filter:"number",
				sortType: "number"
			},
			{
        Header: "Proxima fecha pago",
				accessor: "subscripcion.proxima_fecha_pago",
				Cell:DateCell,
				filter:"daterange",
				sortType: "date"
			},
			{
        Header: "Tipo de pago",
				accessor: "subscripcion.tipo_pago",
				filter:"select",
				filterOptions:[
					{value:"", label:"Todos"},
					{value:"mensual", label:"Mensual"},
					{value:"anual", label:"Anual"},
					{value:"Manual", label:"Manual"},
				]
			},
			{
        Header: "Asesor",
				accessor: "subscripcion.asesor",
				filter:"select",
				filterOptions:[
					{value:"", label:"Todos"},
					{value:"ericka", label:"Ericka"},
					{value:"brenda", label:"Brenda"},
					{value:"clara", label:"Clara"},
				]
			},
			{
        Header: "Suscripcion activa",
				accessor: "suscripcion_activa",
				Cell:BoolCell,
				filter:"bool"
			},
			{
        Header: "Renovado",
				accessor: "renovado",
				Cell:BoolCell,
				filter:"bool"
			},
			{
        Header: "Link stripe",
				accessor: "payments_user.external_id",
				link: (value) => `https://dashboard.stripe.com/customers/${value}`,
				filter:null,
				Cell:LinkCell,
				label:"Ver"
			},
			
			{
        Header: "Correo",
				accessor: "subscripcion.link_pago",
				link: (value, data) => `mailto:${data.correo}`,
				filter:null,
				Cell:LinkCell,
				label:"Enviar"
			},
			{
        Header: "Whatsapp",
				accessor: "subscripcion.link_pago2",
				link: (value, data) => `https://api.whatsapp.com/send?phone=${data.telefono}`,
				filter:null,
				Cell:LinkCell,
				label:"Enviar"
			},
			
    ],
    []
	);

	

	
  const dataChanged = (data) => {
		const total = data.reduce((acc, val)=>acc+(val.ultimo_pago?.amount||0), 0);
		const totalArr = data.map(val=>val.ultimo_pago?.amount);
		console.log({total, data, totalArr})
	}

  return (
    <>
			<Header title="Clientes" />
			Selecciona mes de renovaciones
			<Calendar value={mesActual} onChange={setMesActual} />
      <CrudTable
        data={data?.data||[]}
				columns={columns}
				filters={columns}
        status={{}}
        type={CUSTOMER}
        modal={Cliente}
        options={{loadBeforeEdit:true, orderBy:true, disableInsert:true, disableDelete:true}}
				onFilteredDataChange={dataChanged}

      />
    </>
  );
};

export default TablaClientes;
