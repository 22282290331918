import React from "react";
import BoxForm from "components/FormComponents/BoxForm/BoxForm";
import { useSelector } from "react-redux";

const moment = require("moment");
const elements = [
	{
		type: "calendar",
		name: "fecha",
		label: "Fecha de Consulta"
	},
	{
		type: "text",
		name: "problemas",
		label: "Problemas principales",
	},
	{
		type: "group",
		name: "informacion_tratamiento",
		label: "Información sobre tratamiento",
		fields: [{
				type: "text",
				name: "opinion",
				label: "¿Como te sentiste?",
			},{
				type:"text",
				name: "consumo_agua",
				label: "Consumo de agua simple"
			},{
				type: "starRating",
				name: "calificacion_dieta",
				label: "Calificar dieta"
			},{
				type: "starRating",
				name: "calificacion_ejercicio",
				label: "Calificar ejercicio"
				
			}
		]
	},
	{
		type: "text",
		name: "notas",
		label: "Notas",
	},
	{
		type: "text",
		name: "signos_sintomas",
		label: "Signos y Síntomas"
	},
	{
		type: "multiText",
		name: "objetivos",
		label: "Objetivos y metas para el paciente",
		flex: true,
		fullWidth: true,
		// fields: [
		// 	{
		// 		type:"text",
		// 		name:"objetivo",
		// 		labelInput: "Objetivo",
		// 	}
		// ]
	},
];
const elementsPrimeraConsulta = [
	{
		type: "calendar",
		name: "fecha",
		label: "Fecha de Consulta"
	},
	{
		type: "text",
		name: "notas",
		label: "Notas",
		multiline: true
	},
	{
		type: "text",
		name: "signos_sintomas",
		label: "Signos y Síntomas",
		multiline: true

	},
	{
		type: "multiText",
		name: "objetivos",
		label: "Objetivos y metas para el paciente",
		// flex: true,
		// fullWidth: true,
		// fields: [
		// 	{
		// 		type:"text",
		// 		name:"objetivo",
		// 		labelInput: "Objetivo",
		// 	}
		// ]
	},
];
const GeneralConsulta = props => {
	const consultas = useSelector(state =>state["CONSULTATION"]?.data[props.idPaciente]);
	
	const primera = consultas?.reduce((last, current)=>{
		if(current._trash)return last;

		if(!moment(last.fecha).isValid() || !last.fecha)return current;
		if(!moment(current.fecha).isValid() || !current.fecha)return last;
		if(moment(current.fecha).isBefore(last.fecha))return current;
		return last;
	},{});


	return (
		<>
			<BoxForm data={props.data} elements={primera?._id===props.idConsulta ? elementsPrimeraConsulta: elements} onChange={props.onChange} indexElements={true} ></BoxForm>
		</>
	);
};

export default GeneralConsulta;
