import React from "react";
import CrudTable from "../../components/Table/CrudTable/CrudTable";
import ModalConsultasOnline from "./ModalConsultasOnline";
import { useSelector } from "react-redux";
import Header from "../../components/Header/Header";
import RefCell from "components/Table/CellTypes/RefCell/RefCell";
import { ONLINE_CONSULTATION, NUTRITIONIST, ONLINE_PATIENT } from "store/constants";

const Premios = () => {
	const data = useSelector(state => state[ONLINE_CONSULTATION.name]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Paciente",
				accessor: "id_paciente",
				type: ONLINE_PATIENT,
				Cell: RefCell,
				filter: "ref",
			},
			{
				Header: "Nutriologo",
				accessor: "id_nutriologo",
				type: NUTRITIONIST,
				Cell: RefCell,
				filter: "ref",
			},
			{
				Header: "Fecha inicio",
				accessor: "fecha_inicio",
				filter: "daterange"
			},
			{
				Header: "Duracion",
				accessor: "duracion"
			},
			{
				Header: "Status",
				accessor: "status"
			},
			{
				Header: "Calificacion",
				accessor: "calificacion"
			},
			{
				Header: "Review",
				accessor: "review"
			},
			{
				Header: "Problemas",
				accessor: "problemas"
			},
			{
				Header: "Total",
				accessor: "total"
			}
		],
		[]
	);



	return (
		<>
			<Header title="Consultas Online" />

			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				filters={columns}
				type={ONLINE_CONSULTATION}
				modal={ModalConsultasOnline}
				options={{filter: true,orderBy:true,disableInsert:true}}
			/>
		</>
	);
};

export default Premios;
