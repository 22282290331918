import Swal from "sweetalert2"

import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

function fire(...params){
	console.log({params})
	return MySwal.fire(...params)
}
window.MySwal = MySwal
export default fire;