import React from "react";
import CrudTable from "../../../components/Table/CrudTable/CrudTable";
import ColorCell from "../../../components/Table/CellTypes/ColorCell/ColorCell";
import { useSelector } from "react-redux";
import Etiqueta from "./Etiqueta";
import { NUTRITIONIST_NOTIFICATION_TAGS } from "store/constants";

const TabRecetas = () => {
	const data = useSelector(state => state[NUTRITIONIST_NOTIFICATION_TAGS.name]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Etiqueta",
				accessor: "nombre"
			},
			{
				Header: "Color",
				accessor: "color",
				Cell: ColorCell,
				disableFilters: true
			}
		],
		[]
	);
	const filters = React.useMemo(
		() => [
			{
				Header: "Etiqueta",
				accessor: "nombre"
			}
		],
		[]
	);

	return (
		<>
			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				type={NUTRITIONIST_NOTIFICATION_TAGS}
				modal={Etiqueta}
				filters={filters}
				options={{ orderBy: true }}
			/>
		</>
	);
};

export default TabRecetas;
