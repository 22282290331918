
export function sumValues(obj) {
	return Object.values(obj).reduce((sum, value) => {
		if (typeof value === "object") {
			return sum + sumValues(value); // Recursive call for nested objects
		}
		return sum + value;
	}, 0);
}
export function deepSum(obj1={}, obj2={}, subtract) {
	let result = {};

	// Helper function to check if a value is a plain object
	function isPlainObject(value) {
		return value && typeof value === "object" && value.constructor === Object;
	}

	// Helper function to sum values
	function sumValues(value1, value2) {
		return (typeof value1 === "number" ? value1 : 0) + (typeof value2 === "number" ? value2 : 0);
	}
	function subtractValues(value1, value2) {
		return (typeof value1 === "number" ? value1 : 0) - (typeof value2 === "number" ? value2 : 0);
	}

	// Helper function to handle summing
	function handleSum(key) {
		if (isPlainObject(obj1[key]) && isPlainObject(obj2[key])) {
			// Sum objects recursively
			result[key] = deepSum(obj1[key], obj2[key], subtract);
		} else if (isPlainObject(obj1[key]) || isPlainObject(obj2[key])) {
			// Copy the object as is if only one object has the key
			result[key] = isPlainObject(obj1[key]) ? {...obj1[key]} : {...obj2[key]};
		} else {
			// Sum non-object values
			if(subtract){
				result[key] = subtractValues(obj1[key], obj2[key]);
			}else{
				result[key] = sumValues(obj1[key], obj2[key]);
				
			}
		}
	}

	// Iterate over the keys of both objects
	Object.keys(obj1).forEach(handleSum);
	Object.keys(obj2).forEach(key => {
		if (!result.hasOwnProperty(key)) {
			handleSum(key);
		}
	});

	return cleanObject(result);
}
export function deepMult(obj1={}, mult) {
	let result = {};

	// Helper function to check if a value is a plain object
	function isPlainObject(value) {
		return value && typeof value === "object" && value.constructor === Object;
	}

	// Helper function to sum values
	function multValues(value1, mult) {
		return (typeof value1 === "number" ? value1 : 0) * mult;
	}

	// Helper function to handle summing
	function handleMult(key) {
		if (isPlainObject(obj1[key])) {
			// Sum objects recursively
			result[key] = deepMult(obj1[key], mult);
		} else {
			result[key] = multValues(obj1[key], mult);
		}
	}

	// Iterate over the keys of both objects
	Object.keys(obj1).forEach(handleMult);

	return cleanObject(result);
}
export function cleanObject(obj={}){
	for(let k in obj){
		if(typeof obj[k]==="object" && Object.keys(obj[k]).length===0)delete obj[k]
	}
	return obj;
}