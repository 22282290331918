import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import PropTypes from "prop-types";

const Categoria = props => {    
	const elements = [
		{
			type: "text",
			name: "nombre",
			label: "Nombre",
		},
		{
			type: "text",
			name: "slug",
			label: "Slug",
		},
		{
			type: "file",
			name: "foto",
			label: "Imagen",
		}
	];
	return (
		<>
			<ModalForm elements={elements} onChange={props.onChange} data={props.data} onFile={props.onFile}></ModalForm>
		</>
	);
};

export default Categoria;

Categoria.propTypes = {
	onChange: PropTypes.func,
	data: PropTypes.object
};