import React, {useState} from "react";
import PropTypes from "prop-types";
import { FormGroup, TextareaAutosize, Tooltip, Button, Modal } from "@mui/material";
import { useImmer } from "use-immer";
import { useTranslation } from "react-i18next";
import {FileCopy} from "@mui/icons-material";

import ButtonAddTiempo from "../ButtonAddTiempo";
import TiempoComidaVertical from "../TiempoComidaVertical";
import useSkipFirstRender from "Hooks/useSkipFirstRender";
import SelectCopy from "pages/Dietas/DietaMenus/SelectCopy";

import Box from "components/Box/Box";
import {useDialog} from "Contexts/DialogContext";

import "./DietaMenus.scss";


import { AddToDietaButton } from "pages/Dietas/Components/AddToDietaButton/AddToDietaButton";
import { ListOfAddedProductsAndRecipes } from "pages/Dietas/Components/ListOfAddedProductsAndRecipes/ListOfAddedProductsAndRecipes";
import {SelectProductOrRecipeModal} from "pages/Dietas/Components/SelectProductOrRecipeModal/SelectProductOrRecipeModal";

const _ = require("lodash");
// const useStyles = makeStyles({
// 	root: {
// 		fontSize: 8,
// 	},
// });

const Dieta = (props) => {
	const {t} = useTranslation("common");
	const [openDialog, closeDialog] = useDialog();
	const [openModal, setOpenModal] = useState(false);
	const [currentlyEditing, setCurrentlyEditing] = useState({
		idx_tiempo: null,
		idx_dia: null,
		list: {
			recipes: [],
			products: [],
		},
	});


	var tiempos_comida = [
		{
			nombre_tiempo: {
				name: "desayuno",
				label: t("carpeta_dietas_desayuno"),
				img: "desayuno",
				color: "#EDC940"
			},
			menu: ["", "", "", "", "", "", ""],
			recetas: [[], [], [], [], [], [], []],
			productos: [[], [], [], [], [], [], []],
		},
		{
			nombre_tiempo: {
				name: "colacion1",
				label: t("carpeta_dietas_colacion"),
				img: "colacion1",
				color: "#54BDE9"
			},
			menu: ["", "", "", "", "", "", ""],
			recetas: [[], [], [], [], [], [], []],
			productos: [[], [], [], [], [], [], []],
		},
		{
			nombre_tiempo: {
				name: "comida",
				label: t("carpeta_dietas_comida"),
				img: "comida",
				color: "#72BEB2"
			},
			menu: ["", "", "", "", "", "", ""],
			recetas: [[], [], [], [], [], [], []],
			productos: [[], [], [], [], [], [], []],
		},
		{
			nombre_tiempo: {
				name: "colacion2",
				label: t("carpeta_dietas_colacion"),
				img: "colacion2",
				color: "#4868AC"
			},
			menu: ["", "", "", "", "", "", ""],
			recetas: [[], [], [], [], [], [], []],
			productos: [[], [], [], [], [], [], []],
		},
		{
			nombre_tiempo: {
				name: "cena",
				label: t("carpeta_dietas_cena"),
				img: "cena",
				color: "#9563A3"
			},
			menu: ["", "", "", "", "", "", ""],
			recetas: [[], [], [], [], [], [], []],
			productos: [[], [], [], [], [], [], []],
		}
	];
	
	const dias = [
		{ name: "sunday", label: t("modal_dias_laborales_domingo") },
		{ name: "monday", label: t("modal_dias_laborales_lunes") },
		{ name: "tuesday", label: t("modal_dias_laborales_martes") },
		{ name: "wednesday", label: t("modal_dias_laborales_miercoles") },
		{ name: "thursday", label: t("modal_dias_laborales_jueves") },
		{ name: "friday", label: t("modal_dias_laborales_viernes") },
		{ name: "saturday", label: t("modal_dias_laborales_sabado") },
	];

	const [dieta, setDieta] = useImmer(
		props.value
			? props.value
			: tiempos_comida
	);
	const debounceChange = React.useRef(
		_.debounce((d) => {
			props.onChange(d, "tiempos_comida");
		}, 1000)
	);

	useSkipFirstRender(() => {
		debounceChange.current(dieta);
	}, [dieta]);

	const change = (e, i, j) => {
		//e: event
		e.persist();
		setDieta(draft => {
			draft[j].menu[i] = e.target.value;
		});
	};

	const addTiempoComida = (idx_tiempo) => {
		setDieta(draft => {
			draft.splice(idx_tiempo, 0, {
				nombre_tiempo: {
					name: "extra",
					label: "",
					img: "",
					color: "#A5DD72"
				},
				menu: ["", "", "", "", "", "", ""],
				recetas: [[], [], [], [], [], [], []],
				productos: [[], [], [], [], [], [], []],
			});
		});
	};

	const setProductsAndRecepiesInDieta = (list, idx_tiempo, idx_dia) => {

		if (dieta[idx_tiempo].productos === undefined) {
			setDieta(draft => {
				draft[idx_tiempo].productos = [[], [], [], [], [], [], []];
			});
		}
		if (dieta[idx_tiempo].recetas === undefined) {
			setDieta(draft => {
				draft[idx_tiempo].recetas = [[], [], [], [], [], [], []];
			});
		}

		setDieta(draft => {
			draft[idx_tiempo].productos[idx_dia] = list.products;
			draft[idx_tiempo].recetas[idx_dia] = list.recipes;
		});
	};

	const deleteTiempo = (idx_tiempo) => {
		setDieta(draft => {
			draft.splice(idx_tiempo,1);
		});
	};

	const onChangeTiempoComida = (name, value, idx) => {
		setDieta(draft => {
			draft[idx].nombre_tiempo[name] = value;
		});
	};

	const handleRemoveProduct = (idx_tiempo, idx_dia, productIdtoRemove) => {
		const filtered = dieta[idx_tiempo].productos[idx_dia].filter(productId => productId !== productIdtoRemove);
		setDieta(draft => {
			draft[idx_tiempo].productos[idx_dia] = filtered;
		});
	};

	const handleRemoveRecipe = (idx_tiempo, idx_dia, receipeIdtoRemove) => {
		const filtered = dieta[idx_tiempo].recetas[idx_dia].filter(recipeId => recipeId !== receipeIdtoRemove);
		setDieta(draft => {
			draft[idx_tiempo].recetas[idx_dia] = filtered;
		});
	};

	const handleRemoveEverything = (idx_tiempo, idx_dia) => {
		setDieta(draft => {
			draft[idx_tiempo].productos[idx_dia] = [];
			draft[idx_tiempo].recetas[idx_dia] = [];
		});
	};

	function copiar(alimento, productos, recetas){
		openDialog({
			onClose:(to)=>{
				if(to){
					setDieta(diet=>{
						to.forEach(([idxDia, idxTiempo])=>{

							// initalize old recepie fields without products and recepies:
							if (diet[idxTiempo].productos === undefined) {
								diet[idxTiempo].productos = [[], [], [], [], [], [], []];
							}
							if (diet[idxTiempo].recetas === undefined) {
								diet[idxTiempo].recetas = [[], [], [], [], [], [], []];
							}

							// set the actual values:
							diet[idxTiempo].menu[idxDia] += `\n${alimento}`;
							diet[idxTiempo].productos[idxDia] = [...new Set([...diet[idxTiempo].productos[idxDia], ...productos])];
							diet[idxTiempo].recetas[idxDia] = [...new Set([...diet[idxTiempo].recetas[idxDia], ...recetas])];
						});
					});
				}
			},
			Content: SelectCopy,
			dieta,
			width:"md"
		});
	}

	const handleClosedSelectProductOrRecipeModal = (list) => {
		// console.log("===> selected list in parent: ", list);
		// console.log("===> currently editing: ", currentlyEditing);

		if (list !== null){
			setProductsAndRecepiesInDieta(list, currentlyEditing.idx_tiempo, currentlyEditing.idx_dia);
		}

		setOpenModal(false);
	};

	const handleAddToDietaButtonClick = (idx_tiempo, idx_dia) => {
		setCurrentlyEditing({
			idx_tiempo: idx_tiempo,
			idx_dia: idx_dia,
			list: {
				recipes: dieta[idx_tiempo].recetas ? dieta[idx_tiempo].recetas[idx_dia] : [],
				products: dieta[idx_tiempo].productos ? dieta[idx_tiempo].productos[idx_dia] : []
			}
		});
		setOpenModal(true);
	};

	return (
		<>
			{/* {console.log("===> dieta", dieta)} */}
			<div className="container-dieta-menus printDiv">
			
				<FormGroup style={{minWidth: "850px"}}>
					<Box label={t("menu_dieta_box_label")} fullWidth>
						<div>
							<div className="content-table" style={{paddingLeft: "10%"}}>
								<>
									<div className="row-table">
										<div className="container-add-tiempo-comida avoid-print">
											<ButtonAddTiempo addTiempoComida={addTiempoComida} index={0} text="+"/>
										</div>
										{Object.values(dieta).map((value, i) => (
											<div key={i} style={{display:"flex", width:`${100/Object.values(dieta).length}%`}}>
												<TiempoComidaVertical tiempoComida={value} index={i} onChange={onChangeTiempoComida} del={deleteTiempo}/>
											
												<div className="container-add-tiempo-comida avoid-print">
													<ButtonAddTiempo addTiempoComida={addTiempoComida} index={i+1} text="+" />
												</div>
											</div>
										))}
									</div>
								</>
							</div>
							<div className="container-table">
								<div>
									{dias.map((dia, idx_dia) => (
										<div key={idx_dia} className="break-end-page" style={{display:"flex", marginBottom:"20px", minHeight:"150px"}}>
											<div key={idx_dia} style={{width: "10%", background: "#E5F5D7", height:"unset", display:"flex", alignItems:"center", justifyContent:"center"}}>
												<p style={{color: "#000"}}>{dia.label}</p>
											</div>
											<div className="panelTiempo" style={{ width: "90%", display: "flex", margin: 0, justifyContent: "space-around"}}>
												{Object.values(dieta).map(
													(tiempo, idx_tiempo) => (
														<>
															<div className="cont-alimento" key={idx_tiempo + idx_dia}>
																<div className="allow-print">
																	<div>

																		{tiempo.menu[idx_dia].split("\n").map(i => (
																			<div key={i}>
																				<p className="print-text">{i}</p>
																			</div>
																		))}	
																	</div>
																</div>
																<TextareaAutosize
																	value={tiempo.menu[idx_dia]}
																	style={{width:"100%", resize:"vertical"}}
																	onChange={e => {
																		change(e, idx_dia, idx_tiempo);
																	}}
																	placeholder={t("escribe_aqui")}
																	className="text-area avoid-print"
																/>
																
																<div className="avoid-print">
																	<AddToDietaButton text={t("dietas_menu_anadir_receta_o_producto")} onClick={() => handleAddToDietaButtonClick(idx_tiempo, idx_dia)}/>
																</div>

																{/* {console.log("===> tiempo: ", tiempo)} */}
																<ListOfAddedProductsAndRecipes products={tiempo.productos ? tiempo.productos[idx_dia] : []} recipes={tiempo.recetas ? tiempo.recetas[idx_dia] : []} 
																	handleRemoveProduct={handleRemoveProduct} handleRemoveRecipe={handleRemoveRecipe} idx_tiempo={idx_tiempo} idx_y={idx_dia}
																/>

																<div className="avoid-print show-on-hover cont-butt-copy">
																	<Tooltip title={t("dieta_menu_select_dieta_copiar")}>
																		<FileCopy style={{width:20, height:20, background:"#A5DD72", padding:"0 1px 0 1px", color:"white", borderRadius:3, }} onClick={()=>copiar(tiempo.menu[idx_dia], tiempo.productos ? tiempo.productos[idx_dia] : [], tiempo.recetas ? tiempo.recetas[idx_dia] : [])} />
																	</Tooltip>
																	<Tooltip  title="Borrar selección" className="header-actions">
																		<span style={{ "cursor": "pointer"}} onClick={() => handleRemoveEverything(idx_tiempo, idx_dia)}>
																			<img src={require("assets/img/icons/del.png")} alt="no_data" style={{
																				"width": "20px", 
																				"height": "20px", 
																				"margin-left": "5px",
																			}}/>
																		</span>
																	</Tooltip>
																</div>
															</div>
														</>
													)
												)}
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</Box>
				</FormGroup>
				<SelectProductOrRecipeModal openModal={openModal} onClose={handleClosedSelectProductOrRecipeModal} previouslySelected={currentlyEditing.list}/>
			</div>
		</>);
};

export default Dieta;

Dieta.propTypes = {
	value: PropTypes.array,
	onChange: PropTypes.func
};

