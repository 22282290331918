import React from "react";
import PropTypes from "prop-types";
import { FormGroup, Checkbox, Button } from "@mui/material";
import Box from "components/Box/Box";
import { useTranslation } from "react-i18next";
import "./DietaMenus.scss";

const Dieta = ({ onClose }) => {
	const {t} = useTranslation("common");
	const dias = [
		{ name: "monday", label: t("modal_dias_laborales_lunes") },
		{ name: "tuesday", label: t("modal_dias_laborales_martes") },
		{ name: "wednesday", label: t("modal_dias_laborales_miercoles") },
		{ name: "thursday", label: t("modal_dias_laborales_jueves") },
		{ name: "friday", label: t("modal_dias_laborales_viernes") },
		{ name: "saturday", label: t("modal_dias_laborales_sabado") },
		{ name: "sunday", label: t("modal_dias_laborales_domingo") },
	];

	const [checkedTiempos, setCheckedTiempos] = React.useState({});

	const handleCheck = (dia) => (e) => {
		
		setCheckedTiempos(prev=>({...prev, [dia]:e.target.checked}));
	};
	const guardar = () => {
		let to = {};
		to = Object.fromEntries(Object.entries(checkedTiempos).filter(([,val])=>val));
		onClose(to);
	};
	
	return (
		<>
			<div className="container-dieta-menus printDiv">
			
				<FormGroup>
					<Box fullWidth>
						<div className="container-table">
							<div>
								{/* {dias.map((dia, idx_dia) => <Checkbox onChange={handleCheck(idx_dia)} /> )} */}
								{dias.map((dia, idx_dia) => <Dia handleCheck={handleCheck} checkedTiempos={checkedTiempos} dia={dia} idx_dia={idx_dia} key={idx_dia} /> )}
							</div>
						</div>
						
					</Box>
					<Button variant="contained" color="primary" onClick={guardar}>{t("guardar")}</Button>
				</FormGroup>
			</div>
		</>);
};

export default Dieta;

Dieta.propTypes = {
	value: PropTypes.array,
	onChange: PropTypes.func
};


function Dia({idx_dia, dia, handleCheck}){
	return <div key={idx_dia} className="break-end-page" style={{display:"flex", marginBottom:"20px"}}>
		<div key={idx_dia} style={{width: "30%", height:"unset", display:"flex", alignItems:"center", justifyContent:"center"}}>
			<p>{dia.label}</p>
		</div>
		<div className="panelTiempo" style={{ width: "70%", display: "flex", margin: 0, justifyContent: "space-around"}}>
			
			<Checkbox
				onChange={handleCheck(idx_dia)}
				inputProps={{}}
			/>
		</div>
	</div>;
}