import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";

const Premio = props => {
	const elements = [
		{
			type:"text",
			name:"nombre",
			label:"Nombre"
		},
		{
			type:"text",
			name:"codigo",
			label:"Codigo"
		},
		{
			type: "text",
			name: "max_usos",
			label: "Maximo numero de usos",
		},
		{
			type: "text",
			name: "max_usos_cliente",
			label: "Maximo numero de usos por cliente",
		},
		{
			type: "calendar",
			name: "fecha_inicio",
			label: "Fecha inicio",
		},
		{
			type: "calendar",
			name: "fecha_fin",
			label: "Fecha Fin",
		},
		{
			type: "text",
			subtype: "number",
			name: "monto_descuento",
			label: "$ descuento",
		},
		{
			type: "text",
			subtype: "number",
			name: "p_descuento",
			label: "% descuento",
		},
		{
			type: "bool",
			name: "automatico",
			label: "Automatico",
		},
		{
			type: "bool",
			name: "solo_clientes_nuevos",
			label: "Solo usuarios nuevos",
	},
	];
	return (
		<>
			<ModalForm elements={elements} onChange={props.onChange} data={props.data}></ModalForm>
		</>
	);
};

export default Premio;