const axios = require("axios");

let url = process.env.REACT_APP_API_URL;
let  instance = axios.create({
	baseURL: url,
	timeout: 60000,
});
export function init(token){
	instance.defaults.headers["Authorization"] = `Bearer ${token}`;
	
}
const token = window.localStorage.getItem("nutrify:super");
export const Authorization = `Bearer ${token}`;

export function get(uri, params){
	return new Promise((resolve, reject)=>{
		instance.get(uri,{params}).then(function (response) {
			resolve(response);
		}).catch(function (error) {
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject(error.response?.data.error || error.message);
		});
	});
}
export function post(uri, data){
	return new Promise((resolve, reject)=>{
		instance.post(uri, data).then(function (response) {
			resolve(response);
		}).catch(error=>{
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject(error.response?.data.error || error.message);
		});
	});
}
export function put(uri, data){
	return new Promise((resolve, reject)=>{
		console.log(uri,data);
		instance.put(uri, data).then(function (response) {
			resolve(response);
		}).catch(function (error) {
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject(error.response?.data.error || error.message);
		});
	});
}
export function remove(uri, data){
	return new Promise((resolve, reject)=>{
		instance.delete(uri, data).then(function (response) {
			resolve(response);
		}).catch(function (error) {
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject(error.response?.data.error || error.message);
		});
	});
}
export function upload(uri, file){
	return new Promise((resolve, reject)=>{
		console.log(uri,file);
		// const url = 'http://localhost:3000/patients/img';
		const formData = new FormData();
		if(Array.isArray(file)){
			file.forEach(f => {
				formData.append(f.name,f.file);
			});
		}else{
			formData.append("file",file.file);
		}
		const config = {
			headers: {
				"content-type": "multipart/form-data"
			},
			timeout:180000
		};
		instance.post(uri, formData, config).then(function (response) {
			resolve(response);
		}).catch(function (error){
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject(error.response?.data.error || error.message);
		});
	}); 
}
export default {post, get, put, remove, upload, init};


export const crudFunctions = (type, {replacements, parent_id, baseUrl}={}) => {
	const tipo = type.url;
	const tail = type.tail_url;
	const url = tail ? `${tipo}/${parent_id}/${tail}` : `${tipo}`;

	return {
		getAll: ({queryKey})=>{
			if(queryKey.includes("ref")) return get(`/${url}/?all=true`);
			return get(`/${url}`);
		},
		getIds: ({search})=>{
			return post(`/${url}/`,{getIds:true, search});
		},
		getOne: (id)=>{
			return get(`/${url}/${id}`);
		},
		getPaginated: async ({queryKey})=>{
			const response = await post(`/${tipo}`,{page:queryKey[queryKey.length-1].page, search:queryKey[queryKey.length-1].filter, query:queryKey[queryKey.length-1].query, sort:queryKey[queryKey.length-1].sort});
			return response.data;
		},
		getOneQueryKey: ({queryKey})=>{
			return get(`/${url}/${queryKey[1]}`);
		},
		insert:async ({files, ...data})=>{
			const response = await post(`/${url}`, data);
			if(files){
				const r = await upload(`/${url}/${response.data._id}/files`, files);
				return r;
			}
		},
		update:async (id, {files, ...data})=>{
			await put(`/${url}/${id}`, data);
			if(files){
				const r = await upload(`/${url}/${id}/files`, files);
				return r;
			}
		},
		trash:(id)=>{
			return put(`/${url}/${id}`, {_trash: true});
		},
		untrash:(id)=>{
			return put(`/${url}/${id}`, {_trash: false});
		},
		delete:(id)=>{
			return remove(`/${url}/${id}`);
		},
		...replacements
	};
};


export const getRecetaSeleccionAlimentosAi = (nombre) => post("/ai/receta", {nombre});
export const track = ()=>{}
export const getCustomer = ()=>{}