import React from "react";

import { FormGroup } from "@mui/material";
import getData from "../getData";
import BoxElement from "../Element/BoxElement";
import Masonry from 'react-masonry-css'

import "./BoxForm.scss";
const Boxform = props => {
	const getFilterBy = element => {
		if (element.type === "allValues" || element.showHistoric)
			return props.data[element.filterBy];
	};
	return (
		<>
			{props.data ? (
				<FormGroup>
					<Masonry
						breakpointCols={2}
						className="my-masonry-grid"
						columnClassName="my-masonry-grid_column">
					{/* <MagicGrid centerOnly gutter={20} items={props.elements.length}> */}
						{props.elements.map((element, idx) => (
							<div className="item" key={element.id || element.name}>
								<BoxElement 
									files={props.data?.files}
									filterBy={getFilterBy(element)} 
									element={element} 
									onChange={props.onChange} 
									onFile={props.onFile}
									value={getData(element, props.data)} 
									index={props.indexElements ? `${idx+1}.` : ""} 
								/>
							</div>
						))}
						</Masonry>
					{/* </MagicGrid> */}
				</FormGroup>
			) : (
				<></>
			)}
			{/* {props.data ? (
				<FormGroup>
					<Grid container spacing={3}>
						{props.elements.map((element, idx) => (
							<Grid
								key={element.name + idx}
								item
								xs={12}
								md={element.fullWidth ? 12 : 6} 
							>
								<BoxElement {...props} filterBy={getFilterBy(element)} element={element} onChange={props.onChange} value={getData(element, props.data)} />
							</Grid>
						))}
					</Grid>
				</FormGroup>
			) : (
				<></>
			)} */}
		</>
	);
};

export default Boxform;
