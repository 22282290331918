import React from "react";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import {useWhyDidYouUpdate} from "hooks";
import MenuList from "./MenuList";
import Option from "./Option";
import _ from "lodash";

const filterOptions = (labelAccessor)=>(candidate, input) => {
	if (input && input.length>3) {
		const currentCandidate = candidate.data[labelAccessor]?.toLowerCase();
		input = input.toLowerCase();
		return currentCandidate?.indexOf(input) > -1;
	}
	return true;
};
const ReactSelect = (props) => {
	const { disabled, options, value, onChange, placeholder, labelAccessor, accessor, menuStyles, controlStyles, menuPosition, optionStyle } = props;
	// useWhyDidYouUpdate("Select,", props);
	const change = (data) => {
		onChange(data, props.name);
	};
	const selected = React.useMemo(()=>{
		if(props.multiSelect){
			return options?.filter(o=>{
				return value?.includes(_.get(o, accessor||"_id"))
			})
		}
		return options?.find(o=>value===_.get(o, accessor||"_id"))
	},[value, options]);


	return (
		<Select
			options={options}
			styles={{
				control: (baseStyles, state) => ({
					...baseStyles,
					// minWidth:250,
					width:"100%",
					background:"transparent",
					...controlStyles
				}),
				menu:(baseStyles, state) => ({
					...baseStyles,
					zIndex:999999,
					// position:menuPosition|| "absolute",
					...menuStyles,
					position:"unset",

				}),
				placeholder:(baseStyles, state) =>({
					...baseStyles,
					opacity:1,
					color:"rgb(117,117,117)",
					// fontWeight: 351,
					fontSize: 13
				}),
				indicatorsContainer:(baseStyles, state) =>({
					...baseStyles,
					height:30
				}),
				container: styles => ({ ...styles,  width: "100%" }),
				option: optionStyle
			}}
			style={{width:"100%"}}
			isMulti={props.multiSelect}
			value={selected}
			onChange={change}
			classNamePrefix="react-select"
			filterOption={filterOptions(labelAccessor)}
			placeholder={placeholder}
			getOptionLabel={(option) => _.get(option, labelAccessor || "nombre")}
			getOptionValue={(option) => _.get(option, accessor || value)}
			isDisabled={disabled}
			components={{
				...(props.Option ? {Option:props.Option} : {})
			}}
		/>
	);
};

export default ReactSelect;