import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

export default function Boolean(props) {
	const handleChange = event => {
		event.persist();
		props.onChange(event.target.value === "true", props.name);
	};
	React.useEffect(()=>{
		if(props.value===undefined || props.value===null){
			props.onChange(false, props.name);
		}
	},[props.value]);

	return (
		<>
			<FormControl component="fieldset" disabled={props.value!==undefined && props.disableOnValue }>
				{/* <FormLabel component="legend">{props.label}</FormLabel> */}
				<RadioGroup
					value={props.value ? props.value : ""}
					onChange={handleChange}
				>
					<FormControlLabel
						value={false}
						control={<Radio checked={!props.value} />}
						label={props.falseLabel ? props.falseLabel : "No"}
					/>
					<FormControlLabel
						value={true}
						control={<Radio checked={props.value} />}
						label={props.trueLabel ? props.trueLabel : "Si"}
					/>
				</RadioGroup>

        
			</FormControl>
		</>
	);
}
