import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import common_es from "locales/es/common.json";
import common_en from "locales/en/common.json";
// import LanguageDetector from "i18next-browser-languagedetector";

// import Backend from "i18next-http-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
// don"t want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

i18n
	// load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
	// learn more: https://github.com/i18next/i18next-http-backend
	// .use(Backend)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	// .use(LanguageDetector) //Detector de lenguaje de navegador
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		fallbackLng: "es",
		debug: true,

		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		resources: {
			es: {
				common: common_es
			},
			en: {
				common: common_en
			}
		}
	});


export default i18n;