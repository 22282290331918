import React, {useRef} from "react";
import Dieta from "./Dieta";
import PropTypes from "prop-types";
import { FormGroup } from "@mui/material";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import PrintButton from "components/Buttons/PrintButton/PrintButton";
import HeaderPrint from "components/Buttons/PrintButton/HeaderPrint";
import { MENU_DIET } from "store/constants";

const DietaOpciones = props => {

	const elements = React.useMemo(()=>[
		{
			type: "bool",
			name: "u",
			label: "Unlocked",
		},
		{
			type: "text",
			name: "nombre",
			label: "Nombre",
		},
		{
			type: "text",
			name: "recomendaciones",
			label: "Recomendaciones generales",
			multiline:true
		},
		{
			type: "radio",
			name: "mostrar_equivalentes",
			label: "Mostrar equivalentes",
			options: [
				{label: "No", value: "no"},
				{label: "Equivalentes simplificados", value: "simplificado"},
				{label: "Equivalentes SMAE", value: "smae"}
			],
			default: "no"
		},
		{
			type: "if",
			name: ["mostrar_equivalentes", "lista_equivalencias"],
			formula:(val)=>val === "simplificado",
			field:
				{
					type: "ref",
					name: "lista_equivalencias",
					label: "Lista de equivalencias",
					selector:(state)=>state.EQUIVALENT_LIST.data,
					extraItems:[{nombre:"Todos",_id:"-1"}],
					default:"-1"
				}
		},
		{
			type: "if",
			name: ["mostrar_equivalentes", "lista_equivalencias_smae"],
			formula:(val)=>val === "smae",
			field:
				{
					type: "ref",
					name: "lista_equivalencias_smae",
					label: "Lista de equivalencias SMAE",
					selector:(state)=>state.EQUIVALENT_SMAE_LIST.data,
					extraItems:[{nombre:"Todos",_id:"-1"}],
					default:"-1"
				}
		}
	],[]);
	const ref = useRef(null);
	return (
		<>
			<div style={props.select && {float:"right"}} className="buttons-dietas">
				<PrintButton type={MENU_DIET.name} printRef={ref} />
			</div>
			{/* <div style={{display: "none"}}>
				<div className="printable" ref={ref}>
					<HeaderPrint data={props.data}/>
					<Dieta onChange={props.onChange} value={props.data?.tiempos_comida} />
				</div>
			</div> */}
			<div className="container-dieta" ref={ref}>
				<ModalForm print elements={elements} onChange={props.onChange} data={props.data}>
				</ModalForm>
				<div className="allow-print">
					<HeaderPrint data={props.data}/>
				</div>
				<br></br>
				<Dieta onChange={props.onChange} value={props.data?.tiempos_comida} />
			</div>
		</>
	);
};

export default DietaOpciones;

DietaOpciones.propTypes = {
	onChange: PropTypes.func,
	data: PropTypes.object,
	select: PropTypes.bool
};