import React from "react";
import PropTypes from "prop-types";
// import { TextField } from "@mui/material";
import TextField from "components/FormComponents/Text/Text";
import styled from "styled-components"
// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
	column: { filterValue, preFilteredRows, setFilter, id },
}) {
  
	return (
		<div
			style={{
				display: "flex",
			}}
		>
      <Box selected={filterValue===undefined|| filterValue===""} onClick={()=>setFilter()}>Todo</Box>
      <Box selected={filterValue===true} onClick={()=>setFilter(true)}>✅</Box>
      <Box selected={filterValue===false} onClick={()=>setFilter(false)}>❌</Box>
			
		</div>
	);
}
export default NumberRangeColumnFilter;

NumberRangeColumnFilter.propTypes = {
	column: PropTypes.object
};
const Box = styled.div`
	padding:10px;
	${props=>props.selected && "background:white;"}
`