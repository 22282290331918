import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import grupos from "misc/grupos";

export default function EquivalenciaCell({ cell }) {
	return (
		<>
			<Grid container style={{justifyContent:"center"}}>
				{grupos.map(grupo => <>
					{cell.row.original.equivalencia[grupo.name]>0 &&
						<Grid key={grupo.name} item>
							<Grid container direction="column">
								<img
									style={{float:"left", objectFit: "contain",height:"20px",margin:"4px"}}
									src={require(`assets/img/${grupo.name}.png`)}
									className="img-category"
								/>
								{cell.row.original.equivalencia[grupo.name]}
							</Grid>
						</Grid>
					}
				</>)}
			</Grid>
		</>
	);
}


EquivalenciaCell.propTypes = {
	cell: PropTypes.object
};