import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";



export default function MyRadio(props) {
	const handleChange = (e) => {
		e.persist();
		props.onChange(e.target.value, props.name);
	};
	return (
		<div>
			<FormControl component="fieldset" >
				<RadioGroup color="primary" row={props.row} value={props.value} onChange={handleChange}>
					{
						props.options.map(option=>
							<FormControlLabel color="primary" key={option.id || option.value} value={option.value} control={<Radio color="primary" checked={option.value===props.value} />} label={option.label} />
						)
					}
				</RadioGroup>
			</FormControl>
        
		</div>
	);
}