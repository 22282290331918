import { loadRequest, insertRequest, updateRequest, removeRequest,trashRequest,untrashRequest } from "./generators";
import { PRIZE} from "../constants";
import { all } from "redux-saga/effects";

const loadPrizes= loadRequest(PRIZE);
const insertPrize = insertRequest(PRIZE);
const updatePrize = updateRequest(PRIZE);
const removePrize = removeRequest(PRIZE);
const trashPrize = trashRequest(PRIZE);
const untrashPrize = untrashRequest(PRIZE);

function* rootSaga() {
	yield all([
		loadPrizes(),
		insertPrize(),
		updatePrize(),
		removePrize(),
		trashPrize(),
		untrashPrize()
	]);
}
export default rootSaga;