import React from "react";

import { FormGroup } from "@mui/material";
import ModalElement from "../Element/ModalElement";
import getData from "../getData";
import Box from "../../Box/Box";
import "./ModalForm.scss";
const ModalForm = props => {
	const {data, ...propsNoData} = props;

	return (<>
		
		<FormGroup>
			<Box className="modal-form-box" fullWidth={props.fullWidth} label={props.label} sublabel={props.subLabel} >
				<>
					{props.elements.map((element, idx) => (
						<ModalElement
							key={element.name+idx}
							{...propsNoData}
							element={element}
							value={getData(element, props.data)}
							files={props.data?.files}
							onChange={props.onChange}
							onFile={props.onFile || element.onFile}
						></ModalElement>
					))}
					{props.children}
				</>
			</Box>
		</FormGroup></>
	);
};

export default ModalForm;
