import React from "react";
import ModalElement from "../Element/ModalElement";
import PropTypes from "prop-types";

const If = props => {
	return (
		<>
			{ props.formula(...props.name.reduce((acc,n)=>(acc.push(props.value?.[n]),acc),[])) &&
				<ModalElement element={props.element.field}
					style={{margin:0}}
					onChange={props.onChange}
					onFile={props.onFile}
					value={props.value?.[props.field?.name]}
					parentValues={props.value}
					
				/> 
			}
		</>
	);
};
export default If;

If.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.object,
	element: PropTypes.object,
	onFile: PropTypes.func,
	name: PropTypes.array,
	formula: PropTypes.func
};