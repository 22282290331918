import React from "react";
import PropTypes from "prop-types";
// import { TextField } from "@mui/material";
import TextField from "components/FormComponents/Text/Text";
// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
	column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  
	return (
		<div
			style={{
				display: "flex",
			}}
		>
			<TextField
				value={filterValue[0]}
				type="number"
				onChange={val => {
					setFilter([val, filterValue[1]]);
					
				}}
				placeholder={`Min`}
				variant="outlined"
				size="small"
			/>
      <div style={{display:"flex",alignItems:"center",flexDirection:"column",justifyContent:"center"}}>a</div>
			<TextField
				value={filterValue[1]}
				type="number"
				onChange={val => {
					setFilter([filterValue[0], val]);

					// if(val==="")val=undefined
					// setFilter([filterValue[0], isFinite(val) ? parseFloat(val) : val]);
				}}
				placeholder={`Max`}
				variant="outlined"
				size="small"

			/>
		</div>
	);
}
export default NumberRangeColumnFilter;

NumberRangeColumnFilter.propTypes = {
	column: PropTypes.object
};