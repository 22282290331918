import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { INFOGRAFIA } from "../constants";
import { all } from "redux-saga/effects";

const loadMessage = loadRequest(INFOGRAFIA);
const insertMessage = insertRequest(INFOGRAFIA);
const updateMessage = updateRequest(INFOGRAFIA);
const removeMessage = removeRequest(INFOGRAFIA);
const trashMessage = trashRequest(INFOGRAFIA);
const untrashMessage = untrashRequest(INFOGRAFIA);

function* rootSaga() {
	yield all([
		loadMessage(),
		insertMessage(),
		updateMessage(),
		removeMessage(),
		trashMessage(),
		untrashMessage()
	]);
}
export default rootSaga;