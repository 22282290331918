import React from "react";
import { Modal, Fade, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";

import styles from "Styles.module.scss";

export default function PlanesYPagos(props) {
	return <>
		<Modal open={props.open} onClose={props.onClose} className={styles.modal}>
			<Fade in={props.open}>
				<div className={styles.paper} style={{position:"relative", width: props.fullWidth ? "90%" : props.widthPaper ? props.widthPaper : ""}}>
					<div style={{position:"absolute",top:"0",right:"0"}}>
						<IconButton onClick={props.onClickClose}><CloseIcon /></IconButton>
					</div>
					<div style={{textAlign:"center", fontSize:"1.5em",fontWeight:"bold",marginBottom:"10px"}}>{props.title}</div>
					{props.children}
				</div>
			</Fade>
		</Modal>
	</>;
}

PlanesYPagos.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	fullWidth: PropTypes.bool,
	widthPaper: PropTypes.string,
	onClickClose: PropTypes.func,
	title: PropTypes.string
};
/*
open
onClose
fullWidth
onClickClose
title
*/
