import React from "react";
import CrudTable from "../../components/Table/CrudTable/CrudTable";
import Infografia from "./Infografia";
import { useSelector } from "react-redux";
import Header from "../../components/Header/Header";
import { INFOGRAFIA } from "store/constants";
import FileCell from "components/Table/CellTypes/FileCell/FileCell"
const Premios = () => {
	const data = useSelector(state => state[INFOGRAFIA.name]);

	console.log({data})
	const columns = React.useMemo(
		() => [
			{
				Header: "Titulo",
				accessor: "titulo"
			},
			{
				Header: "Imagen",
				accessor: "archivos",
				Cell:FileCell
			}
		],
		[]
	);

	return (
		<>
			<Header title="Descuentos" />

			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				filters={columns}
				type={INFOGRAFIA}
				modal={Infografia}
				options={{filter: true,orderBy:true}}
			/>
		</>
	);
};

export default Premios;
