import React from "react";
import { Chart } from 'react-charts'

const _ = require("lodash");

export default function BoolCell ({ cell }){
	const data = React.useMemo(() => [
		{
			label: cell.column.Header,
			data: cell.value,
		},
	],[cell.column.Header, cell.value])

	const primaryAxis = React.useMemo(() => ({
		getValue: cell.column.xAccessor,
	}), [cell.column.xAccessor]);

	const secondaryAxes = React.useMemo(()=> [{
		getValue: cell.column.yAccessor,
		elementType:"line"
	}],[cell.column.yAccessor])

	console.log({data: data[0]})
	if(!data[0].data){
		return <div>nada</div>;
	}
	return (
		<div style={{height:70, width:200}}>
			<Chart
				options={{
					data,
					primaryAxis,
					secondaryAxes,
					primaryCursor:false,
					secondaryCursor:false,
				}}
			/>
		</div>
		
	)
}