import React from "react";
import ModalElement from "../Element/ModalElement";
const _ = require("lodash");

const If = props => {
	const params = Array.isArray(props.value) ? props.value: [props.value];
	return (
		<>
			{ props.formula(...params) &&
				props.element.fields.map(field=>
					<>
					<ModalElement key={field.name} element={field}
						style={{margin:0}}
						onChange={props.onChange}
						onFile={props.onFile}
						value={_.get(props.value, field.name)}
						files={props.files}
					/> 
					</>
				)
				
			}
		</>
	);
};
export default If;
