import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { TextField } from "@mui/material";

export default function BoolDetail(props) {
	const handleChange = event => {
		event.persist();
		let a = { ...props.value, value: event.target.value === "true" };
		props.onChange(a, props.name);
	};

	return (
		<>
			<FormControl component="fieldset">
				{/* <FormLabel component="legend">{props.label}</FormLabel> */}
				<RadioGroup
					value={props.value ? props.value.value : ""}
					onChange={handleChange}
				>
					<FormControlLabel
						value={false}
						control={<Radio color="primary" checked={props.value && !props.value.value} />}
						label={props.falseLabel ? props.falseLabel : "No"}
					/>
					<FormControlLabel
						value={true}
						control={<Radio color="primary" checked={props.value && props.value.value} />}
						label={props.trueLabel ? props.trueLabel : "Si"}
					/>
				</RadioGroup>
				{props.value && props.value.value && (
					props.onTrueOptions ?
						<>
							<RadioGroup
								style={{paddingLeft:"20px"}}
								value={props.detail ? props.value.detail : ""}
								onChange={e =>
									props.onChange({
										value: true,
										detail: e.target.value
									},props.name)
								}
							>
								{props.onTrueOptions.map(option=>
									<FormControlLabel
										key={option.value}
										value={option.value}
										control={<Radio color="primary" checked={option.value===props.value.detail} />}
										label={option.label}
									/>
								)}
            
							</RadioGroup>

						</>
						:
						<>
							<TextField
								label={props.showLabel ? (props.labelInput ? props.labelInput : "Especifica" ) : ""}
								variant="outlined"
								value={props.value.detail}
								onChange={e =>
									props.onChange({
										value: true,
										detail: e.target.value
									},props.name)
								}
							></TextField>
						</>
				)}
			</FormControl>
		</>
	);
}
