import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import DietaMenusSelect from "./DietaMenusSelect";
import CrudTable from "components/Table/CrudTable/CrudTable";
import {getNutitionistId} from "api/loader";
import { MENU_SELECT_DIET, NUTRITIONIST } from "store/constants";
import AppView from "components/AppView/AppView";
import { useTranslation } from "react-i18next";
import Modal from "components/Modal/Modal";
import ComposicionAlimentos from "pages/Configuracion/Nutriologos/ComposicionAlimentos";
import SettingsIcon from "@mui/icons-material/Settings";
import { Button } from "@mui/material";
import {track} from "api";

const TabDietas = () => {
	const nutId = getNutitionistId();
	const dispatch = useDispatch();
	const {t} = useTranslation("common");
	const data = useSelector(state => state[MENU_SELECT_DIET.name]);
	const dataNutritionist = useSelector(state => state[NUTRITIONIST.name].data.find(n=>n._id===nutId));
	const [selectedData, setSelectedData] = useState();
	const [showModalAsignacion, setShowModalAsignacion] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [composicion, setComposicion] = useState({});
	
	const onClickEdit = (data) => {
		setSelectedData(data);
		setShowModal(true);
	};

	const saveComposicion = () => {
		dispatch({type: "UPDATE_NUTRITIONIST", payload: {tab_comp_alim: composicion, _id: nutId}});
		setShowModalAsignacion(false);
	};

	const columns = React.useMemo(
		() => [
			{
				Header: t("nombre"),
				accessor: "nombre"
			}
		],
		[]
	);
	const filters = React.useMemo(
		() => [
			
			{	
				Header: t("nombre"),
				accessor: "nombre",
			}
		],
		[]
	);

	const onInsert = () =>{
		track("Create diet", {tipo: MENU_SELECT_DIET.name});
	};
	return (
		<>
			{(dataNutritionist && !dataNutritionist?.tab_comp_alim) ? <Composicion onChange={setComposicion} save={saveComposicion} idNutritionist={nutId} />
				: 
				<>
					{/* <div style={{textAlign: "right"}}>
						<Button variant="contained" color="secondary" onClick={()=>setShowModalAsignacion(true)}><SettingsIcon /></Button>
					</div> */}
					<CrudTable
						data={data.data}
						status={data.view}
						columns={columns}
						type={MENU_SELECT_DIET}
						onInsert={onInsert}
						modal={DietaMenusSelect}
						filters={filters}
						options={{ orderBy: true, saveAs: true, saveAsNameKey:"nombre", loadBeforeEdit:true,  }}
						addLabel={t("crear_dieta")}
						fullWidth
						lockRows
						// editAction={onClickEdit}
						// insertAction={onClickEdit}
					/>
				</>
			}
			<Modal onClickClose={()=>setShowModalAsignacion(false)} open={showModalAsignacion} widthPaper="60%" variant="contained" color="primary">
				<Composicion onChange={setComposicion} close={()=>setShowModalAsignacion(false)} idNutritionist={nutId} />
			</Modal>
			{/* <Modal onClickClose={()=>setShowModal(false)} open={showModal} widthPaper="90%"><DietaMenusSelect handleModal={()=>setShowModal(false)} data={selectedData} saveAs={true}/></Modal> */}
		</>
	);
};

export default TabDietas;

const Composicion = ({ close, idNutritionist}) => {
	const {t} = useTranslation("common");
	return(
		<>
			<ComposicionAlimentos idNutritionist={idNutritionist} />
			<div style={{textAlign: "center", marginTop: 10}}>
				<Button variant="contained" color="secondary" onClick={close}>Cerrar</Button> 
			</div>
		</>
	);
};