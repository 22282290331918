import React from "react";
import CrudTable from "../../components/Table/CrudTable/CrudTable";
import ModalNutriologo from "./ModalNutriologo";
import { useSelector } from "react-redux";
import Header from "../../components/Header/Header";
import { NUTRITIONIST } from "store/constants";
import BoolCell from "components/Table/CellTypes/BoolCell/BoolCell"
const Premios = () => {
	const data = useSelector(state => state[NUTRITIONIST.name]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Nombre",
				accessor: "nombre"
			},
			{
				Header: "Status",
				accessor: "online.status",
				Cell:BoolCell
			},
			{
				Header: "Calificacion",
				accessor: "online.calificacion"
			},
			{
				Header: "# Consultas",
				accessor: "nConsultas"
			}
		],
		[]
	);

	return (
		<>
			<Header title="Nutriologos" />

			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				filters={columns}
				type={NUTRITIONIST}
				modal={ModalNutriologo}
				options={{filter: true,orderBy:true, disableEdit: true, disableInsert:true, disableDelete: true}}
			/>
		</>
	);
};

export default Premios;
