import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";

import "./AutoSaveStatus.css";
var classNames = require("classnames");

const statuses = {
	LOADED:"Cambios guardados automáticamente",
	UPDATE_FAILED:"Error al guardar los cambios",
	SAVING:"Guardando",
};
export default function AutoSaveStatus(props) {
	
	return (
		<div  className={classNames({ 
			"save-box":true,
			"failed-box": props.status.status==="UPDATE_FAILED", 
			"saving-box": props.status.status==="SAVING", 
			"saved-box":props.status.status==="LOADED" })}>
			<div style={{display: "inline"}}>{statuses[props.status.status]}</div>
			{props.status.status==="UPDATE_FAILED"&&<button className="reintentar-button" onClick={props.save}>Reintentar</button>}
		</div>

	);
}