import React from "react";
import { useSelector } from "react-redux";
import ListaEquivalentes from "./ListaEquivalentes";
import CrudTable from "components/Table/CrudTable/CrudTable";
import CountCell from "components/Table/CellTypes/CountCell/CountCell";

import { EQUIVALENT_LIST } from "store/constants";

const TabListaEquivalentes = () => {
	const data = useSelector(state => state[EQUIVALENT_LIST.name]);

	const columns = React.useMemo(
		() => [
			{
				accessor: "nombre",
				Header: "Nombre"
			},
			{
				Header: "Alimentos seleccionados",
				accessor: "equivalencias",
				Cell:CountCell
			}
		],
		[]
	);
	const filters = React.useMemo(
		() => [
			{
				accessor: "nombre",
				Header: "Nombre"
			}
		],
		[]
	);
	
	
	return (
		<>
			<>
				<CrudTable
					data={data.data}
					status={data.view}
					columns={columns}
					type={EQUIVALENT_LIST}
					modal={ListaEquivalentes}
					filters={filters}
					options={{ orderBy: true, saveAs:true, filter: true }}
					fullWidth
				/>
			</>
		</>
	);
};

export default TabListaEquivalentes;
