import React, {useState, useEffect} from "react";
import Select from "react-select";
// import Final from "../Final.js";
import {getNutitionistId} from "api/loader";
import { MENU_SELECT_LIST, NUTRITIONIST, CUSTOMER } from "store/constants";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {useQuery} from "react-query";
import {get} from "api";
import MenuList from "./MenuList";
import Option from "./Option";
import fold from "accent-fold";

const filterOptions = (candidate, input) => {
	if (input && input.length>2) {
		input = fold(input.toLowerCase());
		
		return fold(candidate.data.nombre).indexOf(input) > -1;
	}
	return true;
};
const ReactSelect = ({ value, onChange, placeholder, editValue, isMulti }) => {
	const nutId = getNutitionistId();
	// const [Final, setFinal] = React.useState({});
	// React.useEffect(()=>{
	// 	fetch("/Final.json").then(
	// 		function(res){
	// 			return res.json();
	// 		}).then(function(data){
	// 			console.log({data})
	// 		setFinal(data);
	// 	}).catch(
	// 		function(err){
	// 			console.log(err, ' error')
	// 		}
	// 	)

	// 	// fetch("/Final.json").then(r=>console.log(r));
	// },[]);
	const { data:Final } = useQuery("equivalenciasMenu", () => fetch("/Final.json").then((res)=>res.json()) );
	const { data:menuSelectList } = useQuery("menu-select-alimentos",  ()=>get("/menu-select/list2"));

	console.log({menuSelectList})

	const customer = useSelector(state => state[CUSTOMER.name].data);	
	const composicion = ["smae","tcac","usda","cesnid","ecu","incap","inta","tpca"]//Object.entries(customer?.nutritionist?.tab_comp_alim||{}).filter(([, val])=>val).map(([key])=>key);

	const [listas, setListas] = useState([]);

  useEffect(() => {
    // Function to fetch data for each item in composicion and update state
    const fetchData = async () => {
      try {
        // Fetch data for each composition
				console.log({composicion})
        const promises = composicion.map(c => fetch("/listas/"+c + ".json").then(response => response.json()));
        // Await all fetches and set data state
        const results = await Promise.all(promises);
        setListas(results);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, []); // Dependency array ensures this runs only when composicion changes

	// const { data:Final } = useQuery("equivalenciasMenu", () => fetch("/Final.json").then((res)=>res.json()) );


	// const menuSelectList = useSelector(state => state[MENU_SELECT_LIST.name].data);
	const Options = listas.concat(menuSelectList?.data || []).flat().sort((a,b)=>(a.nombre.length < b.nombre.length) ? -1 : (a.nombre.length > b.nombre.length) ? 1 : 0);

	const change = (data) => {
		onChange({
			nombre: data.nombre,
			id: data.id,
			_id: data._id,
			medidas: data.medidas,
			categoria: data.categoria,
			micronutr: data.micronutr,
			tags:data.tags,
			alimentos:data.alimentos,
			// custEquiv: data.custEquiv
			// prot: data.prot,
			// lip: data.lop,
			// hidr: data.hidr,
		});
	};
	
	return (
		<ContainerSelectAlimento>
			<Select
				cacheOptions
				options={Options}
				value={editValue ? Options.find(val => val?.id === editValue.id) : value && [value]}
				onChange={change}
				classNamePrefix="react-select"
				blurInputOnSelect={true}
				isMulti={isMulti}
				filterOption={filterOptions}
				placeholder={placeholder}
				getOptionLabel={(option) => option.nombre}
				components={{
					MenuList,
					Option
				}}
			/>
		</ContainerSelectAlimento>
	);
};

export default ReactSelect;

const ContainerSelectAlimento = styled.div`
	.react-select__control--is-focused .react-select__single-value { 
		display: none; 
	}
`;