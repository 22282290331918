import React from "react";
import CrudTable from "../../components/Table/CrudTable/CrudTable";
import Categorias from "./Categorias";
import { useSelector } from "react-redux";
import { PRODUCT_CATEGORY } from "store/constants";

const TabCategorias = () => {
	const data = useSelector(state => state[PRODUCT_CATEGORY.name]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Categoría",
				accessor: "nombre",
			}
		],
		[]
	);

	return (
		<>
			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				filters={columns}
				type={PRODUCT_CATEGORY}
				modal={Categorias}
				options={{ orderBy: true }}
			/>
		</>
	);
};

export default TabCategorias;
