import React, {useState, useEffect} from "react";
import {Edit, Done} from "@mui/icons-material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormGroup, Button, TextareaAutosize, IconButton, TextField } from "@mui/material";
import PropTypes from "prop-types";

import Menu from "@mui/material/Menu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
const TiempoComida = ({tiempoComida, index, onChange, del}) => {

	
	// useEffect(() => {
	// 	if(label || img){
	// 		onChange({label, img}, index)
	// 	}
	// 	}, [img, label])
			
			
			
	const [label, setLabel] = useState("");
	const [editImg, setEditImg] = useState(false);
	const [editlabel, setEditLabel] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	return (
		<div style={{display: "flex", flexDirection:"column", alignItems:"center",width: "80%"}}>			
			<IconButton
				aria-label="more"
				// aria-controls="long-menu"
				// aria-haspopup="true"
				// disableRipple={false}
				onClick={handleClick}
				style={{width: "100%", borderRadius: 0, margin: "12px", padding: 0}}
				className="avoid-print"
			>
				<MoreHorizIcon fontSize="small"/>
			</IconButton>
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
			>
				<MenuItem onClick={()=>{
					setEditImg(true);
					handleClose();
				}}>
			Editar ícono
				</MenuItem>
				<MenuItem onClick={()=>{
					setEditLabel(true);
					handleClose();
				}}>
			Editar título
				</MenuItem>
				<MenuItem onClick={()=>{
					del(index);
					handleClose();
					setEditLabel(false);
				}}>
			Eliminar
				</MenuItem>
			</Menu>
			<div style={{
				display:"flex",
				// background:tiempoComida.nombre_ti	empo.color+"38", 
				flexDirection: "column",	
				alignItems: "center", 
				justifyContent: "center",
				width: "100%"
			}}>
				{editImg || !tiempoComida.nombre_tiempo.img ? 
					<>
						<Select
							value={tiempoComida.nombre_tiempo.img}
							renderValue={(value)=>(
								<img
									src={require(`assets/img/${value}.png`)}
									style={{height:"30px"}}
									className="img-category"
								/>
							)}
							onChange={(e)=>{
								onChange("img", e.target.value, index);
								setEditImg(false);
							}}
						>
							<MenuItem value={"desayuno"}>
				
								<img
									src={require("assets/img/desayuno.png")}
									style={{height:"30px"}}
									className="img-category"
								/>
							</MenuItem>
							<MenuItem value={"colacion1"}>
								<img
									src={require("assets/img/colacion1.png")}
									style={{height:"30px"}}
									className="img-category"
								/>
				
							</MenuItem>
							<MenuItem value={"comida"}>
								<img
									src={require("assets/img/comida.png")}
									style={{height:"30px"}}
									className="img-category"
								/>
				
							</MenuItem>
							<MenuItem value={"colacion2"}>
								<img
									src={require("assets/img/colacion2.png")}
									style={{height:"30px"}}
									className="img-category"
								/>
							</MenuItem>
							<MenuItem value={"cena"}>
								<img
									src={require("assets/img/cena.png")}
									style={{height:"30px"}}
									className="img-category"
								/>
							</MenuItem>
						</Select>
						<p style={{color: "#00000070"}}>Elige un ícono</p>
					</>
					:
					<div>
						<img
							src={require(`assets/img/${tiempoComida.nombre_tiempo.img}.png`)}
							alt={tiempoComida.nombre_tiempo.name}
							className="img-category"
						/>
					</div>
				}
				{editlabel || !tiempoComida.nombre_tiempo.label ? 
					<div style={{textAlign: "center", marginTop: "5px"}}>
						<TextField 
							placeholder="Asigna un nombre" 
							value={label} 
							onChange={(e) => setLabel(e.target.value)}
							className="text-field"
						/>
						<div style={{display: "flex", justifyContent: "center"}}>
							{tiempoComida.nombre_tiempo.label &&
								<Button
									style={{marginTop: "5px"}}
									onClick={() => {
										setEditLabel(false);
										setLabel("");
									}}
								>Cancelar</Button>
							}
							<Button 
								variant="contained" 
								color="secondary" 
								style={{marginTop: "5px"}}
								onClick={() => {
									onChange("label", label, index);
									setLabel("");
									setEditLabel(false);
								}}
							>Asignar</Button>
						</div>
					</div>
					:
					<p>{tiempoComida.nombre_tiempo.label}</p>
				}
			</div>
		</div>
	);
};

export default TiempoComida;

TiempoComida.propTypes = {
	tiempoComida: PropTypes.object, 
	index: PropTypes.number,
	del: PropTypes.func,
	onChange: PropTypes.func,
};