import React from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { PATIENTS, CONSULTATION } from "store/constants";
import moment from "moment";

export default function DurninWomersley(props){
	let match = useRouteMatch();
	const dataPatient = useSelector(state=>state[PATIENTS.name].data.find(n=>n._id===match.params.idPaciente));
	const dataConsulta = useSelector(state=>state[CONSULTATION.name].data[match.params.idPaciente].find(n=>n._id===match.params.idConsulta));
	const genero = dataPatient.genero;
	const edad = moment().diff(moment(dataPatient.fecha_nacimiento), "years");

	function Dc(){
		if(genero === "masculino"){
			if(edad >= 20 || edad <= 29){
				return (1.1631 - (0.0632*Math.log10(dataConsulta.pliegue_triceps+dataConsulta.pliegue_biceps+dataConsulta.pliegue_subescapular+dataConsulta.pliegue_cresta_iliaca)));
			}else if(edad >= 30 || edad <= 39){
				return (1.1422 - (0.0544*Math.log10(dataConsulta.pliegue_triceps+dataConsulta.pliegue_biceps+dataConsulta.pliegue_subescapular+dataConsulta.pliegue_cresta_iliaca)));
			}else if(edad >= 40 || edad <= 49){
				return (1.1620 - (0.0700*Math.log10(dataConsulta.pliegue_triceps+dataConsulta.pliegue_biceps+dataConsulta.pliegue_subescapular+dataConsulta.pliegue_cresta_iliaca)));
			}else if(edad >= 50 || edad <= 72){
				return (1.1715 - (0.0799*Math.log10(dataConsulta.pliegue_triceps+dataConsulta.pliegue_biceps+dataConsulta.pliegue_subescapular+dataConsulta.pliegue_cresta_iliaca)));
			}
		}else if(genero === "femenino"){
			if(edad >= 20 || edad <= 29){
				return (1.1599 - (0.0717*Math.log10(dataConsulta.pliegue_triceps+dataConsulta.pliegue_biceps+dataConsulta.pliegue_subescapular+dataConsulta.pliegue_cresta_iliaca)));
			}else if(edad >= 30 || edad <= 39){
				return (1.1423 - (0.0632*Math.log10(dataConsulta.pliegue_triceps+dataConsulta.pliegue_biceps+dataConsulta.pliegue_subescapular+dataConsulta.pliegue_cresta_iliaca)));
			}else if(edad >= 40 || edad <= 49){
				return (1.1333 - (0.0612*Math.log10(dataConsulta.pliegue_triceps+dataConsulta.pliegue_biceps+dataConsulta.pliegue_subescapular+dataConsulta.pliegue_cresta_iliaca)));
			}else if(edad >= 50 || edad <= 68){
				return (1.1339 - (0.0645*Math.log10(dataConsulta.pliegue_triceps+dataConsulta.pliegue_biceps+dataConsulta.pliegue_subescapular+dataConsulta.pliegue_cresta_iliaca)));
			}
		}
	}

	const total = ((495/Dc())-450);

	if(isNaN(total)) return (0);
	return (total).toFixed(2);
}