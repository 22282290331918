import React, { useState, useEffect } from "react";
// import Select from "react-select";
import { categoriasEquivMap, medidas, micronutrimentosMap, catMicronutrimentosMap, catEquivalenciasSmae } from "constants/index";
import { tagsDietasAutomaticas } from "constants/ai";
import IconEq from "../IconEq";

import { Alert, Button, Checkbox, CircularProgress } from "@mui/material";
import useSkipFirstRender from "Hooks/useSkipFirstRender";
import Select from "components/Select/Select";
import Radio from "components/FormComponents/Radio/Radio";
import HighlightOffIcon  from '@mui/icons-material/HighlightOff';
// import {HighlightOffIcon as Delete} from "@mui/icons-material";
import styled from "styled-components";
import MultiRefListaAlimentos from "./MultiRefListaAlimentos";
import { useTranslation } from "react-i18next";
import SelectOrInput from "components/FormComponents/Select/SelectOrInput";
import cx from "classnames";
import {Puntos} from "pages/Dietas/DietaMenusSelect/Dieta/AIDiet2";
import AddComida from "../Dieta/AddComida";
import {useDialog} from "context/DialogContext";
import editIcon from "assets/img/edit.png";
import {deepMult, deepSum} from "functions/general";
import _debounce from "lodash.debounce";
import useListaAlimentos from "pages/Dietas/useListaAlimentos";
import {getRecetaSeleccionAlimentosAi} from "api";
var stringSimilarity = require("string-similarity");
// import aiIcon from "assets/img/ai-white.png"

var shortid = require("shortid");

const tiemposComida = [
	{
		value:"d", 
		label:"Desayuno", 
		tipos:[{value:"b", label:"Bebida"},{value:"gu", label:"Guarnicion"},{value:"pp", label:"Platillo principal"}]
	}, {
		value:"co", 
		label:"Comida",
		tipos:[{value:"b", label:"Bebida"},{value:"gu", label:"Guarnicion"},{value:"pp", label:"Platillo principal"}, {value:"so", label:"Sopa"},{value:"ens", label:"Ensalada"}, {value:"po", label:"Postre"}]

	}, {
		value:"ce", 
		label:"Cena",
		tipos:[{value:"b", label:"Bebida"},{value:"gu", label:"Guarnicion"},{value:"pp", label:"Platillo principal"},{value:"ens", label:"Ensalada"}]
	}, {
		value:"sn",
		label:"Snack",
		tipos:[{value:"snc", label:"Snack sencillo"},{value:"snp", label:"Snack preparado"}]
	}
];
const generalTags = {
	tiempos:["d","co","ce","sn"],
	tipos: ["b","gu", "pp", "ens","snp"],
	restricciones:["ll","lg","vt","vg","ba","bg","k"]
}

function AddAlimentoMenuSelect({data, onChange, alimentoPreparado}) {
	const {t} = useTranslation("common");
	const [preventEffect, setPreventEffect] = useState(true);
	const [openDialog, closeDialog] = useDialog();

	const [lista, setLista] = useState(data ?? {
		nombre: "",
		searchString: "",
		categoria: alimentoPreparado? "preparados":"",
		lista: t("dieta_menu_select_add_alimento_menu_select_mi_lista"),
		id: shortid.generate(),
		alimentos:[],
		preparado:alimentoPreparado,
		tags:[],
		eq_auto:true,
		medidas: [{
			cant: 100,
			gram: 100,
			unidad: t("grupos_gramos"),
			kcal: undefined,
			prot: undefined,
			lip: undefined,
			hidr: undefined,
		},{
			cant: undefined,
			gram: undefined,
			unidad: "",
			kcal: undefined,
			prot: undefined,
			lip: undefined,
			hidr: undefined,
		}],
		micronutr: {}
	});
	const [selectMicronutr, setSelectMicronutr] = useState(false);
	const debounceChange = React.useRef(
		_debounce((d) => {
			onChange(d);
		}, 1000)
	);
	useSkipFirstRender(() => {
		debounceChange.current(lista);
	}, [lista]);

	const onChangeData = (value, key) => {
		if(value===undefined)return;
		setLista((prev) => {
			let newLista = { ...prev, [key]: value };

			if(newLista.eq_auto && !newLista.preparado){
				let medidas = newLista.medidas.map(m=>{
					const eq = calculateFoodEquivalent(m, newLista.categoria);
					return {
						...m,
						eq
					};

				})
				newLista.medidas = medidas;
			}
			return newLista
		});
	};

	const openModalEq = () => {
		openDialog({
			onClose:(eq)=>{
				if(eq){
					setLista(p=>{
						let medidas = p.medidas.map(m=>{
							const mult = m.kcal / p.medidas[0].kcal;
							return {
								...m,
								eq: deepMult(eq, mult)
							};
						});
					
						return {...p, medidas};
					});
				}
			},
			Content: Puntos,
			width:"md",
			value: lista.medidas[0].eq,
		});
		
	};
	const changeEqManual = () => {
		if(lista.eq_auto){
			openModalEq();
		}
		onChangeData(!lista.eq_auto, "eq_auto");
	};
	const onChangeMedida = (value, key, index) => {
		setLista((prev) => {
			const updatedMedida = { ...prev.medidas[index], [key]: value };
			let newMedidas = [
				...prev.medidas.slice(0, index),
				updatedMedida,
				...prev.medidas.slice(index + 1),
			];
			newMedidas = recalculateExtras(lista, newMedidas)

			return { ...prev, medidas: newMedidas };
		});
		setPreventEffect(false);
	};
	const recalculateExtras = (lista, medidas) => {
		const defaultMedida = medidas[0]
	
		
		const extraMedidas = medidas.map((medida) => {
			

			const total = ["kcal", "prot", "lip", "hidr"].reduce((acc, key)=>{
				acc[key] = +(((medida.gram * defaultMedida[key]) / defaultMedida.gram).toFixed(2)) || 0;
				return acc;
			}, {kcal: 0, prot: 0, lip: 0, hidr: 0, eq:{}});

			let eq;
			if (lista.eq_auto) {
				eq = calculateFoodEquivalent(total, lista.categoria);
			}else{
				const mult = medida.gram / defaultMedida.gram;
				eq = deepMult(defaultMedida.eq, mult);	
			}

			return {
				...medida,
				...total,
				eq
			};
		});
		return extraMedidas
		
	}


	const addMicronutrimento = (keys, reason, detail) => {
		if(reason === "remove-option"){
			deleteMicronutrimentos(detail.option);
			return;
		}
		if(keys){
			setLista(prev=>({...prev, micronutr: {...prev.micronutr, [detail.option]: 0}}));
		}
	};

	const deleteMicronutrimentos = (key) => {
		const preview = lista.micronutr;
		preview[key] = "";
		setLista(prev=>({...prev, micronutr: preview}));
	};

	const editMicronutrimento = (value, key) => {
		setLista(prev=>({...prev, micronutr: {...prev.micronutr, [key]: value}}));
	};

	const eliminarAlimento = (idx) => {
		const listaAlimentos = lista.alimentos.slice(0, idx).concat(lista.alimentos.slice(idx + 1));
		updateListaAlimentos(listaAlimentos);

	};

	const addAlimento = (a) => {
		console.log({a})
		const listaAlimentos = [...lista.alimentos, a];
		updateListaAlimentos(listaAlimentos);
	};
	const [tagsTipoComida, setTagsTipoComida] = React.useState();
	React.useEffect(()=>{
		if(lista.tiempos_c){
			const a=mapValuesToTiempos(tiemposComida, lista.tiempos_c)

			setTagsTipoComida(a);
		}

	}, [lista.tiempos_c]);

	const onChangeTiempoComida = (v) => {
		const tags = v.map(v=>v.value);
		setLista((prev) => ({ ...prev, tiempos_c:tags }));
	};
	const onChangeTipoComida = (v) => {
		const tags = v.map(v=>v.value);
		setLista((prev) => ({ ...prev, tipos_c:tags }));
	};
	const onChangeTagsRestricciones = (v) => {
		const tags = v.map(v=>v.value);
		setLista((prev) => ({ ...prev, tags:tags }));
	};
	

	const updateListaAlimentos = (listaAlimentos) => {
		setLista(prev=>({
			...prev,
			alimentos: listaAlimentos
		}));

		let macros = listaAlimentos.reduce((acc, val)=>{
			acc.gram += (val.gram||0);
			acc.kcal += (val.kcal||0);
			acc.prot += (val.prot||0);
			acc.hidr += (val.hidr||0);
			acc.lip += (val.lip||0);

			acc.eq = deepSum(acc.eq, val.eq);
			return acc;
		},{gram:0, kcal:0, prot:0, hidr:0, lip:0});
		
		macros = Object.fromEntries(
			Object.entries(macros).map(([key, value]) => {
				if(typeof value==="number") return [key, +value.toFixed(2)]
				return [key, value]
			})
		);

		setLista((prev) => ({
			...prev,
			medidas: [
				{...macros, cant:1, unidad:"porcion"}	
			]
		}));
	};

	const [aiStatus, setAiStatus] = useState({status:"ready", error:null}); 
	const {listas, alimentos} = useListaAlimentos();
	console.log({listas, alimentos})
	
	const ai2 = async ()=>{
		try{
			setAiStatus({status:"loading"})
			const r = await getRecetaSeleccionAlimentosAi(lista.nombre)
			console.log({r})
			setAiStatus({status:"ready"})
			updateListaAlimentos(r.data.alimentos);
			
			setLista(l=>({...l,  modo_preparacion:r.data.modo_preparacion}))
		}catch(e){
			console.error(e)
			setAiStatus({status:"error", error:e})
		}
		
		

	};
	console.log({lista})
	const extraMedidas = React.useMemo(()=>lista.medidas.slice(1), [lista]);
	return (
		<ContainerAddAlimentoSelect>

			<div className="cont-input">
				<p>{t("dieta_menu_select_add_alimento_menu_select_nombre")}</p>
				<div style={{display:"flex"}}>
					<input disabled={aiStatus.status==="loading"} type="text" name="nombre" value={lista.nombre} onChange={(e)=>{
						onChangeData(e.target.value, e.target.name);
						onChangeData((`${e.target.value} (${lista.lista})`).toLowerCase(), "searchString");
					}} />
					{lista?.preparado && <Button onClick={ai2} sx={{ whiteSpace: 'nowrap' }} style={{flexShrink:0,border:0, borderRadius:5, display:"flex",alignItems:"center", color:"white",fontWeight:"bold", background: "linear-gradient(0deg, rgba(149,104,249,1) 0%, rgba(199,104,249,1) 100%)"}}>Crear con IA</Button>}
				</div>
			</div>
				
			{aiStatus.status==="loading" && <div style={{marginTop:10}}><CircularProgress /></div>}
			{aiStatus.status==="error" && <Alert severity="error">{aiStatus.error.message}</Alert>}
			<div className="container-dias-laborales" style={{marginTop:20}}>
				
			</div>

			{lista?.preparado && <>
				<div style={{fontWeight:"bold"}}>Selecciona los alimentos que contiene</div>
				<AddComida hideHeader onSave={addAlimento} />
				<div style={{marginBottom:10}}>{lista?.alimentos?.map((al, idx)=><div key={al.id} style={{padding:10, border:"2px solid #F3F3F3", display:"flex", justifyContent:"space-between"}}>
					<div>{al.nombre} - {al.cant}{al.unidad} {al.unidad!=="gramos" ? `(${al.gram} gr)` : ""}</div>
					<div onClick={()=>eliminarAlimento(idx)}>x</div>
				</div>)}</div>
			</>}
			
			{! lista?.preparado && <div className="cont-input">
				<p>{t("constants_categoria")}</p>
				<Select
					name="categoria"
					options={[
						{ label: t("dieta_menu_select_add_alimento_menu_select_selecciona_categoria"), value: undefined },
						...categoriasEquivMap,
					]}
					value={lista.categoria}
					accessor="value"
					labelAccessor="label"
					onChange={(v,k)=>onChangeData(v.value, k)}
				/>
			</div>}

			<div style={{borderRadius:10, overflow:"hidden"}}>
				<div style={{background:"#3A4D4B", color:"white", fontWeight:"bold", padding:20}}>Detalle nutrimental</div>
				<div style={{background:"#EDF2FF", display:"flex", flexDirection:"column", gap:20, padding:20}}>
					<div style={{display:"flex", gap:20}}>
						<div>Detalle nutricional por: <CustomInput endPlaceholder="gr" disabled={lista.preparado} type="number" value={lista.medidas?.[0].gram || ""} min={0} placeholder="0"  onChange={e=>{
							onChangeMedida(+e.target.value, "cant", 0);
							onChangeMedida(+e.target.value, "gram", 0);
						}} 
						/></div>
						<div>Kcal: <CustomInput disabled={lista.preparado} type="number" value={lista.medidas?.[0].kcal || ""} min={0} placeholder="0" onChange={e=>{
							onChangeMedida(+e.target.value, "kcal", 0);
						}} /></div>
					</div>
					<div style={{display:"flex", gap:20}}>
						<div style={{display:"flex"}}>

							{t("proteinas")} 
							<CustomInput endPlaceholder="gr" disabled={lista.preparado} type="number" value={lista.medidas?.[0].prot || ""} min={0} placeholder="0"  onChange={e=>{
								onChangeMedida(+e.target.value, "prot", 0);
							}} 
							/>
						</div>
						<div style={{display:"flex"}}>
							{t("lipidos")} 
							<CustomInput endPlaceholder="gr" disabled={lista.preparado} type="number" value={lista.medidas?.[0].lip || ""} min={0} placeholder="0"  onChange={e=>{
								onChangeMedida(+e.target.value, "lip", 0);
							}} 
							/>

							
						</div>
						<div style={{display:"flex"}}>
							{t("hidratos_carbono")} 
							<CustomInput endPlaceholder="gr" disabled={lista.preparado} type="number" value={lista.medidas?.[0].hidr || ""} min={0} placeholder="0"  onChange={e=>{
								onChangeMedida(+e.target.value, "hidr", 0);
							}} 
							/>
							
						</div>
						

					</div>
					<div>
						<div style={{display:"flex", gap:10, alignItems:"center", marginBottom:5}}>
							<div>Equivalencia:</div>
							<IconEq eq={lista.medidas?.[0]?.eq} size="lg" />
							{!lista.eq_auto && !lista.preparado && <img style={{width:15}} src={editIcon} onClick={openModalEq} />}
						</div>
						{!lista.preparado && <div onClick={changeEqManual} style={{textDecoration:"underline", cursor:"pointer", color:"#6896F9", fontSize:".9rem"}}>Agregar equivalencias {lista.eq_auto ? "manualmente":"automaticas"}</div>}
					</div>
					
				</div>
			</div>
			
			
			{/* <DetalleNutricional /> */}
			{!lista.preparado && <>
				<div style={{textAlign: "left"}}>
					{(Object.keys(lista.micronutr).length === 0 && !selectMicronutr) && <Button variant="outlined" color="secondary" style={{marginTop:10}} onClick={()=>setSelectMicronutr(true)}>{t("dieta_menu_select_add_alimento_menu_select_anadir_micros")}</Button>}
				</div>
				{(Object.keys(lista.micronutr).length > 0 || selectMicronutr) && 
					<div className="cont cont-micronutrientes">
						<p className="title">{t("dieta_menu_select_add_alimento_menu_select_micros")}</p>
						<MultiRefListaAlimentos 
							options={micronutrimentosMap}
							onChange={addMicronutrimento}
							value={Object.entries(lista.micronutr).filter(([, val]) => val !== "").map(([key]) => key)}
						/>
						{Object.entries(lista.micronutr).filter(([,val]) => val !== "").map(([key, value], idx)=>(
							<div key={idx}>
								
								<div className="cont-input">
									<p>{catMicronutrimentosMap[key]}</p>
									<div className="flex">
										<input type="number" min={0} value={value || undefined} onChange={e=>editMicronutrimento(+e.target.value, key)} />
										<HighlightOffIcon className="button-delete" onClick={()=>deleteMicronutrimentos(key)} />
									</div>
								</div>
							</div>
						))}
					</div>	
				}
			</>}
			{!lista.preparado && <div className="cont cont-medidas">
				<p className="title">Medidas</p>
				{extraMedidas.map((medida, idx) => <AnadirMedida 
					key={idx} 
					idx={idx+1}
					lista={lista}
					medida={medida} 
					onChange={(change, key)=> onChangeMedida(change, key, idx + 1)} 
					onDelete={()=>{
						const medidas = [...lista.medidas];
						medidas.splice(idx + 1, 1);
						setLista(prev => ({...prev, medidas}));
					}}
				/>)}
				<div style={{textAlign: "right"}}>
					<Button variant="contained" color="secondary" onClick={()=>{
						let prevMedidas = [...lista.medidas];
						prevMedidas.push({
							cant: undefined,
							gram: undefined,
							unidad: "",
							kcal: undefined,
							prot: undefined,
							lip: undefined,
							hidr: undefined,
						});
						onChangeData(prevMedidas, "medidas");
					}}>{t("dieta_menu_select_add_alimento_menu_select_anadir_medida")}</Button>
				</div>
			</div>}

			{lista?.preparado &&<div  className="cont-input">
				<div style={{fontWeight:"bold", margin:"10px 0"}}>Modo de preparación</div>

				{lista.modo_preparacion?.map((paso, idx)=><div key={idx} style={{display:"flex", gap:5, alignItems:"center"}}>
					<input onChange={(e)=>setLista(l=>({...l, modo_preparacion:[...l.modo_preparacion.slice(0, idx),e.target.value,...l.modo_preparacion.slice(idx + 1)]}))} disabled={aiStatus.status==="loading"} type="text" name="nombre" value={paso}  />
					<div style={{cursor:"pointer"}} onClick={()=>setLista(l=>({...l, modo_preparacion:[...l.modo_preparacion.slice(0, idx),...l.modo_preparacion.slice(idx + 1)]}))}><HighlightOffIcon className="button-delete" /></div>
				</div>)}
				<Button style={{marginTop:5}} variant="contained" color="secondary" onClick={()=>setLista(l=>({...l, modo_preparacion:[...(l.modo_preparacion||[]), ""]}))}>Agregar paso</Button>
			</div>}

			<div style={{display:"flex", flexDirection:"column", gap:15, marginTop:15}}>
				<div style={{display:"flex", flexDirection:"column", gap:5}}>
					<div style={{fontWeight:"bold"}}>¿A qué tiempo de comida pertenece?</div>
					<Select options={tiemposComida} multiSelect accessor="value" labelAccessor="label" value={lista?.tiempos_c} onChange={onChangeTiempoComida} />
				</div>
				<div style={{display:"flex", flexDirection:"column", gap:5}}>
					<div style={{fontWeight:"bold"}}>¿Qué tipo de alimento es?</div>
					<Select Option={Option} options={tagsTipoComida} multiSelect accessor="value" labelAccessor="label" value={lista?.tipos_c} onChange={onChangeTipoComida} />
				</div>
				<div style={{display:"flex", flexDirection:"column", gap:5}}>
					<div style={{fontWeight:"bold"}}>¿A qué categorías o grupos de alimentos pertenece?</div>
					<Select options={tagsDietasAutomaticas} multiSelect accessor="value" labelAccessor="label" value={lista?.tags} onChange={onChangeTagsRestricciones} />
				</div>
			</div>
			{/* </Box> */}
		</ContainerAddAlimentoSelect>
	);
}

const AnadirMedida = ({ lista, medida, onChange, onDelete, hideDelete }) => {
	const {t} = useTranslation("common");
	return (
		<ContainerAnadirAlimento>
			<div className="cont-input">
				<div className="cont-cant">
					<p>{t("equivalentes_equivalencias_cantidad")}</p>
					{!hideDelete && <HighlightOffIcon className="button-delete" onClick={onDelete} />}
				</div>
				<input type="number" min={0} placeholder="0" value={medida?.cant || undefined} onChange={e=>onChange(+e.target.value, "cant")} />
			</div>
			<div className="cont-input">
				<p>{t("dieta_menu_select_add_alimento_menu_select_medida")}</p>
				<SelectOrInput
					name="unidad"
					options={medidas}
					value={medida?.unidad}
					onChange={onChange}
				/>
			</div>
			<div className="cont-input">
				<p>{t("dieta_menu_select_add_alimento_menu_select_peso_en_gramos")}</p>
				<input type="number" min={0} placeholder="0" value={medida?.gram || undefined} onChange={e=>onChange(+e.target.value, "gram")} />
			</div>
			<div className="cont-input" style={{display:"flex", gap:10, alignItems:"center"}}>
				<p>Equivalencia</p>
				<IconEq eq={medida?.eq} size="md" />
			</div>
		</ContainerAnadirAlimento>
	);
};

export default AddAlimentoMenuSelect;
export { AnadirMedida };

const ContainerAddAlimentoSelect = styled.div`
	.cont-input {
		margin-bottom: 10px;
		p {
			font-size: 12pt;
			font-weight: bold;
			margin-bottom: 5px;
		}
		input {
			padding: 10px;
			border: 1px solid #C8C8C8;
			border-radius: 5px;
			width: 100%;
			font-size: 11pt;
		}
		span{
			margin-left: 5px;
			font-size: 12pt;
			font-weight: bold;
		}
		.p-detalle{
			display: flex;
			align-items: center;
			input{
				margin: 0 5px;
				width: 20%;
			}
		}
	}
	.cont{
		margin: 10px 0;
		.title{
			font-weight: bold;
			font-size: 14pt;

		}
	}
	.cont-micronutrientes{
		padding: 10px 0;
		border-bottom: 1px solid #3b4c4b;
		.cont-input{
			margin-top: 10px;
			input{
				width: 20%;
			}
			.flex{
				display: flex;
				align-items: center;
			}
		}
	}
	.cont-detalle-nutricional{
		.cont-input{
			display: flex;
			align-items: center;
			p{
				width: 30%;
			}
			input{
				width: 20%;
			}
		}
	}
	.button-delete{
		width: 25px; 
		height: 25px; 
		color:#D9534F; 
		padding: 0 1px 0 1px;
		// color: white;
		border-radius: 3px; 
		margin-left: 5px;
	}
	.cont-buttons {
		text-align: right;
	}
`;

const ContainerAnadirAlimento = styled.div`
	padding: 10px 0;
	border-bottom: 1px solid #3b4c4b;
	.cont-input {
		margin-bottom: 10px;
		p {
			font-size: 12pt;
			font-weight: bold;
			margin-bottom: 5px;
		}
		input {
			padding: 10px;
			border: 1px solid #C8C8C8;
			border-radius: 5px;
			width: 100%;
			font-size: 11pt;
		}
		span{
			margin-left: 5px;
			font-size: 12pt;
			font-weight: bold;
		}
	}
	.cont-cant{
		display: flex;
		justify-content: space-between;
	}
`;

const CustomInput = ({endPlaceholder, ...rest}) => {
	
	return <StyledInput className="placeholder" data-placeholder={endPlaceholder}>
		<input {...rest} />  
	</StyledInput>;
};
const StyledInput = styled.div`
	input {
		// text-align: right;
		padding:3px;
		padding-right: 30px;
		border:1px lightgray solid;
		border-radius:5px;
		background:transparent;
		width:90px;
	}


	position: relative;
	display: inline-block;
	

	::after {
			position: absolute;
			right: 5px;
			top: 1px;
			content: attr(data-placeholder);
			pointer-events: none;
			opacity: 0.6;
	}
`;

function mapValuesToTiempos(inputArray, selectedValues) {
	// Step 1: Create a mapping of values to labels
	const valueToLabelMap = {};
	inputArray.forEach(item => {
		valueToLabelMap[item.value] = item.label;
	});

	// Step 2: Create a dictionary of 'tipos' to 'tiempos'
	const tiposToTiempos = {};
	selectedValues.forEach(value => {
		const label = valueToLabelMap[value];
		const tiposArray = inputArray.find(item => item.value === value)?.tipos;
		if(tiposArray){
			tiposArray.forEach(tipo => {
				const tipoValue = tipo.value;
				if (!tiposToTiempos[tipoValue]) {
					tiposToTiempos[tipoValue] = { tiempos: [], label: tipo.label };
				}
				tiposToTiempos[tipoValue].tiempos.push(label);
			});
		}
			
	});

	// Step 3: Create the final array
	const finalArray = Object.entries(tiposToTiempos).map(([k,tipo]) => ({
		value: k,
		label: tipo.label,
		tiempos: tipo.tiempos.join(', ')
	}));

	return finalArray;
}


const Option = (props) => {
	const {children, isSelected, innerProps, data} = props;
	return <div
		className={cx("react-select__option", {
			"react-select__option_selected": isSelected
		})}
		id={innerProps.id}
		tabIndex={innerProps.tabIndex}
		onClick={innerProps.onClick}
		style={{...data?.styles, padding: 10, cursor: "pointer"}}
	>
		<p>{props.data.label}</p>
		<p style={{fontSize: 12, color:"grey"}}>{props.data.tiempos}</p>
	</div>;
};



function calculateFoodEquivalent(foodMacros, groupName) {
	// Find the group in the smae array
	if(!foodMacros) return null;
	const group = catEquivalenciasSmae.find(g => g.cat === groupName);
	if (!group) {
		return {}
		// throw new Error("Group not found");
	}

	let subgrupo;
	let bestMatch = group.aporte;
	let smallestDifference = Number.MAX_VALUE;

	// Check for subcategories
	if (group.subcat) {
		for (const key in group.subcat) {
			const subGroup = group.subcat[key];
			// Calculate the difference in macronutrients between the food and the subgroup
			const difference = Math.abs((foodMacros.cal||0) - (subGroup.aporte.cal||0)) +
													Math.abs((foodMacros.prot||0) - (subGroup.aporte.proteinas||0)) +
													Math.abs((foodMacros.lip||0) - (subGroup.aporte.lipidos||0)) +
													Math.abs((foodMacros.hidr||0) - (subGroup.aporte.hidratos_de_carbono||0));
			// Update the best match if this subgroup is closer
			if (difference < smallestDifference) {
				smallestDifference = difference;
				bestMatch = subGroup.aporte;
				subgrupo = subGroup;
			}
		}
	}
	// Assuming the comparison is based on caloric content
	const equivalent = foodMacros.kcal / bestMatch.cal;

	if(subgrupo){
		return {[group.value]: {
			[subgrupo.value]:Math.round(equivalent * 10) / 10}
	};

	}
	// Return the rounded equivalent
	return {[group.value]: Math.round(equivalent * 10) / 10};

}
