export const tiemposComidaDietasAutomaticas = [
	{value:"d", label:"Desayuno"},
	{value:"co", label:"Comida"},
	{value:"ce", label:"Cena"},
	{value:"sn", label:"Snack"},
]
export const tiposComidaDietasAutomaticas = [
	{value:"snp", label:"Snack preparado"},
	{value:"b", label:"Bebida"},
	{value:"gu", label:"Guarnición"},
	{value:"pp", label:"Platillo principal"},
	{value:"ent", label:"Entrada"},
	{value:"po", label:"Postre"},
	{value:"so", label:"Sopa"},
	{value:"ens", label:"Ensalada"},
]
export const tagsDietasAutomaticas = [
	{value:"ll", label:"Libre de lácteo"},
	{value:"lg", label:"Libre de gluten"},
	{value:"vt", label:"Vegetariana"},
	{value:"vg", label:"Vegana"},
	{value:"ba", label:"Bajo en azucar"},
	{value:"bg", label:"Bajo en grasa"},
	{value:"k", label:"Kosher"},
]

export const	
	BEBIDA_DESAYUNO = ["b","d"],
	GUARNICION_DESAYUNO = ["gu","d"],
	PLATILLO_DESAYUNO = ["pp","d"],

	SNACK_PREPARADO = ["snp"],
	SNACK = ["sn", "snc"],
	BEBIDA_COMIDA = ["b","co"],
	GUARNICION_COMIDA = ["gu","co"],
	POSTRE_COMIDA = ["po","co"],
	SOPA_COMIDA = ["so","co"],
	PLATILLO_COMIDA = ["pp","co"],
	ENSALADA_COMIDA = ["ens","co"],
	SOPA = ["so"],
	POSTRE = ["po"],

	BEBIDA_CENA = ["b","ce"],
	ENSALADA_CENA = ["ens","ce"],
	GUARNICION_CENA = ["gu","ce"],
	PLATILLO_CENA = ["pp","ce"];

export const tiposPlanes = [{
	from:800,
	to:1299,
	opciones:[{
		label:"Plan Desayuno Ligero",
		slots:[
			{nombre: "desayuno", slots:[BEBIDA_DESAYUNO, PLATILLO_DESAYUNO]},
			{nombre: "comida", slots:[BEBIDA_COMIDA, PLATILLO_COMIDA,GUARNICION_COMIDA]},
			{nombre: "cena", slots:[BEBIDA_CENA,PLATILLO_CENA]},
		],
		value:"1"
	},{
		label:"Plan Cena Ligero",
		slots:[
			{nombre: "desayuno", slots:[BEBIDA_DESAYUNO, PLATILLO_DESAYUNO]},
			{nombre: "comida", slots:[BEBIDA_COMIDA, PLATILLO_COMIDA,GUARNICION_COMIDA]},
			{nombre: "cena", slots:[BEBIDA_CENA,ENSALADA_CENA]},
		],
		value:"2"
	},{
		label:"Plan de 4 tiempos",
		slots:[
			{nombre: "desayuno", slots:[BEBIDA_DESAYUNO,PLATILLO_DESAYUNO,GUARNICION_DESAYUNO]},
			{nombre: "colacion1", slots:[SNACK,SNACK_PREPARADO]},
			{nombre: "comida", slots:[BEBIDA_COMIDA, PLATILLO_COMIDA,GUARNICION_COMIDA]},
			{nombre: "cena", slots:[BEBIDA_CENA,ENSALADA_CENA]},
		],
		value:"3"
	},{
		label:"Plan de 5 tiempos",
		slots:[
			{nombre: "desayuno", slots:[BEBIDA_DESAYUNO,PLATILLO_DESAYUNO,GUARNICION_DESAYUNO]},
			{nombre: "colacion1", slots:[SNACK, SNACK_PREPARADO]},
			{nombre: "comida", slots:[BEBIDA_COMIDA, PLATILLO_COMIDA,GUARNICION_COMIDA,ENSALADA_COMIDA]},
			{nombre: "colacion2", slots:[SNACK,SNACK_PREPARADO]},
			{nombre: "cena", slots:[BEBIDA_CENA,PLATILLO_CENA]},
		],
		value:"4"
	}]
	},{
	from:1300,
	to:1599,
	opciones:[{
		label:"Plan Desayuno Ligero",
		slots:[
			{nombre: "desayuno", slots:[BEBIDA_DESAYUNO, PLATILLO_DESAYUNO]},
			{nombre: "comida", slots:[BEBIDA_COMIDA, PLATILLO_COMIDA,GUARNICION_COMIDA,ENSALADA_COMIDA]},
			{nombre: "cena", slots:[BEBIDA_CENA,PLATILLO_CENA]},
		],
		value:"1"
	},{
		label:"Plan Cena Ligera",
		slots:[
			{nombre: "desayuno", slots:[BEBIDA_DESAYUNO, PLATILLO_DESAYUNO]},
			{nombre: "comida", slots:[BEBIDA_COMIDA,SOPA,PLATILLO_COMIDA,GUARNICION_COMIDA]},
			{nombre: "cena", slots:[BEBIDA_CENA,ENSALADA_CENA]},
		],
		value:"2"
	},{
		label:"Plan de 4 tiempos",
		slots:[
			{nombre: "desayuno", slots:[BEBIDA_DESAYUNO,PLATILLO_DESAYUNO,GUARNICION_DESAYUNO]},
			{nombre: "colacion1", slots:[SNACK,SNACK_PREPARADO]},
			{nombre: "comida", slots:[BEBIDA_COMIDA,GUARNICION_COMIDA, PLATILLO_COMIDA,]},
			{nombre: "cena", slots:[BEBIDA_CENA,ENSALADA_CENA]},
		],
		value:"3"
	},{
		label:"Plan de 5 tiempos",
		slots:[
			{nombre: "desayuno", slots:[BEBIDA_DESAYUNO,PLATILLO_DESAYUNO,GUARNICION_DESAYUNO]},
			{nombre: "colacion1", slots:[SNACK,SNACK_PREPARADO]},
			{nombre: "comida", slots:[BEBIDA_COMIDA,SOPA,PLATILLO_COMIDA,GUARNICION_COMIDA, ENSALADA_COMIDA]},
			{nombre: "colacion2", slots:[SNACK, SNACK_PREPARADO]},
			{nombre: "cena", slots:[BEBIDA_CENA,PLATILLO_CENA]},
		],
		value:"4"
	}]
	},{
	from:1600,
	to:2699,
	opciones:[{
		label:"Plan desayuno ligero",
		slots:[
			{nombre: "desayuno", slots:[BEBIDA_DESAYUNO, PLATILLO_DESAYUNO]},
			{nombre: "comida", slots:[BEBIDA_COMIDA,PLATILLO_COMIDA,GUARNICION_COMIDA,ENSALADA_COMIDA]},
			{nombre: "cena", slots:[BEBIDA_CENA,PLATILLO_CENA]},
		],
		value:"1"
	},{
		label:"Plan cena Ligero",
		slots:[
			{nombre: "desayuno", slots:[BEBIDA_DESAYUNO,PLATILLO_DESAYUNO,GUARNICION_DESAYUNO]},//hay comidas principales en desayuno?
			{nombre: "comida", slots:[BEBIDA_COMIDA,SOPA,PLATILLO_COMIDA,GUARNICION_COMIDA]},
			{nombre: "cena", slots:[BEBIDA_CENA,ENSALADA_CENA]},
		],
		value:"2"
	},{
		label:"Plan de 4 tiempos",
		slots:[
			{nombre: "desayuno", slots:[BEBIDA_DESAYUNO,PLATILLO_DESAYUNO,GUARNICION_DESAYUNO]},//hay comidas principales en desayuno?
			{nombre: "colacion1", slots:[SNACK,SNACK_PREPARADO]},
			{nombre: "comida", slots:[BEBIDA_COMIDA,SOPA,PLATILLO_COMIDA,GUARNICION_COMIDA]},
			{nombre: "cena", slots:[BEBIDA_CENA,ENSALADA_CENA]},//hay bebidas de cena?
		],
		value:"3"
	},{
		label:"Plan de 5 tiempos",
		slots:[
			{nombre: "desayuno", slots:[BEBIDA_DESAYUNO, GUARNICION_DESAYUNO,PLATILLO_DESAYUNO]},//hay comidas principales en desayuno?
			{nombre: "colacion1", slots:[SNACK,SNACK_PREPARADO]},
			{nombre: "comida", slots:[BEBIDA_COMIDA,SOPA,PLATILLO_COMIDA,GUARNICION_COMIDA,ENSALADA_COMIDA]},
			{nombre: "colacion2", slots:[SNACK, SNACK_PREPARADO]},
			{nombre: "cena", slots:[BEBIDA_CENA,ENSALADA_CENA]},//hay bebidas de cena?
		],
		value:"4"
	}]
	},{
	from:2700,
	to:2999,
	opciones:[{
		label:"Plan de 4 tiempos ligero",
		slots:[
			{nombre: "desayuno", slots:[BEBIDA_DESAYUNO, GUARNICION_DESAYUNO,PLATILLO_DESAYUNO]},
			{nombre: "colacion1", slots:[SNACK,SNACK_PREPARADO]},
			{nombre: "comida", slots:[BEBIDA_COMIDA,SOPA,GUARNICION_COMIDA,ENSALADA_COMIDA,PLATILLO_COMIDA,]},
			{nombre: "cena", slots:[BEBIDA_CENA,GUARNICION_CENA,PLATILLO_CENA]},
		],
		value:"1"
	},{
		label:"Plan de 5 tiempos",
		slots:[
			{nombre: "desayuno", slots:[BEBIDA_DESAYUNO, GUARNICION_DESAYUNO,PLATILLO_DESAYUNO]},
			{nombre: "colacion1", slots:[SNACK,SNACK_PREPARADO]},
			{nombre: "comida", slots:[BEBIDA_COMIDA,SOPA,GUARNICION_COMIDA,ENSALADA_COMIDA,PLATILLO_COMIDA,POSTRE]},
			{nombre: "colacion2", slots:[SNACK,SNACK_PREPARADO]},
			{nombre: "cena", slots:[BEBIDA_CENA,GUARNICION_CENA,PLATILLO_CENA]},
		],
		value:"2"
	},{
		label:"Plan de 6 tiempos",
		slots:[
			{nombre: "predesayuno", slots:[GUARNICION_DESAYUNO]},
			{nombre: "desayuno", slots:[BEBIDA_DESAYUNO, GUARNICION_DESAYUNO,PLATILLO_DESAYUNO]},
			{nombre: "colacion1", slots:[SNACK,SNACK_PREPARADO]},
			{nombre: "comida", slots:[BEBIDA_COMIDA,SOPA,GUARNICION_COMIDA,ENSALADA_COMIDA,PLATILLO_COMIDA,["po"]]},
			{nombre: "colacion2", slots:[SNACK, SNACK_PREPARADO]},
			{nombre: "cena", slots:[BEBIDA_CENA,GUARNICION_CENA,PLATILLO_CENA]},
		],
		value:"3"
	},{
		label:"Plan de 6 tiempos ligero",
		slots:[
			{nombre: "predesayuno", slots:[GUARNICION_DESAYUNO]},
			{nombre: "desayuno", slots:[BEBIDA_DESAYUNO, GUARNICION_DESAYUNO,PLATILLO_DESAYUNO]},
			{nombre: "colacion1", slots:[SNACK,SNACK_PREPARADO]},
			{nombre: "comida", slots:[BEBIDA_COMIDA,SOPA,GUARNICION_COMIDA,ENSALADA_COMIDA,PLATILLO_COMIDA]},
			{nombre: "colacion2", slots:[SNACK, SNACK_PREPARADO]},
			{nombre: "cena", slots:[BEBIDA_CENA,GUARNICION_CENA,PLATILLO_CENA]},
		],
		value:"4"
	}]
	},{
	from:3000,
	to:3500,
	opciones:[{
		label:"Plan de 4 tiempos ligero",
		slots:[
			{nombre: "desayuno", slots:[BEBIDA_DESAYUNO, GUARNICION_DESAYUNO,PLATILLO_DESAYUNO]},
			{nombre: "colacion1", slots:[SNACK,SNACK_PREPARADO]},
			{nombre: "comida", slots:[BEBIDA_COMIDA,["so"],GUARNICION_COMIDA,ENSALADA_COMIDA,PLATILLO_COMIDA,]},
			{nombre: "cena", slots:[BEBIDA_CENA,GUARNICION_CENA,PLATILLO_CENA]},
		],
		value:"1"
	},{
		label:"Plan de 5 tiempos",
		slots:[
			{nombre: "desayuno", slots:[BEBIDA_DESAYUNO,GUARNICION_DESAYUNO,PLATILLO_DESAYUNO]},
			{nombre: "colacion1", slots:[SNACK,SNACK_PREPARADO]},
			{nombre: "comida", slots:[BEBIDA_COMIDA,["so"],GUARNICION_COMIDA,ENSALADA_COMIDA,PLATILLO_COMIDA,POSTRE]},
			{nombre: "colacion2", slots:[SNACK, SNACK_PREPARADO]},
			{nombre: "cena", slots:[BEBIDA_CENA,GUARNICION_CENA,PLATILLO_CENA]},
		],
		value:"2"
	},{
		label:"Plan de 6 tiempos",
		slots:[
			{nombre: "predesayuno", slots:[GUARNICION_DESAYUNO]},
			{nombre: "desayuno", slots:[BEBIDA_DESAYUNO,GUARNICION_DESAYUNO,PLATILLO_DESAYUNO]},
			{nombre: "colacion1", slots:[SNACK,SNACK_PREPARADO]},
			{nombre: "comida", slots:[BEBIDA_COMIDA,["so"],GUARNICION_COMIDA,ENSALADA_COMIDA,PLATILLO_COMIDA,POSTRE]},
			{nombre: "colacion2", slots:[SNACK,SNACK_PREPARADO]},
			{nombre: "cena", slots:[BEBIDA_CENA,GUARNICION_CENA,PLATILLO_CENA]},
		],
		value:"3"
	},{
		label:"Plan de 6 tiempos ligero",
		slots:[
			{nombre: "predesayuno", slots:[GUARNICION_DESAYUNO]},
			{nombre: "desayuno", slots:[BEBIDA_DESAYUNO,GUARNICION_DESAYUNO,PLATILLO_DESAYUNO]},
			{nombre: "colacion1", slots:[SNACK,SNACK_PREPARADO]},
			{nombre: "comida", slots:[BEBIDA_COMIDA,SOPA,GUARNICION_COMIDA,ENSALADA_COMIDA,PLATILLO_COMIDA]},
			{nombre: "colacion2", slots:[SNACK,SNACK_PREPARADO]},
			{nombre: "cena", slots:[BEBIDA_CENA,GUARNICION_CENA,PLATILLO_CENA]},
		],
		value:"4"
	}]
	}];
