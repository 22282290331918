import React, {useState} from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Button, TextField } from "@mui/material";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import desayunoImg from "assets/img/desayuno.png";
import colacion1Img from "assets/img/colacion1.png";
import comidaImg from "assets/img/comida.png";
import colacion2Img from "assets/img/colacion2.png";
import cenaImg from "assets/img/cena.png";
import DynamicImage from "components/DynamicImage";

const TiempoComida = ({tiempoComida, index, onChange}) => {
	const {t} = useTranslation("common");

	
	// useEffect(() => {
	// 	if(label || img){
	// 		onChange({label, img}, index)
	// 	}
	// 	}, [img, label])
			
			
			
	const [label, setLabel] = useState("");
	const [editImg, setEditImg] = useState(false);
	const [editlabel, setEditLabel] = useState(false);
	
	return (
		<div style={{display: "flex", flexDirection:"column", alignItems:"center",width: "100%"}}>			
			<div style={{
				display:"flex",
				// background:tiempoComida.nombre_ti	empo.color+"38", 
				flexDirection: "column",	
				alignItems: "center", 
				justifyContent: "center",
				width: "100%"
			}}>
				{editImg || !tiempoComida.nombre_tiempo.img ? 
					<>
						<Select
							value={tiempoComida.nombre_tiempo.img}
							renderValue={(value)=>(
								<DynamicImage
									src={`${value}.png`}
									style={{height:"30px"}}
									className="img-category"
								/>
							)}
							onChange={(e)=>{
								onChange("img", e.target.value, index);
								setEditImg(false);
							}}
						>
							<MenuItem value={"desayuno"}>
				
								<img
									src={desayunoImg}
									style={{height:"30px"}}
									className="img-category"
								/>
							</MenuItem>
							<MenuItem value={"colacion1"}>
								<img
									src={colacion1Img}
									style={{height:"30px"}}
									className="img-category"
								/>
				
							</MenuItem>
							<MenuItem value={"comida"}>
								<img
									src={comidaImg}
									style={{height:"30px"}}
									className="img-category"
								/>
				
							</MenuItem>
							<MenuItem value={"colacion2"}>
								<img
									src={colacion2Img}
									style={{height:"30px"}}
									className="img-category"
								/>
							</MenuItem>
							<MenuItem value={"cena"}>
								<img
									src={cenaImg}
									style={{height:"30px"}}
									className="img-category"
								/>
							</MenuItem>
						</Select>
						<p style={{color: "#00000070"}}>{t("tiempo_comida_elige_icono")}</p>
					</>
					:
					<div>
						<DynamicImage
							src={`${tiempoComida.nombre_tiempo.img}.png`}
							alt={tiempoComida.nombre_tiempo.name}
							className="img-category"
						/>
					</div>
				}
				{editlabel || !tiempoComida.nombre_tiempo.label ? 
					<div style={{textAlign: "center", marginTop: "5px"}}>
						<TextField 
							placeholder={t("tiempo_comida_asignar_nombre")}
							value={label} 
							onChange={(e) => setLabel(e.target.value)}
							className="text-field"
						/>
						<div style={{display: "flex", justifyContent: "center"}}>
							{tiempoComida.nombre_tiempo.label &&
								<Button
									style={{marginTop: "5px"}}
									onClick={() => {
										setEditLabel(false);
										setLabel("");
									}}
								>{t("cancelar")}</Button>
							}
							<Button 
								variant="contained" 
								color="secondary" 
								style={{marginTop: "5px"}}
								onClick={() => {
									onChange("label", label, index);
									setLabel("");
									setEditLabel(false);
								}}
							>{t("tiempo_comida_asignar")}</Button>
						</div>
					</div>
					:
					<p>{tiempoComida.nombre_tiempo.label}</p>
				}
			</div>
		</div>
	);
};

export default TiempoComida;

TiempoComida.propTypes = {
	tiempoComida: PropTypes.object, 
	index: PropTypes.number,
	del: PropTypes.func,
	onChange: PropTypes.func,
};