import React, { useState } from "react";

import { AnadirMedida } from "../ListaAlimentos/AddAlimentoMenuSelect";
import { Button } from "@mui/material";
import { post } from "api";

function AddAlimentoFromList({alimento, handleModal}) {
	//TODO, resvisar cual tomar
	const defaultMedida = alimento.medidas?.[1] ||  alimento.medidas?.[0];
	const [medida, setMedida] = useState({
		id_alimento: String(alimento.id),
		cant: undefined,
		gram: undefined,
		unidad: "",
		kcal: undefined,
		prot: undefined,
		lip: undefined,
		hidr: undefined,
	});

	const saveMedida = () => {
		//TODO NO GUARDA EQUIVALENCIAS
		post(`${process.env.REACT_APP_API_URL}menu-select/medida`, medida).then(({data})=>{
			const medidas = [...alimento.medidas, data];
			handleModal({...alimento, medidas, defaultMedida: data});
		}).catch(err=>null
			// console.log(err)
		);
	};

	const changeMedidaOtro = (value, name) => {
		const original = name === "gram" ? value : !defaultMedida.gram ? defaultMedida.cant : defaultMedida.gram ?? 0;
		
		let total = ["kcal", "prot", "lip", "hidr"].reduce((acc, key) => {
			acc[key] = +(((original * defaultMedida[key]) / defaultMedida.gram).toFixed(2)) || 0;
			return acc;
		}, { kcal: 0, prot: 0, lip: 0, hidr: 0 });

		console.log({original, value, name, defaultMedida})
		total.eq = Object.keys(defaultMedida.eq||{}).reduce((acc, key) => {
			if (typeof defaultMedida.eq[key] === "object") {
				// Handle nested objects
				acc[key] = {};
				Object.keys(defaultMedida.eq[key]).forEach(subKey => {
					acc[key][subKey] = +(((original * (defaultMedida.eq[key]?.[subKey] ?? defaultMedida[subKey])) / defaultMedida.gram).toFixed(2)) || 0;
				});
			} else {
				// Directly handle number values
				acc[key] = +(((original * (defaultMedida.eq[key] ?? 1)) / defaultMedida.gram).toFixed(2)) || 0;
			}
			return acc;
		}, {});

		// const total = ["kcal", "prot", "lip", "hidr"].reduce((acc, key)=>{
		// 	acc[key] = +(((original * defaultMedida[key]) / defaultMedida.gram).toFixed(2)) || 0;
		// 	return acc;
		// }, {kcal: 0, prot: 0, lip: 0, hidr: 0});
		const newMedida = {...medida, [name]: value, ...total};
		console.log({newMedida})
		setMedida(newMedida);
	};
	
	return (
		<div>
			<AnadirMedida medida={medida || {}} onChange={changeMedidaOtro} hideDelete />
			<Button color="secondary" variant="contained" onClick={saveMedida}>Añadir medida</Button>
		</div>
	);
}

export default AddAlimentoFromList;