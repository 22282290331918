import React,{useRef} from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import BoxForm from "components/FormComponents/BoxForm/BoxForm";
import AccesoApp from "./AccesoApp";
import PrintButton from "components/Buttons/PrintButton/PrintButton";
const elements = [
	{
		type: "file",
		name: "foto",
		label: "Foto"
	},
	{
		type: "group",
		name: "datos_personales",
		label: "Datos personales",
		fields: [{
			type: "text",
			name: "folio",
			label: "Folio del paciente"
		},{
			type: "text",
			name: "curp",
			label: "CURP"
		},{
			type: "ref",
			name: "id_nutriologo",
			label: "Nutriológo",
			selector:(state)=>state.NUTRITIONIST.data
		},{
			type: "text",
			name: "nombre",
			label: "Nombre Completo"
		},{
			type: "calendar",
			name: "fecha_nacimiento",
			label: "Fecha de Nacimiento",
		},{
			type: "text",
			name: "fecha_nacimiento",
			id:'edad',
			label: "Edad",
			readOnly: true,
			formula: function(age){return moment().diff(moment(age), "years");}
		}]
	},
	{
		type: "group",
		name: "datos_personales2",
		label: "Datos personales",
		fields: [{
			type: "select",
			name: "genero",
			label: "Sexo",
			options:[
				{value:undefined,label:"Sin selección"},
				{value:"masculino",label:"Masculino"},
				{value:"femenino",label:"Femenino"},
			]
		},{
			type: "text",
			name: "nacionalidad",
			label: "Nacionalidad"
		},{
			type: "select",
			name: "pais_nacimiento",
			label: "País de nacimiento",
			options: [
				{value:"mexico",label:"México"},
				{value:"otro",label:"Otro"},
			]
		},{
			type: "select",
			name: "grupo_etnico",
			label: "Grupo étnico",
			options:[
				{value:undefined,label:"Sin selección"},
				{value:"asiatico",label:"Asiático"},
				{value:"caucasico",label:"Caucásico"},
				{value:"latino",label:"Latino"},
				{value:"otro",label:"Otro"},
			]
		},{
			type: "select",
			name: "religion",
			label: "Religión",
			options:[
				{value:undefined,label:"Sin selección"},
				{value:"budista",label:"Budista"},
				{value:"catolica",label:"Católica"},
				{value:"cristiana",label:"Cristiana"},
				{value:"judia",label:"Judía"},
				{value:"musulmana",label:"Musulmana"},
				{value:"otra",label:"Otra"},
			]
		}]
	},
	{
		type: "group",
		name: "datos_contacto",
		label: "Datos de contacto",
		fields: [{
			type: "text",
			name: "domicilio",
			label: "Domicilio particular",
			moreRows: true
		},{
			type: "text",
			name: "estado",
			label: "Estado"
		},{
			type: "text",
			name: "municipio",
			label: "Municipio"
		},{
			type: "text",
			name: "localidad",
			label: "Localidad",
		}]
	},
	{
		type: "group",
		name: "datos_contacto2",
		label: "Datos de contacto",
		fields: [{
			type: "text",
			name: "correo",
			label: "Correo electrónico",
			labelAdornment: "@",
		},{
			type: "text",
			name: "telefono_casa",
			label: "Teléfono casa",
			labelAdornment: "#",
		},{
			type: "text",
			name: "telefono_oficina",
			label: "Teléfono oficina",
			labelAdornment: "#",
		},{
			type: "text",
			name: "telefono",
			label: "Teléfono celular",
			labelAdornment: "#",
		}]
	},
	{
		type: "group",
		name: "datos_acompanante",
		label: "Datos del acompañante",
		fields: [{
			type: "text",
			name: "nombre_acompanante",
			label: "Nombre completo",
		},{
			type: "text",
			name: "direccion_acompanante",
			label: "Dirección completa"
		},{
			type: "text",
			name: "telefono_acompanante",
			label: "Teléfono",
			labelAdornment: "#",
		},{
			type: "text",
			name: "parentesco_acompanante",
			label: "Parentesco con el paciente"
		}]
	}
];


const DatosPersonalesConsulta = props => {

	const selectorData = useSelector(state => state["PATIENTS"]?.data.find(data => data._id === props.idPaciente));

	const ref = useRef(null);

	return (
		<>
			<div style={{marginBottom:"10px", display:"flex", justifyContent: "space-between", alignItems:"center", float:"right"}}>
			
				<PrintButton printRef={ref} />
			</div>
				<p>Historia Clínica basada en la NORMA Oficial Mexicana NOM-024-SSA3-2012</p>
			
			<div className="printable" ref={ref}>
				<BoxForm data={props.data} elements={elements} onChange={props.onChange} onFile={props.onFile} indexElements={true} ></BoxForm>
				
				<AccesoApp data={selectorData} idPaciente={props.idPaciente}  />
			</div>
		</>
	);
};

export default DatosPersonalesConsulta;