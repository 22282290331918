import React from "react";
import Chip from "@mui/material/Chip";
import { withStyles } from "@mui/styles";

const StyledChip = withStyles({
	root: {
		background: "#87F0F8",
		borderRadius: "10px"
	},
	label: {
		color: "#000",
		fontStyle: "italic",
		fontSize: "10pt"
	}
})(Chip);

function ChipComponent({label, style}) {
	return (
		<div style={{textAlign: "right", ...style}}>
			<StyledChip 
				color="primary"
				label={label}
			/>
		</div>
	);
}

export default ChipComponent;
