import React from "react";
import PropTypes from "prop-types";
import { FormGroup, Button, Tooltip, FormControlLabel, Checkbox, CircularProgress, IconButton } from "@mui/material";
import { useImmer } from "use-immer";
import { current } from "immer";
import { useTranslation } from "react-i18next";
import i18n from "i18n";
import styled from "styled-components";
import {Edit, FileCopy} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, AlertTitle } from "@mui/lab";
import copyIcon from "assets/img/copy.png";
import editIcon from "assets/img/edit.png";
import chartIcon from "assets/img/charts.png";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import ClearIcon from '@mui/icons-material/Clear';
import intercambiosIcon from "assets/img/intercambios.png";
import aiGenerateIcon from "assets/img/aigenerate.png";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import ButtonAddTiempo from "../../ButtonAddTiempo";
import TiempoComidaVertical from "./TiempoComidaVertical";
import useSkipFirstRender from "Hooks/useSkipFirstRender";
import Box from "components/Box/Box";
import SelectCopy from "./SelectCopy";
import SelectCopyDay from "./SelectCopyDay";
import Macros from "./Chart/Macros";
import EditCalorias from "./EditCalorias";
import {Puntos} from "./AIDiet2";
import AddComida from "./AddComida";
import ChipComponent from "components/FormComponents/Chip/Chip";
import AssignMacros from "./AssignMacros";
import DetalleDia from "./DetalleDia";
import Modal from "components/Modal/Modal";
import AgregarAlimento from "./AgregarAlimento";
import {useDialog} from "context/DialogContext";
import {useQuery} from "react-query";
import {get} from "api";
import ModalButton from "components/Buttons/ModalButton/ModalButton"
import Ref from "components/FormComponents/Ref/Ref"
import RefListaCompras from "components/FormComponents/SpecificComponents/RefListaCompras"
import "./DietaMenus.scss";
import IconEq from "../IconEq";
import {frutasIcon, verdurasIcon, leguminosasIcon, azucaresIcon, proteinasIcon, lacteosIcon, cerealesIcon, grasasIcon, } from "assets/img/grupos";
import swal from "functions/swal";
import Text from "components/FormComponents/Text/Text";
import Radio from "components/FormComponents/Radio/Radio";
import Boolean from "components/FormComponents/Boolean/Boolean";
import File from "components/FormComponents/File/File";

import useAlimentosAI from "Hooks/useAlimentosAI";
import {EQUIVALENT_SMAE_LIST, EQUIVALENT_LIST} from "store/constants"
import _get from "lodash.get";
import _debounce from "lodash.debounce";
import _isEqual from "lodash.isequal"
import {deepSum, deepMult} from "functions/general";
import ImageUploader from "pages/Dietas/ImgRecomendaciones";


var shortid = require("shortid");



const macros = [
	{ color:"#E77067", label: i18n.t("common:proteinas"), name:"prot", unit: "gr" },
	{ color:"#F7D542", label: i18n.t("common:lipidos"), name:"lip", unit: "gr" },
	{ color:"#7BAAFB", label: i18n.t("common:hidratos_carbono"), name:"hidr", unit: "gr" },
	{ color:"#3B4C4B", label: i18n.t("common:dieta_menu_select_energia"), name:"kcal", unit: "kcal" }
];

	
const dias = [
	{ name: "monday", label: i18n.t("common:modal_dias_laborales_lunes") },
	{ name: "tuesday", label: i18n.t("common:modal_dias_laborales_martes") },
	{ name: "wednesday", label: i18n.t("common:modal_dias_laborales_miercoles") },
	{ name: "thursday", label: i18n.t("common:modal_dias_laborales_jueves") },
	{ name: "friday", label: i18n.t("common:modal_dias_laborales_viernes") },
	{ name: "saturday", label: i18n.t("common:modal_dias_laborales_sabado") },
	{ name: "sunday", label: i18n.t("common:modal_dias_laborales_domingo") },
];
// const dias = [
// 	{ name: "sunday", label: "Domingo"},
// 	{ name: "monday", label: "Lunes"},
// 	{ name: "tuesday", label: "Martes"},
// 	{ name: "wednesday", label: "Miércoles"},
// 	{ name: "thursday", label: "Jueves"},
// 	{ name: "friday", label: "Viernes"},
// 	{ name: "saturday", label: "Sábado"},
// ];

const Dieta = (props) => {
	const [openDialog, closeDialog] = useDialog();

	const {t} = useTranslation("common");
	var tiempos_comida = [
		{
			nombre_tiempo: {
				name: "desayuno",
				label: t("carpeta_dietas_desayuno"),
				img: "desayuno",
				color: "#EDC940",
				hora_comer: "2021-10-01T12:30:00.472Z",
			},
			menu: [[],[],[],[],[],[],[]]
		},
		{
			nombre_tiempo: {
				name: "colacion1",
				label: t("carpeta_dietas_colacion"),
				img: "colacion1",
				color: "#54BDE9",
				hora_comer: "2021-10-01T16:30:00.472Z"
			},
			menu: [[],[],[],[],[],[],[]]
		},
		{
			nombre_tiempo: {
				name: "comida",
				label: t("carpeta_dietas_comida"),
				img: "comida",
				color: "#72BEB2",
				hora_comer: "2021-10-01T19:30:00.472Z"
			},
			menu: [[],[],[],[],[],[],[]]
		},
		{
			nombre_tiempo: {
				name: "colacion2",
				label: t("carpeta_dietas_colacion"),
				img: "colacion2",
				color: "#4868AC",
				hora_comer: "2021-10-01T23:00:00.472Z"
			},
			menu: [[],[],[],[],[],[],[]]
		},
		{
			nombre_tiempo: {
				name: "cena",
				label: t("carpeta_dietas_cena"),
				img: "cena",
				color: "#9563A3",
				hora_comer: "2021-10-02T02:00:00.472Z"
			},
			menu: [[],[],[],[],[],[],[]]
		}
	];
	

	
	const [modalChangeDistribucion, setModalChangeDistribucion] = React.useState(false);
	const [dieta, _setDieta] = useImmer(
		props.value?.tiempos_comida ? props.value : {
			nombre: props.value.nombre,
			recomendaciones: props.value.recomendaciones,
			img_recomendaciones: props.value.img_recomendaciones,
			vis_pac:{
				ene_dia: false,
				ene_alim: false,
				mostrar_puntos_alim:props.value.subtipo==="eq"?true:false,
				mostrar_puntos_dia: props.value.subtipo==="eq"?true:false,
			},
			conf_dias: props.value.conf_dias,
			tiempos_comida,
			subtipo:props.value.subtipo,
			mostrar_equivalentes: props.value.subtipo==="eq" ? "smae":"no",
			lista_equivalencias:"-1",
			lista_equivalencias_smae:"-1",
		}
	);	
	React.useEffect(()=>{
		if(props.value?.tiempos_comida){
			props.onChange(props.value);
		}
	},[]);

	const setDieta = (f, k) => {
		// preventOnChange.current = false;
		_setDieta((draft)=>{
			f(draft);
			if(k){
				props.onChange(_get(current(draft), k), k);
			}else{
				props.onChange(current(draft));
				
			}
		});
	}

	// React.useEffect(()=>{
	// 	if(props.value?.tiempos_comida){
	// 		// console.log("changing dieta to:", props.value.tiempos_comida)
	// 		// _setDieta(()=>props.value)
	// 	}
	// },[props.value]);


	const isEqDieta= dieta.subtipo ==="eq";
	
	const isAi = dieta.ai;
	
	const {getEquivalencias, getAlimentosFiltrados} = useAlimentosAI();
	
	const recalcularManualmente = (idx_dia, idx_tiempo, idx_alimento) =>{
		// const slots = dieta.distribucion_menu[idx_tiempo].slots[idx_alimento];
		const alimentoReemplazar = dieta.tiempos_comida[idx_tiempo].menu[idx_dia][idx_alimento];
		// const original = sorted.pop();
		openDialog({
			onClose:(alimento)=>{
				if(alimento){
					setDieta(draft => {
						draft.tiempos_comida[idx_tiempo].menu[idx_dia][idx_alimento] = alimento;
					});
				}
			},
			Content: Intercambios,
			original:alimentoReemplazar,
			alimentos: getEquivalencias({idx_dia, idx_tiempo, idx_alimento, dieta, isEq: isEqDieta}),
			isEqDieta,
			width:"md"
		});

	}
	const recalcularTiempo = (idxDia, idxTiempo) => {
		
		
	}
	const recalcularDia = (idxDia, objetivo) => {
		
	}

	


	const addTiempoComida = (idx_tiempo) => {
		setDieta(draft => {
			draft.tiempos_comida.splice(idx_tiempo, 0, {
				nombre_tiempo: {
					name: "extra",
					label: "",
					img: "",
					color: "#A5DD72",
					hora_comer: ""
				},
				menu: [[],[],[],[],[],[],[]]
			});
		});
	};

	const deleteTiempo = (idx_tiempo) => {
		setDieta(draft => {
			draft.tiempos_comida.splice(idx_tiempo,1);
		});
	};

	const onChangeTiempoComida = (name, value, idx) => {
		setDieta(draft => {
			draft.tiempos_comida[idx].nombre_tiempo[name] = value;
		});
	};

	const onChangeAlimento = (alimento)=>{
		openDialog({
			onClose:(to)=>{
				if(to) agregarAlimentos(to, [alimento], true);
			},
			disableBackdropClick:true,
			Content: SelectCopy,
			dieta: dieta.tiempos_comida,
			alimento: alimento,
			width:"md"
		});
	};

	const agregarAlimentos = (to, alimentos, push) => {
		if(alimentos){
			setDieta(diet=>{
				to.forEach(([idxDia, idxTiempo])=>{
					if(!push){
						diet.tiempos_comida[idxTiempo].menu[idxDia] = [];
					}
					alimentos.forEach((alimento,idx)=>{
						const alim = {...alimento, shortid: shortid.generate()};
						if(push){
							diet.tiempos_comida[idxTiempo].menu[idxDia].push(alim);
							return; 
						}else{
							diet.tiempos_comida[idxTiempo].menu[idxDia][idx] = alim;
						}
					});
				});
			});
		}
	};

	const copiarDia = (from, to) => {
		let dietaFrom = dieta.tiempos_comida.map(val=>val.menu[from]); //From
		dietaFrom = dietaFrom.map(val=>val.map(val2=>({...val2, shortid: shortid.generate()})));
		setDieta(draft=>{
			draft.tiempos_comida.forEach((day, idx)=>{
				Object.keys(to).forEach((toDay)=>{
					day.menu[toDay] = dietaFrom[idx];
				});
			});
		});
	};

	const onChangeData = (value, key) => {
		setDieta(draft => {
			draft[key] = value;
		}, key);
	};
	return (
		<div className="cont-dieta-menu-select">
			<div style={{marginBottom:20}} className="avoid-print">
				<div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginBottom:20}}>
					<div style={{width:400}}>
						<div style={{fontWeight:"bold", marginBottom:5}}>Nombre</div>
						<Text value={dieta.nombre} name="nombre" onChange={onChangeData} />
					</div>
					<div style={{display:"flex", gap:10}}>
						{/* <HeaderPrint data={dieta}/> */}
						<ConfiguracionApp isAi={isAi} dieta={dieta} setDieta={setDieta} setModalChangeDistribucion={setModalChangeDistribucion} onChangeData={onChangeData} />
					</div>
					
				</div>
				

				<div style={{fontWeight:"bold", marginBottom:5}}>Recomendaciones</div>
				<Text multiline value={dieta.recomendaciones} name="recomendaciones" onChange={onChangeData} />
				<ImageUploader CustomButtonComponent={<Button>Seleccionar</Button>} value={props.value?.img_recomendaciones} name="img_recomendaciones" onFile={props.onFile} removeFile={props.removeFile} onRemoveExistingFile={props.onRemoveExistingFile} __deleted_files={props.value?.__deleted_files|| props.__deleted_files} onChange={props.onChange} />


			</div>
			
			{/* <AssignMacros onChange={(val, key)=>{
				setDieta(draft=>{
					draft[key] = val;
				});
			}} data={dieta} /> */}
			{/* <ModalForm print elements={elements} onChange={onChangeData} data={dieta} /> */}
		
			<div className="container-dieta-menus printDiv" style={{overflowX: "scroll"}}>
				{/* <div className="avoid-print" style={{width: "50%", margin: "auto", marginBottom: "10px"}}> 
					<ChipComponent label={t("dieta_menu_select_dieta_busca_anade")}/>
					<AddComida onSave={onChangeAlimento} />			
				</div> */}
				<FormGroup>
					{/* <Box label={t("dieta_menu_select_dieta_label")} fullWidth> */}
						<div className="cont-menu">
							<div className="content-table" style={{paddingLeft:isEqDieta?"10%": "13%", backgroundColor: "white"}}>
								<>
									<div className="row-table">
										<div className="container-add-tiempo-comida avoid-print">
											{!isAi && <ButtonAddTiempo addTiempoComida={addTiempoComida} index={0} text="+"/>}
										</div>
										{dieta.tiempos_comida?.map((value, i) => (
											<div key={i} style={{display:"flex",justifyContent:"center", width:`${100/Object.values(dieta.tiempos_comida).length}%`}}>
												<TiempoComidaVertical isAi={isAi} tiempoComida={value} index={i} onChange={onChangeTiempoComida} del={deleteTiempo}/>
											
												<div className="container-add-tiempo-comida avoid-print">
													{!isAi && <ButtonAddTiempo addTiempoComida={addTiempoComida} index={i+1} text="+" />}
												</div>
											</div>
										))}
									</div>
								</>
							</div>
							<div className="container-table">
								<div>
									{dias.map((dia, idx_dia) => <Dia isEqDieta={isEqDieta} isAi={isAi} recalcularTiempo={recalcularTiempo} recalcularDia={recalcularDia} recalcularManualmente={recalcularManualmente} confDias={dieta.conf_dias} editConfDias={onChangeData} setDieta={setDieta} agregarAlimentos={agregarAlimentos} copiarDia={copiarDia} dia={dia} idx_dia={idx_dia} dieta={dieta} key={idx_dia} optionsSelect={props.optionsSelect}/> )}
								</div>
							</div>
						</div>
					{/* </Box> */}
				</FormGroup>
			</div>
			<Modal onClickClose={()=>setModalChangeDistribucion(false)} open={modalChangeDistribucion} widthPaper="60%">
				<Alert severity="warning">
					<AlertTitle>{t("dieta_menu_select_dieta_advertencia")}</AlertTitle>
					{t("dieta_menu_select_dieta_advertencia_al_modificar")}
				</Alert>
				<AssignMacros hideData onSave={(data)=>{
					data?.conf_dias && setDieta(draft=>{
						draft.conf_dias = data.conf_dias;
					});
					setModalChangeDistribucion(false);
				}} />
			</Modal>
		</div>);
};

export default Dieta;

// Dieta.propTypes = {
// 	value: PropTypes.array,
// 	onChange: PropTypes.func
// };


const grupos = [{
	name:"c",
	label:"Cereales",
	img:cerealesIcon,
	subgrupos:[{
		name:"c.sg",
		label:"Sin grasa",
		img:cerealesIcon,
	},{
		name:"c.cg",
		label:"Con grasa",
		img:cerealesIcon
	}]
	},{
	name:"p",
	label:"Proteina",
	img:proteinasIcon,
	subgrupos:[{
		name:"p.mb",
		label:"Muy bajo",
		img:proteinasIcon,
	},{
		name:"p.mo",
		label:"Moderado",
		img:proteinasIcon
	},{
		name:"p.b",
		label:"Bajo",
		img:proteinasIcon
	},{
		name:"p.a",
		label:"Alto",
		img:proteinasIcon
	}]
	},{
	name:"g",
	label:"Grasa",
	img:grasasIcon,
	subgrupos:[{
		name:"g.sp",
		label:"Sin proteina",
		img:grasasIcon,
	},{
		name:"g.cp",
		label:"Con proteina",
		img:grasasIcon
	}]
	},{
		name:"f",
		label:"Fruta",
		img:frutasIcon
	},{
		name:"v",
		label:"Verdura",
		img:verdurasIcon
	},{
		name:"l",
		label:"Lacteo",
		img:lacteosIcon,
		subgrupos:[{
			name:"l.d",
			label:"Descremada",
			img:lacteosIcon,
		},{
			name:"l.sd",
			label:"Semi descremada",
			img:lacteosIcon
		},{
			name:"l.e",
			label:"Entera",
			img:lacteosIcon
		},{
			name:"l.ca",
			label:"Con azúcar",
			img:lacteosIcon
		}]
	},{
		name:"azu",
		label:"Azucar",
		img:azucaresIcon,
		subgrupos:[{
			name:"azu.sg",
			label:"Sin grasa",
			img:azucaresIcon,
		},{
			name:"azu.cg",
			label:"Con grasa",
			img:azucaresIcon
		}]
	},{
		name:"leg",
		label:"Leguminosa",
		img:leguminosasIcon
	}]
function sumProperties(obj1={}, obj2={}) {
  let result = {};

  // Function to determine if a value is an object
  const isObject = (val) => val && typeof val === 'object';

  // Function to sum values
  const sumValues = (key) => {
    if (isObject(obj1[key]) || isObject(obj2[key])) {
      // If either value is an object, recursively sum their properties
      return sumProperties(isObject(obj1[key]) ? obj1[key] : {}, isObject(obj2[key]) ? obj2[key] : {});
    } else {
      // Sum numeric values, handling undefined or null
      return (obj1[key] || 0) + ((obj2[key] || 0));
    }
  };

  // Get all unique keys from both objects
  const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

  // Sum the properties
  allKeys.forEach(key => {
    result[key] = sumValues(key);
  });

  return result;
}

function Dia({isAi, isEqDieta, dieta, idx_dia, dia, agregarAlimentos, copiarDia, confDias, editConfDias, optionsSelect, recalcularTiempo, recalcularDia, recalcularManualmente, setDieta}){
	const {tiempos_comida} = dieta;
	const sumObject = (obj) => {
		if(typeof obj==="object")return Object.values(obj).reduce((acc,v)=>acc+v,0);
		return obj
	}
	const puntosConsumidos = React.useMemo(()=>{
		return Object.values(tiempos_comida).reduce((acc, tiempoComida) => {
			tiempoComida.menu[idx_dia].forEach((alimento)=>{
				acc = sumProperties(acc, alimento.eq);				

			});
			return acc;
		},{});
	},[tiempos_comida]);
	const puntosConsumidosGlobal = Object.fromEntries(Object.entries(puntosConsumidos).map(([k,v])=>[k,sumObject(v)]))
	const eq = grupos.map(g=>{
		if(g.subgrupos) {
			g.value = g.subgrupos.reduce((acc, v)=>{
				return acc+_get(confDias[idx_dia], v.name, 0)
			},0)
			g.subgrupos = g.subgrupos.map(g=>{
				return {...g, value:_get(confDias[idx_dia], g.name, 0)}
			})

			return g;
		}
		g.value = _get(confDias[idx_dia], g.name)
		return g;

	}).filter(g=> g.value || _get(puntosConsumidos, g.name))//,[confDias,idx_dia,])
	
	const [openDialog, closeDialog] = useDialog();
	const consumidos = React.useMemo(()=>{
		return Object.values(tiempos_comida).reduce((acc, tiempoComida) => {
			tiempoComida.menu[idx_dia].forEach((alimento)=>{
				// acc.kcal += isNaN(alimento.kcal) ? 0 : alimento.kcal;
				const prot = isNaN(alimento.prot) ? 0 : alimento.prot;
				acc.prot += prot;
				const lip = isNaN(alimento.lip) ? 0 : alimento.lip;
				acc.lip += lip;
				const hidr = isNaN(alimento.hidr) ? 0 : alimento.hidr;
				acc.hidr += hidr;
				// if(idx_dia===0){
				// 	console.log({hidr, alimento, acc:acc.hidr})
				// }

				

				acc.kcal += prot*4 + lip*9 + hidr*4;

			});
			return acc;
		},{kcal:0, prot:0, lip:0, hidr:0});
	},[tiempos_comida]);



	function onEdit(){
		console.log("onEdit")	
		if(isEqDieta){
			openDialog({
				onClose:(data)=>{
					if(data){
						let conf_dias = [...confDias];
						conf_dias[idx_dia] = data;
						editConfDias(conf_dias, "conf_dias");
						recalcularDia(idx_dia, data)
					}
				},
				Content: Puntos,
				width:"md",
				idx_dia,
				value: confDias[idx_dia],
				showWarning:true
			});
		}else{
			openDialog({
				onClose:(data)=>{
					if(data?.conf){
						let conf_dias = [...confDias];
						conf_dias[idx_dia] = data.conf;
						editConfDias(conf_dias, "conf_dias");
						recalcularDia(idx_dia, data.conf)
					}
				},
				Content: EditCalorias,
				width:"md",
				idx_dia,
				conf: confDias[idx_dia],
				isAi:isAi
			});
		}
		
	}

	function copiar(){
		openDialog({
			onClose:(to)=>{
				if(to){
					copiarDia(idx_dia, to);

				} 
			},
			Content: SelectCopyDay,
			width:"sm"
		});
	}

	function detallesTiempo(){
		openDialog({
			onClose:(to)=>{
				if(to){
					copiarDia(idx_dia, to);

				} 
			},
			Content: DetalleDia,
			avgSum:true,
			title: `Detalle nutrimental ${dias[idx_dia].label}`,
			alimentos: tiempos_comida.map(val=>val.menu[idx_dia]),
			nombres_tiempo: tiempos_comida.map(val=>val.nombre_tiempo.label),
			confDias: confDias[idx_dia],
			dia: idx_dia,
		});
	}
	return <DiaStyled className="break-end-page" style={{display:"flex", marginBottom:"20px", minHeight:"150px"}}>
		<div style={{background: "#EEF2FD"}}>
			<div>
				<p style={{background: "#404B4B", color:"white", fontWeight: "bold", fontSize: "18px",textAlign:"center", height:50, borderRadius:10, display:"flex", alignItems:"center",justifyContent:"center", minWidth:100}}>{dia.label}</p>
				<div className="showOnHover showOnHoverDia">
					<EditButton onClick={onEdit} />
					<FileCopyButton onClick={copiar} />
				</div>
			</div>
			<div key={idx_dia} style={{ background: "#EEF2FD", display:"flex", gap:10, flexDirection:"column", padding: isEqDieta ? 20:10}}>
				{/* <div className="avoid-print"> */}
				{isEqDieta ? <>
					{eq.map(g=><PuntosTooltip key={g} grupo={g} consumidos={puntosConsumidos} consumidosGlobal={puntosConsumidosGlobal} />)}
				</> : <>
					{macros.map(m=><div key={m.name} className={dieta?.imprimir_macros===false ? "avoid-print": ""}>
						<div style={{fontWeight:"bolder"}}>{m.label}</div>
						<div>{Number(consumidos[m.name]).toFixed(0)}/{Number(confDias[idx_dia][m.name]).toFixed(0)}</div>
					</div>)}
				</>}
				
				
				{/* {macros.map(m=><Macros label={m.label} key={m.name} unit={m.unit} value={consumidos[m.name]} max={confDias[idx_dia][m.name]} color={m.color} size="sm" />)} */}
				<div className="avoid-print">
					<EditButton onClick={onEdit} />
					<DetallesButton onClick={detallesTiempo} />
					{/* <Button style={{marginTop: "5px"}} size="small" color="secondary" variant="outlined" onClick={detallesTiempo}>Detalles</Button> */}
				</div>
				{/* </div> */}
			</div>
		</div>
		<div className="panelTiempo" style={{ width: "90%", display: "flex", margin: 0, justifyContent: "space-around"}}>
			{/* Mostrar los tiempos de comida */}
			{Object.values(tiempos_comida).map((tiempoComida, idx_tiempo) => <TiempoComida setDieta={setDieta} isEqDieta={isEqDieta} isAi={isAi} dieta={tiempos_comida} agregarAlimentos={agregarAlimentos} tiempoComida={tiempoComida.menu[idx_dia]} idx_tiempo={idx_tiempo} idx_dia={idx_dia} key={idx_tiempo} optionsSelect={optionsSelect} recalcularTiempo={recalcularTiempo} recalcularManualmente={recalcularManualmente} onChange={editConfDias} />)}
		</div>
	</DiaStyled>;
}
function round(n){
	return Math.round((n||0)*10)/10
}
function PuntosTooltip({ grupo, consumidos, consumidosGlobal }) {
	const renderGroup = (group, consumed) => (
		<div style={{ display: "flex", alignItems: "center", gap: 5 }}>
			<img style={{ width: 20, height: 20, objectFit: "contain" }} src={group.img} />
			<div>{round(consumed)}</div>
			<div>/</div>
			<div>{round(group.value)}</div>
		</div>
	);

	const renderSubgroup = (subg) => (
		<div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 5 }}>
			<img style={{ width: 20, height: 20, objectFit: "contain" }} src={subg.img} />
			<div>{subg.label}</div>
			<div style={{ display: "flex" }}>
				<div>{round(_get(consumidos, subg.name, 0))}</div>
				<div>/</div>
				<div>{round(subg.value)}</div>
			</div>
		</div>
	);

	return (
		<>
			{grupo.subgrupos ? (
				<Tooltip title={
					<div style={{ display: "flex", gap: 10, alignItems: "center", justifyContent: "center" }}>
						{grupo.subgrupos.filter(subg => _get(consumidos, subg.name, 0) || subg.value).map(renderSubgroup)}
					</div>
				}>
					{renderGroup(grupo, _get(consumidosGlobal, grupo.name))}
				</Tooltip>
		) : (
				renderGroup(grupo, _get(consumidosGlobal, grupo.name))
			)}
		</>
	);
}

const DiaStyled = styled.div`
	// box-shadow: 1px 4px 3px 0px rgba(204,202,202,0.75);
	// -webkit-box-shadow: 1px 4px 3px 0px rgba(204,202,202,0.75);
	// -moz-box-shadow: 1px 4px 3px 0px rgba(204,202,202,0.75);
	.panelTiempo{
		// padding-bottom:10px;
	}
	:hover  .showOnHover {
		// display: block;
		// width:100%;
		// text-align:right;
	}
	.showOnHover{
		display:none;
		/* position: absolute;
		right: 0;
		 top: 0; */
		 
		}
	 .showOnHoverDia{
		 /* display:none; */
		 position: absolute;
		 right: 0;
		 top: 0;
		 float:right;
		 
	 }

`;
const BoxTiempoStyled = styled.div`
	display:flex;
	flex-direction:column;
	justify-content:space-between;
	// align-items:center;
	padding: 10px;
	// width: 25%;
	// border: 1px solid #bbb;
	box-shadow: 1px 4px 3px 0px rgba(204,202,202,0.75);
	-webkit-box-shadow: 1px 4px 3px 0px rgba(204,202,202,0.75);
	-moz-box-shadow: 1px 4px 3px 0px rgba(204,202,202,0.75);
	margin: 3px;
	border-radius: 5px;
	:hover  .showOnHover {
		// display: block;
		/* float:right; */
		width:100%;
		text-align:right;
 	}
	 .showOnHover{
		 display:none;
		 
	 }

`;
function TiempoComida({isAi, isEqDieta, tiempoComida, idx_dia, idx_tiempo, agregarAlimentos, dieta, optionsSelect, recalcularTiempo, recalcularManualmente, setDieta}){
	return <>
		<BoxTiempoStyled style={{width:`${100/dieta.length}%`}}>
 

			<BoxTiempo isAi={isAi} isEqDieta={isEqDieta} tiempoComida={tiempoComida} recalcularManualmente={(idx)=>recalcularManualmente(idx_dia, idx_tiempo, idx)} idx_tiempo={idx_tiempo} idx_dia={idx_dia} setDieta={setDieta} />
			<BoxVacio isAi={isAi} tiempoComida={tiempoComida} agregarAlimentos={agregarAlimentos} idx_tiempo={idx_tiempo} idx_dia={idx_dia} dieta={dieta} optionsSelect={optionsSelect} recalcularTiempo={recalcularTiempo} />

			
			
		</BoxTiempoStyled>
	</>;
}
function BoxTiempo({tiempoComida, recalcularManualmente, idx_dia, idx_tiempo, setDieta, isAi, isEqDieta}){
	return <div>
		{(tiempoComida || []).map((alimento, idx_alimento)=><Alimento isAi={isAi} isEqDieta={isEqDieta} setDieta={setDieta} key={idx_alimento} alimento={alimento}  recalcularManualmente={recalcularManualmente} idx_dia={idx_dia} idx_tiempo={idx_tiempo} idx_alimento={idx_alimento} />)}
	</div>;
}
function isNumber(value) {
   return typeof value === 'number' && isFinite(value) && value>0;
}
function Alimento({alimento, idx_alimento, idx_dia, idx_tiempo, recalcularManualmente, setDieta, isAi, isEqDieta}){
	const [isEditing, setIsEditing] = React.useState(false);
	const [value, setValue] = React.useState(alimento);
	React.useEffect(()=>{
		setValue(alimento)
	},[alimento]);
	const onChangeCantidad = (idx, value) => {
		const numberValue = +value;
		setValue(v=>{
			const alimentos = v.alimentos.map((val, i)=>{
				if(i===idx) {
					const oldAlimento = alimento.alimentos[i];
					const oldValue = oldAlimento?.cant;

					const kcal = numberValue*oldAlimento.kcal/oldValue;
					const prot = numberValue*oldAlimento.prot/oldValue;
					const lip = numberValue*oldAlimento.lip/oldValue;
					const hidr = numberValue*oldAlimento.hidr/oldValue;
					const gram = numberValue*oldAlimento.gram/oldValue;

					const eq = multObject(alimento.alimentos[i].eq, value/oldValue);
					return {...val, kcal, prot, lip, hidr, gram, eq, cant:value};
				}
				return val;
			})
			const eq = calcularEqFromAlimentos(alimentos);
			const macros = calcularMacrosFromAlimentos(alimentos)
			return {...v, ...macros, alimentos, eq}
		});
	}
	const isValid = ()=>{
		if(value?.cant<=0)return false
		for(let i=0;i<value.alimentos?.length;i++){
			const alimento = value.alimentos[i];
			if(alimento?.cant<=0)return false
		}
		return true;
	}
	const onChangePorcion = (value) => {
		const numberValue = +value;
		setValue(v=>{
			const mult = numberValue/alimento?.cant;
			const oldValue = alimento?.cant;
			const kcal = numberValue* (alimento.kcal||0)/oldValue;
			const prot = numberValue* (alimento.prot||0)/oldValue;
			const lip =	 numberValue* (alimento.lip||0)/oldValue;
			const hidr = numberValue* (alimento.hidr||0)/oldValue;
			const gram = numberValue* (alimento.gram||0)/oldValue;
			const eq = deepMult(alimento.eq, mult)

			return {...v, kcal, prot, lip, hidr, gram, cant:value, eq};
		});
	}
	const eliminar = (idx) => {
		setValue(v=>{
			const alimentos = v.alimentos.filter((val, i)=>{
				if(i===idx) return false
				return true;
			})
			const macros = calcularMacrosFromAlimentos(alimentos)
			const eq = calcularEqFromAlimentos(alimentos);
			return {...v, ...macros, alimentos, eq}
		});
	}
	const calcularMacrosFromAlimentos = (alimentos)=>{
		return alimentos.reduce((acc, val)=>{
			return {
				kcal: acc.kcal+ (val.kcal||0),
				prot: acc.prot+ (val.prot||0),
				hidr: acc.hidr+ (val.hidr||0),
				lip: acc.lip+ (val.lip||0),
			}
		},{kcal:0, prot:0, lip:0, hidr:0});
	}
	const calcularEqFromAlimentos = (alimentos, cant)=>{
		return alimentos.reduce((acc, val)=>{
			return sumProperties(acc, val.eq)
		},{});
	}
	const guardar = () => {
		
		if(!isValid()){
			swal({
				title: "Error",
				text:  "No se pueden guardar alimentos en 0, si deseas eliminarlo da click en la x",
				icon: "warning",
				buttons: [ "Confirmar"],
				dangerMode: true,
			})
			return;
		}
		setDieta(draft => {
			draft.tiempos_comida[idx_tiempo].menu[idx_dia][idx_alimento] = value
		});
		setIsEditing(false);
	}
	const cancelar = () => {
		setValue(alimento)
		setIsEditing(false);
	}

	return <div  style={{marginBottom: 10, fontSize: "10pt", display: "flex", alignItems: "flex-start"}}>
		<p style={{marginRight: 5}}>&#8226;</p>
		<AlimentoStyled style={{display:"flex", flexDirection:"column"}}>
			{!isEditing && <> 
				<div className="recalcular">
					{isAi &&<Tooltip title="Ver intercambios" onClick={()=>recalcularManualmente(idx_alimento)}>
						<img src={intercambiosIcon} />
					</Tooltip>}
					<Tooltip title="Editar" onClick={()=>setIsEditing(true)}>
						<img src={editIcon} style={{height:20, objectFit:"contain", padding:"0 1px 0 1px", color:"white", borderRadius:3}} />
					</Tooltip>
				</div>
			</>}
			{isEditing && <div className="save-buttons">
				<Tooltip title="Cancelar" onClick={cancelar}>
					<CancelIcon style={{width:20, color:"red"}} />
				</Tooltip>
				<Tooltip title="Guardar" onClick={guardar}>
					<SaveIcon style={{width:20, color:"green"}} />
				</Tooltip>
			
			</div>}
			<div style={{display:"flex", alignItems:"center", gap:3}}>
				{isEqDieta && (!alimento.alimentos || alimento.alimentos.length===0 ) && <IconEq eq={alimento.eq} />}
				{isEditing ? <SmallInput placeholder={alimento?.cant} type="number" onChange={v=>onChangePorcion(v)} value={value?.cant} /> : `${decimalToFraction(alimento?.cant)}
				${correctedUnidad(alimento.unidad)}`} de {!isEditing ? alimento.nombre: <SmallTextarea style={{width:"unset"}} onChange={newV=>setValue(v=>({...v, nombre:newV}))} value={value.nombre} />} {alimento.equiv && "o " + alimento.equiv.map(val=>`${decimalToFraction(val?.cant)} ${correctedUnidad(val.unidad)} de ${val.nombre}`).join(" o ")}
			</div>
			<div style={{paddingLeft:5, color:"gray", fontSize:".9em", fontStyle:"italic"}}>
				{value?.alimentos?.map((a, subIdx)=><div key={subIdx} style={{display:"flex", alignItems:"center", gap:3}}>
					- {isEqDieta && <IconEq eq={a.eq} cant={alimento?.cant} />}{isEditing ? 
						<SmallInput type="number" placeholder={alimento.alimentos[subIdx]?.cant}  onChange={v=>onChangeCantidad(subIdx, v)} value={a?.cant} /> 
					:
						`${decimalToFraction(a?.cant*alimento?.cant)}`
					} {a.unidad ?? ""} de {a.nombre} {isEditing && <Tooltip title="Eliminar" onClick={()=>eliminar(subIdx)}><ClearIcon style={{width:20, color:"red"}} /></Tooltip>}
				</div>)}
			</div>
		</AlimentoStyled>
	</div>
}

const multObject = (obj, multiplier) => {
	return Object.fromEntries(
			Object.entries(obj||{}).map(([key, value]) => {
					// Check if value is an object and recursively call multObject
					if (typeof value === "object" && value !== null) {
							return [key, multObject(value, multiplier)];
					}
					// Check if value is a number before multiplying
					if (typeof value === "number") {
							return [key, value * multiplier];
					}
					// Return the value as is if it's not a number
					return [key, value];
			})
	);
};


function SmallInput({onChange, value, style={}, type="text"}){
	const initial = React.useRef(value);
	return <SmallInputStyled><input type={type} style={{ width: 30, border: "1px solid lightgrey", borderRadius: 5, ...style}} onChange={(e)=>onChange(e.target.value)} value={value||""} placeholder={initial.current} /></SmallInputStyled>
}
function SmallTextarea({onChange, value, style={}}){
	const initial = React.useRef(value);
	return <textarea style={{ width: 30, border: "1px solid lightgrey", borderRadius: 5, ...style}} onChange={(e)=>onChange(e.target.value)} value={value||""} placeholder={initial.current} />
}
function correctedUnidad(u=""){
	if(u==="porcion") return "porción";
	return u;
}
function decimalToFraction(dec){
	switch(dec){
		case .5:return "1/2";
		case .25:return "1/4";
		case .75:return "3/4";
		case .2:return "1/5";
		case .33:return "1/3";
		case .67:return "2/3";
	}
	return dec;
}

function BoxVacio({isAi, idx_dia, idx_tiempo, agregarAlimentos, tiempoComida, dieta, optionsSelect, recalcularTiempo}){
	const [openDialog, closeDialog] = useDialog();

	function agregarAlimento(){
		console.log("agregarAlimento")
		openDialog({
			onClose:(alimentos)=>{
				
				if(alimentos){
					agregarAlimentos([[idx_dia, idx_tiempo]], alimentos);
				}
			},
			disableBackdropClick:true,
			Content: AgregarAlimento,
			dieta,
			extraInfo: {idx_dia, idx_tiempo},
			alimentos: tiempoComida,
			optionsSelect: optionsSelect,
			width:"lg"
			
		});
	}
	function copiar(){
		openDialog({
			onClose:(to)=>{
				if(to) agregarAlimentos(to, tiempoComida);
			},
			Content: SelectCopy,
			dieta,
			width:"md"
		});
	}
	return <>
		<div className="avoid-print" style={{marginTop:10, display:"flex", justifyContent:"flex-end", alignItems:"center"}}>
			{/* <div> */}
			{isAi && <AiGenerateButton onClick={()=>recalcularTiempo(idx_dia, idx_tiempo)}/>}
			<EditButton onClick={agregarAlimento}/>
			<FileCopyButton onClick={copiar}/>
			
		</div>
	</>;
}
const DetallesButton = ({onClick}) => (
	<Tooltip title="Ver detalles">
		<img src={chartIcon} style={{height:20, objectFit:"contain", padding:"0 1px 0 1px", color:"white", borderRadius:3, marginRight:5}} onClick={onClick} />
	</Tooltip>
);
const EditButton = ({onClick}) => (
	<Tooltip title={i18n.t("common:dieta_menu_select_dieta_editar_ver_detalles")}>
		<img src={editIcon} style={{height:20, objectFit:"contain", padding:"0 1px 0 1px", color:"white", borderRadius:3, marginRight:5}} onClick={onClick} />
		{/* <Edit style={{width:20, height:20, background:"#A5DD72", padding:"0 1px 0 1px", color:"white", borderRadius:3, marginRight:5}} onClick={onClick} /> */}
	</Tooltip>
);

const FileCopyButton = ({onClick}) => (
	<Tooltip title={i18n.t("common:dieta_menu_select_dieta_copiar")}>
		<img src={copyIcon} style={{ height:20, objectFit:"contain", padding:"0 1px 0 1px", color:"white", borderRadius:3, marginRight:5}} onClick={onClick} />
		{/* <FileCopy style={{width:20, height:20, background:"#A5DD72", padding:"0 1px 0 1px", color:"white", borderRadius:3, }} onClick={onClick} /> */}
	</Tooltip>
);
const AiGenerateButton = ({onClick}) => {
	const [loading, setLoading] = React.useState();
	const click = async () => {
		setLoading(true)
		await onClick()
		setLoading(false)
	}
	if(loading) return <CircularProgress />
	return <Tooltip title="Recalcular">
		<img src={aiGenerateIcon} style={{ height:20, objectFit:"contain", padding:"0 1px 0 1px", color:"white", borderRadius:3, marginRight:5}} onClick={click} />
		{/* <FileCopy style={{width:20, height:20, background:"#A5DD72", padding:"0 1px 0 1px", color:"white", borderRadius:3, }} onClick={onClick} /> */}
	</Tooltip>
};

const ConfiguracionApp = ({dieta, setDieta, setModalChangeDistribucion, onChangeData, isAi}) => {
	return <>
		<ModalButton variant="outlined" modalProps={{dieta, setDieta, setModalChangeDistribucion, onChangeData, isAi}} modal={ModalConfiguracionApp} label={<Button className="avoid-print" variant="outlined" color="secondary">Configuración para el paciente</Button> } />
	</>
}
const ModalConfiguracionApp = (props) => {
	const {dieta, setDieta, setModalChangeDistribucion, onChangeData, closeModal} = props
	const {t} = useTranslation("common");

	return <>
		{dieta?.subtipo==="eq" ? <>
			<div style={{fontWeight:"bold", marginBottom:10}}>Lista de equivalencias</div>
			<Radio options={[
					{label: "No", value: "no"},
					{label: "Si", value: "smae"},
				]} value={dieta.mostrar_equivalentes} name="mostrar_equivalentes" onChange={onChangeData}/>
			{dieta.mostrar_equivalentes==="smae" && <Ref categorySelector={EQUIVALENT_SMAE_LIST} default="-1" extraItems={[{nombre:t("todos"),_id:"-1"}]} selector={(state)=>state.EQUIVALENT_SMAE_LIST.data} label={t("carpeta_dietas_lista_equivalencias_smae")} value={dieta?.lista_equivalencias_smae} onChange={onChangeData} name="lista_equivalencias_smae" />}
		</>: <>
			<div style={{fontWeight:"bold"}}>{t("carpeta_dietas_mostrar_equivalentes")}</div>
			<Radio options={[
					{label: "No", value: "no"},
					{label: t("carpeta_dietas_equivalentes_simplificados"), value: "simplificado"},
					{label: t("carpeta_dietas_equivalentes_smae"), value: "smae"},
				]} value={dieta.mostrar_equivalentes} name="mostrar_equivalentes" onChange={onChangeData}/>
			{dieta.mostrar_equivalentes==="simplificado" && <Ref categorySelector={EQUIVALENT_LIST} default="-1" extraItems={[{nombre:t("todos"),_id:"-1"}]} selector={(state)=>state.EQUIVALENT_LIST.data} label={t("carpeta_dietas_lista_equivalencias")} value={dieta?.lista_equivalencias} onChange={onChangeData} name="lista_equivalencias" />}
			{dieta.mostrar_equivalentes==="smae" && <Ref categorySelector={EQUIVALENT_SMAE_LIST} default="-1" extraItems={[{nombre:t("todos"),_id:"-1"}]} selector={(state)=>state.EQUIVALENT_SMAE_LIST.data} label={t("carpeta_dietas_lista_equivalencias_smae")} value={dieta?.lista_equivalencias_smae} onChange={onChangeData} name="lista_equivalencias_smae" />}
		</>}
		
		
		
		<div style={{fontWeight:"bold", marginTop:20}}>{t("smae_dieta_seleccion_seleccion_vista")}</div>
		<div className="avoid-print" style={{display:"grid", gridTemplateColumns:"1fr 1fr"}}>
			<FormControlLabel 
				control={<Checkbox color="secondary" checked={dieta?.vis_pac?.ene_dia} onChange={e=>setDieta(draft=>{draft.vis_pac.ene_dia = e.target?.checked;})} value={dieta?.vis_pac?.ene_dia} />}
				label={t("dieta_menu_select_dieta_kcal_totales_dia")}
			/>
			<FormControlLabel 
				control={<Checkbox color="secondary" checked={dieta?.vis_pac?.ene_alim} onChange={e=>setDieta(draft=>{draft.vis_pac.ene_alim = e.target?.checked;})} value={dieta?.vis_pac?.ene_alim} />}
				label={t("dieta_menu_select_dieta_kcal_totales_alimento")}
			/>
			{dieta?.subtipo==="eq" && <>
				<FormControlLabel 
					control={<Checkbox color="secondary" checked={dieta?.vis_pac?.mostrar_puntos_dia} onChange={e=>setDieta(draft=>{draft.vis_pac.mostrar_puntos_dia = e.target?.checked;})} value={dieta?.vis_pac?.mostrar_puntos_dia} />}
					label="Equivalencias por dia"
				/>
				<FormControlLabel 
					control={<Checkbox color="secondary" checked={dieta?.vis_pac?.mostrar_puntos_alim} onChange={e=>setDieta(draft=>{draft.vis_pac.mostrar_puntos_alim = e.target?.checked;})} value={dieta?.vis_pac?.mostrar_puntos_alim} />}
					label="Equivalencias por alimento"
				/>
			</>}
		</div>
		
		{props.isAi && <div>
			<div style={{fontWeight:"bold", marginTop:20}}>Mostrar intercambios automaticos al paciente</div>
			<Radio options={[
				{label: "No", value: false},
				{label: "Si", value: true},
			]} value={dieta.mostrar_intercambios_automaticos_app} name="mostrar_intercambios_automaticos_app" onChange={onChangeData}/>
		</div>}

		<div style={{fontWeight:"bold", marginTop:20}}>{t("carpeta_dietas_mostrar_lista_compras")}</div>
		<Radio options={[
				{label: "No", value: "no"},
				{label: "Si", value: "si"},
			]} value={dieta.mostrar_lista_compras} name="mostrar_lista_compras" onChange={onChangeData}/>
		{dieta.mostrar_lista_compras==="si" && <RefListaCompras value={dieta?.lista_compras} dieta={dieta} onChange={onChangeData} name="lista_compras" />}

		{dieta?.subtipo==="eq" ? <>
			<div style={{fontWeight:"bold", marginTop:20}}>Mostrar equivalencias en impresión</div>
				<Radio default={true} options={[
					{label: "No", value: false},
					{label: "Si", value: true},
				]} value={dieta.imprimir_equivalencias} name="imprimir_equivalencias" onChange={onChangeData}/>
		</> : <>
			<div style={{fontWeight:"bold", marginTop:20}}>Mostrar macros en impresión</div>
				<Radio default={true} options={[
					{label: "No", value: false},
					{label: "Si", value: true},
				]} value={dieta.imprimir_macros} name="imprimir_macros" onChange={onChangeData}/>
		</>}
		<div style={{float:"right", marginTop:20}}>
			<Button variant="outlined" color="secondary" onClick={closeModal}>Guardar</Button>

		</div>
	</>
}

function Intercambios({original, alimentos, onClose, isEqDieta}){
	return <IntercambiosTable>
		<div style={{float:"right",marginTop:"-10px"}}>
			<IconButton onClick={()=>onClose()}><CloseIcon /></IconButton>
		</div>
		<div style={{textAlign:"center", fontSize:"1.3em", marginBottom:10}}>Alimento a intercambiar</div>
		<div style={{fontWeight:"bold", textAlign:"center", fontSize:"1.5em", marginBottom:10}}>{original.nombre}</div>
		{isEqDieta ? <div style={{margin:"auto", display:"flex", gap:5, justifyContent:"center"}}><IconEq size="lg" eq={original.eq} /></div>
		:
		<div className="original">
			{original.prot&&<div>
				<div>Proteinas</div>
				<div>{original.prot} gr</div>
			</div>}
			{original.lip && <div>
				<div>Lipidos</div>
				<div>{original.lip} gr</div>
			</div>}
			{original.hidr && <div>
				<div>Hidratos de carbono</div>
				<div>{original.hidr} gr</div>
			</div>}
			{original.kcal && <div>
				<div>Kcal</div>
				<div>{original.kcal} kcal</div>
			</div>}
		</div>}
		<table>
			<tr>
				<th>Alimento</th>
				{isEqDieta ? <th>Equivalencia</th> :<>
					<th>Proteinas</th>
					<th>Lípidos</th>
					<th>Hidratos de carbono</th>
					<th>Kcal</th>
				</>}
				<th></th>
			</tr>
			{alimentos.map((a, idx)=><tr key={idx}>
				<td>{a.nombre}</td>
				{isEqDieta ? <td><div style={{display:"flex", gap:5}}><IconEq size="md" eq={a.medidas.eq} /></div></td> : <>
					<td>{a.medidas.prot} {a.medidas.prot ? "gr" :""}</td>
					<td>{a.medidas.lip} {a.medidas.lip ? "gr":""}</td>
					<td>{a.medidas.hidr} {a.medidas.hidr ? "gr":""}</td>
					<td>{a.medidas.kcal} {a.medidas.kcal ? "kcal":""}</td>
				</>}
				<td><Button variant="outlined" color="secondary" onClick={()=>onClose({...a,tags:original.tags, cant:1, prot:a.medidas.prot, lip:a.medidas.lip, hidr:a.medidas.hidr, unidad:a.medidas.unidad, kcal:a.medidas.kcal, eq:a.medidas.eq})}>Seleccionar</Button></td>
			</tr>)}
		</table>
	</IntercambiosTable>
}
const IntercambiosTable = styled.div`
.original{
	background:#EFF4FF;
	display:flex;
	gap:20px;
	text-align:center;
	margin:auto;
	max-width:440px;
	justify-content:center;
	align-items:center;
	padding:20px;
	corner-radius:10px;
	div{
		min-width:100px;
	}
}
table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  font-family: Arial, sans-serif;
}
th {
  color: black;
  font-weight: normal;
  padding: 12px;
  border-bottom: 1px solid #ddd;
  border-right: none;
}
td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
// tr:nth-child(2) {
// 	background: #EFF4FF;
// }
td:last-child {
  border-right: none;
}
`
const AlimentoStyled = styled.div`
	position:relative;
	width:100%;
	// padding-right:40px;
	.recalcular{
		display:none;
		position:absolute;
		right:0;
		cursor:pointer;
		background:white;
		border-radius:4px;

		img{
			width:15px;
			height:15px;
			object-fit:contain;
		}
	}
	.save-buttons{
		display:block;
		position:absolute;
		right:0;
		top:-5px;
		cursor:pointer;
		background:white;
		border-radius:4px;

		img{
			width:15px;
			height:15px;
			object-fit:contain;
		}
	}
	&:hover .recalcular {
		display: flex; /* or inline, inline-block, etc., depending on your layout */
		gap:5px;
		align-items:center;
	}
`
const SmallInputStyled = styled.div`
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type=number] {
		-moz-appearance: textfield;
	}
`