import React, {useState} from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import {NUTRITIONIST} from "store/constants"
import api from "api";
import Nutriologo from "./Nutriologo";
import CrudTable from "components/Table/CrudTable/CrudTable";
import { post } from "api";
import swal from "@sweetalert/with-react";
import { Button } from "@mui/material";
import moment from "moment";

const Receta = props => {
    const elements = React.useMemo(()=>[
				{
					type: "calendarHeatmap",
					name: ["sesiones","asesorias", "subscripcion", "_created_date"],
					label: "Uso software",
					transformFunc:(data)=>{
						const sesiones = data.sesiones
						const asesorias = data.asesorias
						const subscripcion = data.subscripcion
						console.log({data})
						// data = data.reduce((acc, d) => ({ ...acc, ...d.sesiones }), {});

						const datesOfMonth = getDatesOfMonth();
						const results = [];
						datesOfMonth.forEach((date) => {
								const formattedDate = date.toISOString().split('T')[0];
								const formattedDate2 = moment(date).format("YYYY-MM-DD");
								results.push({
										date: formattedDate,
										count: sesiones[formattedDate2] ? sesiones[formattedDate2] / 3600 : 0
								});
						});
						const idx = findIndexOfDate(results, subscripcion.primera_fecha_pago)
						if(idx>=0){
							results[idx].event="contratacion"
						}
						console.log({data})
						const idx2 = findIndexOfDate(results, data._created_date)
						if(idx2>=0){
							results[idx2].event="registro"
						}

						for(let i=0;i<asesorias?.length;i++){
							const a = asesorias[i];
							if(a.fecha){
								const idx = findIndexOfDate(results, new Date(a.fecha))
								if(idx>=0){
									if(a.tipo==="Capacitacion"){
										results[idx].event="asesoria"
									}
									if(a.tipo==="capacitacion_g"){
										results[idx].event="asesoria_g"
									}
								}
							}
							
						}
						


						return results;

						// data = data.reduce((acc, d)=>({...acc, ...d.sesiones}), {});
						// return Object.entries(data||{}).map(([date, count]) => ({date, count:count/3600 }));
					},
					startDate: new Date(Math.max(moment(props.data.subscripcion.primera_fecha_pago).subtract(1,"month").toDate(), new Date().setMonth(new Date().getMonth() - 12)))
				},
        {
            type: "text",
            name: "clave",
            label: "Usuario",
        },
        {
            type: "text",
            name: "nombre",
            label: "Nombre",
        },
        {
            type: "text",
            name: "telefono",
            label: "Telefono",
				},
				{
					type: "bool",
					name: "subscripcion.gratis",
					label: "Gratis",
				},
        {
            type:'ref',
            name:'subscripcion.id_plan',
            label:'Plan contratado',
            selector:(state)=>state.PLAN.data
        },
        {
            type: "bool",
            name: "subscripcion.activo",
            label: "Subscripcion activa",
        },
        {
            type: "calendar",
            name: "subscripcion.vencimiento",
            label: "Vencimiento",
				},
				{
					type: "calendar",
					name: "subscripcion.primera_fecha_pago",
					label: "Primera fecha de pago",
				},
				{
					type: "calendar",
					name: "subscripcion.fecha_reconexion",
					label: "Fecha de reconexion",
				},
				{
					type: "calendar",
					name: "subscripcion.proxima_fecha_pago",
					label: "Proxima fecha de pago",
				},
				{
					type: "select",
					name: "subscripcion.tipo_pago",
					label: "Tipo de pago",
					options:[
						{label:"",value:""},
						{label:"Manual",value:"manual"},
						{label:"Anual",value:"anual"},
						{label:"Mensual",value:"mensual"},
						{label:"MSI",value:"msi"},
						{label:"Plazos",value:"plazos"},
					]
				},
				{
					type: "text",
					name: "payments_user.external_id",
					label: "Id de Stripe",
				},
				{
					type: "text",
					name: "recomendado_por",
					label: "Codigo de recomendacion",
				},
        {
						type: "text",
						subtype:"number",
            name: "subscripcion.limite_pacientes",
            label: "Limite de pacientes",
        },
        {
						type: "text",
						subtype:"number",
            name: "subscripcion.limite_nutriologos",
            label: "Limite de nutriologos",
        },
        {
						type: "text",
						subtype:"number",
            name: "subscripcion.limite_consultorios",
            label: "Limite de consultorios",
				},
				{
					type: "bool",
					name: "subscripcion.consultas_online",
					label: "Consultas online",
				},
				{
					type: "bool",
					name: "subscripcion.roles",
					label: "Roles",
				},
				{
					type: "select",
					name: "subscripcion.asesor",
					label: "Asesor",
					options:[
						{value:"", label:""},
						{value:"ericka", label:"Ericka"},
						{value:"brenda", label:"Brenda"},
						{value:"clara", label:"Clara"},
					]
				},
				{
					type: "select",
					name: "subscripcion.status_renovacion",
					label: "Status de renovacion",
					options:[
						{value:"", label:""},
						{label:"Va a renovar",value:"a_renovar"},
						{label:"No quiere renovar",value:"no_quiere_renovar"},
						{label:"Sin respuesta", value:"sin_respuesta"},
						{label:"Mas adelante", value:"mas_adelante"},
						{label:"Otros", value:"otro"},
					]
				},
				{
					type: "select",
					name: "subscripcion.motivo_no_renovacion",
					label: "Motivo de no renovacion",
					options:[
						{value:"", label:""},
						{label:"No da consultas",value:"no_da_consultas"},
						{label:"Personal",value:"personal"},
						{label:"Otro software", value:"otro_software"},
						{label:"Costo", value:"costo"},
						{label:"No se acomodo", value:"no_se_acomodo"},
					]
				},
				{
					type: "text",
					name: "subscripcion.notas_no_renovacion",
					label: "Notas de no renovacion",
				},
				{
					type: "text",
					multiline:true,
					name: "notas",
					label: "Notas",
				},
				{
					component: ButtonSendEmailPersonalizacion,
					name: "",
					label: "Enviar correo personalización",
					id_customer: props.data._id
				},
        {
            type: "bool",
            name: "personalizacion.activa",
            label: "Personalizacion activa",
				},
				{
					type:"multiGroup",
					name:"payments_user.charges",
					label:"Cargos",
					horizontal:true,
					fields:[
						{
							type: "calendar",
							name: "date",
							label: "Fecha",
						},
						{
							type: "text",
							name: "amount",
							label: "Cantidad",
						},
						{
							type: "select",
							name: "forma_pago",
							label: "Forma de pago",
							options:[
								{label:"Sin seleccionar",value:""},
								{label:"Tarjeta",value:"Tarjeta"},
								{label:"Transferencia",value:"Transferencia"},
							]
						},
						{
							type: "text",
							name: "description",
							label: "Notas",
							multiline:true,
						},
						{
							type: "text",
							name: "coupon",
							label: "Cupón utilizado",
              readOnly: true
						},
					]
				},
				{
					type:"multiGroup",
					name:"asesorias",
					label:"Asesorias",
					horizontal:true,
					fields:[
						{
							type: "calendar",
							name: "fecha",
							label: "Fecha",
						},
						{
							type: "select",
							name: "tipo",
							label: "Tipo",
							options:[
								{label:"Sin seleccionar",value:""},
								{label:"Whatsapp",value:"Whatsapp"},
								{label:"Llamada",value:"Llamada"},
								{label:"Capacitacion",value:"Capacitacion"},
								{label:"Capacitacion General",value:"capacitacion_g"},
							]
						},
						{
							type: "bool",
							name: "respondio",
							label: "Respondio",
						},
						{
							type: "text",
							name: "notas",
							label: "Notas",
							multiline:true,
						},
						
					]
				},
        {
            type: "multiIf",
						name: "*",
						formula: (p)=>p?.personalizacion?.activa,
            label: "Personalizacion",
            fields:[
                {
                    type: "color",
                    name: "personalizacion.color_principal",
                    label: "Color principal",
                },
                {
                    type: "color",
                    name: "personalizacion.color_secundario",
                    label: "Color secundario",
                },
                {
                    type: "color",
                    name: "personalizacion.color_menu_principal",
                    label: "Color de menu principal",
                },
                {
                    type: "color",
                    name: "personalizacion.color_menu_secundario",
                    label: "Color de menu secundario",
                },
                {
                    type: "file",
                    name: "personalizacion.imagen_fondo_1",
                    label: "Imagen de fonndo 1"
                },
                {
                    type: "file",
                    name: "personalizacion.imagen_fondo_2",
                    label: "Imagen de fondo 2"
                },
                {
                    type: "file",
                    name: "personalizacion.logo",
                    label: "Logo"
                },
                {
                    type: "file",
                    name: "personalizacion.logo_blanco",
                    label: "Logo en blanco"
                },
                {
                    type: "file",
                    name: "personalizacion.sass",
										label: "Hoja de estilos saas",
										accept:".css"
                }
            ]
        }
	],[]);
	

	const columns = React.useMemo(
    () => [
      {
        Header: "Nombre",
        accessor: "nombre"
      },
      {
        Header: "Telefono",
        accessor: "telefono"
      },
      {
        Header: "Correo",
        accessor: "correo"
      }
    ],
    []
	);
	
	const [nutriologos, setNutriologos] = useState([]);
	React.useEffect(()=>{
		loadNutritionists();
	},[])
	const loadNutritionists = () => {
		api.get(`admin/nutritionists/${props.data?._id}/`).then(n=>{
			setNutriologos(n.data);
		})
	}

  return (
    <>
      <ModalForm elements={elements} onChange={props.onChange} data={props.data} {...props}>
				{props.data?._id 
					&&
					<CrudTable
						_id={props.data?._id}
						data={nutriologos || []}
						status={{}}
						columns={columns}
						type={NUTRITIONIST}
						modal={Nutriologo}
						onInsert={loadNutritionists}
					/>
				}
			</ModalForm>
    </>
  );
};

export default Receta;


const ButtonSendEmailPersonalizacion = ({id_customer}) => <Button variant="contained" color="primary" onClick={() => post("/admin/send-email-subscription", {id_customer, type: "personalizacion"}).then(() => 
swal({
	text: "Mensaje enviado exitosamente",
	icon: "success",
}))}>Enviar</Button>


function getDatesOfMonth() {
	const now = new Date();
	const startOfMonth = moment().subtract(12, "month").toDate()
	const endOfMonth = new Date()

	let date = startOfMonth;
	const dates = [];

	while (date <= endOfMonth) {
			dates.push(new Date(date));
			date.setDate(date.getDate() + 1);
	}

	return dates;
}


function findIndexOfDate(array, targetDate) {
	if(!targetDate)return -1
	if(typeof targetDate==="string"){
		targetDate = new Date(targetDate)
	}
  // Ensure targetDate is in consistent format (e.g., a Date object)
  const searchDate = targetDate.toISOString().split('T')[0];
  
  // Find index
  const index = array.findIndex(obj => {

    return obj.date === searchDate;
  });

  return index;
}