import React, { useState } from "react";

import CalendarHeatmap from 'react-calendar-heatmap';
import { Tooltip as ReactTooltip } from 'react-tooltip'

import 'react-calendar-heatmap/dist/styles.css';
import moment from "moment";
function isFunction(param) {
  return typeof param === 'function';
}

const Text = props => {
	const value = props.value?.sesiones;
	const values = React.useMemo(()=>props.transformFunc(props.value),[value, props.transformFunc])
	const startDate = React.useMemo(()=>props.startDate ? isFunction(props.startDate) ? props.startDate(value): props.startDate : moment().startOf("month").toDate(), [props]);
	const endDate = React.useMemo(()=>props.endDate ? isFunction(props.endDate) ? props.startDate(value): props.endDate : moment().endOf("month").toDate(), [props]);
	return (
		<div style={{height:150}}>
			<CalendarHeatmap
			 	style={{height:150}}
				startDate={startDate}
				endDate={endDate}
				values={values}
				onClick={value => alert(`Clicked on value with count: ${value.count}`)}
				classForValue={value => {
					let classString="";
					if (value?.event) classString += value?.event+" ";
					// if (value?.asesoria_g) classString += 'asesoria_g ';
					// if (value?.contratacion) classString += 'contratacion ';

          if (!value) {
            return 'color-empty';
          }
					if(value.count<0.05)return classString+`color-empty`;

					// return `color-scale-1`;
					if(value.count>3)return classString+`color-scale-5`;
					if(value.count>2)return classString+`color-scale-4`;
					if(value.count>1)return classString+`color-scale-3`;
					if(value.count>.5)return classString+`color-scale-2`;
					if(value.count>.16)return classString+`color-scale-1`;
					return classString+`color-scale-1`;

        }}
				tooltipDataAttrs={value => {
          return {
            'data-tooltip-content': `${value?.event||""} ${value.date} - total uso: ${Number(value.count).toFixed(2)} hrs`,
						'data-tooltip-id':"my-tooltip"
          };
        }}
			/>
			<ReactTooltip id="my-tooltip" />
			
		</div>
	);
    
};

export default Text;

// { date: '2016-01-01', count: 12 },