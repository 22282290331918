import { loadRequest,insertRequest, updateRequest, trashRequest, untrashRequest, removeRequest } from "./generators";
import sub from "./subGenerators";
import { CUSTOMER, SUCURSAL, DIA_INHABIL } from "../constants";
import { all } from "redux-saga/effects";

const loadCustomers = loadRequest(CUSTOMER);
const insertCustomer = insertRequest(CUSTOMER);
const updateCustomer = updateRequest(CUSTOMER);
const trashCustomer = trashRequest(CUSTOMER);
const untrashCustomer = untrashRequest(CUSTOMER);
const removeCustomer = removeRequest(CUSTOMER);


const insertSucursal = sub.insertRequest(SUCURSAL);
const updateSucursal = sub.updateRequest(SUCURSAL);
const trashSucursal = sub.trashRequest(SUCURSAL);
const untrashSucursal = sub.untrashRequest(SUCURSAL);
const removeSucursal = sub.removeRequest(SUCURSAL);

const insertDia = sub.insertRequest(DIA_INHABIL);
const updateDia = sub.updateRequest(DIA_INHABIL);
const trashDia = sub.trashRequest(DIA_INHABIL);
const untrashDia = sub.untrashRequest(DIA_INHABIL);
const removeDia = sub.removeRequest(DIA_INHABIL);


function* rootSaga() {
	yield all([
		loadCustomers(),
		insertCustomer(),
		updateCustomer(),
		trashCustomer(),
		untrashCustomer(),
		removeCustomer(),
		insertSucursal(),
		updateSucursal(),
		trashSucursal(),
		untrashSucursal(),
		removeSucursal(),
		insertDia(),
		updateDia(),
		trashDia(),
		untrashDia(),
		removeDia(),
	]);
}
export default rootSaga;