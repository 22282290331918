import React from "react";


export default function TextCell({cell}){
	console.log({cell})
	return (
		<>
			<div style={{...cell.column.style}}>{cell.column.op ? cell.column.op(Number(cell.value)) : Number(cell.value).toFixed(2)}</div>
		</>
	);
}