import React, { useRef } from "react";
import "./DietaEquivalenciasSmae.scss";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import DietaEquivalencias from "./Dieta";
import PrintButton from "components/Buttons/PrintButton/PrintButton";
import HeaderPrint from "components/Buttons/PrintButton/HeaderPrint";
import PropTypes from "prop-types";
import { EQUIVALENT_SMAE_DIET } from "store/constants";


const Equivalencias = (props) => {


	const elements = React.useMemo(()=>[
		{
			type: "bool",
			name: "u",
			label: "Unlocked",
		},
		{
			type: "text",
			name: "nombre",
			label: "Nombre",
		},
		{
			type: "text",
			name: "recomendaciones",
			label: "Recomendaciones generales",
			multiline:true
		},
		{
			type: "ref",
			name: "lista_equivalencias",
			label: "Lista de equivalentes",
			selector:(state)=>state.EQUIVALENT_SMAE_LIST.data,
			extraItems:[{nombre:"Todos",_id:"-1"}],
			default:"-1"
		}
	],[]);

	const ref = useRef(null);
	return (
		<>
			<div style={props.select && {float:"right"}} className="buttons-dietas">
				<PrintButton type={EQUIVALENT_SMAE_DIET.name} printRef={ref} />
			</div>
			{/* <div style={{display: "none"}}>
				<div className="printable" ref={ref}>
					<HeaderPrint data={props.data}/>
					<DietaEquivalencias value={props.data} onChange={props.onChange} />
				</div>
			</div> */}
			<div className="container-dieta" ref={ref}>
				<ModalForm elements={elements} onChange={props.onChange} data={props.data}></ModalForm>
				<div className="allow-print">
					<HeaderPrint data={props.data}/>
				</div>
				<DietaEquivalencias value={props.data} onChange={props.onChange} />
			</div>
		</>
	);
};

export default Equivalencias;


Equivalencias.propTypes = {
	data: PropTypes.object,
	onChange: PropTypes.func,
	select: PropTypes.bool
};
