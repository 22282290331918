import store from "store/reducers/index";
import api from "./index";
export function login(token){
	window.localStorage.setItem("nutrify:super", token);
	init();
}
export function init(){
	const token = window.localStorage.getItem("nutrify:super");
	console.log(token);
	if(token){
		api.init(token);

		store.dispatch({type:"SET_SESSION",payload:{logged:true}});
		const loadData = [
			// "COLUMNS_DIET",
			// "EQUIVALENT_DIET",
			// "EQUIVALENT",
			// "EQUIVALENT_LIST",
			// "EQUIVALENT_SUBCAT",
			// "EQUIVALENT_SMAE_DIET",
			// "EQUIVALENT_SMAE",
			// "EQUIVALENT_SMAE_LIST",
			// "EQUIVALENT_SMAE_SUBCAT",
			"PATIENTS",
			"PRIZE",
			"MENU_DIET",
			"MENU_SELECT_DIET",
			"MENU_SELECT_LIST",
			"OPTIONS_DIET",
			"RECEIPE",
			"RECEIPE_TAG",
			"RECEIPE_CATEGORY",
			"MESSAGE",
			// "CUSTOMER",
			"PLAN",
			"LICENCE_COUPON",
			"NUTRITIONIST",
			"PRODUCT",
			"PRODUCT_TAG",
			"PRODUCT_CATEGORY",
			"PERSONALIZED_DIET",
			"DISCOUNT",
			// "ONLINE_CONSULTATION",
			// "ONLINE_PATIENT",
			"NUTRITIONIST_NOTIFICATION",
			"NUTRITIONIST_NOTIFICATION_TAGS",
			"INFOGRAFIAS",
			"TUTORIALES"
		];
		loadData.map((key)=>store.dispatch({ type: `LOAD_${key}` }));
	}
	else{
		store.dispatch({type:"SET_SESSION",payload:{logged:false}});
	}
}
export function refresh(key){
	if(key){
		store.dispatch({ type: `LOAD_${key.name}` });
		return;
	}
	const refreshData = [
		"COLUMNS_DIET",
		"EQUIVALENT_DIET",
		"EQUIVALENT",
		"EQUIVALENT_LIST",
		"EQUIVALENT_SUBCAT",
		"EQUIVALENT_SMAE_DIET",
		"EQUIVALENT_SMAE",
		"EQUIVALENT_SMAE_LIST",
		"EQUIVALENT_SMAE_SUBCAT",
		"PATIENTS",
		"PRIZE",
		"MENU_DIET",
		"MENU_SELECT_DIET",
		"MENU_SELECT_LIST",
		"OPTIONS_DIET",
		"RECEIPE",
		"RECEIPE_TAG",
		"RECEIPE_CATEGORY",
		"MESSAGE",
		// "CUSTOMER",
		"PLAN",
		"LICENCE_COUPON",
		"NUTRITIONIST",
		"PRODUCT",
		"PRODUCT_TAG",
		"PRODUCT_CATEGORY",
		"DISCOUNT",
		// "ONLINE_CONSULTATION",
		// "ONLINE_PATIENT",
		"NUTRITIONIST_NOTIFICATION",
		"NUTRITIONIST_NOTIFICATION_TAGS",
		"INFOGRAFIAS",
		"TUTORIALES"


	];
	refreshData.map((key)=>store.dispatch({ type: `LOAD_${key}` }));
}
export function getNutitionistId(){
	const token = window.localStorage.getItem("nutrify:super");
	if(token){
		const jwtData = parseJwt(token);
		console.log(jwtData);
		return jwtData.id_nutriologo;
	}else{
		return -1;
	}
}
export function parseJwt (token) {
	var base64Url = token.split(".")[1];
	var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	var jsonPayload = decodeURIComponent(atob(base64).split("").map(function(c) {
		return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(""));

	return JSON.parse(jsonPayload);
}

export default {login,init, parseJwt};