import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Routes from "./routes/Routes";
import store from "./store/reducers/index";
// import { ThemeProvider } from '@mui/styles';
import { enableMapSet } from 'immer';
import 'react-tooltip/dist/react-tooltip.css'

import DialogContext from "context/DialogContext";
import Theme from "./Theme";
import { useQuery } from 'react-query'
enableMapSet()

function App() {
	const { isLoading, error, data } = useQuery("equivalenciasMenu", () => fetch("/Final.json").then((res)=>res.json()) );

  return (
    // <ThemeProvider theme={Theme}>
			<DialogContext>
				<Router>
					<Switch>
						<Routes />
					</Switch>
				</Router>
			</DialogContext>
    // </ThemeProvider>
  );
}

export default App;
