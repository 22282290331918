import React from "react";
import CrudTable from "components/Table/CrudTable/CrudTable";
import Cupon from "./Cupon";
import { useSelector } from "react-redux";
import {LICENCE_COUPON} from 'store/constants'
import DateCell from "components/Table/CellTypes/DateCell/DateCell";
import BoolCell from "components/Table/CellTypes/BoolCell/BoolCell";
import Header from "components/Header/Header";

const TablaCupones = props => {
  const data = useSelector(state => state["LICENCE_COUPON"]);
  const columns = React.useMemo(
    () => [
      {
        Header: "Código",
        accessor: "codigo"
      },
      {
        Header: "Nombre",
        accessor: "nombre"
      },
      {
        Header: "Descripción",
        accessor: "descripcion"
      },
      {
        Header: "Tipo de pago",
        accessor: "tipo_pago"
      },
      {
        Header: "% Descuento",
        accessor: "p_descuento"
      },
      {
        Header: "Fecha Inicio",
        accessor: "fecha_inicio",
				Cell: DateCell
      },
      {
        Header: "Fecha Fin",
        accessor: "fecha_fin",
				Cell: DateCell
      },
      {
        Header: "Solo clientes nuevos",
        accessor: "solo_clientes_nuevos",
        Cell: BoolCell
      },
      {
        Header: "Máximo usos cliente",
        accessor: "max_usos_cliente"
      },
      {
        Header: "Máximo usos totales",
        accessor: "max_usos"
      },
    ],
    []
  );

  const filters = React.useMemo(
    () => [
      {
        Header: "Código",
        accessor: "codigo"
      },
      {
        Header: "Nombre",
        accessor: "nombre"
      },
      {
        Header: "Descripción",
        accessor: "descripcion"
      },
      {
        Header: "Tipo de pago",
        accessor: "tipo_pago",
        filter:"select",
        filterOptions:[
          {value:"",label: "Todos"},
          {value:"year",label: "Anual"},
          {value:"month",label: "Mensual"}
        ]
      }
    ],
    []
  );

  return (
    <>
			<Header title="Cupones" />
      <CrudTable
        data={data.data.filter(val=>val?.show)}
        status={data.view}
        columns={columns}
				filters={filters}
        type={LICENCE_COUPON}
        modal={Cupon}
        options={{
          disableEdit: true
        }}
      />
    </>
  );
};

export default TablaCupones;
